<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form [formGroup]="form">
  <div mat-dialog-content class="calibrates-theme">

    <div fxLayout="row" class="flexwrap">

      <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="data.isDisabled">
        <mat-form-field class="campoFormulario">
          <input matInput formControlName="equipment" [ngModel]="data.equipment.equipment" #trigger="matAutocompleteTrigger" [matAutocomplete]="auto"
            placeholder="{{ 'protocolEdit.dialog.equipment.equipment.label' | translate }}"
            (keyup)="lookupEquipment($event)" required>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
            (optionSelected)="onEquipmentChange($event, trigger)">
            <mat-option *ngFor="let item of equipmentAutoComplete; let index = index" [value]="item">
              {{ item.name}} ({{item.serialNum}})
            </mat-option>
          </mat-autocomplete>
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.equipment.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex *ngIf="data.isDisabled">
        <a class="under-card-link" style="cursor: pointer;margin: inherit;" (click)="createEquipment()">
          {{ 'calibrateEquipmentEdit.general.equipment.notExists' | translate }}
        </a>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.equipment.label' | translate }}" name="name" disabled
            [(ngModel)]="data.equipment.equipment">
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.maker.label' | translate }}"
            formControlName="maker" disabled [(ngModel)]="data.equipment.maker" [errorStateMatcher]="matcher">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.maker.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.model.label' | translate }}"
            formControlName="model" disabled [(ngModel)]="data.equipment.model" [errorStateMatcher]="matcher">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.model.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.serialNum.label' | translate }}"
            formControlName="serialNum" disabled [(ngModel)]="data.equipment.serialNum" [errorStateMatcher]="matcher">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.serialNum.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.internalId.label' | translate }}"
            formControlName="internalId" disabled [(ngModel)]="data.equipment.internalId" [errorStateMatcher]="matcher">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.internalId.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.location.label' | translate }}"
            formControlName="location" disabled [(ngModel)]="data.equipment.location" required [errorStateMatcher]="matcher">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.location.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

    </div>

  </div>
  <div mat-dialog-actions class="calibrates-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>