import * as _ from 'lodash-es';

import { InternalEquipment, InternalEquipmentFilter, InternalEquipmentFilterPerformance } from '../model/internalEquipment';
import { Observable, from, of } from 'rxjs';

import { DexieService } from './dexieService';
import { EquipmentAutocompleteFilter } from '../model/autocompleteFilter';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { InternalEquipmentStatusEnum } from '../model/internalEquipmentStatus';
import { OnlineService } from './online.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { InternalEquipmentPerformances } from '../model/internalEquipmentPerformances';

@Injectable()
export class InternalEquipmentService {

    constructor(private http: HttpClient,
        private dexieService: DexieService,
        private onlineService: OnlineService) {
    }

    findAll(filter: InternalEquipmentFilter): Observable<any> {
        if (this.onlineService.latestOnline) {
            const clonedFilter = _.cloneDeep(filter);

            const url = environment.thermalUrl + '/internalEquipment/';

            const customFields = {};

            if (clonedFilter.customFields) {
                (clonedFilter.customFields as Map<number, string>)?.forEach((val, key) => {
                    customFields[key] = val;
                });
            }

            clonedFilter.customFields = customFields;

            return this.http.post(url, clonedFilter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.internalEquipments.toArray());
        }
    }

    getExcel(filter: InternalEquipmentFilterPerformance): Observable<any> {
        const url = environment.thermalUrl + '/internalEquipment/excel';
        const clonedFilter = _.cloneDeep(filter);
        const customFields = {};

        if (clonedFilter.customFields) {
            (clonedFilter.customFields as Map<number, string>)?.forEach((val, key) => {
                customFields[key] = val;
            });
        }

        clonedFilter.customFields = customFields;

        return this.http.post(url, clonedFilter, { responseType: 'blob' });
    }

    findAutocomplete(filter: EquipmentAutocompleteFilter): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.thermalUrl + '/internalEquipment/autocomplete';

            return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.internalEquipments.where('name').startsWithAnyOfIgnoreCase(filter.query)
                .limit(filter.pageSize || 10).toArray());
        }
    }

    findOne(id: number): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.thermalUrl + `/internalEquipment/${id}`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.internalEquipments.where({ id }).toArray());
        }
    }

    findOneByName(name: string): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.thermalUrl + `/internalEquipment/name/${name}`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.internalEquipments.where({ name }).toArray()[0]);
        }
    }

    findOneBySerialNum(serialNum: string): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.thermalUrl + '/internalEquipment/serialNum/' + serialNum;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(new Promise((resolve, reject) => {
                this.dexieService.internalEquipments.where({ serialNum }).toArray().then((arr) => {
                    if (arr[0]) {
                        resolve(arr[0]);
                    } else {
                        reject();
                    }
                }).catch(() => reject());
            }));
        }
    }

    save(internalEquipment: InternalEquipment, reason: string): Observable<any> {
        let url = environment.thermalUrl + '/internalEquipment/';

        const form = new FormData();
        form.append('equipment', JSON.stringify(internalEquipment));
        form.append('reason', reason);
        if (internalEquipment.id != null) {
            url += internalEquipment.id;

            return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            url += 'create';

            return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        }
    }

    uploadMassive(data: InternalEquipment[]): Observable<any> {
        const url = environment.calibratesUrl + '/internalEquipment/create/massive';

        return this.http.post(url, data).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    activate(id: number, activate: boolean, reason: string): Observable<any> {
        return this.findOne(id).pipe(map(item => {
            const eq = item as InternalEquipment;
            eq.expansionDay = null;
            eq.extendedExpirationDate = null;
            eq.idStatus = activate ? InternalEquipmentStatusEnum.EN_USO : InternalEquipmentStatusEnum.FUERA_DE_USO;
            return this.save(eq, reason);
        }, err => {
            return of(err);
        }));
    }

    downloadPdf(id: number): Observable<any> {
        const url = environment.thermalUrl + `/report/internalEquipment/${id}`;

        return this.http.get(url, { responseType: 'blob' });
    }

    downloadAuditPdf(id: number): Observable<any> {
        const url = environment.thermalUrl + `/report/internalEquipment/${id}/audit`;

        return this.http.get(url, { responseType: 'blob' });
    }

    saveInternalEquipmentToDexie(): Promise<void> {
        const filter = new EquipmentAutocompleteFilter();

        filter.pageIndex = 0;
        filter.pageSize = 20000;
        filter.sortBy = 'name';
        filter.sortDirection = 'asc';
        filter.forExecution = true;

        return new Promise<void>((resolve) => this.findAutocomplete(filter).subscribe(res => {
            const content = res.content as InternalEquipment[];

            if (content) {
                void this.dexieService.internalEquipments.clear().then(() => {
                    content.forEach((element: InternalEquipment) => {
                        void this.dexieService.internalEquipments.put(element, element.id).then(() => resolve());
                    });
                });
            }
        }));
    }

    checkCopyToGroup(id: number, idGroup: number): Observable<any> {
        const url = environment.calibratesUrl + `/internalEquipment/clonate/${id}/toGroup/${idGroup}/check`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    copyToGroup(id: number, idGroup: number): Observable<any> {
        const url = environment.calibratesUrl + `/internalEquipment/clonate/${id}/toGroup/${idGroup}`;

        return this.http.put(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllPerformance(filter: InternalEquipmentFilterPerformance): Observable<any> {
        if (this.onlineService.latestOnline) {
            const clonedFilter = _.cloneDeep(filter);

            const url = environment.thermalUrl + '/internalEquipment/filter-performances/';

            const customFields = {};

            (clonedFilter.customFields as Map<number, string>).forEach((val, key) => {
                customFields[key] = val;
            });

            clonedFilter.customFields = customFields;

            return this.http.post(url, clonedFilter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.internalEquipments.toArray());
        }
    }

    getAllByGroup(idGroup: number, idEquipment: number): Observable<any> {

        if (!this.onlineService.latestOnline) {
            return of([]);
        }

        const url = `${environment.calibratesUrl}/internalEquipment/clonate/toGroup/${idGroup}/toEquipment/${idEquipment}/folder`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    copyToGroupWithIdFolder(id: number, idGroup: number, idFolder: number): Observable<any> {
        const url = environment.calibratesUrl + `/internalEquipment/clonate/${id}/toGroup/${idGroup}/folder/${idFolder}`;

        return this.http.put(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

}
