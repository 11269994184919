/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable max-len */
import * as _ from 'lodash-es';
import * as moment from 'moment';

import { ActivatedRoute, Router } from '@angular/router';
import {
  Calibration,
  CalibrationAsLeft,
  CalibrationResponsibleEnum,
  CalibrationStatus,
  CalibrationVariable,
  CalibrationVariableConfig,
  ExternalCalibration,
  ExternalCalibrationVariable,
  ICalibration
} from 'src/app/model/calibration';
import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Variable, VariableTypeEnum } from 'src/app/model/variable';

import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { AppType, AppTypeEnum } from 'src/app/model/appType';
import { AppTypeService } from 'src/app/services/appType.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { AttachmentCalibratesService } from 'src/app/services/attachmentCalibrates.service';
import { AttachmentThermalService } from 'src/app/services/attachmentThermal.service';
import { CalibrationResponsibleService } from 'src/app/services/calibrationResponsible.service';
import { CalibrationService } from 'src/app/services/calibration.service';
import { CalibrationUtils } from 'src/app/utils/calibratesUtils';
import { Client } from 'src/app/model/client';
import { ClientService } from 'src/app/services/client.service';
import { Constants } from 'src/app/utils/constants';
import { CustomDatepickerHeaderComponent } from '../../shared/datepicker-custom-header/datepicker-custom-header.component';
import { DateUtils } from 'src/app/utils/dateUtils';
import { ExternalCalibrationService } from 'src/app/services/externalCalibration.service';
import { GenericClass, GenericClassTranslate } from 'src/app/model/genericClass';
import { InternalEquipment } from 'src/app/model/internalEquipment';
import { InternalEquipmentCustomField } from 'src/app/model/internalEquipmentCustomField';
import { InternalEquipmentCustomFieldValue } from 'src/app/model/internalEquipmentCustomFieldValue';
import { InternalEquipmentCustomFieldsService } from 'src/app/services/internalEquipmentCustomFields.service';
import { InternalEquipmentEditCreateCalibrationComponent } from './internal-equipment-edit-create-calibration.component';
import { InternalEquipmentFolder } from 'src/app/model/internalEquipmentFolder';
import { InternalEquipmentFolderService } from 'src/app/services/internalEquipmentFolderService';
import { InternalEquipmentService } from 'src/app/services/internalEquipment.service';
import { InternalEquipmentStatusEnum } from 'src/app/model/internalEquipmentStatus';
import { InternalEquipmentStatusService } from 'src/app/services/internalEquipmentStatus.service';
import { MatDialog } from '@angular/material/dialog';
import { NumberUtils } from 'src/app/utils/numberUtils';
import { ReasonDialogComponent } from '../../shared/reason-dialog/reason-dialog.component';
import { RoleEnum } from 'src/app/model/roleUser';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StringUtils } from 'src/app/utils/stringUtils';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { VariableTypeService } from 'src/app/services/variableType.service';
import { saveAs } from 'file-saver';
import { takeUntil } from 'rxjs/operators';
import { ActionTypeService } from 'src/app/services/actionType.service';
import { InternalEquipmentPerformances, InternalEquipmentPerformancesDate } from '../../../model/internalEquipmentPerformances';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UsersService } from '../../../services/users.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { MatSelectionListChange } from '@angular/material/list';
import { FieldTypeEnum } from 'src/app/model/fieldType';
import { InternalEquipmentCustomSelectValueService } from '../../../services/internalEquipmentCustomSelectValue.service';
import { InternalEquipmentCustomSelectValue } from '../../../model/internalEquipmentCustomSelectValue';
import { EquipmentPerformances } from '../../../utils/equipmentUtils';
import { IsothermalCharacterizationService } from 'src/app/services/isothermalCharacterization.service';
import { ExecutionService } from 'src/app/services/execution.service';
import { MatTable } from '@angular/material/table';
import { InternalEquipmentEditDatePerformancesComponent } from './internal-equipment-edit-date-performances/internal-equipment-edit-date-performances.component';
import { RoleUser } from '../../../model/roleUser';

@Component({
  selector: 'app-internal-equipment-edit',
  templateUrl: './internal-equipment-edit.component.html'
})
export class InternalEquipmentEditComponent implements OnInit, OnDestroy {
  equipment: InternalEquipment = new InternalEquipment();
  equipmentCalibration: InternalEquipmentPerformances = new InternalEquipmentPerformances();
  equipmentVerification: InternalEquipmentPerformances = new InternalEquipmentPerformances();
  equipmentMaintenance: InternalEquipmentPerformances = new InternalEquipmentPerformances();
  equipmentQualification: InternalEquipmentPerformances = new InternalEquipmentPerformances();
  equipmentCharacterization: InternalEquipmentPerformances = new InternalEquipmentPerformances();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChildren('asLeftTableMultiChannel') asLeftTableMultiChannel: MatTable<any> | QueryList<MatTable<any>>;

  returnType: string;
  idClientURL: number;

  statuses: GenericClass[];
  calibrationResponsibles: GenericClass[];
  appTypes: AppType[];
  variables: Variable[];

  clients: Client[];
  clientsFiltered: Client[];
  currentUser: User;
  folders: InternalEquipmentFolder[];

  calibrations: ICalibration[];
  verifications: ICalibration[];
  maintenances: ICalibration[];
  qualifications: ICalibration[];

  asLeftColsInt = ['number', 'point', 'pattern', 'average', 'correction', 'U'];
  asLeftColsExt = ['number', 'point', 'average', 'correction', 'U'];
  statusActions = [{translation: 'according', id: 1}, {translation: 'notAccording', id: 3}, {translation: 'pending', id: 3}];

  externalCalibration: ExternalCalibration;

  customDatepickerHeader = CustomDatepickerHeaderComponent;

  isEdit = false;
  isExternal = false;
  isInternal = false;
  pendingReview = false;

  mapCalibrationsAccordion: Map<number, boolean> = new Map();

  disableSupplier = true;
  extendedDay = true;
  stateOriginal: number;

  users: User[];
  usersFiltered: User[];

  isManager = false;

  customSelects: InternalEquipmentCustomSelectValue[] = [];

  actionTypes: GenericClassTranslate[];
  isCalibration: boolean;
  isVerification: boolean;
  isMaintenance: boolean;
  isQualification: boolean;
  isCharacterization: boolean;

  idsActions: number[] = [];
  idsActionSelected: number[] = [];

  isDataVerificationOpened= false;
  isDataCalibrationOpened= false;

  selectedRole: RoleUser;
  roles: RoleUser[];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private equipmentService: InternalEquipmentService,
    private internalEquipmentCustomFieldService: InternalEquipmentCustomFieldsService,
    private internalEquipmentStatusService: InternalEquipmentStatusService,
    private clientService: ClientService,
    private appTypeService: AppTypeService,
    private variableTypeService: VariableTypeService,
    private attachmentThermalService: AttachmentThermalService,
    private attachmentCalibratesService: AttachmentCalibratesService,
    private calibrationResponsibleService: CalibrationResponsibleService,
    private calibrationService: CalibrationService,
    private externalCalibrationService: ExternalCalibrationService,
    private isothermalCharacterizationService: IsothermalCharacterizationService,
    private executionService: ExecutionService,
    private userService: UserService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private folderService: InternalEquipmentFolderService,
    private actionTypeService: ActionTypeService,
    private usersService: UsersService,
    private internalEquipmentCustomSelectValueService: InternalEquipmentCustomSelectValueService) {
  }

  ngOnInit(): void {
    this.statuses = [];
    this.clients = [];
    this.appTypes = [];
    this.variables = [];
    this.calibrationResponsibles = [];
    this.equipment = new InternalEquipment();
    this.equipmentCalibration = new InternalEquipmentPerformances();
    this.equipmentMaintenance = new InternalEquipmentPerformances();
    this.equipmentQualification = new InternalEquipmentPerformances();
    this.equipmentVerification = new InternalEquipmentPerformances();
    const currentRolUser= this.userService.currentRoleUser;
    const currentProfile = this.userService.currentProfile;

    this.roles = currentRolUser;
    this.selectedRole =  currentRolUser.find(p => p.idGroup === currentProfile.idActiveProfile);
    this.currentUser = this.userService.currentProfile;
    this.isExternal = this.router.url.toLowerCase().includes('external');
    this.isInternal = this.router.url.toLowerCase().includes('internal');
    this.spinnerService.show();

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const id = params[Constants.FIELD_ID] as number;
      this.returnType = params[Constants.FIELD_RETURN] as string;
      this.idClientURL = params[Constants.FIELD_ID_CLIENT] as number;

      this.currentUser = this.userService.currentProfile;
      this.isExternal = this.router.url.toLowerCase().includes('external');
      this.isInternal = this.router.url.toLowerCase().includes('internal');
      if (id == null) {
        this.cancel();
      }

      if (+id === 0) {
        this.spinnerService.hide();

        if (this.isInternal) {
          this.internalEquipmentStatusService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => {
            if (!this.pendingReview) {
              data = data.filter(d => d.id !== InternalEquipmentStatusEnum.PENDING_REVIEW);
            }
            this.statuses = data.filter(d => d.id !== (InternalEquipmentStatusEnum.CONFORME || InternalEquipmentStatusEnum.NO_CONFORME));
          });
          this.appTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: AppType[]) => this.appTypes = data);
          this.calibrationResponsibleService.findAll().pipe(takeUntil(this.destroy$))
            .subscribe((data: GenericClass[]) => this.calibrationResponsibles = data);
        }
      } else {
        this.equipmentService.findOne(id).pipe(takeUntil(this.destroy$)).subscribe((result: InternalEquipment) => {
          if (result != null) {
            this.isEdit = true;
            this.equipment = result;
            this.pendingReview = this.equipment.idStatus === InternalEquipmentStatusEnum.PENDING_REVIEW;

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (this.equipment.performances?.length > 0) {
              const rCalibration = this.equipment.performances.find(t => t.idActionType === 1);

              if (rCalibration) {
                this.idsActions.push(1);
                this.equipmentCalibration = rCalibration;
                this.equipmentCalibration.processDate = DateUtils.anyToDate(this.equipmentCalibration.processDate);
                this.equipmentCalibration.expirationDate = DateUtils.anyToDate(this.equipmentCalibration.expirationDate);
                // this.isCalibration = true;
              }
              const rVerification = this.equipment.performances.find(t => t.idActionType === 2);

              if (rVerification) {
                this.idsActions.push(2);
                this.equipmentVerification = rVerification;
              }

              const rMaintenance = this.equipment.performances.find(t => t.idActionType === 3);

              if (rMaintenance) {
                this.idsActions.push(3);
                this.equipmentMaintenance = rMaintenance;
              }

              const rQualification = this.equipment.performances.find(t => t.idActionType === 4);

              if (rQualification) {
                this.idsActions.push(4);
                this.equipmentQualification = rQualification;
              }

              const rCharacterization = this.equipment.performances.find(t => t.idActionType === EquipmentPerformances.CHARACTERIZATION);

              if (rCharacterization) {
                this.idsActions.push(EquipmentPerformances.CHARACTERIZATION);
                this.equipmentCharacterization = rCharacterization;
              }

              this.idsActionSelected = [...this.idsActions];
            }

            this.onChangeActionType();

            this.stateOriginal = this.equipment.idStatus;
            this.disableSupplier = this.isExternal || this.equipment.responsible === CalibrationResponsibleEnum.INTERNAL;
            if (this.isInternal) {
              this.internalEquipmentStatusService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => {
                if (!this.pendingReview) {
                  data = data.filter(d => d.id !== InternalEquipmentStatusEnum.PENDING_REVIEW);
                }
                this.statuses = data.filter(d => d.id !== (InternalEquipmentStatusEnum.CONFORME || InternalEquipmentStatusEnum.NO_CONFORME));
              });
            }
            if (this.equipment.expansionDay) {
              this.extendedDay = true;
            } else {
              this.extendedDay = false;
            }

            this.internalEquipmentCustomFieldService.findAll().subscribe(res => {

              const fields = res.content as InternalEquipmentCustomField[];

              this.equipment.customFields = fields.map(f => {
                const originalField = this.equipment.customFields?.find(f1 => f1.idCustomField === f.id);
                const newValue = new InternalEquipmentCustomFieldValue();

                newValue.idCustomField = f.id;
                newValue.idType = f.idType;
                newValue.name = f.name;
                if (originalField != null) {
                  newValue.id = originalField.id;
                  newValue.value = originalField.value;
                }

                if (f.idType === FieldTypeEnum.SELECT_ONE) {
                  this.internalEquipmentCustomSelectValueService.findByIdField(f.id).subscribe(customSelect => {
                    newValue.valueSelect = customSelect;
                    this.customSelects = customSelect;
                  })
                }

                return newValue;
              });
            });
            this.loadCalibrations(id);
          } else {
            this.equipment = null;
          }
        }, () => {
          this.equipment = null;
        }, () => {
          if (this.equipment == null) {
            this.cancel();
          }
          this.spinnerService.hide();
        });

      }

      if (this.isInternal) {
        this.internalEquipmentStatusService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => {
          if (!this.pendingReview) {
            data = data.filter(d => d.id !== InternalEquipmentStatusEnum.PENDING_REVIEW);
          }
          this.statuses = data.filter(d => d.id !== (InternalEquipmentStatusEnum.CONFORME || InternalEquipmentStatusEnum.NO_CONFORME));
        });
        this.appTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: AppType[]) => this.appTypes = data);
        this.calibrationResponsibleService.findAll().pipe(takeUntil(this.destroy$))
          .subscribe((data: GenericClass[]) => this.calibrationResponsibles = data);
      }
    });

    this.folders = [];
    this.folderService.getAll().pipe(takeUntil(this.destroy$)).subscribe((data: InternalEquipmentFolder[]) => this.folders = data);

    if (this.isExternal) {
      this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Client[]) => {
        this.clients = data;
        this.clientsFiltered = this.clients;

        if (this.equipment.id == null && this.idClientURL != null) {
          this.equipment.idClient = +this.idClientURL;
        }
      });
    }

    const disallowedVariables: number[] = [VariableTypeEnum.LETHALITY];
    this.variableTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Variable[]) => {
      this.variables = data.filter(v => !disallowedVariables.includes(v.id));
    });
    this.actionTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClassTranslate[]) => this.actionTypes = data);

    this.users = [];
    this.isManager = this.userService.currentProfile?.manager || this.userService.currentProfile?.admin;
    this.usersService.findAllFromGroupAppType(AppTypeEnum.CALIBRATES).pipe(takeUntil(this.destroy$))
      .subscribe((data: User[]) => {
        this.users = data;
        this.usersFiltered = this.users;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChangeResponsibleCheck(event, actionType: number): void {
    const idResponsible = event.source.value as number;
    if (idResponsible === CalibrationResponsibleEnum.INTERNAL) {
      const group = this.roles?.find(r => r.idGroup === this.selectedRole.idGroup)?.groupName;
      if (actionType === EquipmentPerformances.CALIBRATION) {
        this.equipmentCalibration.supplier = group;
      } else if (actionType === EquipmentPerformances.VERIFICATION) {
        this.equipmentVerification.supplier = group;
      }
    } else {
      this.equipment.supplier = null;
    }
  }

  saveDate(field: string, event: any): void {
    const dateVariable = event;
    if (dateVariable != null) {
      const date = moment(`${dateVariable as string} 12:00:00 + 0:00`, 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }
      
      this.equipment[field] = date;
      if (field === 'calibrateDate') {
        if (this.equipment.calibrationFrequency){
          if (typeof this.equipment.calibrationFrequency === 'string') {
            if (this.equipment.calibrationFrequency && this.equipment.calibrationFrequency?.match('^[0-9]+$')) {
              this.equipment.expirationDate = _.cloneDeep(this.equipment.calibrateDate);
              this.equipment.expirationDate.setMonth(this.equipment.expirationDate.getMonth() + Number(this.equipment.calibrationFrequency));
            } else {
              this.equipment.expirationDate = _.cloneDeep(this.equipment.calibrateDate);
              this.equipment.expirationDate.setFullYear(this.equipment.expirationDate.getFullYear() + 1);
            }
          } else {
            this.equipment.expirationDate = _.cloneDeep(this.equipment.calibrateDate);
            this.equipment.expirationDate.setMonth(this.equipment.expirationDate.getMonth() + Number(this.equipment.calibrationFrequency));
          }
        } else {
          this.equipment.expirationDate = _.cloneDeep(this.equipment.calibrateDate);
          this.equipment.expirationDate.setFullYear(this.equipment.expirationDate.getFullYear() + 1);
        }
      }

      if (this.equipment?.expansionDay) {
        this.onChangeDateExpanded();
      }
    }

    if (field === 'processDate' && !this.equipmentCalibration.expirationDate) {
      this.equipmentCalibration.expirationDate = _.cloneDeep(this.equipmentCalibration.processDate);
      this.equipmentCalibration.expirationDate.setFullYear(this.equipmentCalibration.expirationDate.getFullYear() + 1);
    }
  }

  saveDateField(field: string, event: any): void {
    let value: Date | string = event.value;
    if (value != null) {
      if (value instanceof Date) {
        value = moment(value).format('DD/MM/YYYY');
      }

      const date = moment(value + ' 12:00:00 + 0:00', 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }

      this.equipment[field] = date;
    }
  }

  saveDateMaintenance(field: string, event: any): void {
    const dateVariable = event;
    if (dateVariable != null) {
      const date = moment(`${dateVariable as string} 12:00:00 + 0:00`, 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }

      this.equipmentMaintenance[field] = date;
      if (this.equipmentMaintenance?.expansionDay) {
        this.onChangeDateExpanded();
      }
    }

    if (field === 'processDate' && !this.equipmentMaintenance.expirationDate) {
      this.equipmentMaintenance.expirationDate = _.cloneDeep(this.equipmentMaintenance.processDate);
      this.equipmentMaintenance.expirationDate.setFullYear(this.equipmentMaintenance.expirationDate.getFullYear() + 1);
    }
  }

  saveDateVerification(field: string, event: any): void {
    const dateVariable = event;
    if (dateVariable != null) {
      const date = moment(`${dateVariable as string} 12:00:00 + 0:00`, 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }

      this.equipmentVerification[field] = date;
      if (this.equipment?.expansionDay) {
        this.onChangeDateExpanded();
      }
    }

    if (field === 'processDate' && !this.equipmentVerification.expirationDate) {
      this.equipmentVerification.expirationDate = _.cloneDeep(this.equipmentVerification.processDate);
      this.equipmentVerification.expirationDate.setFullYear(this.equipmentVerification.expirationDate.getFullYear() + 1);
    }
  }

  getAsLeftList(variable: CalibrationVariable): CalibrationAsLeft[] {
    const res: CalibrationAsLeft[] = [];

    variable.config?.forEach((config, indexConfig) => {
      config.asLeft?.forEach(aL => {
        aL.indexConfig = indexConfig;

        aL.uncertaintyResolution = config.uncertaintyResolution;

        res.push(aL);
      });
    });

    return res.sort((c1, c2) => c1.id - c2.id);
  }

  isCalibrationOpened(index: number): boolean {
    return this.mapCalibrationsAccordion.get(index);
  }

  setCalibrationOpened(index: number, state: boolean): void {
    this.mapCalibrationsAccordion.set(index, state);
  }

  newExternalCalibration(): void {
    const item = new ExternalCalibration();

    item.calibrationDate = new Date();
    item.calibrationDate = moment().toDate();
    if (this.equipment.calibrationFrequency) {
      if (typeof this.equipment.calibrationFrequency === 'string' && this.equipment.calibrationFrequency?.match('^[0-9]+$')) {
        item.expirationDate = _.cloneDeep(item.calibrationDate);
        item.expirationDate.setMonth(item.expirationDate.getMonth() + Number(this.equipment.calibrationFrequency));
      } else if (typeof this.equipment.calibrationFrequency === 'number') {
        item.expirationDate = _.cloneDeep(item.calibrationDate);
        item.expirationDate.setMonth(item.expirationDate.getMonth() + Number(this.equipment.calibrationFrequency));
      } else {
        item.expirationDate = _.cloneDeep(item.calibrationDate);
        item.expirationDate.setFullYear(item.expirationDate.getFullYear() + 1);
      }
    } else {
      item.expirationDate = _.cloneDeep(item.calibrationDate);
      item.expirationDate.setFullYear(item.expirationDate.getFullYear() + 1);
    }
    item.idEquipment = this.equipment.id;
    item.variables = [];
    this.equipment.idsVariables.forEach(idVar => {
      const variable = new ExternalCalibrationVariable();
      variable.idVariable = idVar;

      const vari = this.variables.find(v => v.id === idVar);
      variable.variableTranslation = vari.translation;

      item.variables.push(variable);
    });

    const dialogRef = this.dialog.open(InternalEquipmentEditCreateCalibrationComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        item,
        idEquipment: this.equipment.id,
        nameEquipment: this.equipment.name,
        idsActions: this.idsActionSelected,
        equipmentCalibration: this.equipmentCalibration,
        equipmentVerification: this.equipmentVerification,
        frequency: this.equipment.calibrationFrequency
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExternalCalibration) => {

      if (result != null) {
        this.externalCalibration = result;
        this.calibrations.push(this.externalCalibration);
        this.onChangeDateExpanded();

        if (result.actionType === EquipmentPerformances.CALIBRATION) {
            this.equipmentCalibration.processDate = result.calibrationDate;
            this.equipmentCalibration.expirationDate = result.expirationDate;
            this.equipmentCalibration.documentNumber = result.certificateNum;
            this.equipmentCalibration.idStatus = InternalEquipmentStatusEnum.EN_USO;
        } else if (result.actionType === EquipmentPerformances.CHARACTERIZATION) {
            this.equipmentCharacterization.processDate = result.calibrationDate;
            this.equipmentCharacterization.expirationDate = result.expirationDate;
            this.equipmentCharacterization.documentNumber = result.certificateNum;
            if (result.expirationDate.getTime() < new Date().getTime()) {
              this.equipmentCharacterization.idStatus = InternalEquipmentStatusEnum.NO_CONFORME;
            } else {
              this.equipmentCharacterization.idStatus = InternalEquipmentStatusEnum.CONFORME;
            }
        } else if (result.actionType === EquipmentPerformances.MAINTENANCE) {
            this.equipmentMaintenance.processDate = result.calibrationDate;
            this.equipmentMaintenance.expirationDate = result.expirationDate;
            this.equipmentMaintenance.documentNumber = result.certificateNum;
            if (result.expirationDate.getTime() < new Date().getTime()) {
              this.equipmentMaintenance.idStatus = InternalEquipmentStatusEnum.NO_CONFORME;
            } else {
              this.equipmentMaintenance.idStatus = InternalEquipmentStatusEnum.CONFORME;
            }
        } else if (result.actionType === EquipmentPerformances.QUALIFICATION) {
            this.equipmentQualification.processDate = result.calibrationDate;
            this.equipmentQualification.expirationDate = result.expirationDate;
            this.equipmentQualification.documentNumber = result.certificateNum;
            if (result.expirationDate.getTime() < new Date().getTime()) {
              this.equipmentQualification.idStatus = InternalEquipmentStatusEnum.NO_CONFORME;
            } else {
              this.equipmentQualification.idStatus = InternalEquipmentStatusEnum.CONFORME;
            }
        } else if (result.actionType === EquipmentPerformances.VERIFICATION) {
            this.equipmentVerification.processDate = result.calibrationDate;
            this.equipmentVerification.expirationDate = result.expirationDate;
            this.equipmentVerification.documentNumber = result.certificateNum;
            this.equipmentVerification.idStatus = InternalEquipmentStatusEnum.EN_USO;
        }
        
      }
    });
  }

  goToCalibration(idCalibration: number, idTypeAction: number): void {
    let url: string;
    if (this.router.url.includes('calibrates')){
      if (idTypeAction === EquipmentPerformances.CHARACTERIZATION) {
        url = `calibrates/isothermalCharacterization?id=${idCalibration}`
      } else if (idTypeAction === EquipmentPerformances.QUALIFICATION) {
        url = `thermal/execution?id=${idCalibration}`
      }else {
        url = `calibrates/calibration?id=${idCalibration}`
      }
    } else if (this.router.url.includes('thermal')) {
      if (idTypeAction === EquipmentPerformances.CHARACTERIZATION) {
        url = `thermal/isothermalCharacterization?id=${idCalibration}`
      } else if (idTypeAction === EquipmentPerformances.QUALIFICATION) {
        url = `thermal/execution?id=${idCalibration}`
      } else {
        url = `thermal/calibration?id=${idCalibration}`
      }
    }
    void this.router.navigateByUrl(url);
  }

  editExternalCalibration(idExternalCalibration: number): void {
    event.stopPropagation();

    this.spinnerService.show();

    this.externalCalibrationService.findOne(idExternalCalibration).pipe(takeUntil(this.destroy$)).subscribe(row => {

      row = CalibrationUtils.objectToExternalCalibration(row);

      this.spinnerService.hide();
      const dialogRef = this.dialog.open(InternalEquipmentEditCreateCalibrationComponent, {
        minWidth: '50%',
        maxHeight: '95vh',
        data: {
          item: _.cloneDeep(row) as ExternalCalibration,
          idEquipment: this.equipment.id,
          nameEquipment: this.equipment.name,
          idsActions: this.idsActionSelected,
          frequency: this.equipment.calibrationFrequency
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExternalCalibration) => {
        if (result != null) {
          this.externalCalibration = result;
          /* result.expirationDate = _.cloneDeep(result.calibrationDate);
          result.expirationDate.setFullYear(result.expirationDate.getFullYear() + 1); */
          const calIndex = this.calibrations.findIndex(e => e.id === result.id);
          this.calibrations[calIndex] = this.externalCalibration;
          this.onChangeDateExpanded();

          if (result.actionType === EquipmentPerformances.CALIBRATION) {
            this.equipmentCalibration.processDate = result.calibrationDate;
            this.equipmentCalibration.expirationDate = result.expirationDate;
            this.equipmentCalibration.documentNumber = result.certificateNum;
            if (result.expirationDate.getTime() < new Date().getTime()) {
              this.equipmentCalibration.idStatus = InternalEquipmentStatusEnum.CADUCADO;
            } else {
              this.equipmentCalibration.idStatus = InternalEquipmentStatusEnum.EN_USO;
            }
          } else if (result.actionType === EquipmentPerformances.CHARACTERIZATION) {
            this.equipmentCharacterization.processDate = result.calibrationDate;
            this.equipmentCharacterization.expirationDate = result.expirationDate;
            this.equipmentCharacterization.documentNumber = result.certificateNum;
            if (result.expirationDate.getTime() < new Date().getTime()) {
              this.equipmentCharacterization.idStatus = InternalEquipmentStatusEnum.NO_CONFORME;
            } else {
              this.equipmentCharacterization.idStatus = InternalEquipmentStatusEnum.CONFORME;
            }
          } else if (result.actionType === EquipmentPerformances.MAINTENANCE) {
            this.equipmentMaintenance.processDate = result.calibrationDate;
            this.equipmentMaintenance.expirationDate = result.expirationDate;
            this.equipmentMaintenance.documentNumber = result.certificateNum;
            if (result.expirationDate?.getTime() < new Date().getTime()) {
              this.equipmentMaintenance.idStatus = InternalEquipmentStatusEnum.NO_CONFORME;
            } else {
              this.equipmentMaintenance.idStatus = InternalEquipmentStatusEnum.CONFORME;
            }
          } else if (result.actionType === EquipmentPerformances.QUALIFICATION) {
            this.equipmentQualification.processDate = result.calibrationDate;
            this.equipmentQualification.expirationDate = result.expirationDate;
            this.equipmentQualification.documentNumber = result.certificateNum;
            if (result.expirationDate?.getTime() < new Date().getTime()) {
              this.equipmentQualification.idStatus = InternalEquipmentStatusEnum.NO_CONFORME;
            } else {
              this.equipmentQualification.idStatus = InternalEquipmentStatusEnum.CONFORME;
            }
          } else if (result.actionType === EquipmentPerformances.VERIFICATION) {
            this.equipmentVerification.processDate = result.calibrationDate;
            this.equipmentVerification.expirationDate = result.expirationDate;
            this.equipmentVerification.documentNumber = result.certificateNum;
            if (result.expirationDate.getTime() < new Date().getTime()) {
              this.equipmentVerification.idStatus = InternalEquipmentStatusEnum.CADUCADO;
            } else {
              this.equipmentVerification.idStatus = InternalEquipmentStatusEnum.EN_USO;
            }
          }
        }
      });
    });
  }

  deleteExternalCalibration(idExternalCalibration: number): void {
    event.stopPropagation();

    this.spinnerService.show();

    this.externalCalibrationService.delete(idExternalCalibration).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.snackBarService.sendSuccess(this.translate.instant('calibrateEquipmentList.dialog.delete.ok') as string);
      this.spinnerService.hide();
      this.reloadEquipment();
    }, () => {
      this.snackBarService.sendError(this.translate.instant('calibrateEquipmentList.dialog.delete.error') as string);
      this.spinnerService.hide();
    });
  }

  downloadCertificateEquipment(index: number): void {
    event.stopPropagation();

    const calibration = this.calibrations[index];

    if (calibration && calibration.id) {
      this.spinnerService.show();

      let call: Observable<any> = null;

      if (calibration.internal) {
        if (calibration.actionType === EquipmentPerformances.CHARACTERIZATION) {
          call = this.isothermalCharacterizationService.downloadPdfVersion(calibration.id, calibration.version);
        } else if (calibration.actionType === EquipmentPerformances.QUALIFICATION) {
          call = this.executionService.downloadPdfVersion(calibration.id, calibration.version);
        }else {
          call = this.attachmentCalibratesService.downloadCertificateCalibration(calibration.id);
        }
      } else {
        call = this.attachmentCalibratesService.downloadCertificateExternalCalibration(calibration.id);
      }

      if (call == null) {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.downloadPdf.error') as string);

        return;
      }

      call.pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        saveAs(res, this.equipment.name + '.pdf');
        this.spinnerService.hide();
      }, error => {
        console.error(error);
        this.spinnerService.hide();
      });
    }

  }

  downloadCertificateLastCertificateCalibration(index: number): void {
    event.stopPropagation();

    const calibration = this.calibrations[index];

    if (calibration && calibration.id) {
      this.spinnerService.show();

      if (calibration.actionType === EquipmentPerformances.CHARACTERIZATION) {
        this.isothermalCharacterizationService.downloadPdfVersion(calibration.id, calibration.version).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
          saveAs(res, calibration.certificateNum + '.pdf');
          this.spinnerService.hide();
        }, error => {
          console.error(error);
          this.spinnerService.hide();
        });
      } else if (calibration.actionType === EquipmentPerformances.QUALIFICATION) {
        this.executionService.downloadPdfVersion(calibration.id, calibration.version).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
          saveAs(res, calibration.certificateNum + '.pdf');
          this.spinnerService.hide();
        }, error => {
          console.error(error);
          this.spinnerService.hide();
        });
      } else {
        this.calibrationService.downloadPdfLastVersion(calibration.id).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
          saveAs(res, calibration.certificateNum + '.pdf');
          this.spinnerService.hide();
        }, error => {
          console.error(error);
          this.spinnerService.hide();
        });
      }
    }

  }

  canSave(): boolean {
    const rolesCanSaveInternal = [RoleEnum.ADMIN, RoleEnum.MANAGER, RoleEnum.RESPONSABLE];

    return this.isExternal || rolesCanSaveInternal.includes(this.currentUser.idActiveRole) && !this.pendingReview;
  }

  reviewEquipment(): void {
    if (this.isResponsibleEquipment()) {
      this.requestReason((reason) => {
        this.requestSign((token: string) => {
          this.equipmentService.findOne(this.equipment.id).pipe(takeUntil(this.destroy$)).subscribe((result: InternalEquipment) => {
            result.idStatus = InternalEquipmentStatusEnum.EN_USO;

            this.equipmentService.save(result, reason).pipe(takeUntil(this.destroy$)).subscribe((res: InternalEquipment) => {
              this.equipment = res;

              this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.update.ok') as string);

              this.reloadEquipment();
            }, err => {
              if (err != null && err.error != null && typeof err.error === 'string') {
                this.snackBarService.sendError(err.error as string);
              } else {
                this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.update.error') as string);
              }
            });
          });
        });
      });
    } else {
      if (!this.equipment.responsibleEquipment) {
        this.requestResponsibleEquipment((confirm) => {
          if (confirm) {
            this.requestReason((reason) => {
              this.requestSign((token: string) => {
                this.equipmentService.findOne(this.equipment.id).pipe(takeUntil(this.destroy$)).subscribe((result: InternalEquipment) => {
                  result.idStatus = InternalEquipmentStatusEnum.EN_USO;
                  result.responsibleEquipment = this.currentUser.username;
                  this.equipmentService.save(result, reason).pipe(takeUntil(this.destroy$)).subscribe((res: InternalEquipment) => {
                    this.equipment = res;

                    this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.update.ok') as string);

                    this.reloadEquipment();
                  }, err => {
                    if (err != null && err.error != null && typeof err.error === 'string') {
                      this.snackBarService.sendError(err.error as string);
                    } else {
                      this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.update.error') as string);
                    }
                  });
                });
              });
            });
          } else {
            this.requestSaveIfNotResponsibleEquipment((acceptSave) => {
              if (acceptSave) {
                this.requestReason((reason) => {
                  this.requestSign((token: string) => {
                    this.equipmentService.findOne(this.equipment.id).pipe(takeUntil(this.destroy$))
                    .subscribe((result: InternalEquipment) => {
                      result.idStatus = InternalEquipmentStatusEnum.EN_USO;

                      this.equipmentService.save(result, reason).pipe(takeUntil(this.destroy$)).subscribe((res: InternalEquipment) => {
                        this.equipment = res;

                        this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.update.ok') as string);

                        this.reloadEquipment();
                      }, err => {
                        if (err != null && err.error != null && typeof err.error === 'string') {
                          this.snackBarService.sendError(err.error as string);
                        } else {
                          this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.update.error') as string);
                        }
                      });
                    });
                  });
                });
              } else {
                this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.resposible.equipment.error') as string);
              }
            });
          }
        });
      } else {
        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.resposible.equipment.error') as string);
      }
    }
  }

  saveEquipment(): void {
    this.equipment.internal = this.isInternal;

    const errores: string[] = [];

    if (StringUtils.isEmpty(this.equipment.name)) {
      errores.push(this.translate.instant('internalEquipmentEdit.general.name.error') as string);
    }

    if (StringUtils.isEmpty(this.equipment.location)) {
      errores.push(this.translate.instant('internalEquipmentEdit.general.location.error') as string);
    }

    if (StringUtils.isEmpty(this.equipment.equipment)) {
      errores.push(this.translate.instant('internalEquipmentEdit.general.equipment.error') as string);
    }

    if (StringUtils.isEmpty(this.equipment.maker)) {
      errores.push(this.translate.instant('internalEquipmentEdit.general.maker.error') as string);
    }

    if (StringUtils.isEmpty(this.equipment.serialNum)) {
      errores.push(this.translate.instant('internalEquipmentEdit.general.serialNum.error') as string);
    }

    if (!this.equipment.internal && this.equipment.idClient == null) {
      errores.push(this.translate.instant('internalEquipmentEdit.general.client.error') as string);
    }

    if (!this.idsActionSelected || this.idsActionSelected?.length === 0) {
      errores.push(this.translate.instant('internalEquipmentEdit.general.actionTypes.error') as string);
    }

    if (this.isCalibration) {
      this.equipmentCalibration.idActionType = EquipmentPerformances.CALIBRATION;
      /* if (this.equipment.internal && this.equipmentCalibration.idStatus == null) {
        errores.push(this.translate.instant('internalEquipmentEdit.general.status.error') as string);
      } */

      if (this.equipment.internal && this.equipmentCalibration.responsible == null) {
        errores.push(this.translate.instant('internalEquipmentEdit.general.calibrationResponsible.error') as string);
      }

      if (this.equipmentCalibration.processDate) {
        this.equipmentCalibration.processDate = moment(this.equipmentCalibration.processDate, 'DD/MM/YYYY').toDate();
      }

      if (this.equipmentCalibration.expirationDate) {
        this.equipmentCalibration.expirationDate = moment(this.equipmentCalibration.expirationDate, 'DD/MM/YYYY').toDate();
      }

      if (!StringUtils.isEmpty(this.equipmentCalibration.frequency)) {
        const vNumber = new RegExp('^[0-9]+$');
        if (!vNumber.test(this.equipmentCalibration.frequency)) {
          errores.push(this.translate.instant('internalEquipmentEdit.general.calibrationFrequencyType.error') as string);
        }
      } else {
        errores.push(this.translate.instant('internalEquipmentEdit.general.frecuency.error') as string);
      }
    }

    if (this.isVerification) {
      this.equipmentVerification.idActionType = EquipmentPerformances.VERIFICATION;
      if (this.equipment.internal && this.equipmentVerification.responsible == null) {
        errores.push(this.translate.instant('internalEquipmentEdit.general.calibrationResponsible.error') as string);
      }

      if (!StringUtils.isEmpty(this.equipmentVerification.frequency)) {
        const vNumber = new RegExp('^[0-9]+$');
        if (!vNumber.test(this.equipmentVerification.frequency)) {
          errores.push(this.translate.instant('internalEquipmentEdit.general.verificationFrequencyType.error') as string);
        }
      } else {
        errores.push(this.translate.instant('internalEquipmentEdit.general.frecuency.error') as string);
      }
    }

    if (this.isMaintenance) {
      this.equipmentMaintenance.idActionType = EquipmentPerformances.MAINTENANCE;
    }

    if (this.isQualification) {
      this.equipmentQualification.idActionType = EquipmentPerformances.QUALIFICATION;
    }

    if (this.isCharacterization) {
      this.equipmentCharacterization.idActionType = EquipmentPerformances.CHARACTERIZATION;
    }

    if (errores.length === 0) {
      this.equipment.performances = [];
      if (this.isCalibration) {
        this.equipment.performances.push(this.equipmentCalibration);
      }
      if (this.isVerification) {
        this.equipment.performances.push(this.equipmentVerification);
      }
      if (this.isMaintenance) {
        this.equipment.performances.push(this.equipmentMaintenance);
      }
      if (this.isQualification) {
        this.equipment.performances.push(this.equipmentQualification);
      }
      if (this.isCharacterization) {
        this.equipment.performances.push(this.equipmentCharacterization);
      }
      if (!this.equipment.idStatus) {
        this.equipment.idStatus = InternalEquipmentStatusEnum.EN_USO;
      }
      this.previewDatePerformaces();
    } else if (errores.length !== 0) {
      this.snackBarService.sendError(errores.join('\n'));
    }

  }

  showUploadCertificateToInternalEquipment(): boolean {
    const allowed = [CalibrationResponsibleEnum.EXTERNAL];

    return allowed.includes(this.equipment.responsible);
  }

  uploadCertificateToInternalEquipment(event): void {
    const file = event.target.files[0] as File;

    this.spinnerService.show();
    this.attachmentThermalService.uploadCertificateToInternalEquipment(this.equipment.id, file).pipe(takeUntil(this.destroy$))
      .subscribe((item: number) => {
        this.equipment.idCertificate = item;

        this.spinnerService.hide();

        this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.uploadCertificate.ok') as string);
      }, () => {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.uploadCertificate.error') as string);
      });
  }

  uploadCertificateToCalibration(index: number, eventFile): void {
    event.stopPropagation();

    const file = eventFile.target.files[0] as File;

    const calibration = this.calibrations[index];

    if (calibration && calibration.id) {
      this.spinnerService.show();

      let call: Observable<any> = null;

      if (calibration.internal) {
        call = this.attachmentThermalService.uploadCertificateToCalibration(calibration.id, file);
      } else {
        call = this.externalCalibrationService.uploadCertificateToCalibration(calibration.id, file);
      }

      if (call == null) {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.uploadCertificate.error') as string);

        return;
      }

      call.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.spinnerService.hide();

        this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.uploadCertificate.ok') as string);

        this.loadCalibrations();
      }, () => {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.uploadCertificate.error') as string);
      });
    }

  }

  deleteCertificateToCalibration(index: number): void {
    event.stopPropagation();

    const calibration = this.calibrations[index];

    if (calibration && calibration.id) {
      this.spinnerService.show();

      let call: Observable<any> = null;

      if (calibration.internal) {
        call = this.attachmentThermalService.deleteCertificateToCalibration(calibration.id);
      } else {
        call = this.externalCalibrationService.deleteCertificateToCalibration(calibration.id);
      }

      if (call == null) {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.uploadCertificate.error') as string);

        return;
      }

      call.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.spinnerService.hide();

        this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.uploadCertificate.ok') as string);
      }, () => {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.uploadCertificate.error') as string);
      });
    }

  }

  downloadPdf(): void {
    this.spinnerService.show();

    const id = this.equipment.id;
    this.equipmentService.downloadPdf(id).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const n = this.translate.instant('internalEquipmentEdit.files.certificateInternalEquipment', { name: this.equipment.name }) as string;
      saveAs(res, n);

      this.spinnerService.hide();
    }, () => {
      this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.downloadPdf.error') as string);

      this.spinnerService.hide();
    });
  }

  downloadAuditPdf(): void {
    this.spinnerService.show();

    const id = this.equipment.id;
    this.equipmentService.downloadAuditPdf(id).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name = this.translate.instant('internalEquipmentEdit.files.auditInternalEquipment', { name: this.equipment.name }) as string;
      saveAs(res, name);

      this.spinnerService.hide();
    }, () => {
      this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.downloadPdf.error') as string);

      this.spinnerService.hide();
    });
  }

  getDecimalsResolution(resolution: number): number {
    let res = NumberUtils.countDecimals(resolution);

    if (resolution === 1) {
      res = 0;
    }

    return res;
  }

  getDecimalsUncertainty(uncertaintyResolution: number): number {
    let res = 0;
    const uncertaintyDecimals = NumberUtils.countDecimals(uncertaintyResolution);

    if (uncertaintyDecimals === 0) {
      res = uncertaintyResolution;
    } else {
      res = uncertaintyDecimals;
    }

    return res;
  }

  getDecimalsPattern(pattern: number): number {
    return NumberUtils.countDecimals(pattern);
  }

  cancel(): void {
    const type = this.getTypeEquipment();
    let url: string;
    if (this.router.url.includes('calibrates')){
      url = `calibrates/${type}Equipments`;
    } else if (this.router.url.includes('thermal')) {
      url = `thermal/${type}Equipments`;
    } else if (this.router.url.includes('air')) {
      url = `air/${type}Equipments`;
    }
    void this.router.navigateByUrl(url);
  }

  isNotValid(calibration: Calibration): boolean {
    return calibration.idStatus === CalibrationStatus.NO_VALIDO;
  }

  hasCalibrationPlan(): boolean {
    return this.currentUser.showCalibrationPlan;
  }

  disablesSupplier(): boolean {
    if (this.isCalibration || this.isVerification) {
      if (this.equipmentCalibration?.responsible && this.equipmentVerification?.responsible) {
        this.disableSupplier = this.isExternal || (this.equipmentCalibration.responsible === CalibrationResponsibleEnum.INTERNAL && this.equipmentVerification.responsible === CalibrationResponsibleEnum.INTERNAL);
      } else if (this.equipmentCalibration?.responsible) {
        this.disableSupplier = this.isExternal || (this.equipmentCalibration.responsible === CalibrationResponsibleEnum.INTERNAL);
      } else if (this.equipmentVerification?.responsible) {
        this.disableSupplier = this.isExternal || (this.equipmentVerification.responsible === CalibrationResponsibleEnum.INTERNAL);
      } else {
        this.disableSupplier = this.isExternal;
      }
    }  else {
      this.disableSupplier = this.isExternal
    }
    return this.disableSupplier || !this.hasCalibrationPlan();
  }

  getFolderName(item: InternalEquipmentFolder): string {
    if (item == null) {
      return '';
    }

    let prefix = '';
    if (item.idFather != null) {
      const parent = this.folders.find(f => f.id === item.idFather);
      prefix = this.getFolderName(parent).concat(' > ');
    }

    return prefix.concat(item.name);
  }

  showCalibrationPlan(): boolean {
    return this.currentUser.showCalibrationPlan;
  }

  decline(): void {
    if (this.isResponsibleEquipment()) {
      this.requestReason((reason) => {
        this.spinnerService.show();
        this.equipment.idStatus = InternalEquipmentStatusEnum.FUERA_DE_USO;
          this.equipmentService.save(this.equipment, reason).pipe(takeUntil(this.destroy$)).subscribe((res: InternalEquipment) => {
            this.equipment = res;

            this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.update.ok') as string);

            this.reloadEquipment();

          }, err => {
            if (err != null && err.error != null && typeof err.error === 'string') {
              this.snackBarService.sendError(err.error as string);
            } else {
              this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.update.error') as string);
            }
          });
      });
    } else {
      if (!this.equipment.responsibleEquipment) {
        this.requestReason((reason) => {
          this.spinnerService.show();
          this.equipment.idStatus = InternalEquipmentStatusEnum.FUERA_DE_USO;
            this.equipmentService.save(this.equipment, reason).pipe(takeUntil(this.destroy$)).subscribe((res: InternalEquipment) => {
              this.equipment = res;

              this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.update.ok') as string);

              this.reloadEquipment();

            }, err => {
              if (err != null && err.error != null && typeof err.error === 'string') {
                this.snackBarService.sendError(err.error as string);
              } else {
                this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.update.error') as string);
              }
            });
        });
      } else {
        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.resposible.equipment.error') as string);
      }
    }
  }

  previewDatePerformaces(): void {
    let isCalibrationNotDate = false;
    let isCharaterizationNotDate = false;
    let isVerificationNotDate = false;
    let isMaintenanceNotDate = false;
    let isQualificationNotDate = false;

    if (this.isCalibration) {
      isCalibrationNotDate = !this.equipmentCalibration?.processDate ? true : false;
    }
    if (this.isCharacterization) {
      isCharaterizationNotDate = !this.equipmentCharacterization?.processDate ? true : false;
    }
    if (this.isVerification) {
      isVerificationNotDate = !this.equipmentVerification?.processDate ? true : false;
    }
    if (this.isMaintenance) {
      isMaintenanceNotDate = !this.equipmentMaintenance?.processDate ? true : false;
    }
    if (this.isQualification) {
      isQualificationNotDate = !this.equipmentQualification?.processDate ? true : false;
    }

    if (isCalibrationNotDate || isCharaterizationNotDate || isVerificationNotDate || isMaintenanceNotDate || isQualificationNotDate) {
      this.spinnerService.hide();
      const dialogRef = this.dialog.open(InternalEquipmentEditDatePerformancesComponent, {
        minWidth: '50%',
        maxHeight: '95vh',
        data: {
          frecuencyCalibration: this.equipmentCalibration.frequency,
          frecuencyVerification: this.equipmentVerification.frequency,
          isCalibrationNotDate,
          isCharaterizationNotDate,
          isVerificationNotDate,
          isMaintenanceNotDate,
          isQualificationNotDate
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: InternalEquipmentPerformancesDate) => {
        if (result != null) {
          if (this.isCalibration) {
            if (result.processDateCalibration) {
              this.equipmentCalibration.processDate = result.processDateCalibration;
            }
            if (result.expirationDateCalibration) {
              this.equipmentCalibration.expirationDate = result.expirationDateCalibration;
              if (result.expirationDateCalibration.getTime() < new Date().getTime()) {
                this.equipmentCalibration.idStatus = InternalEquipmentStatusEnum.CADUCADO;
              } else {
                this.equipmentCalibration.idStatus = InternalEquipmentStatusEnum.EN_USO;
              }
            }
            }
          if (this.isMaintenance) {
            if (result.processDateMaintenance) {
              this.equipmentMaintenance.processDate = result.processDateMaintenance;
          }
            if (result.expirationDateMaintenance) {
              this.equipmentMaintenance.expirationDate = result.expirationDateMaintenance;
              if (result.expirationDateMaintenance.getTime() < new Date().getTime()) {
                this.equipmentMaintenance.idStatus = InternalEquipmentStatusEnum.NO_CONFORME;
              } else {
                this.equipmentMaintenance.idStatus = InternalEquipmentStatusEnum.CONFORME;
              }
            }
          }
          if (this.isQualification) {
            if (result.processDateQualification) {
              this.equipmentQualification.processDate = result.processDateQualification;
            }
            if (result.expirationDateQualification) {
              this.equipmentQualification.expirationDate = result.expirationDateQualification;
              if (result.expirationDateQualification.getTime() < new Date().getTime()) {
                this.equipmentQualification.idStatus = InternalEquipmentStatusEnum.NO_CONFORME;
              } else {
                this.equipmentQualification.idStatus = InternalEquipmentStatusEnum.CONFORME;
              }
            }
          }
          if (this.isCharacterization) {
            if (result.processDateCharaterization) {
              this.equipmentCharacterization.processDate = result.processDateCharaterization;
            }
            if (result.expirationCharaterization) {
              this.equipmentCharacterization.expirationDate = result.expirationCharaterization;
              if (result.expirationCharaterization.getTime() < new Date().getTime()) {
                this.equipmentCharacterization.idStatus = InternalEquipmentStatusEnum.NO_CONFORME;
              } else {
                this.equipmentCharacterization.idStatus = InternalEquipmentStatusEnum.CONFORME;
              }
            }
          }
          if (this.isVerification) {
            if (result.processDateVerification) {
              this.equipmentVerification.processDate = result.processDateVerification;
            }
            if (result.expirationVerification) {
              this.equipmentVerification.expirationDate = result.expirationVerification;
              if (result.expirationVerification.getTime() < new Date().getTime()) {
                this.equipmentVerification.idStatus = InternalEquipmentStatusEnum.CADUCADO;
              } else {
                this.equipmentVerification.idStatus = InternalEquipmentStatusEnum.EN_USO;
              }
            }
          }
          this.save(this.equipment.id == null);
        }
      });
    } else {
      this.save(this.equipment.id == null);
    }
  }

  onChangeActionType(): void {
    const resultCalibration = this.idsActionSelected?.find(e => e === EquipmentPerformances.CALIBRATION);
    if (resultCalibration) {
      this.isCalibration = true;
    } else {
      this.isCalibration = false;
    }

    const resultV = this.idsActionSelected?.find(e => e === EquipmentPerformances.VERIFICATION);
    if (resultV) {
      this.isVerification = true;
    } else {
      this.isVerification = false;
    }

    const resultM = this.idsActionSelected?.find(e => e === EquipmentPerformances.MAINTENANCE);
    if (resultM) {
      this.isMaintenance = true;
    } else {
      this.isMaintenance = false;
    }

    const resultQ = this.idsActionSelected?.find(e => e === EquipmentPerformances.QUALIFICATION);
    if (resultQ) {
      this.isQualification = true;
    } else {
      this.isQualification = false;
    }

    const resultChar = this.idsActionSelected?.find(e => e === EquipmentPerformances.CHARACTERIZATION);
    if (resultChar) {
      this.isCharacterization = true;
    } else {
      this.isCharacterization = false;
    }

  }


  onChangeDateExpanded(): void {
    if (this.isCalibration) {
      if (this.extendedDay && this.equipmentCalibration?.expansionDay) {
        this.equipmentCalibration.idStatus = InternalEquipmentStatusEnum.EXTENDIDO;
        const dateExpired = _.cloneDeep(this.equipmentCalibration.expirationDate);
        // eslint-disable-next-line max-len
        const result = dateExpired.setDate(this.equipmentCalibration.expirationDate.getDate() + this.equipmentCalibration.expansionDay);
        this.equipmentCalibration.extendedExpirationDate = DateUtils.anyToDate(result);
      }
    }
  }

  onChangeDateExpandedCheck(event: MatCheckboxChange): void {
    this.extendedDay = event.checked;
    if (!event.checked) {
      this.equipmentCalibration.extendedExpirationDate = null;
      this.equipmentCalibration.expansionDay = null;
      this.equipmentCalibration.idStatus = this.stateOriginal;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.equipmentCalibration.idStatus = InternalEquipmentStatusEnum.EXTENDIDO;
    }
  }

  checkExtedend(): boolean {
    return this.extendedDay;
  }

  onChangeNotificationResponsible(event: MatCheckboxChange): void {
    this.equipment.notificationResposibleEq = event.checked;
  }

  setValue(field: InternalEquipmentCustomFieldValue, number: number, value: string | MatCheckboxChange | MatSelectionListChange):void {
    if (value instanceof MatCheckboxChange) {
      value = value.checked.toString();
    } else if (value instanceof MatSelectionListChange) {
      value = value.source.selectedOptions.selected.map(i => i.value as string).join(';');
    }

    field.value = value
  }

  highlight(eq: InternalEquipmentPerformances): string {
    let $res: string;
    if (this.isDeactivate(eq)) {
      $res = 'status-action-deactivate';
    } else if (eq.nearToExpire) {
      $res = 'status-action-warning';
    } else if (eq.idStatus === InternalEquipmentStatusEnum.EXTENDIDO) {
      $res = 'status-action-warning';
    }
    return $res;
  }

  isDeactivate(eq: InternalEquipmentPerformances): boolean {
    return eq.idStatus === InternalEquipmentStatusEnum.FUERA_DE_USO || eq.idStatus === InternalEquipmentStatusEnum.CADUCADO ||  eq.idStatus === InternalEquipmentStatusEnum.NO_CONFORME;
  }

  descriptionStatusActions(idStatus: number): string {
    if (idStatus === InternalEquipmentStatusEnum.EN_USO) {
      return this.translate.instant('internalEquipmentStatus.active') as string;
    } else if (idStatus === InternalEquipmentStatusEnum.CADUCADO){
      return this.translate.instant('internalEquipmentStatus.timedOut') as string;
    } else if (idStatus === InternalEquipmentStatusEnum.FUERA_DE_USO){
      return this.translate.instant('internalEquipmentStatus.shutDown') as string;
    } else if (idStatus === InternalEquipmentStatusEnum.PENDING_REVIEW){
      return this.translate.instant('internalEquipmentStatus.pendingReview') as string;
    } else if (idStatus === InternalEquipmentStatusEnum.PENDING_CERTIFICATE){
      return this.translate.instant('internalEquipmentStatus.pendingCertificate') as string;
    } else if (idStatus === InternalEquipmentStatusEnum.EXTENDIDO){
      return this.translate.instant('internalEquipmentStatus.extended') as string;
    } else if (idStatus === InternalEquipmentStatusEnum.NO_CONFORME){
      return this.translate.instant('internalEquipmentStatus.actions.notAccording') as string;
    } else if (idStatus === InternalEquipmentStatusEnum.CONFORME){
      return this.translate.instant('internalEquipmentStatus.actions.according') as string;
    }
  }

  getAsLeftListMultichannel(variable: CalibrationVariable, indexChannel: number): CalibrationAsLeft[] {
    const res: CalibrationAsLeft[] = [];

    if (variable?.valuesMultichannel) {
      variable.valuesMultichannel[indexChannel].asLeft?.forEach(aL => {
        const config = variable.config.find(e => e.id === aL.idCalibrationConfig);
        aL.uncertaintyResolution = config ? config.uncertaintyResolution : 0;

        res.push(aL);
        this.updateTable(this.asLeftTableMultiChannel);
      });
      this.updateTable(this.asLeftTableMultiChannel);
    }

    return res.sort((a1, a2) => a1.id - a2.id);
  }

  editAndDeleteRowPerfomances(idAction: number, isEdit: boolean) {
    let isCalibrationNotDate = idAction === EquipmentPerformances.CALIBRATION;
    let isCharaterizationNotDate = idAction === EquipmentPerformances.CHARACTERIZATION;;
    let isVerificationNotDate = idAction === EquipmentPerformances.VERIFICATION;;
    let isMaintenanceNotDate = idAction === EquipmentPerformances.MAINTENANCE;;
    let isQualificationNotDate = idAction === EquipmentPerformances.QUALIFICATION;

    if (isCalibrationNotDate || isCharaterizationNotDate || isVerificationNotDate || isMaintenanceNotDate || isQualificationNotDate) {
      const dialogRef = this.dialog.open(InternalEquipmentEditDatePerformancesComponent, {
        minWidth: '50%',
        maxHeight: '95vh',
        data: {
          frecuencyCalibration: this.equipmentCalibration.frequency,
          frecuencyVerification: this.equipmentVerification.frequency,
          isCalibrationNotDate,
          isCharaterizationNotDate,
          isVerificationNotDate,
          isMaintenanceNotDate,
          isQualificationNotDate
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: InternalEquipmentPerformancesDate) => {
        if (result != null) {
          if (this.isCalibration) {
            if (result.processDateCalibration) {
              this.equipmentCalibration.processDate = result.processDateCalibration;
              this.equipmentCalibration.idStatus = InternalEquipmentStatusEnum.EN_USO;
            }
            if (result.expirationDateCalibration) {
              this.equipmentCalibration.expirationDate = result.expirationDateCalibration;
            }
            }
          if (this.isMaintenance) {
            if (result.processDateMaintenance) {
              this.equipmentMaintenance.processDate = result.processDateMaintenance;
              this.equipmentMaintenance.idStatus = InternalEquipmentStatusEnum.CONFORME;
          }
            if (result.expirationDateMaintenance) {
              this.equipmentMaintenance.expirationDate = result.expirationDateMaintenance;
            }
            }
          if (this.isQualification) {
            if (result.processDateQualification) {
              this.equipmentQualification.processDate = result.processDateQualification;
              this.equipmentQualification.idStatus = InternalEquipmentStatusEnum.CONFORME;
          }
            if (result.expirationDateQualification) {
              this.equipmentQualification.expirationDate = result.expirationDateQualification;
            }
            }
          if (this.isCharacterization) {
            if (result.processDateCharaterization) {
              this.equipmentCharacterization.processDate = result.processDateCharaterization;
              this.equipmentCharacterization.idStatus = InternalEquipmentStatusEnum.EN_USO;
          }
            if (result.expirationCharaterization) {
              this.equipmentCharacterization.expirationDate = result.expirationCharaterization;
            }
            }
          if (this.isVerification) {
            if (result.processDateVerification) {
              this.equipmentVerification.processDate = result.processDateVerification;
              this.equipmentVerification.idStatus = InternalEquipmentStatusEnum.EN_USO;
          }
            if (result.expirationVerification) {
              this.equipmentVerification.expirationDate = result.expirationVerification;
            }
          }
        }
      });
    }
  }

  calculateNextActivity(actionType: number): Date {
    let resultDate: Date | string;
    if (actionType === EquipmentPerformances.CALIBRATION) {
      if (this.equipmentCalibration && this.equipmentCalibration.expirationDate) {
        if (this.equipmentCalibration.expirationDate instanceof Date) {
          resultDate = moment(this.equipmentCalibration.expirationDate).format('DD/MM/YYYY');
        } else {
          resultDate = this.equipmentCalibration.expirationDate;
        }
  
        const date = moment.utc(resultDate, 'DD/MM/YYYY');
        let dateValid: Date;
        if (date.isValid()) {
          dateValid = date.toDate();
        } else {
          dateValid =  moment.utc(resultDate, 'YYYY-MM-DD').toDate();
        }
        resultDate = _.cloneDeep(dateValid);
        resultDate.setMonth(resultDate.getMonth() + Number(this.equipmentCalibration.frequency));
        return resultDate;
      }
    } else if (actionType === EquipmentPerformances.VERIFICATION){
      if (this.equipmentVerification && this.equipmentVerification.expirationDate) {
        resultDate = moment(this.equipmentVerification.expirationDate).format('DD/MM/YYYY');
  
        const date = moment.utc(resultDate, 'DD/MM/YYYY');
        let dateValid: Date;
        if (date.isValid()) {
          dateValid = date.toDate();
        } else {
          dateValid =  moment.utc(resultDate, 'YYYY-MM-DD').toDate();
        }
        resultDate = _.cloneDeep(dateValid);
        resultDate.setMonth(resultDate.getMonth() + Number(this.equipmentVerification.frequency));
        return resultDate;
      }
    } else if (actionType === EquipmentPerformances.MAINTENANCE){
      if (this.equipmentMaintenance && this.equipmentMaintenance.expirationDate) {
        if (this.equipmentMaintenance.expirationDate instanceof Date) {
          resultDate = moment(this.equipmentMaintenance.expirationDate).format('DD/MM/YYYY');
        } else {
          resultDate = this.equipmentMaintenance.expirationDate;
        }
  
        const date = moment.utc(resultDate, 'DD/MM/YYYY');
        let dateValid: Date;
        if (date.isValid()) {
          dateValid = date.toDate();
        } else {
          dateValid =  moment.utc(resultDate, 'YYYY-MM-DD').toDate();
        }
        resultDate = _.cloneDeep(dateValid);
        resultDate.setMonth(resultDate.getMonth() + 12)
        return resultDate;
      }
    } else if (actionType  === EquipmentPerformances.QUALIFICATION){
      if (this.equipmentQualification && this.equipmentQualification.expirationDate) {
        if (this.equipmentQualification.expirationDate instanceof Date) {
          resultDate = moment(this.equipmentQualification.expirationDate).format('DD/MM/YYYY');
        } else {
          resultDate = this.equipmentQualification.expirationDate;
        }
  
        const date = moment.utc(resultDate, 'DD/MM/YYYY');
        let dateValid: Date;
        if (date.isValid()) {
          dateValid = date.toDate();
        } else {
          dateValid =  moment.utc(resultDate, 'YYYY-MM-DD').toDate();
        }
        resultDate = _.cloneDeep(dateValid);
        resultDate.setMonth(resultDate.getMonth() + 12);
        return resultDate;
      }
    } else if (actionType === EquipmentPerformances.CHARACTERIZATION) {
      if (this.equipmentCharacterization && this.equipmentCharacterization.expirationDate) {
        if (this.equipmentCharacterization.expirationDate instanceof Date) {
          resultDate = moment(this.equipmentCharacterization.expirationDate).format('DD/MM/YYYY');
        } else {
          resultDate = this.equipmentCharacterization.expirationDate;
        }
  
        const date = moment.utc(resultDate, 'DD/MM/YYYY');
        let dateValid: Date;
        if (date.isValid()) {
          dateValid = date.toDate();
        } else {
          dateValid =  moment.utc(resultDate, 'YYYY-MM-DD').toDate();
        }
        resultDate = _.cloneDeep(dateValid);
        resultDate.setMonth(resultDate.getMonth() + 12);
        return resultDate;
      }
    }
  }

  deleteExternalCalibrationWithReason(idExternalCalibration: number): void {
    this.requestReason((reason) => {
      this.requestSign((token) => {
        event.stopPropagation();

        this.spinnerService.show();
    
        this.externalCalibrationService.deleteWithReason(idExternalCalibration,reason).pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.snackBarService.sendSuccess(this.translate.instant('calibrateEquipmentList.dialog.delete.ok') as string);
          this.spinnerService.hide();
          this.reloadEquipment();
        }, () => {
          this.snackBarService.sendError(this.translate.instant('calibrateEquipmentList.dialog.delete.error') as string);
          this.spinnerService.hide();
        });
      })
    });
  }

  private save(isNew: boolean) {
    this.requestReason((reason) => {
      this.requestSign(() => {
        this.spinnerService.show();
          this.equipmentService.save(this.equipment, reason).pipe(takeUntil(this.destroy$)).subscribe((res: InternalEquipment) => {
            this.equipment = res;
            if (this.externalCalibration) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              this.externalCalibrationService.save(this.externalCalibration, this.externalCalibration.uploadFile)
              .pipe(takeUntil(this.destroy$)).subscribe(() => {
                if (isNew) {
                  this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.create.ok') as string);
                } else {
                  this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.update.ok') as string);
                }

                if (StringUtils.isNotEmpty(this.returnType)) {
                  if (this.returnType === 'createCalibration') {
                    let url: string;
                    if (this.router.url.includes('calibrates')){
                      url = `calibrates/calibration?id=0&idEquipment=${this.equipment.id}`
                    } else if (this.router.url.includes('thermal')) {
                      url = `thermal/calibration?id=0&idEquipment=${this.equipment.id}`
                    }
                    void this.router.navigateByUrl(url);

                    return;
                  }
                }
                this.spinnerService.hide();
                this.reloadEquipment();
              }, () => {
                const isCreation = this.externalCalibration.id != null;

                if (isCreation) {
                  this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.create.error') as string);
                } else {
                  this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.update.error') as string);
                }
                this.spinnerService.hide();
              });
            } else {
              if (isNew) {
                this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.create.ok') as string);
              } else {
                this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.update.ok') as string);
              }

              if (StringUtils.isNotEmpty(this.returnType)) {
                if (this.returnType === 'createCalibration') {
                  let url: string;
                  if (this.router.url.includes('calibrates')){
                    url = `calibrates/calibration?id=0&idEquipment=${this.equipment.id}`
                  } else if (this.router.url.includes('thermal')) {
                    url = `thermal/calibration?id=0&idEquipment=${this.equipment.id}`
                  }
                  void this.router.navigateByUrl(url);
                  this.spinnerService.hide();
                  return;
                }
              }
              this.reloadEquipment();
            }
          }, err => {
            if (err != null && err.error != null && typeof err.error === 'string') {
              this.snackBarService.sendError(err.error as string);
              this.spinnerService.hide();

            } else if (isNew) {
              this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.create.error') as string);
              this.spinnerService.hide();

            } else {
              this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.update.error') as string);
              this.spinnerService.hide();

            }
          }, () => {
            this.spinnerService.hide();
          });
      });
    });

  }

  private reloadEquipment() {
    const type = this.getTypeEquipment();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    let url: string;
    if (this.router.url.includes('calibrates')){
      url = `calibrates/${type}Equipment?id=${this.equipment.id}`;
    } else if (this.router.url.includes('thermal')) {
      url = `thermal/${type}Equipment?id=${this.equipment.id}`;
    } else if (this.router.url.includes('air')) {
      url = `air/${type}Equipment?id=${this.equipment.id}`;
    }
    void this.router.navigateByUrl(url);
  }

  private loadCalibrations(id: number = this.equipment.id) {
    this.calibrations = [];

    if (id == null || id === 0) {
      return;
    }

    this.calibrationService.findAllFromEquipment(id).pipe(takeUntil(this.destroy$)).subscribe((calibs: Calibration[]) => {

      calibs.forEach(calib => {
        calib.internal = true;
        calib.variables?.forEach((variable, vIndex) => {

          if (variable.valuesMultichannel) {
            variable.valuesMultichannel?.forEach(config => {
              config.asLeft = config?.asLeft?.map(aL => {
                aL = CalibrationUtils.objectToAsLeft(aL);

                const foundConfig = this.findConfig(calib, vIndex, aL.point);

                aL.resolution = foundConfig.resolution;

                const patterns = variable?.patterns?.map(p => {
                  if (ArrayUtils.isEmpty(p.values)) {
                    return null;
                  }

                  const res = p.values?.filter(point => point.value === aL.point);

                  if (ArrayUtils.isEmpty(res)) {
                    return null;
                  } else {
                    return res[0];
                  }

                })?.filter(p => p != null)?.map(p => p.uncertainty);

                aL.patterns = patterns;

                return aL;
              });
            });
          } else {
            variable.config?.forEach(config => {
              config.asLeft = config.asLeft?.map(aL => {
                aL = CalibrationUtils.objectToAsLeft(aL);

                const foundConfig = this.findConfig(calib, vIndex, aL.point);

                aL.resolution = foundConfig.resolution;

                const patterns = variable.patterns?.map(p => {
                  if (ArrayUtils.isEmpty(p.values)) {
                    return null;
                  }

                  const res = p.values?.filter(point => point.value === aL.point);

                  if (ArrayUtils.isEmpty(res)) {
                    return null;
                  } else {
                    return res[0];
                  }

                })?.filter(p => p != null)?.map(p => p.uncertainty);

                aL.patterns = patterns;

                return aL;
              });
            });
          }
        });
        this.calibrations.push(calib);
      });

      this.calibrations.sort((a, b) => DateUtils.anyToDate(a.calibrationDate).getTime() - DateUtils.anyToDate(b.calibrationDate).getTime())
        .reverse();

      for (let i = 0; i < this.calibrations.length; i++) {
        this.mapCalibrationsAccordion.set(i, i === 0);
      }

    });

    this.externalCalibrationService.findAllFromEquipment(id).pipe(takeUntil(this.destroy$)).subscribe((calibs: ExternalCalibration[]) => {
      calibs.forEach(calib => {

        calib = CalibrationUtils.objectToExternalCalibration(calib);

        calib.internal = false;
        this.calibrations.push(calib);
      });

      // eslint-disable-next-line max-len
      this.calibrations.sort((a, b) => DateUtils.anyToDate(a.calibrationDate)?.getTime() - DateUtils.anyToDate(b.calibrationDate)?.getTime()).reverse();

      for (let i = 0; i < this.calibrations.length; i++) {
        this.mapCalibrationsAccordion.set(i, i === 0);
      }
    });

    this.isothermalCharacterizationService.historicalEquipment(id).pipe(takeUntil(this.destroy$)).subscribe((calibs: ICalibration[]) => {
      calibs?.forEach(iso => {
        this.calibrations.push(iso);
      });
    });

    this.executionService.historicalEquipment(id).pipe(takeUntil(this.destroy$)).subscribe((calibs: ICalibration[]) => {
      calibs?.forEach(iso => {
        this.calibrations.push(iso);
      });
    });

  }

  private getTypeEquipment() {
    let res = '';

    if (this.isExternal) {
      res = 'external';
    } else if (this.isInternal) {
      res = 'internal';
    }

    return res;
  }

  private findConfig(calibration: Calibration, indexVariable: number, point: number): CalibrationVariableConfig {
    const configs = calibration.variables[indexVariable].config;

    let config: CalibrationVariableConfig = null;

    if (!ArrayUtils.isEmpty(configs)) {
      if (point != null) {
        config = configs.find(c => NumberUtils.isBetweenEq(point, c.rangeInit, c.rangeEnd));
      }

      if (config == null && point != null) {
        config = configs.find(c => c.asLeft.map(aL => aL.point).includes(point));
      }

      if (config == null) {
        config = configs[0];
      }
    }

    return config;
  }

  private requestReason(callback: (reason: string) => void) {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

  private requestSign(callback: (token: string) => void) {
    this.spinnerService.hide();

    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

  private isResponsibleEquipment(): boolean {
    if (this.equipment?.responsibleEquipment) {
      if (this.equipment.responsibleEquipment === this.userService.currentProfile.username) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  private requestResponsibleEquipment(callback: (result: boolean) => void) {
    const message = this.translate.instant('internalEquipmentEdit.dialog.confirmeResponsible') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((response: boolean) => {
      callback(response);
    });
  }

  private requestSaveIfNotResponsibleEquipment(callback: (result: boolean) => void) {
    const message = this.translate.instant('internalEquipmentEdit.dialog.NoconfirmeResponsible') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((response: boolean) => {
      callback(response);
    });
  }

  private updateTable(table: MatTable<any> | QueryList<MatTable<any>>) {
    if (table instanceof QueryList) {
      table?.forEach(t => t.renderRows());
    } else {
      table?.renderRows();
    }
  }

}
