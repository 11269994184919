<mat-tab-group #protocolTabs (selectedTabChange)="onTabChange($event)">
    <mat-tab #protocolTab>

        <ng-template mat-tab-label>{{ 'protocolEdit.general.title' | translate }} </ng-template>

        <form [formGroup]="form">

            <mat-card class="card">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'protocolEdit.general.title' | translate }}
                    </mat-toolbar>

                    <div fxLayout="row" class="flexwrap">

                        <div fxFlex="50" fxFlex.lt-md="100%">
                            <div fxLayout="column">
                                <div fxLayout="row" class="flexwrap">
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <div fxLayout="row" class="flexwrap">
                                            <div fxFlex="50" fxFlex.lt-md="100%">
                                                <div fxLayout="row" class="flexwrap">
                                                    <div fxFlex>
                                                        <mat-form-field>
                                                            <input matInput formControlName="documentCode" required
                                                                [(ngModel)]="protocol.documentCode"
                                                                [errorStateMatcher]="matcher"
                                                                placeholder="{{ 'protocolEdit.general.documentCode.label' | translate }}">
                                                            <mat-error>
                                                                {{ 'protocolEdit.general.documentCode.error' | translate
                                                                }}
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div fxFlex="40px" *ngIf="canEditDocumentCode()">
                                                        <button mat-icon-button (click)="editDocumentCode()">
                                                            <mat-icon>mode_edit</mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div fxFlex="50" fxFlex.lt-md="100%">
                                                <mat-form-field class="campoFormulario">
                                                    <input matInput formControlName="clientDocumentCode"
                                                        [(ngModel)]="protocol.clientDocumentCode"
                                                        [errorStateMatcher]="matcher"
                                                        placeholder="{{ 'protocolEdit.general.clientDocumentCode.label' | translate }}">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>

                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'protocolEdit.general.client.label' | translate }}</mat-label>
                                            <mat-select #clientSelect required formControlName="idClient"
                                                [(ngModel)]="protocol.idClient"
                                                [disabled]="+protocol.signedVersion > 0">
                                                <mat-select-filter *ngIf="clientSelect.focused" [displayMember]="'name'"
                                                    [array]="clients" (filteredReturn)="clientsFiltered =$event"
                                                    [noResultsMessage]="'common.noResults' | translate"
                                                    [placeholder]="'common.selectFilterPlaceholder' | translate">
                                                </mat-select-filter>
                                                <mat-option *ngFor="let client of clientsFiltered" [value]="client.id">
                                                    {{ client.name }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'protocolEdit.general.client.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'protocolEdit.general.process.label' | translate }}
                                            </mat-label>
                                            <mat-select required formControlName="idProcess"
                                                [(ngModel)]="protocol.idProcess">
                                                <mat-option>--</mat-option>
                                                <mat-option *ngFor="let item of processes" [value]="item.id">
                                                    {{ 'processType.' + item.translation | translate }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'protocolEdit.general.process.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput formControlName="projectNo" [(ngModel)]="protocol.projectNo"
                                                required [errorStateMatcher]="matcher"
                                                placeholder="{{ 'protocolEdit.general.projectNo.label' | translate }}">
                                            <mat-error>
                                                {{ 'protocolEdit.general.projectNo.label' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'protocolEdit.general.projectManager.label' | translate }}
                                            </mat-label>
                                            <mat-select formControlName="projectManager"
                                                [(ngModel)]="protocol.usernameProjectManager">
                                                <mat-option>--</mat-option>
                                                <mat-option *ngFor="let item of respField" [value]="item.username">
                                                    {{ item.fullName }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div fxFlex="50" fxFlex.lt-md="100%">
                            <div fxLayout="column">

                                <div fxLayout="row" class="flexwrap">
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput formControlName="regUser" [(ngModel)]="protocol.regUser"
                                                placeholder="{{ 'protocolEdit.general.regUser.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput formControlName="modUser" [(ngModel)]="protocol.modUser"
                                                placeholder="{{ 'protocolEdit.general.modUser.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div fxLayout="row" class="flexwrap">
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput formControlName="regFc" [ngModel]="protocol.regFc | fixDate"
                                                placeholder="{{ 'protocolEdit.general.regFc.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput formControlName="modFc" [ngModel]="protocol.modFc | fixDate"
                                                placeholder="{{ 'protocolEdit.general.modFc.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div fxLayout="row" class="flexwrap">
                                    <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="protocol.statusTranslation != null">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="status" disabled
                                                placeholder="{{ 'protocolEdit.general.status.label' | translate }}"
                                                value="{{ 'protocolStatus.' + protocol.statusTranslation | translate }}">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="50" fxFlex.lt-md="100%"
                                        *ngIf="protocol.currentVersion != '0' && protocol.currentVersion != null">
                                        <div fxLayout="row">
                                            <div fxFlex="50">
                                                <mat-form-field class="campoFormulario">
                                                    <input matInput formControlName="currentVersion"
                                                        [ngModel]="protocol.currentVersion"
                                                        placeholder="{{ 'protocolEdit.general.currentVersion.label' | translate }}">
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="50">
                                                <mat-form-field class="campoFormulario">
                                                    <input matInput formControlName="signedVersion"
                                                        [ngModel]="protocol.signedVersion"
                                                        placeholder="{{ 'protocolEdit.general.signedVersion.label' | translate }}">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>


                </mat-card-content>
            </mat-card>

            <mat-card class="card">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'protocolEdit.referenceDocs.title' | translate }}

                        <button mat-icon-button color="accent" (click)="openNewRefDoc()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </mat-toolbar>

                    <br />

                    <div fxLayout="row">

                        <div fxFlex fxFlex.lt-md="100%">

                            <table mat-table matSort #refDocTable [dataSource]="protocol.referenceDocs"
                                style="width: 95%;">

                                <!-- code Column -->
                                <ng-container matColumnDef="code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        {{ 'protocolEdit.referenceDocs.code.label' | translate }}
                                    </th>
                                    <td mat-cell *matCellDef="let item"> {{ item.code }} </td>
                                </ng-container>

                                <!-- name Column -->
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        {{ 'protocolEdit.referenceDocs.name.label' | translate }}
                                    </th>
                                    <td mat-cell *matCellDef="let item" style="white-space: pre;"> {{ item.name }} </td>
                                </ng-container>

                                <!-- Button edit Column -->
                                <ng-container matColumnDef="edit">
                                    <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
                                    <td mat-cell *matCellDef="let element; let refDocIndex = index">
                                        <button mat-icon-button (click)="editRefDoc(refDocIndex)">
                                            <mat-icon>mode_edit</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <!-- Button delete Column -->
                                <ng-container matColumnDef="delete">
                                    <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
                                    <td mat-cell *matCellDef="let element; let refDocIndex = index">
                                        <button mat-icon-button (click)="removeRefDoc(refDocIndex)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColsRefDoc"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColsRefDoc;"></tr>

                            </table>

                        </div>

                    </div>

                </mat-card-content>

            </mat-card>

            <mat-card class="card">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'protocolEdit.area.title' | translate }}

                        <button mat-icon-button color="accent" (click)="openDialogArea()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </mat-toolbar>

                    <mat-tab-group #areaTabs (selectedTabChange)="onAreaTabChanged($event)">
                        <mat-tab *ngFor="let area of protocol.areas; let indexArea = index">

                            <ng-template mat-tab-label>
                                {{ area.name }}

                                <button mat-icon-button color="accent" *ngIf="areaTab === indexArea"
                                    (click)="openDialogEditArea(indexArea)">
                                    <mat-icon>mode_edit</mat-icon>
                                </button>

                                <button mat-icon-button color="accent" *ngIf="areaTab === indexArea"
                                    (click)="deleteArea(indexArea)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </ng-template>

                            <br />

                            <mat-toolbar color="secondary">
                                {{ 'protocolEdit.equipment.title' | translate }}

                                <button mat-icon-button color="accent" (click)="openDialogEquipment(indexArea)">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </mat-toolbar>

                            <!-- equipos area -->
                            <mat-accordion>
                                <mat-expansion-panel class="overflowX"
                                    *ngFor="let equipment of area.equipments; let indexEquipment = index"
                                    (afterExpand)="equipmentTab = indexEquipment" (closed)="equipmentTab = null">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <div style="margin: auto 0;display: block;">
                                                {{ showEquipment(equipment) }}
                                                -
                                                {{ ('equipmentType.' + getEquipmentType(equipment)) | translate }}
                                            </div>

                                            <button mat-icon-button color="accent"
                                                *ngIf="equipmentTab === indexEquipment"
                                                (click)="openDialogEditEquipment(indexArea, indexEquipment)">
                                                <mat-icon style="font-size: 20px;">mode_edit</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                *ngIf="equipmentTab === indexEquipment"
                                                (click)="deleteEquipment(indexArea, indexEquipment)">
                                                <mat-icon style="font-size: 20px;">delete</mat-icon>
                                            </button>
                                            <button mat-icon-button color="accent"
                                                *ngIf="equipmentTab === indexEquipment && false"
                                                (click)="duplicateEquipment(indexArea, indexEquipment)">
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="equipmentTab === indexEquipment"
                                                (click)="moveToTopEquipment(indexArea, indexEquipment)" [disabled]="indexEquipment === 0">
                                                <mat-icon style="transform: rotate(90deg);font-size: 20px;">first_page
                                                </mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="equipmentTab === indexEquipment"
                                                (click)="movePreviousEquipment(indexArea, indexEquipment)"
                                                [disabled]="indexEquipment === 0">
                                                <mat-icon style="font-size: 20px;">keyboard_arrow_up</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="equipmentTab === indexEquipment"
                                                (click)="moveNextEquipment(indexArea, indexEquipment)"
                                                [disabled]="indexEquipment === (area.equipments.length - 1)">
                                                <mat-icon style="font-size: 20px;">keyboard_arrow_down</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="equipmentTab === indexEquipment"
                                                (click)="moveToBottomEquipment(indexArea, indexEquipment)"
                                                [disabled]="indexEquipment === (area.equipments.length - 1)">
                                                <mat-icon style="transform: rotate(90deg);font-size: 20px;">last_page
                                                </mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                *ngIf="equipmentTab === indexEquipment"
                                                (click)="openDialogEssayEquipment(indexEquipment, indexArea)">
                                                <mat-icon style="font-size: 20px;">add</mat-icon>
                                            </button>

                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <!-- TABLA DE ENSAYOS CREADOS -->
                                    <table mat-table matSort #filtersTableEq  [dataSource]="[equipment]" class="custom-drag-table" cdkDropListOrientation="horizontal" cdkDropList (cdkDropListDropped)="dropEquipment($event, indexArea, indexEquipment)">

                                        <!-- essay Column -->
                                        <ng-container matColumnDef="essay">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'protocolEdit.essays.title.generic' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item">
                                                {{ 'protocolEdit.essays.criteria.label' | translate }}
                                            </td>
                                        </ng-container>

                                        <!-- defPresassure Column -->
                                        <ng-container matColumnDef="defPresassure">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.defPresassure' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'defPresassure')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'defPresassure') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- airSpeed Column -->
                                        <ng-container matColumnDef="airSpeed">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.airSpeed' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'airSpeed')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'airSpeed') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- hepaFilterIntegrity Column -->
                                        <ng-container matColumnDef="hepaFilterIntegrity">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.hepaFilterIntegrity' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'hepaFilterIntegrity')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'hepaFilterIntegrity') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- renFlowAndRate Column -->
                                        <ng-container matColumnDef="renFlowAndRate">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.renFlowAndRate' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'renFlowAndRate')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'renFlowAndRate') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- particleCountRepose Column -->
                                        <ng-container matColumnDef="particleCountRepose">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.particleCountRepose' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'particleCountRepose')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'particleCountRepose') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- particleCountWorking Column -->
                                        <ng-container matColumnDef="particleCountWorking">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.particleCountWorking' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'particleCountWorking')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'particleCountWorking') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- wasteOfLoad Column -->
                                        <ng-container matColumnDef="wasteOfLoad">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.wasteOfLoad' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'wasteOfLoad')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'wasteOfLoad') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- smokeEssayRepose Column -->
                                        <ng-container matColumnDef="smokeEssayRepose">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.smokeEssayRepose' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'smokeEssayRepose')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'smokeEssayRepose') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- smokeEssayWorking Column -->
                                        <ng-container matColumnDef="smokeEssayWorking">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.smokeEssayWorking' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'smokeEssayWorking')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'smokeEssayWorking') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- temperature Column -->
                                        <ng-container matColumnDef="temperature">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.temperature' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'temperature')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'temperature') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- humidity Column -->
                                        <ng-container matColumnDef="humidity">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.humidity' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'humidity')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'humidity') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- light Column -->
                                        <ng-container matColumnDef="light">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.light' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'light')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'light') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- noise Column -->
                                        <ng-container matColumnDef="noise">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.noise' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'noise')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'noise') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- recoveryEssay Column -->
                                        <ng-container matColumnDef="recoveryEssay">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.recoveryEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'recoveryEssay')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'recoveryEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- uvIntensity Column -->
                                        <ng-container matColumnDef="uvIntensity">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.uvIntensity' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'uvIntensity')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'uvIntensity') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- introductionEssay Column -->
                                        <ng-container matColumnDef="introductionEssay">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.introductionEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'introductionEssay')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'introductionEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- electricEssay Column -->
                                        <ng-container matColumnDef="electricEssay">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.electricEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'electricEssay')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'electricEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- filterSaturation Column -->
                                        <ng-container matColumnDef="filterSaturation">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.filterSaturation' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'filterSaturation')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'filterSaturation') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- parallelismTest Column -->
                                        <ng-container matColumnDef="parallelismTest">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.parallelismTest' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'parallelismTest')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'parallelismTest') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- microbioEssay Column -->
                                        <ng-container matColumnDef="microbioEssay">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.microbioEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'microbioEssay')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'microbioEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- formolizatioEquipment Column -->
                                        <ng-container matColumnDef="formolizatioEquipment">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.formolizatioEquipment' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'formolizatioEquipment')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'formolizatioEquipment') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- anexCountRepose Column -->
                                        <ng-container matColumnDef="anexCountRepose">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.anexCountRepose' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'anexCountRepose')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'anexCountRepose') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- anexCountWorking Column -->
                                        <ng-container matColumnDef="anexCountWorking">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.anexCountWorking' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'anexCountWorking')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'anexCountWorking') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- others Column -->
                                        <ng-container matColumnDef="others">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.others' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'others')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'others') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="getDisplayColumns(equipment.essays)">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: getDisplayColumns(equipment.essays);">
                                        </tr>

                                    </table>

                                </mat-expansion-panel>
                            </mat-accordion>

                            <br /><br /><br />

                            <!-- salas area -->
                            <mat-toolbar color="secondary">
                                {{ 'protocolEdit.room.title' | translate }}

                                <button mat-icon-button color="accent" (click)="openDialogRoom(indexArea)">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </mat-toolbar>
                            <mat-accordion>
                                <mat-expansion-panel class="overflowX"
                                    *ngFor="let room of area.rooms; let indexRoom = index"
                                    (afterExpand)="roomTab = indexRoom" (closed)="roomTab = null">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{room.name}}
                                            <button mat-icon-button color="accent" *ngIf="roomTab === indexRoom"
                                                (click)="openDialogEditRoom(indexArea, indexRoom)">
                                                <mat-icon style="font-size: 20px;">mode_edit</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="roomTab === indexRoom"
                                                (click)="deleteRoom(indexArea, indexRoom)">
                                                <mat-icon style="font-size: 20px;">delete</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                *ngIf="roomTab === indexRoom && false"
                                                (click)="duplicateRoom(indexArea, indexRoom)">
                                                <mat-icon style="font-size: 20px;">content_copy</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="roomTab === indexRoom"
                                                (click)="moveToTop(indexArea, indexRoom)" [disabled]="indexRoom === 0">
                                                <mat-icon style="transform: rotate(90deg);font-size: 20px;">first_page
                                                </mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="roomTab === indexRoom"
                                                (click)="movePrevious(indexArea, indexRoom)"
                                                [disabled]="indexRoom === 0">
                                                <mat-icon style="font-size: 20px;">keyboard_arrow_up</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="roomTab === indexRoom"
                                                (click)="moveNext(indexArea, indexRoom)"
                                                [disabled]="indexRoom === (area.rooms.length - 1)">
                                                <mat-icon style="font-size: 20px;">keyboard_arrow_down</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="roomTab === indexRoom"
                                                (click)="moveToBottom(indexArea, indexRoom)"
                                                [disabled]="indexRoom === (area.rooms.length - 1)">
                                                <mat-icon style="transform: rotate(90deg);font-size: 20px;">last_page
                                                </mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="roomTab === indexRoom"
                                                (click)="openDialogEssayRoom(indexRoom, indexArea)">
                                                <mat-icon style="font-size: 20px;">add</mat-icon>
                                            </button>

                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
                                        <div fxFlex="16" *ngFor="let volume of room.volumes; let indexVolume = index">
                                            <strong>Área {{ indexVolume + 1 }}:</strong><br />
                                            {{ 'protocolEdit.dialog.room.height.label' | translate }}:
                                            {{ volume?.height | number:'1.2-2' }} m <br />
                                            {{ 'protocolEdit.dialog.room.surface.label' | translate }}:
                                            {{ volume?.surface | number:'1.2-2' }} m² <br />
                                            {{ 'protocolEdit.dialog.room.volume.label' | translate }}:
                                            {{ volume?.volume | number:'1.1-1' }} m³
                                        </div>
                                        <div fxFlex="16">
                                            <div fxLayout="row" class="flexwrap">
                                                <div fxFlex>
                                                    <mat-form-field class="campoFormulario">
                                                        <input matInput name="totalSurface{{indexRoom}}"
                                                            [ngModel]="getTotalSurface(room) | number:'1.2-2'" disabled
                                                            [ngModelOptions]="{standalone: true}"
                                                            placeholder="{{ 'protocolEdit.dialog.room.totalSurface.label' | translate }}">
                                                    </mat-form-field>
                                                </div>
                                                <div fxFlex>
                                                    <mat-form-field class="campoFormulario">
                                                        <input matInput name="totalVolume{{indexRoom}}"
                                                            [ngModel]="getTotalVolume(room) | number:'1.1-1'" disabled
                                                            [ngModelOptions]="{standalone: true}"
                                                            placeholder="{{ 'protocolEdit.dialog.room.totalVolume.label' | translate }}">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- TABLA DE ENSAYOS CREADOS -->
                                    <table mat-table matSort #filtersTableRoom [dataSource]="[room]" class="custom-drag-table" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="dropRoomEssay($event, indexArea, indexRoom)">

                                        <!-- essay Column -->
                                        <ng-container matColumnDef="essay">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'protocolEdit.essays.title.generic' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item">
                                                {{ 'protocolEdit.essays.criteria.label' | translate }}
                                            </td>
                                        </ng-container>

                                        <!-- defPresassure Column -->
                                        <ng-container matColumnDef="defPresassure">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.defPresassure' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'defPresassure')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'defPresassure') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- airSpeed Column -->
                                        <ng-container matColumnDef="airSpeed">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.airSpeed' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'airSpeed')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'airSpeed') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- hepaFilterIntegrity Column -->
                                        <ng-container matColumnDef="hepaFilterIntegrity">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.hepaFilterIntegrity' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'hepaFilterIntegrity')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'hepaFilterIntegrity') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- renFlowAndRate Column -->
                                        <ng-container matColumnDef="renFlowAndRate">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.renFlowAndRate' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'renFlowAndRate')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'renFlowAndRate') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- particleCountRepose Column -->
                                        <ng-container matColumnDef="particleCountRepose">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.particleCountRepose' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'particleCountRepose')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'particleCountRepose') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- particleCountWorking Column -->
                                        <ng-container matColumnDef="particleCountWorking">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.particleCountWorking' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'particleCountWorking')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'particleCountWorking') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- wasteOfLoad Column -->
                                        <ng-container matColumnDef="wasteOfLoad">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.wasteOfLoad' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'wasteOfLoad')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'wasteOfLoad') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- smokeEssayRepose Column -->
                                        <ng-container matColumnDef="smokeEssayRepose">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.smokeEssayRepose' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'smokeEssayRepose')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'smokeEssayRepose') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- smokeEssayWorking Column -->
                                        <ng-container matColumnDef="smokeEssayWorking">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.smokeEssayWorking' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'smokeEssayWorking')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'smokeEssayWorking') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- temperature Column -->
                                        <ng-container matColumnDef="temperature">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.temperature' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'temperature')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'temperature') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- humidity Column -->
                                        <ng-container matColumnDef="humidity">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.humidity' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'humidity')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'humidity') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- light Column -->
                                        <ng-container matColumnDef="light">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.light' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'light')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'light') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- noise Column -->
                                        <ng-container matColumnDef="noise">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.noise' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'noise')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'noise') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- recoveryEssay Column -->
                                        <ng-container matColumnDef="recoveryEssay">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.recoveryEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'recoveryEssay')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'recoveryEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- uvIntensity Column -->
                                        <ng-container matColumnDef="uvIntensity">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.uvIntensity' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'uvIntensity')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'uvIntensity') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- introductionEssay Column -->
                                        <ng-container matColumnDef="introductionEssay">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.introductionEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'introductionEssay')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'introductionEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- electricEssay Column -->
                                        <ng-container matColumnDef="electricEssay">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.electricEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'electricEssay')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'electricEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- filterSaturation Column -->
                                        <ng-container matColumnDef="filterSaturation">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.filterSaturation' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'filterSaturation')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'filterSaturation') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- parallelismTest Column -->
                                        <ng-container matColumnDef="parallelismTest">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.parallelismTest' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'parallelismTest')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'parallelismTest') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- microbioEssay Column -->
                                        <ng-container matColumnDef="microbioEssay">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.microbioEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'microbioEssay')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'microbioEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- formolizatioEquipment Column -->
                                        <ng-container matColumnDef="formolizatioEquipment">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.formolizatioEquipment' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'formolizatioEquipment')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'formolizatioEquipment') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- anexCountRepose Column -->
                                        <ng-container matColumnDef="anexCountRepose">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.anexCountRepose' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'anexCountRepose')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'anexCountRepose') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- anexCountWorking Column -->
                                        <ng-container matColumnDef="anexCountWorking">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.anexCountWorking' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'anexCountWorking')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'anexCountWorking') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>


                                        <!-- others Column -->
                                        <ng-container matColumnDef="others">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.others' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'others')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'others') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="getDisplayColumns(room.essays)"></tr>
                                        <tr mat-row *matRowDef="let row; columns: getDisplayColumns(room.essays);">
                                        </tr>

                                    </table>

                                </mat-expansion-panel>
                            </mat-accordion>

                            <br /><br /><br />

                            <!-- gases area -->
                            <mat-toolbar color="secondary">
                                {{ 'protocolEdit.compressedGas.title' | translate }}

                                <button mat-icon-button color="accent" (click)="openDialogCompressedGas(indexArea)">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </mat-toolbar>
                            <mat-accordion>
                                <mat-expansion-panel class="overflowX"
                                    *ngFor="let compressedGas of area.compressedGases; let indexCompressedGas = index"
                                    (afterExpand)="compressedGasTab = indexCompressedGas"
                                    (closed)="compressedGasTab = null">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{compressedGas.code}}
                                            <button mat-icon-button color="accent"
                                                *ngIf="compressedGasTab === indexCompressedGas"
                                                (click)="openDialogEditCompressedGas(indexArea, indexCompressedGas)">
                                                <mat-icon style="font-size: 20px;">mode_edit</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                *ngIf="compressedGasTab === indexCompressedGas"
                                                (click)="deleteCompressedGas(indexArea, indexCompressedGas)">
                                                <mat-icon style="font-size: 20px;">delete</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                *ngIf="compressedGasTab === indexCompressedGas && false"
                                                (click)="duplicateCompressedGas(indexArea, indexCompressedGas)">
                                                <mat-icon style="font-size: 20px;">content_copy</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                *ngIf="compressedGasTab === indexCompressedGas"
                                                (click)="moveToTopCompressedGas(indexArea, indexCompressedGas)"
                                                [disabled]="indexCompressedGas === 0">
                                                <mat-icon style="transform: rotate(90deg);font-size: 20px;">
                                                    first_page
                                                </mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                *ngIf="compressedGasTab === indexCompressedGas"
                                                (click)="movePreviousCompressedGas(indexArea, indexCompressedGas)"
                                                [disabled]="indexCompressedGas === 0">
                                                <mat-icon style="font-size: 20px;">keyboard_arrow_up</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                *ngIf="compressedGasTab === indexCompressedGas"
                                                (click)="moveNextCompressedGas(indexArea, indexCompressedGas)"
                                                [disabled]="indexCompressedGas === (area.compressedGases.length - 1)">
                                                <mat-icon style="font-size: 20px;">keyboard_arrow_down
                                                </mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                *ngIf="compressedGasTab === indexCompressedGas"
                                                (click)="moveToBottomCompressedGas(indexArea, indexCompressedGas)"
                                                [disabled]="indexCompressedGas === (area.compressedGases.length - 1)">
                                                <mat-icon style="transform: rotate(90deg);font-size: 20px;">
                                                    last_page
                                                </mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                *ngIf="compressedGasTab === indexCompressedGas"
                                                (click)="openDialogEssayCompressedGas(indexCompressedGas, indexArea)">
                                                <mat-icon style="font-size: 20px;">add</mat-icon>
                                            </button>

                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <!-- TABLA DE ENSAYOS CREADOS -->
                                    <table mat-table matSort #filtersTableCGas [dataSource]="[compressedGas]" cdkDropListOrientation="horizontal" class="custom-drag-table" cdkDropList (cdkDropListDropped)="dropcompressedGasesEssay($event, indexArea, indexCompressedGas)">

                                        <!-- essay Column -->
                                        <ng-container matColumnDef="essay">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'protocolEdit.essays.title.generic' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item">
                                                {{ 'protocolEdit.essays.criteria.label' | translate }}
                                            </td>
                                        </ng-container>

                                        <!-- defPresassure Column -->
                                        <ng-container matColumnDef="defPresassure">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.defPresassure' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'defPresassure')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'defPresassure') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- airSpeed Column -->
                                        <ng-container matColumnDef="airSpeed">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.airSpeed' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'airSpeed')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'airSpeed') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- particleCount Column -->
                                        <ng-container matColumnDef="particleCount">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.particleCount' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'particleCount')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'particleCount') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- microbioEssay Column -->
                                        <ng-container matColumnDef="microbioEssay">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.microbioEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'microbioEssay')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'microbioEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- hydrocarbonLevel Column -->
                                        <ng-container matColumnDef="hydrocarbonLevel">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.hydrocarbonLevel' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'hydrocarbonLevel')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'hydrocarbonLevel') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- dewPoint Column -->
                                        <ng-container matColumnDef="dewPoint">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.dewPoint' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'dewPoint')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'dewPoint') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- linePreasure Column -->
                                        <ng-container matColumnDef="linePreasure">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.linePreasure' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'linePreasure')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'linePreasure') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- coConcentration Column -->
                                        <ng-container matColumnDef="coConcentration">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.coConcentration' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'coConcentration')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'coConcentration') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- co2Concentration Column -->
                                        <ng-container matColumnDef="co2Concentration">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.co2Concentration' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'co2Concentration')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'co2Concentration') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- noConcentration Column -->
                                        <ng-container matColumnDef="noConcentration">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.noConcentration' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'noConcentration')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'noConcentration') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- no2Concentration Column -->
                                        <ng-container matColumnDef="no2Concentration">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.no2Concentration' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'no2Concentration')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'no2Concentration') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- anexCount Column -->
                                        <ng-container matColumnDef="anexCount">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.anexCount' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'anexCount')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'anexCount') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- others Column -->
                                        <ng-container matColumnDef="others">
                                            <th mat-header-cell *matHeaderCellDef cdkDrag>
                                                {{ 'essayAir.others' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'others')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'others') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="getDisplayColumns(compressedGas.essays)">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: getDisplayColumns(compressedGas.essays);">
                                        </tr>

                                    </table>

                                </mat-expansion-panel>
                            </mat-accordion>

                            <br /><br /><br />

                        </mat-tab>
                    </mat-tab-group>

                </mat-card-content>
            </mat-card>

            <app-protocol-edit-attachments #attachmentsComponent [disableOfflineButton]="disableOfflineButton">
            </app-protocol-edit-attachments>

            <mat-card class="card">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'protocolEdit.observations.title' | translate }}
                    </mat-toolbar>

                    <div fxLayout="column">
                        <div fxFlex="100%">
                            <mat-form-field class="campoFormulario" style="width: 100%">
                                <textarea rows="5" matAutosizeMinRows="5" matInput
                                    placeholder="{{ 'protocolEdit.observations.label' | translate }}"
                                    [(ngModel)]="protocol.observations" formControlName="observations"
                                    [errorStateMatcher]="matcher" style="resize: none;">
                      </textarea>
                            </mat-form-field>
                        </div>
                    </div>

                </mat-card-content>
            </mat-card>

            <mat-card class="card">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'protocolEdit.notes.title' | translate }}
                    </mat-toolbar>

                    <div fxLayout="column">
                        <div fxFlex="100%">
                            <mat-form-field class="campoFormulario" style="width: 100%">
                                <textarea rows="5" matAutosizeMinRows="5" matInput
                                    placeholder="{{ 'protocolEdit.notes.label' | translate }}"
                                    [(ngModel)]="protocol.notes" formControlName="notes" [errorStateMatcher]="matcher"
                                    style="resize: none;">
                      </textarea>
                            </mat-form-field>
                        </div>
                    </div>

                </mat-card-content>
            </mat-card>

            <mat-card class="card">
                <mat-card-actions>
                    <div fxFlex></div>
                    <button mat-raised-button color="primary" *ngIf="showRevertSign()" (click)="revertSign()">
                        {{ 'button.revertSign' | translate }} </button>
                    <div matTooltip="{{ 'button.disabled.offline' | translate }}"
                        [matTooltipDisabled]="!disableOfflineButton">
                        <button mat-raised-button color="primary" (click)="saveProtocol()"
                            [disabled]="disableOfflineButton">{{ 'button.save' | translate }}</button>
                    </div>
                    <div fxFlex="5px"></div>
                    <div matTooltip="{{ 'button.disabled.offline' | translate }}"
                        [matTooltipDisabled]="!disableOfflineButton">
                        <button mat-raised-button color="primary" *ngIf="showManualSign()" (click)="manualSign()"
                            [disabled]="disableOfflineButton">{{ 'button.sign-manual' | translate }}</button>
                    </div>
                    <div fxFlex="5px"></div>
                    <div matTooltip="{{ 'button.disabled.offline' | translate }}"
                        [matTooltipDisabled]="!disableOfflineButton">
                        <button mat-raised-button color="primary" *ngIf="showAutomaticSign()" (click)="prepareToSign()"
                            [disabled]="disableOfflineButton">{{ 'button.sign-automatic' | translate }}</button>
                    </div>
                    <div fxFlex="5px"></div>
                    <div matTooltip="{{ 'button.disabled.offline' | translate }}"
                        [matTooltipDisabled]="!disableOfflineButton">
                        <button mat-raised-button color="accent" *ngIf="protocol.documentCode != null"
                            (click)="downloadPdf()" [disabled]="disableOfflineButton">{{ 'button.generateDoc' |
                            translate }}</button>
                    </div>
                    <div fxFlex="5px"></div>
                    <button mat-raised-button color="secondary" (click)="cancel()">{{ 'button.cancel' | translate
                        }}</button>
                </mat-card-actions>
            </mat-card>

        </form>

    </mat-tab>
    <mat-tab #auditTab label="{{ 'protocolEdit.historical.title' | translate }}" *ngIf="protocol.id != null">
        <app-protocol-edit-audit [idProtocol]="protocol.id" [documentCode]="protocol.documentCode">
        </app-protocol-edit-audit>
    </mat-tab>
</mat-tab-group>