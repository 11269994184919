/* eslint-disable max-len */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LangFlag, TranslationService } from 'src/app/services/translation.service';

import { BroadcastChannelService } from 'src/app/services/broadcastChannel.service';
import { ChangeProfileDialogComponent } from '../change-profile-dialog/change-profile-dialog.component';
import { DrawerService } from '../../../services/drawer.service';
import { LoginService } from '../../../services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { StringUtils } from '../../../utils/stringUtils';
import { OnlineService } from '../../../services/online.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {

  isLoggedIn: boolean;

  currentUser: User;

  isLoginRoute = false;

  isLoginClient = true;

  menuHide = true;

  disableOfflineButton = true;

  public langs: LangFlag[];
  public activeFlag: string;

  public unreadNotifications = 0;

  private isLoggedInService = false;
  private destroy$ = new Subject<void>();

  constructor(
    private loginService: LoginService,
    private drawerService: DrawerService,
    public translationService: TranslationService,
    private userService: UserService,
    private broadcastChannelService: BroadcastChannelService,
    public dialog: MatDialog,
    private onlineService: OnlineService,
    private router: Router) {

    this.currentUser = null;
    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe(user => {
      this.currentUser = user;
    });

    this.userService.unread$.pipe(takeUntil(this.destroy$)).subscribe(unread => {
      this.unreadNotifications = unread;
    });
  }

  ngOnInit(): void {
    this.loginService.isLoggedIn$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.isLoggedInService = data;
      this.recalculateIsLoggedIn();
    });
    this.disableOfflineButton = this.onlineService.latestOnline;


    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      if (data.url) {
        this.isLoginRoute = !data.url.startsWith('/login');

        if (data.url.startsWith('/login-client') || data.url.startsWith('/forgotPasswordClient')) {
          this.isLoginRoute = false;
          this.isLoginClient = true;
        }
        this.recalculateIsLoggedIn();

        if (data.url === '/') {
          this.menuHide = false;
        } else {
          this.menuHide = true;
        }
      }

      this.showMenu();
    });

    this.langs = this.translationService.configuredLangs;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout(): void {
    this.loginService.logout();
    this.broadcastChannelService.sendMessageForceLogout();
  }

  showChangeProfile(): boolean {
    return this.currentUser.profiles != null && this.currentUser.profiles.length > 1;
  }

  openChangeProfile(): void {

    const dialogRef = this.dialog.open(ChangeProfileDialogComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {

      if (result != null) {
        this.reloadPage();
      }
    });
  }

  reloadPage(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    void this.router.navigateByUrl('/');
  }

  toggleDrawer(): void {
    this.drawerService.toggle();
  }

  public changeLang(lang: string): void {
    this.translationService.changeLang(lang);
  }

  public anyUnreadNotification(): boolean {
    return this.unreadNotifications != null && this.unreadNotifications !== 0;
  }

  public getUnreadNotifications(): string {
    let res = '';

    if (this.anyUnreadNotification()) {
      res = this.unreadNotifications.toString();

      if (this.unreadNotifications > 99) {
        res = '99+';
      }
    }

    return res;
  }

  showChangeLangIcon(): boolean {
    return this.langs.length > 1;
  }

  getInitials(): string {
    let res = '';
    if (this.onlineService.latestOnline) {
      if (this.currentUser.fullName) {
        res = StringUtils.getInitials(this.currentUser.fullName);
      }
    } else {
      res = 'offline'
    }
    return res;
  }

  showLanguageInMain(): boolean {
    if (this.router.url === '/') {
      return true;
    } else {
      return false;
    }
  }

  showMenu(): boolean {
    if (this.router.url ==='/' || this.router.url === '/air' || this.router.url ==='/thermal' || this.router.url ==='/calibrates') {
      return false;
    } else {
      return  true;
    }
  }

  private recalculateIsLoggedIn() {
    this.isLoggedIn = this.isLoggedInService && this.isLoginRoute;
  }

}
