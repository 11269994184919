import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Client } from 'src/app/model/client';
import { Group } from 'src/app/model/group';
import { User } from 'src/app/model/user';
import { AttachmentThermalService } from 'src/app/services/attachmentThermal.service';
import { ClientService } from 'src/app/services/client.service';
import { ManageGroupsService } from 'src/app/services/manageGroups.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UserService } from 'src/app/services/user.service';
import { Constants } from 'src/app/utils/constants';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { CustomDatepickerHeaderComponent } from '../../shared/datepicker-custom-header/datepicker-custom-header.component';
import { MatDialog } from '@angular/material/dialog';
import { ContactPerson } from 'src/app/model/model';
import { ClientContatDialogContactPersonComponent } from './client-contat-dialog-contact-person/client-contat-dialog-contact-person.component';
import * as _ from 'lodash';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { GroupEnum } from 'src/app/utils/groupUtils';


@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html'
})
export class ClientEditComponent implements OnDestroy {

  @ViewChild('uploadLogo') uploadLogoInput: ElementRef;

  client: Client;

  image: Blob;

  currentUser: User;

  groups: Group[];

  matcher = new MyErrorStateMatcher();

  customDatepickerHeader = CustomDatepickerHeaderComponent;

  selectedGroup: number;

  private destroy$ = new Subject<void>();

  private breadcrumbLabel: string;

  constructor(
    private sanitizer: DomSanitizer,
    fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private translate: TranslateService,
    private attachmentThermalService: AttachmentThermalService,
    private userService: UserService,
    private manageGroupsService: ManageGroupsService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    this.currentUser = this.userService.currentProfile;

    this.spinnerService.show();

    if (this.showGroupToClone()) {
      this.manageGroupsService.findAllToClone().pipe(takeUntil(this.destroy$)).subscribe((res: Group[]) => this.groups = res)
    }
    

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const id = params[Constants.FIELD_ID] as number;

      if (id == null) {
        this.cancel();
      }

      this.client = new Client();
      if (+id === 0) {
        this.spinnerService.hide();
      } else {
        this.clientService.findOne(id).pipe(takeUntil(this.destroy$)).subscribe((result: Client) => {
          if (result != null) {
            this.client = result;
            this.breadcrumbLabel = this.client.name;
            this.reloadImage();
          } else {
            this.client = null;
          }
        }, () => {
          this.client = null;
        }, () => {
          if (this.client == null) {
            this.cancel();
          }
          this.spinnerService.hide();
        });
      }
      const currentProfile = this.userService.currentProfile;
      this.selectedGroup = currentProfile.profiles.find(p => p.idGroup === currentProfile.idActiveProfile)?.idGroup;
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  saveClient(): void {

    const errores: string[] = [];

    if (!this.checkValid()) {
      errores.push(this.translate.instant('clientEdit.form.error.invalid') as string);
    }

    if (errores.length === 0) {
      const isCreate = this.client.id == null;
      this.save(isCreate);
    } else if (errores.length !== 0) {
      this.snackBarService.sendError(errores.join('\n'));
    }

  }

  onLogoUpload(event): void {

    const file = event.target.files[0] as File;

    this.spinnerService.show();
    this.attachmentThermalService.uploadLogoToClient(this.client.id, file).pipe(takeUntil(this.destroy$)).subscribe((item: number) => {
      this.client.idLogo = item;

      this.reloadImage();

      this.spinnerService.hide();

      this.snackBarService.sendSuccess(this.translate.instant('groupEdit.form.uploadLogo.ok') as string);
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('groupEdit.form.uploadLogo.error') as string);
    });

    this.uploadLogoInput.nativeElement.value = '';
  }

  reloadImage(): void {
    this.image = null;

    if (this.client.idLogo) {
      this.attachmentThermalService.downloadLogoToClient(this.client).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        this.image = res;
      }, () => {
        this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.downloadImage.error.generic') as string);

        this.spinnerService.hide();
      });
    }
  }

  public showImage(): SafeUrl {
    if (this.image == null) {
      return '';
    }

    const unsafeImageUrl = URL.createObjectURL(this.image);

    return this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
  }

  cancel(): void {
    void this.router.navigateByUrl('/clients');
  }

  downloadAuditPdf(): void {
    this.spinnerService.show();

    const id = this.client.id;
    this.clientService.downloadAuditPdf(id).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      saveAs(res, this.translate.instant('clientEdit.files.auditClient', { name: this.client.name }) as string);

      this.spinnerService.hide();
    }, () => {
      this.snackBarService.sendError(this.translate.instant('clientEdit.form.downloadAuditClient.error') as string);

      this.spinnerService.hide();
    });
  }

  showGroupToClone(): boolean {
    return this.currentUser.showClone && this.currentUser.admin && !this.currentUser.manager;
  }

  openDialogPerson(): void {
    let person = new ContactPerson();
    person.clientId = this.client.id;

    const dialogRef = this.dialog.open(ClientContatDialogContactPersonComponent, {
      minWidth: '40%',
      maxHeight: '95vh',

      data: {
        person: person,
        isEdit: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ContactPerson) => {
      if (result != null) {
        result.clientId=this.client.id;
        if (!this.client.persons) {
          this.client.persons = [];
        }
        this.client.persons.push(result);
      }
    });
  }

  openDialogEditPerson(idx: number): void {
    const eq = this.client.persons[idx];

    const dialogRef = this.dialog.open(ClientContatDialogContactPersonComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        person: _.cloneDeep(eq),
        isEdit: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ContactPerson) => {
      if (result != null) {
        this.client.persons[idx] = result;
      }
    });
  }

  deleteRow(index: number): void {
    this.client.persons.splice(index, 1);
  }

  restorePassword(idx: number): void {
    const person = this.client.persons[idx];
    this.spinnerService.show();
    this.userService.restorePasswordPortal(person.username).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      this.spinnerService.hide();
      this.snackBarService.sendSuccess(this.translate.instant('clientEdit.form.restorePasswordPortal.success') as string);
    }, () => {
      this.snackBarService.sendError(this.translate.instant('clientEdit.form.restorePasswordPortal.error') as string);

      this.spinnerService.hide();
    });
    
  }

  private checkValid() {
    let res = true;

    res = res && this.client.name != null && this.client.name.trim() !== '';
    res = res && this.client.address != null && this.client.address.trim() !== '';

    return res;
  }

  private save(isNew: boolean) {
    if (ArrayUtils.isEmpty(this.client.persons) && this.selectedGroup === GroupEnum.QUALIPHARMA) {
      this.snackBarService.sendError(this.translate.instant('manageUsers.dialog.clientHasUsers.error') as string);
    } else if (this.checkNames()) {
      this.snackBarService.sendError(this.translate.instant('manageUsers.dialog.duplicate.name.error') as string);
    } else {
      this.spinnerService.show();
      this.clientService.save(this.client).pipe(takeUntil(this.destroy$)).subscribe((res: Client) => {
        this.client = res;
        this.reloadClient();

        if (isNew) {
          this.snackBarService.sendSuccess(this.translate.instant('clientEdit.form.create.ok') as string);
        } else {
          this.snackBarService.sendSuccess(this.translate.instant('clientEdit.form.update.ok') as string);
        }
        this.spinnerService.hide();
      }, () => {
        if (isNew) {
          this.snackBarService.sendError(this.translate.instant('clientEdit.form.create.error') as string);
        } else {
          this.snackBarService.sendError(this.translate.instant('clientEdit.form.update.error') as string);
        }
        this.spinnerService.hide();
      }
      );
    }

  }
 
  private reloadClient() {
    this.clientService.findOne(this.client.id).pipe(takeUntil(this.destroy$)).subscribe((data: Client) => this.client = data);
  }

  private checkNames(): boolean {
    let valuesAlreadySeen = []
    for (let i = 0; i < this.client.persons.length; i++) {
      let value = this.client.persons[i]
      for (let z = 0; z < valuesAlreadySeen.length; z++) {
        if (value.name === valuesAlreadySeen[z].name) {
          return true;
        }
      }
      valuesAlreadySeen.push(value);
    }
    return false
  }
}
