import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { SaveExecutionOfflineService } from '../../services/saveExecutionOffline.service';
import { SnackBarService } from '../../services/snackBar.service';
import { SpinnerService } from '../../services/spinner.service';
import { Constants } from '../../utils/constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DexieService } from '../../services/dexieService';
import { OnlineService } from '../../services/online.service';
import { RoleEnum, RoleUser } from '../../model/roleUser';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from '../../services/theme.service';
import { AppTypeEnum } from '../../model/appType';
import { GlobalStateService } from '../../services/globalState.service';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss']
})
export class PrincipalComponent implements OnInit, OnDestroy {

  anyUnsentAction = false;
  disableOfflineButton = false;
  showThermal = false;
  showAir = false;
  showCalibrates = false;

  selectedGroup: number;
  selectedApp: number;
  isThermal = Constants.APP_THERMAL;
  isAir = Constants.APP_AIR;
  isCalibrates = Constants.APP_CALIBRATES;

  timestampt: string;
  roles: RoleUser[];

  currentTab: string;

  roouterPath: boolean;

  private online: boolean;

  private destroy$ = new Subject<void>();

  constructor(private router: Router,
    private userService: UserService,
    private translate: TranslateService,
    private saveExecutionOfflineService: SaveExecutionOfflineService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private dexieService: DexieService,
    private onlineService: OnlineService,
    private themeService: ThemeService,
    private route: ActivatedRoute,
    private globalStateService: GlobalStateService) {
    }

  ngOnInit(): void {
    this.roouterPath = false;
    const currentProfile = this.userService.currentProfile;

    this.selectedGroup = currentProfile.profiles.find(p => p.idGroup === currentProfile.idActiveProfile)?.idGroup;
    // eslint-disable-next-line max-len
    const calibrate = currentProfile.profiles.find(p => p.idGroup === currentProfile.idActiveProfile && p.idApp === AppTypeEnum.CALIBRATES);
    if (calibrate) {
      this.showCalibrates = true;
    }
    // eslint-disable-next-line max-len
    const thermal = currentProfile.profiles.find(p => p.idGroup === currentProfile.idActiveProfile && p.idApp === AppTypeEnum.THERMAL);
    if (thermal) {
      this.showThermal = true;
    }
    // eslint-disable-next-line max-len
    const air = currentProfile.profiles.find(p => p.idGroup === currentProfile.idActiveProfile && p.idApp === AppTypeEnum.AIR);
    if (air) {
      this.showAir = true;
    }
    // count dexie changes- spinner
    void this.checkChanges();
    this.onlineService.online$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.disableOfflineButton = !res;

      this.updateBadgeUpload();
    });

    this.disableOfflineButton = !this.onlineService.latestOnline;

    this.updateBadgeUpload();

    if (!this.onlineService.latestOnline) {
        void this.router.navigate(['air'], { queryParamsHandling: 'merge' });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  uploadData(): void {
    this.spinnerService.show();

    this.onlineService.executeActions(() => {
      this.updateBadgeUpload();

      this.spinnerService.hide();
    });
  }

  onSelectionOption(option: number): void {
    this.spinnerService.show();
    this.userService.changeProfile(this.selectedGroup, option).pipe(takeUntil(this.destroy$)).subscribe(item => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.userService.saveProfile(item);
      if (option  === AppTypeEnum.AIR) {
        this.globalStateService.setHomeAirState('air-state');
        void this.router.navigate(['air']);
        this.roouterPath = true;
      } else if (option  === AppTypeEnum.THERMAL) {
        this.globalStateService.setHomeThermalState('thermal-state');
        void this.router.navigate(['thermal']);
        this.roouterPath = true;
      } else if (option  === AppTypeEnum.CALIBRATES) {
        this.globalStateService.setHomeCalibratesState('calibrates-state');
        void this.router.navigate(['calibrates']);
        this.roouterPath = true;
      }
      this.spinnerService.hide();
    }, () => {
      this.spinnerService.hide();
    });
  }


  checkRouterDisabledOffline(url: string): string {
    return '';
  }


  showThermalOption(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }

    const app = currentUser.profiles.filter(e => e.idApp === AppTypeEnum.THERMAL);

    return app ? true : false;
  }

  showCalibratesOption(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }

    const app = currentUser.profiles.filter(e => e.idApp === AppTypeEnum.CALIBRATES);

    return app ? true : false;

  }

  showAirOption(): boolean {
    const currentUser = this.userService.currentProfile;
    if (currentUser == null) {
      return false;
    }

    const app = currentUser.profiles.filter(e => e.idApp === AppTypeEnum.AIR);

    return app ? true : false;
  }

  showCalibrationPlan(): boolean {
    const idRole = this.userService.currentProfile.idActiveApp;

    const rolesCanManage = [RoleEnum.ADMIN, RoleEnum.MANAGER];

    return rolesCanManage.includes(idRole) && this.userService.currentProfile.showCalibrationPlan;
  }

  private async checkChanges() {
    this.online = this.onlineService.latestOnline;

    const result = this.dexieService.changes.filter(e => !e.deleted);
    const count = await result.count();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
    const resultAtt = this.dexieService.attached.filter(e => !e.deleted && e.idEssay != null);
    const countAtt = await resultAtt.count();
    if ((this.online && count > 0) || (this.online && countAtt > 0)) {
      this.spinnerService.show();
      void this.saveExecutionOfflineService.saveOfflineChanges().then(() => {
        void this.dexieService.recreate().then(() => {
          this.spinnerService.hide();
          this.snackBarService.sendSuccess(this.translate.instant('executionEdit.form.saveOffline.ok') as string);
          this.snackBarService.sendWarn(this.translate.instant('executionEdit.form.saveOffline.warning') as string);
        });
      });
    } else {
      void this.saveExecutionOfflineService.deleteCacheDixie();
    }
  }
  private updateBadgeUpload() {
    this.onlineService.countPendingActions().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.anyUnsentAction = !this.disableOfflineButton && (res !== 0);
    });
  }
}
