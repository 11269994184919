import { ExternalCalibration } from '../model/calibration';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ExternalCalibrationService {

    constructor(private http: HttpClient) {
    }

    findOne(id: number): Observable<any> {
        const url = environment.calibratesUrl + `/externalCalibration/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllFromEquipment(id: number): Observable<any> {
        const url = environment.calibratesUrl + `/externalCalibration/equipment/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    save(externalCalibration: ExternalCalibration, file: File): Observable<any> {
        let url = environment.calibratesUrl + '/externalCalibration/';

        const form = new FormData();
        form.append('calibration', JSON.stringify(externalCalibration));

        if (file) {
            form.append('document', file, file.name);
        }

        if (externalCalibration.id != null) {
            url += externalCalibration.id;
            return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            url += 'create';
            return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        }
    }

    uploadCertificateToCalibration(idCalibration: number, file: File): Observable<any> {
        const url = environment.calibratesUrl + `/externalCalibration/${idCalibration}/certificate/`;
        const form = new FormData();
        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    deleteCertificateToCalibration(idCalibration: number): Observable<any> {
        const url = environment.calibratesUrl + `/externalCalibration/${idCalibration}/certificate/`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    delete(id: number): Observable<any> {
        const url = environment.calibratesUrl + `/externalCalibration/${id}`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadPdfVersion(idExternalCalibration: number, version: string): Observable<any> {
        const url = environment.calibratesUrl + `/report/externalCalibration/${idExternalCalibration}/version/${version}`;

        return this.http.post(url, {}, { responseType: 'blob' });
    }

    deleteWithReason(id: number, reason: string): Observable<any> {
        const url = environment.calibratesUrl + `/externalCalibration/${id}/${reason}`;
        const form = new FormData();
        form.append('reason', reason);
        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
