import { EMPTY, Observable, throwError } from 'rxjs';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ServiceUnavailableInterceptor implements HttpInterceptor {
    constructor(private loginService: LoginService, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(req).pipe(catchError(err => this.handleAuthError(err)));
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        // Handle your auth error or rethrow

        // En caso de error de servicio no disponible, redirigimos a la página de mantenimiento
        if (err != null && (err.status === HttpStatusCode.ServiceUnavailable)) {
            this.loginService.clearSession();

            void this.router.navigateByUrl('/maintenance');

            return EMPTY;
        }
        return throwError(err);
    }

}
