export class InternalEquipmentPerformances {
    id: number;
    idEquipment: number;
	idActionType: number;
    verificationPoint: string;
    equipmentRange: string;
    resolution: string;
    tolerance: string;
    frequency: string;
    acquisitionDate: Date;
    responsible: number;
    supplier: string;
    fee: number;
    expirationDate: Date;
    processDate: Date;
    documentNumber: string;
    idCertificate: number;
    idStatus: number;
    executionNumber: string;
    useRange: string;
    name?: string;
    extendedExpirationDate: Date;
    expansionDay: number;
    nearToExpire: boolean;
    expired: boolean;
}

export class InternalEquipmentPerformancesDate {
    expirationDateMaintenance: Date;
    processDateMaintenance: Date;
    expirationDateCalibration: Date;
    processDateCalibration: Date;
    expirationCharaterization: Date;
    processDateCharaterization: Date;
    expirationVerification: Date;
    processDateVerification: Date;
    expirationDateQualification: Date;
    processDateQualification: Date;
}

export class InternalPerformancesDocument {
    actionType: number;
    documentNumber: string;
    idDocument: number;
}