import { GenericAttachment, GenericFilter } from './genericClass';

import { Area } from './area';
import { CriticalInstrumentation } from './criticalInstrumentation';
import { Equipment } from './equipment';
import { Essay } from './essay';
import { ReferenceDocument } from './referenceDocument';
import { ValidatorSpecifics } from './validatorSpecific';

export class Protocol {
    id: number;

    projectNo: string;
    documentCode: string;

    idProcess: number;
    processTranslation: string;

    idClient: number;
    nameClient: string;
    clientAddress: string;
    clientDocumentCode: string;

    idPhase: number;
    phaseTranslation: string;

    usernameProjectManager: string;

    idInstrumentation: number; // FIXME: A desaparecer
    nameInstrumentation: string; // FIXME: A desaparecer

    regUser: string;
    modUser: string;

    regFc: Date;
    modFc: Date;

    realizationDate: Date;

    idStatus: number;
    nameStatus: string;
    statusTranslation: string;

    currentVersion: string;
    signedVersion: string;

    observations: string;

    validator: ValidatorSpecifics; // FIXME: A desaparecer

    reason: string;
    usedIn: number;

    department: string;

    equipments: Equipment[] = [];
    essays: Essay[] = [];
    criticalInstrumentations: CriticalInstrumentation[] = [];
    referenceDocs: ReferenceDocument[] = [];

    currentlyUsed: boolean;
    // instrumentations: Instrumentation[]; // TODO: A implementar
}

export class ProtocolAssignable {

    id: number;

    idClient: number;
    nameClient: string;
    clientAddress: string;

    idProcess: number;
    nameProcess: string;
    processTranslation: string;

    projectNo: string;
    documentCode: string;

    idStatus: number;
    nameStatus: string;

    currentVersion: number;
    signedVersion: number;

    equipment: string;
    serialNumber: string;
    internalId: string;
}

export class ProtocolAttachment extends GenericAttachment {
    idProtocol: number;
}

export class ProtocolFilter extends GenericFilter {
    id: number;

    projectNo: string;
    documentCode: string;
    clientDocumentCode: string;

    idProcess: string;
    idClient: number;
    idPhase: number;
    idInstrumentation: number;

    regUser: string;
    modUser: string;
    projectManager: string;

    regFcStart: Date;
    regFcEnd: Date;

    modFcStart: Date;
    modFcEnd: Date;

    realizationFcStart: Date;
    realizationFcEnd: Date;

    idStatus: number;

    equipment: string;
    equipmentMaker: string;
    equipmentModel: string;
    equipmentSerialNum: string;
    equipmentInternalId: string;
    equipmentLocation: string;

    autocomplete: string;

    department: string;

    areaName: string;

    cols: string[];
}

export class ProtocolAudit {
    id: number;
    username: string;

    idAction: number;
    actionName: string;

    idStatus: number;
    nameStatus: string;
    statusTranslation: string;

    date: Date;

    currentVersion: string;
    signedVersion: string;

    changes: string;

    canDownload: boolean;
}

export class ProtocolAuditFilter extends GenericFilter {
}

export class ProtocolAttachmentFilter extends GenericFilter {
}

export enum ProtocolStatus {
    PENDIENTE_FIRMA = 1,
    FIRMADO = 3
}

export enum ProtocolActionEnum {

    CREATE = 1,
    SAVE = 2,
    SIGN = 4,
    UPLOAD_ATTACHMENT = 5,
    DELETE_ATTACHMENT = 6,
    MODIFY_PROTOCOL = 7,
    MODIFY_EQUIPMENT = 8,
    MODIFY_CRITICAL_INSTR = 9,
    MODIFY_ESSAY = 10,
    MODIFY_INSTR_USE = 11,
    MODIFY_REFERENCE_DOCUMENT = 12
}

export class ProtocolAir {
    id: number;

    projectNo: string;
    documentCode: string;

    idProcess: number;
    nameProcess: string;

    idClient: number;
    nameClient: string;
    clientAddress: string;
    clientDocumentCode: string;

    usernameProjectManager: string;

    regUser: string;
    modUser: string;

    regFc: Date;
    modFc: Date;

    realizationDate: Date;

    idStatus: number;
    nameStatus: string;
    statusTranslation: string;

    currentVersion: string;
    signedVersion: string;

    observations: string;

    notes: string;

    reason: string;
    usedIn: number;

    referenceDocs: ReferenceDocument[] = [];
    areas: Area[] = [];
}

export class ProtocolAirFilter extends GenericFilter {
    id: number;

    documentCode: string;
    projectNo: string;
    clientDocumentCode: string;

    idProcess: string;
    idClient: number;
    idPhase: number;
    idInstrumentation: number;

    regUser: string;
    modUser: string;
    projectManager: string;

    regFcStart: Date;
    regFcEnd: Date;

    modFcStart: Date;
    modFcEnd: Date;

    realizationFcStart: Date;
    realizationFcEnd: Date;

    idStatus: number;

    area: string;
    equipment: string;
    equipmentMaker: string;
    equipmentModel: string;
    equipmentSerialNum: string;
    equipmentInternalId: string;
    equipmentLocation: string;

    autocomplete: string;

    cols: string[];
}