/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { CalibrationAnnualPlan, CalibrationAnnualPlanGroup } from '../../../../model/calibration';
import { CalibrationAnnualPlanService } from '../../../../services/calibration-annual-plan.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { SnackBarService } from '../../../../services/snackBar.service';
import { TranslateService } from '@ngx-translate/core';
import * as saveAs from 'file-saver';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { NumberUtils } from '../../../../utils/numberUtils';

@Component({
  selector: 'app-calibration-plan-edit-generate-dialog',
  templateUrl: './calibration-plan-edit-generate-dialog.component.html',
  styleUrls: ['./calibration-plan-edit-generate-dialog.component.scss']
})
export class CalibrationPlanEditGenerateDialogComponent implements OnInit {

  calibrationAnnualPlan: CalibrationAnnualPlan;
  annualPlanDatasource: CalibrationAnnualPlanGroup[];
  mapCalibrationsAccordion: Map<number, boolean> = new Map();

  private destroy$ = new Subject<void>();

  constructor(private calibrationAnnualPlanService: CalibrationAnnualPlanService,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
    private spinnerService: SpinnerService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<CalibrationPlanEditGenerateDialogComponent>
  ) { }

  ngOnInit(): void {
    this.calibrationAnnualPlan = new CalibrationAnnualPlan();
    this.findAllByGroup();
  }

  findAllByGroup(): void {
    this.spinnerService.show();

    this.calibrationAnnualPlanService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: CalibrationAnnualPlanGroup[]) => {
      this.annualPlanDatasource = res;
      this.spinnerService.hide();
    }, error => {
      console.error(error);
      this.spinnerService.hide();
    });
  }

  prepareSave(): void {
   if (this.calibrationAnnualPlan.year) {
    if (NumberUtils.isNumber(this.calibrationAnnualPlan.year?.toString())) {
      const dataPlan = this.annualPlanDatasource?.find(e => e.year === Number(this.calibrationAnnualPlan?.year));
      if (!dataPlan) {
        this.save();
      } else {
        const year = dataPlan.year;
        this.snackBarService.sendError(this.translate.instant('calibration-plan-annual.dialog.year.exists.error', { year }) as string);
      }
    } else {
      this.snackBarService.sendError(this.translate.instant('calibration-plan-annual.form.year.type.error') as string);
    }
   } else {
    this.snackBarService.sendError(this.translate.instant('calibration-plan-annual.form.year.error') as string);
   }
  }

  save(): void {
    this.spinnerService.show();
    this.calibrationAnnualPlanService.save(this.calibrationAnnualPlan).pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.findAllByGroup();
      this.calibrationAnnualPlan = new CalibrationAnnualPlan();
      this.spinnerService.hide();
    }, error => {
      console.error(error);
      this.spinnerService.hide();
    });
  }

  isDefinitiveForVersion(cal: CalibrationAnnualPlan[]): boolean {
    const cals = cal.filter(e => !e.definitive);

    if (cals && cals?.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  newVersion(vigencia: number): void {
    const message = this.translate.instant('calibration-plan-annual.dialog.year.exists.confirm') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response) {
        this.spinnerService.show();

        this.calibrationAnnualPlanService.saveVersion(vigencia).pipe(takeUntil(this.destroy$)).subscribe((res) => {
          this.findAllByGroup();
          this.spinnerService.hide();
        }, error => {
          console.error(error);
          this.spinnerService.hide();
        });
      } else {
        this.calibrationAnnualPlan = new CalibrationAnnualPlan();
      }
    });
  }

  setCalibrationOpened(index: number, state: boolean): void {
    this.mapCalibrationsAccordion.set(index, state);
  }

  isCalibrationOpened(index: number): boolean {
    return this.mapCalibrationsAccordion.get(index);
  }

  signPlanAnnual(planAnnual: CalibrationAnnualPlan): void {
    this.spinnerService.show();

    this.calibrationAnnualPlanService.signPlan(planAnnual).pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res) {
        this.calibrationAnnualPlanService.generateCalibrationPlanById(planAnnual.id).pipe(takeUntil(this.destroy$)).subscribe((doc: Blob) => {
          saveAs(doc, `${planAnnual.year}-${planAnnual.currentVersion}.pdf`);
          this.findAllByGroup();
          this.spinnerService.hide();
        }, error => {
          console.error(error);
          this.spinnerService.hide();
        });
      }
    }, error => {
      console.error(error);
      this.spinnerService.hide();
    });
  }

  preprateGenerate(planAnnual: CalibrationAnnualPlan): void {
    if (!planAnnual.definitive) {
      const message = this.translate.instant('calibration-plan-annual.dialog.year.definitive.confirm') as string;

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });
      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
        if (response) {
          this.signPlanAnnual(planAnnual);
        } else {
          this.downloadCertificateAnnualPlan(planAnnual);
        }
      });
    } else {
      this.downloadCertificateAnnualPlan(planAnnual);
    }
  }

  uploadPdfManual(event, planAnnual: CalibrationAnnualPlan): void {
    const file = event.target.files[0] as File;

    this.spinnerService.show();

    this.calibrationAnnualPlanService.uploadDocumentManual(planAnnual.id, file).subscribe((item: number) => {
      planAnnual.idAttachment = item;
      planAnnual.definitive = true;

      this.spinnerService.hide();

      this.snackBarService.sendSuccess(this.translate.instant('calibration-plan-annual.dialog.form.uploadCertificate.ok') as string);
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('calibration-plan-annual.dialog.form.uploadCertificate.error') as string);
    });
  }
  downloadCertificateAnnualPlan(planAnnual: CalibrationAnnualPlan): void {
    event.stopPropagation();
    this.spinnerService.show();

    if (planAnnual && planAnnual.id) {
      this.spinnerService.show();

      let call: Observable<any> = null;

      if (planAnnual.definitive && planAnnual.idAttachment) {
        call = this.calibrationAnnualPlanService.downloadCalibrationPlanByIdAttachment(planAnnual.idAttachment);
      } else {
        call = this.calibrationAnnualPlanService.generateCalibrationPlanById(planAnnual.id);
      }

      if (call == null) {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.downloadPdf.error') as string);

        return;
      }

      call.pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        saveAs(res, `${planAnnual.year}-${planAnnual.currentVersion}.pdf`);
        this.spinnerService.hide();
      }, error => {
        console.error(error);
        this.spinnerService.hide();
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
