/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { CalibrationAnnualPlan } from '../model/calibration';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';

@Injectable({
  providedIn: 'root'
})
export class CalibrationAnnualPlanService {

  constructor(private http: HttpClient) { }

  save(calibration: CalibrationAnnualPlan): Observable<any> {
    let url = environment.calibratesUrl + '/calibrationAnnualPlan/';
    if (calibration.id !== null && calibration.id !== undefined) {
      url += calibration.id;
      return this.http.put(url, calibration).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    } else {
      url += 'create';
      return this.http.post(url, calibration).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
  }

  signPlan(calibration: CalibrationAnnualPlan): Observable<any> {
    const url = environment.calibratesUrl + '/calibrationAnnualPlan/sign';
    return this.http.post(url, calibration).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  findAll(): Observable<any> {
    return this.http.get(environment.calibratesUrl + '/calibrationAnnualPlan/allGroup').pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  downloadCalibrationPlanByIdAttachment(idAttachment: number): Observable<any> {
    return this.http.get(environment.calibratesUrl + `/calibrationAnnualPlan/attachment/${idAttachment}`, { responseType: 'blob' });
  }

  generateCalibrationPlanById(id: number): Observable<any> {
    return this.http.get(environment.calibratesUrl + `/calibrationAnnualPlan/document/${id}`, { responseType: 'blob' });
  }

  saveVersion(year: number): Observable<any> {
    return this.http.post(`${environment.calibratesUrl}/calibrationAnnualPlan/newVersion/${year}`, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  uploadDocumentManual(id: number, file: File): Observable<any> {
    const url = environment.calibratesUrl + `/calibrationAnnualPlan/${id}/document`;

    const form = new FormData();
    form.append('file', file, file.name);

    return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }
}
