import { Component, Inject, OnInit } from '@angular/core';
import { InternalEquipmentPerformancesDate } from '../../../../model/internalEquipmentPerformances';
import { CustomDatepickerHeaderComponent } from '../../../shared/datepicker-custom-header/datepicker-custom-header.component';
import * as _ from 'lodash';
import * as moment from 'moment';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ThemeService } from '../../../../services/theme.service';
import { SnackBarService } from '../../../../services/snackBar.service';
import { TranslateService } from '@ngx-translate/core';

export interface DialogDataInternalEquipmentEditDatePerformances {
  frecuencyCalibration: number;
  frecuencyVerification: number;
  isCalibrationNotDate: boolean;
  isCharaterizationNotDate: boolean;
  isVerificationNotDate: boolean;
  isMaintenanceNotDate: boolean;
  isQualificationNotDate: boolean;
}

@Component({
  selector: 'app-internal-equipment-edit-date-performances',
  templateUrl: './internal-equipment-edit-date-performances.component.html',
  styleUrls: ['./internal-equipment-edit-date-performances.component.scss']
})
export class InternalEquipmentEditDatePerformancesComponent implements OnInit {

  dataDate: InternalEquipmentPerformancesDate = new InternalEquipmentPerformancesDate();

  customDatepickerHeader = CustomDatepickerHeaderComponent;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataInternalEquipmentEditDatePerformances,
  public dialogRef: MatDialogRef<InternalEquipmentEditDatePerformancesComponent>,
  public dialog: MatDialog,
  private themeService: ThemeService,
  public snackBarService: SnackBarService,
  private translate: TranslateService) { }

  ngOnInit(): void {
  }

  saveDate(field: string, event: any): void {
    let value: Date | string = event.value;
    if (value != null) {

      if (value instanceof Date) {
        value = moment(value).format('DD/MM/YYYY');
      }

      const date = moment(value + ' 12:00:00 + 0:00', 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }

      if (field === 'processDateCalibration') {
        this.dataDate.processDateCalibration = date;

        let frequency: number;
      // eslint-disable-next-line max-len
        this.dataDate.expirationDateCalibration = _.cloneDeep(this.dataDate.processDateCalibration);
        if (this.data?.frecuencyCalibration) {
          frequency = this.data.frecuencyCalibration;
        } else {
          frequency = 12;
        }
        this.dataDate.expirationDateCalibration.setMonth(this.dataDate.expirationDateCalibration.getMonth() + Number(frequency));
      }

      if (field === 'expirationDateCalibration') {
        this.dataDate.expirationDateCalibration = date;
      }
    }

  }

  saveDateQualification(field: string, event: any): void {
    let value: Date | string = event.value;
    if (value != null) {

      if (value instanceof Date) {
        value = moment(value).format('DD/MM/YYYY');
      }

      const date = moment(value + ' 12:00:00 + 0:00', 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }

      if (field === 'processDateQualification') {
        this.dataDate.processDateQualification = date;
      // eslint-disable-next-line max-len
        this.dataDate.expirationDateQualification = _.cloneDeep(this.dataDate.processDateQualification);
        this.dataDate.expirationDateQualification.setMonth(this.dataDate.expirationDateQualification.getMonth() + 12);
      }

      if (field === 'expirationDateQualification') {
        this.dataDate.expirationDateQualification = date;
      }
    }
  }

  saveDateMaintenance(field: string, event: any): void {
    let value: Date | string = event.value;
    if (value != null) {

      if (value instanceof Date) {
        value = moment(value).format('DD/MM/YYYY');
      }

      const date = moment(value + ' 12:00:00 + 0:00', 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }

      if (field === 'processDateMaintenance') {
        this.dataDate.processDateMaintenance = date;
      // eslint-disable-next-line max-len
        this.dataDate.expirationDateMaintenance = _.cloneDeep(this.dataDate.processDateMaintenance);
        this.dataDate.expirationDateMaintenance.setMonth(this.dataDate.expirationDateMaintenance.getMonth() + 12);
      }

      if (field === 'expirationDateMaintenance') {
        this.dataDate.expirationDateMaintenance = date;
      }
    }
  }

  saveDateVerification(field: string, event: any): void {
    let value: Date | string = event.value;
    if (value != null) {

      if (value instanceof Date) {
        value = moment(value).format('DD/MM/YYYY');
      }

      const date = moment(value + ' 12:00:00 + 0:00', 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }

      if (field === 'processDateVerification') {
        this.dataDate.processDateVerification = date;

        let frequency: number;
      // eslint-disable-next-line max-len
        this.dataDate.expirationVerification = _.cloneDeep(this.dataDate.processDateVerification);
        if (this.data?.frecuencyVerification) {
          frequency = this.data.frecuencyVerification;
        } else {
          frequency = 12;
        }
        this.dataDate.expirationVerification.setMonth(this.dataDate.expirationVerification.getMonth() + Number(frequency));
      }

      if (field === 'expirationVerification') {
        this.dataDate.expirationVerification = date;
      }
    }
  }

  saveDateCharacterization(field: string, event: any): void {
    let value: Date | string = event.value;
    if (value != null) {

      if (value instanceof Date) {
        value = moment(value).format('DD/MM/YYYY');
      }

      const date = moment(value + ' 12:00:00 + 0:00', 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }

      if (field === 'processDateCharaterization') {
        this.dataDate.processDateCharaterization = date;
      // eslint-disable-next-line max-len
        this.dataDate.expirationCharaterization = _.cloneDeep(this.dataDate.processDateCharaterization);
        this.dataDate.expirationCharaterization.setMonth(this.dataDate.expirationCharaterization.getMonth() + 12);
      }

      if (field === 'expirationCharaterization') {
        this.dataDate.expirationCharaterization = date;
      }
    }
  }

  onOkClick(): void {
    const errores: string[] = [];
    if (this.data.isCalibrationNotDate) {
      if (!this.dataDate.processDateCalibration) {
        errores.push(this.translate.instant('internalEquipment.date.performance.calibrateDate.error') as string);
       } else if (!this.dataDate.expirationDateCalibration) {
        errores.push(this.translate.instant('internalEquipment.date.performance.nextCalibrateDate.error') as string);
       }
    }

    if (this.data.isMaintenanceNotDate) {
      if (!this.dataDate.processDateMaintenance) {
        errores.push(this.translate.instant('internalEquipment.date.performance.maintenanceDate.error') as string);
      } else if (!this.dataDate.expirationDateMaintenance) {
        errores.push(this.translate.instant('internalEquipment.date.performance.nextMaintenanceDate.error') as string);
      }
    }

    if (this.data.isCharaterizationNotDate) {
      if (!this.dataDate.processDateCharaterization) {
        errores.push(this.translate.instant('internalEquipment.date.performance.characterizationDate.error') as string);
      } else if (!this.dataDate.expirationCharaterization) {
        errores.push(this.translate.instant('internalEquipment.date.performance.nextCharacterizationDate.error') as string);
      }
    }

    if (this.data.isQualificationNotDate) {
      if (!this.dataDate.processDateQualification) {
        errores.push(this.translate.instant('internalEquipment.date.performance.qualificationDate.error') as string);
       } else if (!this.dataDate.expirationDateQualification) {
        errores.push(this.translate.instant('internalEquipment.date.performance.nextQualificationDate.error') as string);
       }
    }

    if (this.data.isVerificationNotDate) {
      if (!this.dataDate.processDateVerification) {
        errores.push(this.translate.instant('internalEquipment.date.performance.verificationDate.error') as string);
       } else if (!this.dataDate.expirationVerification) {
        errores.push(this.translate.instant('internalEquipment.date.performance.nextVerificationDate.error') as string);
       }
    }

    if (errores && errores.length > 0) {
      this.snackBarService.sendError(errores.join('\n'));
    } else {
      this.dialogRef.close(this.dataDate);
   }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTheme(): string {
    return this.themeService.getTheme();
  }

}
