<mat-tab-group (selectedTabChange)="onTabChange($event)">
  <mat-tab label="{{ 'executionEdit.general.title' | translate }}">

    <form>

      <mat-card class="card">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'executionEdit.general.title' | translate }}
          </mat-toolbar>

          <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
            <div fxFlex>

              <div fxLayout="column">
                <div fxFlex *ngIf="execution.documentCode != null">

                  <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
                    <div fxFlex>
                      <mat-form-field class="campoFormulario">
                        <input matInput placeholder="{{ 'protocolEdit.general.documentCode.label' | translate }}"
                          name="documentCode" [(ngModel)]="execution.documentCode" disabled>
                      </mat-form-field>
                    </div>
                    <div fxFlex="65px" fxFlex.lt-md="50px" *ngIf="canEditDocumentCode()">
                      <button mat-icon-button (click)="editDocumentCode()">
                        <mat-icon>mode_edit</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>


                <div fxFlex [hidden]="execution?.id != null" *ngIf="false">
                  <span> {{ 'executionEdit.protocol.useExisting' | translate }} </span>
                  <mat-radio-group [ngModel]="useExistingProtocol" name="useExistingProtocol"
                    (change)="onUseExistingProtocolChange($event)">
                    <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
                    <div fxFlex="15px"></div>
                    <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
                  </mat-radio-group>
                </div>

                <div fxFlex [hidden]="execution.id != null || !useExistingProtocol">
                  <mat-form-field class="campoFormulario">
                    <input matInput required [ngModel]="execution.idProtocol" [disabled]="execution.id != null"
                      placeholder="{{ 'executionEdit.general.protocol.label' | translate }}" #protocolAutocomplete
                      #trigger="matAutocompleteTrigger" [matAutocomplete]="auto" (keyup)="lookupProtocol($event)"
                      [ngModelOptions]="{standalone: true}">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayProtocol"
                      (optionSelected)="onProtocolChange($event)">

                      <mat-option *ngIf="loadingAutocomplete$ | async">
                        <mat-spinner diameter="50" class="centerIcon"></mat-spinner>
                      </mat-option>

                      <ng-container *ngIf="!(loadingAutocomplete$ | async)">
                        <mat-option *ngFor="let prot of protocolsFiltered" [value]="prot">
                          {{ displayProtocol(prot) }}
                        </mat-option>
                      </ng-container>

                    </mat-autocomplete>
                    <mat-error>
                      {{ 'executionEdit.general.protocol.error' | translate }}
                    </mat-error>

                    <button matSuffix mat-icon-button color="accent" (click)="openSearchProtocol()">
                      <mat-icon style="font-size: large;">search</mat-icon>
                    </button>
                  </mat-form-field>
                </div>

                <div fxFlex *ngIf="execution.id != null && protocol?.id != null">
                  <div fxFlex>
                    <mat-form-field class="campoFormulario">
                      <input matInput placeholder="{{ 'executionEdit.general.protocol.label' | translate }}"
                        name="protocolDocumentCode" [ngModel]="protocol.documentCode" disabled>
                    </mat-form-field>
                  </div>
                  <div fxFlex="65px" fxFlex.lt-md="50px">
                    <button mat-icon-button (click)="downloadProtocolPdf()">
                      <mat-icon class="hidden-sm">cloud_download</mat-icon>
                    </button>
                  </div>
                </div>

                <div fxFlex="50">
                  <mat-form-field class="campoFormulario" *ngIf="protocol?.id != null">
                    <mat-label>{{ 'executionEdit.protocol.process.label' | translate }}</mat-label>
                    <mat-select name="idProcess" [(ngModel)]="execution.idProcess"
                      [disabled]="!enableEditInstrumentationToUse()">
                      <mat-option *ngFor="let item of processes" [value]="item.id">
                        {{ 'processType.' + item.translation | translate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div fxFlex="50">
                  <mat-form-field class="campoFormulario">
                    <input matInput placeholder="{{ 'protocolEdit.general.clientDocumentCode.label' | translate }}"
                      name="clientDocumentCode" [(ngModel)]="execution.clientDocumentCode">
                  </mat-form-field>
                </div>

                <div fxFlex="50">
                  <mat-form-field class="campoFormulario">
                    <input matInput placeholder="{{ 'protocolEdit.general.department.label' | translate }}"
                      name="department" [(ngModel)]="execution.department">
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div fxFlex>
              <div fxLayout="row" fxLayout.xs="column" class="flexwrap" [hidden]="execution.id == null">

                <div fxFlex="50" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <input matInput placeholder="{{ 'protocolEdit.general.regUser.label' | translate }}" name="regUser"
                      disabled [(ngModel)]="execution.regUser">
                  </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <input matInput placeholder="{{ 'protocolEdit.general.modUser.label' | translate }}" name="modUser"
                      disabled [(ngModel)]="execution.modUser">
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <input matInput placeholder="{{ 'protocolEdit.general.regFc.label' | translate }}" name="regFc"
                      disabled [ngModel]="execution.regFc | fixDate">
                  </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <input matInput placeholder="{{ 'protocolEdit.general.modFc.label' | translate }}" name="modFc"
                      disabled [ngModel]="execution.modFc | fixDate">
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%">
                  <div fxLayout="row" fxLayout.xs="column" class="flexwrap" [hidden]="execution.id == null">
                    <div fxFlex fxFlex.lt-md="100%">
                      <mat-form-field class="campoFormulario">
                        <input matInput name="status" disabled
                          value="{{ execution.id != null ? ('executionStatus.' + execution.statusTranslation | translate) : '' }}"
                          placeholder="{{ 'protocolEdit.general.status.label' | translate }}">
                      </mat-form-field>
                    </div>
                    <div fxFlex fxFlex.lt-md="100%" *ngIf="execution.according != null">
                      <mat-form-field class="campoFormulario">
                        <input matInput name="according" disabled
                          placeholder="{{ 'protocolEdit.general.according.label' | translate }}"
                          [ngModel]="execution.according ? ('common.yes' | translate) : ('common.no' | translate)">
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div fxFlex="50">
                  <mat-form-field class="campoFormulario">
                    <input matInput placeholder="{{ 'protocolEdit.general.currentVersion.label' | translate }}"
                      name="currentVersion" [ngModel]="execution.currentVersion" disabled>
                  </mat-form-field>
                </div>

              </div>
            </div>
          </div>

        </mat-card-content>
      </mat-card>

      <mat-card class="card" [hidden]="execution.id == null">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'executionEdit.referenceDocs.title' | translate }}

            <button mat-icon-button color="accent" (click)="openNewRefDoc()">
              <mat-icon>add</mat-icon>
            </button>
          </mat-toolbar>

          <br />

          <div fxLayout="row">

            <div fxFlex fxFlex.lt-md="100%">

              <table mat-table matSort #refDocTable [dataSource]="execution.referenceDocs" style="width: 95%;">

                <!-- code Column -->
                <ng-container matColumnDef="code">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'executionEdit.referenceDocs.code.label' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let item"> {{ item.code }} </td>
                </ng-container>

                <!-- name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'executionEdit.referenceDocs.name.label' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let item" style="white-space: pre;"> {{ item.name }} </td>
                </ng-container>

                <!-- Button edit Column -->
                <ng-container matColumnDef="edit">
                  <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
                  <td mat-cell *matCellDef="let element; let refDocIndex = index">
                    <button mat-icon-button (click)="editRefDoc(refDocIndex)">
                      <mat-icon>mode_edit</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <!-- Button delete Column -->
                <ng-container matColumnDef="delete">
                  <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
                  <td mat-cell *matCellDef="let element; let refDocIndex = index">
                    <button mat-icon-button (click)="removeRefDoc(refDocIndex)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColsRefDoc"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColsRefDoc;"></tr>

              </table>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card class="card" *ngIf="!useExistingProtocol || protocol?.id != null">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ (useExistingProtocol || protocol?.id != null ? 'executionEdit.protocol.title' :
            'executionEdit.execution.title') | translate }}
          </mat-toolbar>

          <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

            <div fxFlex="33" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario" *ngIf="!useExistingProtocol">
                <mat-label>{{ 'executionEdit.protocol.client.label' | translate }}</mat-label>
                <mat-select #clientSelect name="idClient" [(ngModel)]="execution.idClient">
                  <mat-select-filter *ngIf="clientSelect.focused" [displayMember]="'name'" [array]="clients"
                    (filteredReturn)="clientsFiltered =$event" [noResultsMessage]="'common.noResults' | translate"
                    [placeholder]="'common.selectFilterPlaceholder' | translate"></mat-select-filter>
                  <mat-option *ngFor="let client of clientsFiltered" [value]="client.id">
                    {{client.name}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{ 'executionEdit.protocol.client.error' | translate }}
                </mat-error>
              </mat-form-field>

              <div *ngIf="protocol?.id != null">
                <mat-label>{{ 'executionEdit.protocol.client.label' | translate }}</mat-label>
                <mat-select disabled name="idClient" [(ngModel)]="protocol.idClient">
                  <mat-option *ngFor="let item of clients" [value]="item.id">
                    {{item.name}} ({{item.address}})
                  </mat-option>
                </mat-select>
              </div>
            </div>

            <div fxFlex="33" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario" *ngIf="!useExistingProtocol">
                <mat-label>{{ 'executionEdit.protocol.process.label' | translate }}</mat-label>
                <mat-select name="idProcess" [(ngModel)]="execution.idProcess" required>
                  <mat-option *ngFor="let item of processes" [value]="item.id">
                    {{ 'processType.' + item.translation | translate }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{ 'executionEdit.protocol.process.error' | translate }}
                </mat-error>
              </mat-form-field>

              <mat-form-field class="campoFormulario" *ngIf="protocol?.id != null">
                <mat-label>{{ 'executionEdit.protocol.process.label' | translate }}</mat-label>
                <mat-select disabled name="idProcessProtocol" [(ngModel)]="protocol.idProcess">
                  <mat-option *ngFor="let item of processes" [value]="item.id">
                    {{ 'processType.' + item.translation | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxFlex="33" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario" *ngIf="!useExistingProtocol">
                <input matInput placeholder="{{ 'executionEdit.protocol.projectNo.label' | translate }}"
                  name="projectNo" [(ngModel)]="execution.projectNo" required [disabled]="execution?.id != null">
                <mat-error>
                  {{ 'executionEdit.protocol.projectNo.error' | translate }}
                </mat-error>
              </mat-form-field>

              <mat-form-field class="campoFormulario" *ngIf="protocol?.id != null">
                <input matInput placeholder="{{ 'executionEdit.protocol.projectNo.label' | translate }}"
                  name="projectNo" [(ngModel)]="protocol.projectNo" disabled>
              </mat-form-field>
            </div>

            <div fxFlex="33" fxFlex.lt-md="100%" *ngIf="!useExistingProtocol">
              <mat-form-field class="campoFormulario">
                <mat-label>{{ 'protocolEdit.general.phase.label' | translate }}</mat-label>
                <mat-select required name="idPhase" [(ngModel)]="execution.idPhase" [disabled]="execution?.id != null">
                  <mat-option *ngFor="let item of phases" [value]="item.id">
                    {{ 'phase.' + item.translation | translate }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{ 'protocolEdit.general.phase.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="33" fxFlex.lt-md="100%" *ngIf="!useExistingProtocol && protocol?.id == null">
              <mat-form-field class="campoFormulario">
                <mat-label>{{ 'protocolEdit.general.projectManager.label' | translate }}</mat-label>
                <mat-select name="projectManager" [(ngModel)]="execution.usernameProjectManager"
                  [disabled]="execution?.id != null">
                  <mat-option>--</mat-option>
                  <mat-option *ngFor="let item of respField" [value]="item.username">
                    {{ item.fullName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>

        </mat-card-content>
      </mat-card>

      <mat-card class="card" [hidden]="protocol?.id == null && execution.id == null">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'executionEdit.equipment.title' | translate }}

            <button mat-icon-button color="accent" (click)="openNewEquipment()"
              *ngIf="enableEditEquipment() && execution.id != null && execution.equipments.length < 1">
              <mat-icon>add</mat-icon>
            </button>
          </mat-toolbar>

          <table #equipmentProtocolTable mat-table [dataSource]="protocol?.equipments" style="min-width: 90%"
            *ngIf="execution.id == null">

            <!-- type Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.equipment.type.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ 'equipmentType.' + getEquipmentType(element) | translate }}
              </td>
            </ng-container>

            <!-- equipment Column -->
            <ng-container matColumnDef="equipment">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.equipment.equipment.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.equipment}} </td>
            </ng-container>

            <!-- maker Column -->
            <ng-container matColumnDef="maker">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.equipment.maker.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.maker}} </td>
            </ng-container>

            <!-- model Column -->
            <ng-container matColumnDef="model">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.equipment.model.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.model}} </td>
            </ng-container>

            <!-- serialNum Column -->
            <ng-container matColumnDef="serialNum">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.equipment.serialNo.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.serialNum}} </td>
            </ng-container>

            <!-- internalId Column -->
            <ng-container matColumnDef="internalId">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.equipment.internalId.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.internalId}} </td>
            </ng-container>

            <!-- location Column -->
            <ng-container matColumnDef="location">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.equipment.location.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.location}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="equipmentCols"></tr>
            <tr mat-row *matRowDef="let row; columns: equipmentCols;"></tr>
          </table>

          <table #equipmentTable mat-table [dataSource]="execution.equipments" style="min-width: 90%"
            *ngIf="execution.id != null">

            <!-- type Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.equipment.type.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ 'equipmentType.' + getEquipmentType(element) | translate }}
              </td>
            </ng-container>

            <!-- equipment Column -->
            <ng-container matColumnDef="equipment">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.equipment.equipment.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.equipment}} </td>
            </ng-container>

            <!-- maker Column -->
            <ng-container matColumnDef="maker">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.equipment.maker.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.maker}} </td>
            </ng-container>

            <!-- model Column -->
            <ng-container matColumnDef="model">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.equipment.model.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.model}} </td>
            </ng-container>

            <!-- serialNum Column -->
            <ng-container matColumnDef="serialNum">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.equipment.serialNo.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.serialNum}} </td>
            </ng-container>

            <!-- internalId Column -->
            <ng-container matColumnDef="internalId">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.equipment.internalId.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.internalId}} </td>
            </ng-container>

            <!-- location Column -->
            <ng-container matColumnDef="location">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.equipment.location.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.location}} </td>
            </ng-container>

            <!-- Button edit Column -->
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
              <td mat-cell *matCellDef="let element; let equipmentIndex = index">
                <button mat-icon-button *ngIf="enableEditEquipment()" (click)="openEditEquipment(equipmentIndex)">
                  <mat-icon>mode_edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="equipmentColsEx"></tr>
            <tr mat-row *matRowDef="let row; columns: equipmentColsEx;"></tr>
          </table>

        </mat-card-content>

      </mat-card>

      <mat-card class="card" [hidden]="protocol?.id == null && execution.id == null">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'executionEdit.criticalInstr.title' | translate }}

            <button mat-icon-button color="accent" (click)="openNewCriticalInstrumentation()"
              *ngIf="enableEditEquipment() && execution.id != null">
              <mat-icon>add</mat-icon>
            </button>
          </mat-toolbar>

          <table #criticalInstrTable mat-table style="min-width: 90%" [hidden]="!showCriticalInstrumentation()"
            [dataSource]="datasourceCriticalInstrumentation()">

            <!-- equipment Column -->
            <ng-container matColumnDef="equipment">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.criticalInstr.equipment.label' | translate }}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.equipment}} </td>
            </ng-container>

            <!-- internalId Column -->
            <ng-container matColumnDef="internalId">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.criticalInstr.internalId.label' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="execution.id == null">{{element.internalId}}</span>

                <mat-form-field class="campoFormulario" *ngIf="execution.id != null" style="width: 95%">
                  <input matInput [(ngModel)]="element.internalId" required [errorStateMatcher]="matcher"
                    [ngModelOptions]="{standalone: true}">
                </mat-form-field>
              </td>
            </ng-container>

            <!-- procediment Column -->
            <ng-container matColumnDef="procediment">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.criticalInstr.procediment.label' | translate }}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.procediment}} </td>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="execution.id == null">{{element.internalId}}</span>

                <mat-form-field class="campoFormulario" *ngIf="execution.id != null" style="width: 95%">
                  <input matInput [(ngModel)]="element.internalId" required [errorStateMatcher]="matcher"
                    [ngModelOptions]="{standalone: true}">
                </mat-form-field>
              </td>
            </ng-container>

            <!-- maker Column -->
            <ng-container matColumnDef="maker">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.criticalInstr.maker.label' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="execution.id == null">{{element.maker}}</span>

                <mat-form-field class="campoFormulario" *ngIf="execution.id != null" style="width: 95%">
                  <input matInput [(ngModel)]="element.maker" required [errorStateMatcher]="matcher"
                    [ngModelOptions]="{standalone: true}">
                </mat-form-field>
              </td>
            </ng-container>

            <!-- model Column -->
            <ng-container matColumnDef="model">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.criticalInstr.model.label' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="execution.id == null">{{element.model}}</span>

                <mat-form-field class="campoFormulario" *ngIf="execution.id != null" style="width: 95%">
                  <input matInput [(ngModel)]="element.model" required [errorStateMatcher]="matcher"
                    [ngModelOptions]="{standalone: true}">
                </mat-form-field>
              </td>
            </ng-container>

            <!-- serialNum Column -->
            <ng-container matColumnDef="serialNum">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.criticalInstr.serialNum.label' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="execution.id == null">{{element.serialNum}}</span>

                <mat-form-field class="campoFormulario" *ngIf="execution.id != null" style="width: 95%">
                  <input matInput [(ngModel)]="element.serialNum" required [errorStateMatcher]="matcher"
                    [ngModelOptions]="{standalone: true}">
                </mat-form-field>
              </td>
            </ng-container>

            <!-- certificateNum Column -->
            <ng-container matColumnDef="certificateNum">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'executionEdit.criticalInstr.certificateNum.label' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="execution.id == null">{{element.certificateNum}}</span>

                <mat-form-field class="campoFormulario" *ngIf="execution.id != null" style="width: 95%">
                  <input matInput [(ngModel)]="element.certificateNum" required [errorStateMatcher]="matcher"
                    [ngModelOptions]="{standalone: true}">
                </mat-form-field>
              </td>
            </ng-container>

            <!-- Button edit Column -->
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
              <td mat-cell *matCellDef="let element; let equipmentIndex = index">
                <button mat-icon-button *ngIf="enableEditCriticalInstrumentation()"
                  (click)="openEditCriticalInstrumentation(equipmentIndex)">
                  <mat-icon>mode_edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <!-- uploadCert Column -->
            <ng-container matColumnDef="uploadCert">
              <th mat-header-cell *matHeaderCellDef> {{ 'button.upload' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button [disabled]="disableUploadCriticalInstrumentationButton()"
                  (click)="uploadCertificateCriticalInstr.click()">
                  <mat-icon style="max-height: 20px; vertical-align: text-bottom;"
                    class="mat-icon material-icons {{ styleUploadCriticalInstrumentationButton(element) }}">
                    cloud_upload
                  </mat-icon>
                </button>
                <input accept="application/pdf" hidden type="file"
                  (change)="uploadCertificateCriticalInstrumentation(element, $event)" #uploadCertificateCriticalInstr>
              </td>
            </ng-container>

            <!-- Button delete Column -->
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
              <td mat-cell *matCellDef="let element; let equipmentIndex = index">
                <button mat-icon-button
                  (click)="removeCriticalInstrumentation(equipmentIndex)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="execution.id != null ? criticalInstrCols : criticalInstrColsProt">
            </tr>
            <tr mat-row
              *matRowDef="let row; columns: (execution.id != null ? criticalInstrCols : criticalInstrColsProt);">
            </tr>
          </table>

          <span *ngIf="!showCriticalInstrumentation()">
            <br />
            {{ 'executionEdit.criticalInstr.notRequired' | translate }}
          </span>

        </mat-card-content>
      </mat-card>

      <mat-card class="card" [hidden]="protocol?.id == null && execution.id == null">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'executionEdit.instrumentationUse.title' | translate }}
          </mat-toolbar>

          <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" required
            [(ngModel)]="execution.idInstrumentation" name="idInstrumentation"
            [disabled]="!enableEditInstrumentationToUse()" (change)="onInstrumentationChange($event)">
            <mat-radio-button *ngFor="let item of instrumentationUse" [value]="item.id">
              {{ 'instrumentation.' + item.translation | translate }}
              <button mat-icon-button (click)="editValidator()" *ngIf="canEditValidator(item)"
                matTooltip="{{ 'button.edit' | translate }}">
                <mat-icon>mode_edit</mat-icon>
              </button>
            </mat-radio-button>
          </mat-radio-group>

          <table style="width:100%;" class="tablaConBordes" *ngIf="showValidator()">
            <tr>
              <th>{{ 'protocolEdit.instrumentationUse.validator.calibrationPoint' | translate }}</th>
              <th>{{ 'protocolEdit.instrumentationUse.validator.valueTemp' | translate }}</th>
              <th>{{ 'protocolEdit.instrumentationUse.validator.maxDeviation' | translate }}</th>
              <th>{{ 'protocolEdit.instrumentationUse.validator.minStability' | translate }}</th>
            </tr>
            <tr>
              <td>{{ 'protocolEdit.instrumentationUse.validator.lowTemp' | translate }}</td>
              <td>
                <mat-form-field class="campoFormulario" style="width: 3em">
                  <input matInput [(ngModel)]="execution.validator.lowTemp" name="validatorLowTemp" disabled size="3">
                </mat-form-field>
              </td>
              <td>
                ≤
                <mat-form-field class="campoFormulario" style="width: 3em">
                  <input matInput [(ngModel)]="execution.validator.lowDesv" name="validatorLowDesv" disabled size="3">
                </mat-form-field>
                {{ 'protocolEdit.instrumentationUse.validator.aboutRefTemp' | translate }}
              </td>
              <td>{{ 'protocolEdit.instrumentationUse.validator.notApplicable' | translate }}</td>
            </tr>
            <tr>
              <td>{{ 'protocolEdit.instrumentationUse.validator.highTemp' | translate }}</td>
              <td>
                <mat-form-field class="campoFormulario" style="width: 3em">
                  <input matInput [(ngModel)]="execution.validator.highTemp" name="validatorHighTemp" disabled size="3">
                </mat-form-field>
              </td>
              <td>
                ≤
                <mat-form-field class="campoFormulario" style="width: 3em">
                  <input matInput [(ngModel)]="execution.validator.highDesv" name="validatorHighDesv" disabled size="3">
                </mat-form-field>
                {{ 'protocolEdit.instrumentationUse.validator.aboutRefTemp' | translate }}
              </td>
              <td>{{ 'protocolEdit.instrumentationUse.validator.notApplicable' | translate }}</td>
            </tr>
            <tr>
              <td>{{ 'protocolEdit.instrumentationUse.validator.verifTemp' | translate }}</td>
              <td>
                <mat-form-field class="campoFormulario" style="width: 3em">
                  <input matInput [(ngModel)]="execution.validator.verifTemp" name="validatorVerifTemp" disabled
                    size="3">
                </mat-form-field>
              </td>
              <td>
                ±
                <mat-form-field class="campoFormulario" style="width: 3em">
                  <input matInput [(ngModel)]="execution.validator.verifDesv" name="validatorVerifDesv" disabled
                    size="3">
                </mat-form-field>
                {{ 'protocolEdit.instrumentationUse.validator.aboutRefTemp' | translate }}
              </td>

              <td>
                <mat-form-field class="campoFormulario" style="width: 3em">
                  <input matInput [(ngModel)]="execution.validator.minStability" name="validatorMinStability" disabled
                    size="3">
                </mat-form-field>
                {{ 'protocolEdit.instrumentationUse.validator.tempIn' | translate }}
                <mat-form-field class="campoFormulario" style="width: 3em">
                  <input matInput [(ngModel)]="execution.validator.minStabilityTime" name="validatorMinStabilityTime"
                    disabled size="3">
                </mat-form-field>
                {{ 'protocolEdit.instrumentationUse.validator.minutes' | translate }}
              </td>
            </tr>
          </table>

        </mat-card-content>
      </mat-card>

      <mat-card class="card" [hidden]="protocol?.id == null && execution.id == null">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'executionEdit.essays.title' | translate }}

            <button mat-icon-button color="accent" (click)="openNewEssay()"
              *ngIf="enableEditEquipment() && execution.id != null && false">
              <mat-icon>add</mat-icon>
            </button>
          </mat-toolbar>

          <table #essaysTable mat-table [dataSource]="protocol?.essays" style="min-width: 90%"
            [hidden]="execution.id != null">

            <!-- Type Column -->
            <ng-container matColumnDef="essayType">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.essays.essay.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{'essayType.' + element.translationType | translate }} </td>
            </ng-container>

            <!-- Variables Column -->
            <ng-container matColumnDef="variables">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.essays.variables.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ getVariablesFromEssay(element) }} </td>
            </ng-container>

            <!-- Criteria Column -->
            <ng-container matColumnDef="criteria">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.essays.criteria.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ getCriteriaFromEssay(element) }} </td>
            </ng-container>

            <!-- Load Column -->
            <ng-container matColumnDef="load">
              <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.essays.load.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ getLoadFromEssay(element) }} </td>
            </ng-container>

            <!-- Button see Column -->
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element; let i = index">
                <button mat-icon-button (click)="seeEssayRow(i)">
                  <mat-icon>search</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="essaysCols"></tr>
            <tr mat-row *matRowDef="let row; columns: essaysCols;"></tr>
          </table>

          <app-execution-edit-essay #essaysComponent [idExecution]="execution.id"
            [idStatusExecution]="execution.idStatus" (fcStartEssayEmitter)="onFcStartEssayEmit($event)"
            (updateSensorEssayEmitter)="setSensor($event)" (updateAttachmentListEmitter)="updateAttachmentList()"
            (onIdPeakEmitter)="onIdPeakChange($event)" (reloadPageEmitter)="reloadPageEmit($event)"
            (essayActiveEmitter)="essayActiveEmit($event)" (essayActiveReasonEmitter)="essayActiveReasonEmit($event)">
          </app-execution-edit-essay>

        </mat-card-content>

      </mat-card>

      <app-execution-edit-attachments #attachmentsComponent></app-execution-edit-attachments>

      <mat-card class="card" [hidden]="protocol?.id == null">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'executionEdit.deviations.title' | translate }}
          </mat-toolbar>

          <div fxLayout="column">
            <div fxFlex="100%">
              <mat-form-field class="campoFormulario" style="width: 100%">
                <textarea rows="5" matAutosizeMinRows="5" matInput style="resize: none;"
                  placeholder="{{ 'executionEdit.deviations.deviations.label' | translate }}"
                  [(ngModel)]="execution.deviations" name="deviations"></textarea>
              </mat-form-field>
            </div>
          </div>

        </mat-card-content>
      </mat-card>

      <mat-card class="card" [hidden]="protocol?.id == null">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'executionEdit.observations.title' | translate }}
          </mat-toolbar>

          <div fxLayout="column">
            <div fxFlex="100%" *ngIf="protocol?.id != null">
              <mat-form-field class="campoFormulario" style="width: 100%">
                <textarea rows="5" matAutosizeMinRows="5" matInput disabled [(ngModel)]="protocol.observations"
                  placeholder="{{ 'executionEdit.observations.protocol.label' | translate }}" name="observationProtocol"
                  style="resize: none;"></textarea>
              </mat-form-field>
            </div>

            <div fxFlex="100%">
              <mat-form-field class="campoFormulario" style="width: 100%">
                <textarea rows="5" matAutosizeMinRows="5" matInput [(ngModel)]="execution.observations"
                  name="observationExecution" style="resize: none;"
                  placeholder="{{ 'executionEdit.observations.execution.label' | translate }}"></textarea>
              </mat-form-field>
            </div>
          </div>

        </mat-card-content>
      </mat-card>

      <mat-card class="card" [hidden]="useExistingProtocol && protocol?.id == null">
        <mat-card-actions>
          <div fxFlex></div>
          <button mat-raised-button color="primary" *ngIf="showRevertSign()" (click)="revertSign()">
            {{ 'button.revertSign' | translate }} </button>
          <button mat-raised-button color="primary" *ngIf="showBack()" (click)="calculateBack()">
            {{ 'button.retreat' | translate }} </button>
          <div fxFlex="5px" *ngIf="showExecute()"></div>
          <button mat-raised-button color="primary" *ngIf="showExecute()" (click)="execute()">
            {{ 'button.advance' | translate }}</button>
          <div fxFlex="5px" *ngIf="showManualSign()"></div>
          <button mat-raised-button color="primary" *ngIf="showManualSign()" (click)="manualSign()">
            {{ 'button.sign-manual' | translate }}</button>
          <div fxFlex="5px" *ngIf="showAutomaticSign()"></div>
          <button mat-raised-button color="primary" *ngIf="showAutomaticSign()" (click)="automaticSign()">
            {{ 'button.sign-automatic' | translate }}</button>
          <div fxFlex="10px"></div>
          <button mat-raised-button color="primary" (click)="saveExecution()">
            {{ 'button.save' | translate }} </button>

          <div matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton">
            <button mat-raised-button color="accent" *ngIf="showDownloadButton()" (click)="downloadPdf()"
              [disabled]="disableOfflineButton">{{ 'button.generateDoc' | translate }}</button>
          </div>
          <button mat-raised-button color="secondary" (click)="cancel()">{{ 'button.cancel' | translate }}</button>
        </mat-card-actions>
      </mat-card>
      <br />
      <br />
      <br />

    </form>

  </mat-tab>
  <mat-tab label="{{ 'executionEdit.historical.title' | translate }}" *ngIf="execution.id != null">
    <app-execution-edit-audit [idExecution]="execution.id" [documentCode]="execution.documentCode">
    </app-execution-edit-audit>
  </mat-tab>
</mat-tab-group>