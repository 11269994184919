import { AppTypeEnum } from '../model/appType';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';

@Injectable()
export class ThemeService {

    constructor(private userService: UserService) {
    }

    getTheme(): string {
        const currentApp = this.getCurrentApp();

        let res = '';

        switch (currentApp) {
            case AppTypeEnum.CALIBRATES:
                res = 'calibrates-theme';
                break;
                case AppTypeEnum.AIR:
                    res = 'air-theme';
                    break;
            case AppTypeEnum.THERMAL:
            default:
                res = 'thermal-theme';
                break;
        }
        return res;
    }


    getThemeHome(currentApp: number): string {

        let res = '';

        switch (currentApp) {
            case AppTypeEnum.CALIBRATES:
                res = 'calibrates-theme';
                break;
                case AppTypeEnum.AIR:
                    res = 'air-theme';
                    break;
            case AppTypeEnum.THERMAL:
            default:
                res = 'thermal-theme';
                break;
        }
        return res;
    }

    getLogo(): string {
        const currentApp = this.getCurrentApp();

        let res = '';

        switch (currentApp) {
            case AppTypeEnum.CALIBRATES:
                res = 'qloud_calibrates.png';
                break;
                case AppTypeEnum.AIR:
                    res = 'qloud_air.png';
                    break;
            case AppTypeEnum.THERMAL:
            default:
                res = 'qloud_thermal.png';
                break;
        }
        return res;
    }

    getText(): string {
        const currentApp = this.getCurrentApp();

        let res = '';

        switch (currentApp) {
            case AppTypeEnum.CALIBRATES:
                res = 'landing.main.calibrates';
                break;
            case AppTypeEnum.AIR:
                res = 'landing.main.air';
                break;
            case AppTypeEnum.THERMAL:
            default:
                res = 'landing.main.thermal';
                break;
        }
        return res;
    }

    getThemeIconPath(): string {
        const currentApp = this.getCurrentApp();

        let res = '';

        switch (currentApp) {
            case AppTypeEnum.CALIBRATES:
                res = 'assets/icons/calibrates/';
                break;
                case AppTypeEnum.AIR:
                    res = 'assets/icons/air/';
                    break;
            case AppTypeEnum.THERMAL:
            default:
                res = 'assets/icons/thermal/';
                break;
        }
        return res;
    }

    getThemeIconPathOption(currentApp: number): string {
        let res = '';

        switch (currentApp) {
            case AppTypeEnum.CALIBRATES:
                res = 'assets/icons/calibrates/';
                break;
                case AppTypeEnum.AIR:
                    res = 'assets/icons/air/';
                    break;
            case AppTypeEnum.THERMAL:
            default:
                res = 'assets/icons/thermal/';
                break;
        }
        return res;
    }

    getThemeIconPathSelection(url: string): string {
        if (url?.includes('air')) {
            return this.getThemeIconPathOption(AppTypeEnum.AIR);
        } else if (url?.includes('calibrates')) {
            return this.getThemeIconPathOption(AppTypeEnum.CALIBRATES);
        } else if (url?.includes('thermal')) {
            return this.getThemeIconPathOption(AppTypeEnum.THERMAL);
        }
    }

    private getCurrentApp(): number {
        let res = AppTypeEnum.THERMAL;

        const currentUser = this.userService.currentProfile;

        if (currentUser != null && currentUser.idActiveApp != null) {
            res = currentUser.idActiveApp;
        }

        return res;
    }
}
