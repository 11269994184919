<h1 mat-dialog-title> {{ 'internalEquipmentEdit.used.code.title' | translate }}</h1>
<form>
    <div fxLayout="column" fxLayout.xs="column" class="flexwrap" *ngIf="!showView">

        <div fxLayout="column">
            <div fxFlex="100">
                <mat-form-field class="campoFormulario">
                    <input matInput name="data" type="text"
                        placeholder="{{ 'internalEquipmentEdit.used.code.label' | translate }}" [(ngModel)]="codeUserEquipment"
                        required>
                    <mat-error>
                        {{ 'internalEquipmentEdit.used.code.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center">
            <button style="margin-right: 5px;" mat-raised-button color="primary" (click)="showAndValidateUser()">{{ 'button.advance' | translate
                }}</button>
                
            <button mat-raised-button color="primary" (click)="closeValidate()">{{ 'button.cancel' | translate
                }}</button>
        </div>

    </div>
    <div fxLayout="column" fxLayout.xs="column" class="flexwrap" *ngIf="showView">

       <div fxLayout="row" fxLayout.xs="column" class="flexwrap" >
            <div fxFlex="50" fxFlex.xs="100%" fxFlex.sm="50%">
                <mat-form-field class="campoFormulario">
                    <input matInput name="name" [(ngModel)]="filter.name"
                        placeholder="{{ 'internalEquipmentEdit.used.filter.name.label' | translate }}">
                </mat-form-field>
            </div>

            <div fxFlex="50" fxFlex.xs="100%" fxFlex.sm="50%">
                <mat-form-field class="campoFormulario">
                    <input matInput name="identifitive" [(ngModel)]="filter.identificative"
                        placeholder="{{ 'internalEquipmentEdit.used.filter.identificative.label' | translate }}">
                </mat-form-field>
            </div>

            <div fxFlex="50" fxFlex.xs="100%" fxFlex.sm="50%">
                <mat-form-field class="campoFormulario">
                    <input matInput name="year" [(ngModel)]="filter.year"
                    placeholder="{{ 'internalEquipmentEdit.used.filter.year.label' | translate }}">
                </mat-form-field>
            </div>
       </div>
       <div fxLayout="row"  fxLayoutAlign="center center">
            <button mat-raised-button style="margin-right: 5px;" (click)="generateExcel()" color="primary">
                {{ 'button.filter' | translate }}</button>
            <button mat-raised-button color="primary" (click)="closeValidate()">{{ 'button.cancel' | translate
                }}</button>   
        </div>
    </div>
    

</form>