import { EssayAirTypeEnum } from './essayType';
import { GenericClassTranslate } from './genericClass';

export class Variable extends GenericClassTranslate {
    required: boolean;
    checked: boolean;
    idConfig: number;
    idConfigDependsOn: number;
    idTypeDependency: number;
    measurementUnit: string;
}

export class VariableUnit {
    id: number;
    name: string;
    unit: string;
    idVariable: number;
}

export enum VariableTypeEnum {
    NOT_APPLICABLE = 0,
    TEMPERATURE = 1,
    HUMIDITY = 2,
    CO2 = 3,
    LETHALITY = 4,
    PRESSURE = 5,
    TIME = 7,
    DIMENSIONAL = 11,
    SPEED = 22,
    PORCENTAJE= 24
}

export enum VariableUnitEnum {
    CELSIUS = 1,
    RELATIVE_HUMIDITY_PERCENT = 2,
    CO2_PERCENT = 3,
    LETHALITY = 4,
    PRESSURE_BAR = 5,
    PRESSURE_PASCAL = 8,
    CO2_PPM = 28,
    SPEED_RPM = 18,
    SPEED_M_PER_S = 58,
    SPEED_RCF = 64,
    LENGHT_M = 41
}

export class DefaultUnit {

    private static _defaultValues: Map<number, number>;

    static getDefaultValues(): Map<number, number> {
        if (this._defaultValues == null) {
            this._defaultValues = new Map<number, number>();
            this._defaultValues.set(VariableTypeEnum.TEMPERATURE, VariableUnitEnum.CELSIUS);
            this._defaultValues.set(VariableTypeEnum.HUMIDITY, VariableUnitEnum.RELATIVE_HUMIDITY_PERCENT);
            this._defaultValues.set(VariableTypeEnum.CO2, VariableUnitEnum.CO2_PERCENT);
            this._defaultValues.set(VariableTypeEnum.PRESSURE, VariableUnitEnum.PRESSURE_PASCAL);
            this._defaultValues.set(VariableTypeEnum.SPEED, VariableUnitEnum.SPEED_M_PER_S);
        }

        return this._defaultValues;
    }

    static getDefaultUnitForEssayAir(idEssayType: number): number {
        let res: number = null;

        if (idEssayType === EssayAirTypeEnum.LINE_PRESSURE) {
            res = VariableUnitEnum.PRESSURE_BAR;
        } else if (idEssayType === EssayAirTypeEnum.CO2_CONCENTRATION) {
            res = VariableUnitEnum.CO2_PPM;
        }

        return res;
    }

}