/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-len */
import * as _ from 'lodash-es';
import * as moment from 'moment';
import { v4 as uuid } from 'uuid';

import { ActivatedRoute, Router } from '@angular/router';
import {
  Calibration,
  CalibrationAsFound,
  CalibrationAsLeft,
  CalibrationPattern,
  CalibrationResponsibleEnum,
  CalibrationStatus,
  CalibrationVariable,
  CalibrationVariableConfig
} from 'src/app/model/calibration';
import { CalibrationEditConfirmSaveComponent, DialogDataConfirmSave } from './calibration-edit-dialog-confirmSave.component';
import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GenericClass, GenericClassTranslate, GenericWarnError } from 'src/app/model/genericClass';
import { Variable, VariableTypeEnum, VariableUnit } from 'src/app/model/variable';

import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { CalibrationEditAsFoundDialogComponent } from './calibration-edit-as-found-dialog.component';
import { CalibrationEditAsLeftDialogComponent } from './calibration-edit-as-left-dialog.component';
import { CalibrationEditAuditComponent } from './calibration-edit-audit.component';
import { CalibrationEditAutomaticSignReportComponent } from './calibration-edit-automatic-sign-report.component';
import { CalibrationEditConfigDialogComponent } from './calibration-edit-config-dialog.component';
import { CalibrationEditCreateEquipmentComponent } from './calibration-edit-create-equipment.component';
import { CalibrationEditGenerateReportComponent } from './calibration-edit-generate-report.component';
import { CalibrationEditManualSignReportComponent } from './calibration-edit-manual-sign-report.component';
import { CalibrationEditPatternsDialogComponent } from './calibration-edit-patterns-dialog.component';
import { CalibrationPlaceService } from 'src/app/services/calibrationPlace.service';
import { CalibrationService } from 'src/app/services/calibration.service';
import { CalibrationStatusService } from 'src/app/services/calibrationStatus.service';
import { CalibrationUtils } from 'src/app/utils/calibratesUtils';
import { CheckWarnsErrorsComponent } from '../../shared/check-warns-errors/check-warns-errors.component';
import { Client } from 'src/app/model/client';
import { ClientService } from 'src/app/services/client.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { Constants } from 'src/app/utils/constants';
import { CustomDatepickerHeaderComponent } from '../../shared/datepicker-custom-header/datepicker-custom-header.component';
import { DateUtils } from 'src/app/utils/dateUtils';
import { EquipmentAutocompleteFilter } from 'src/app/model/autocompleteFilter';
import { Group } from 'src/app/model/group';
import { GroupUtils } from 'src/app/utils/groupUtils';
import { InternalEquipment } from 'src/app/model/internalEquipment';
import { InternalEquipmentService } from 'src/app/services/internalEquipment.service';
import { ManageGroupsService } from 'src/app/services/manageGroups.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatTable } from '@angular/material/table';
import { NumberUtils } from 'src/app/utils/numberUtils';
import { Procedure } from 'src/app/model/procedure';
import { ProcedureService } from 'src/app/services/procedure.service';
import { ReasonDialogComponent } from '../../shared/reason-dialog/reason-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StringUtils } from 'src/app/utils/stringUtils';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { VariableTypeService } from 'src/app/services/variableType.service';
import { takeUntil } from 'rxjs/operators';
import { RoleEnum } from '../../../model/roleUser';
import { InternalEquipmentFolder } from 'src/app/model/internalEquipmentFolder';
import { InternalEquipmentSelectFolderComponent } from '../../shared/internal-equipment-select-folder/internal-equipment-select-folder.component';
import { CalibrationAmbientConditions } from '../../../model/calibrationAmbientConditions';
import { CalibrationEditDialogParticlesComponent } from './calibration-edit-dialog-particles.component';
import { ParticlesCalibraton } from '../../../utils/calibratesUtils';
import { InternalEquipmentPerformances } from 'src/app/model/internalEquipmentPerformances';
import { CalibrationEditMultichannelComponent } from './calibration-edit-multichannel/calibration-edit-multichannel.component';
import { CalibrationVariableMultichannel } from '../../../model/calibration';

@Component({
  selector: 'app-calibration-edit',
  templateUrl: './calibration-edit.component.html'
})
export class CalibrationEditComponent implements OnInit, OnDestroy {

  @ViewChildren('configTable') configTables: MatTable<any> | QueryList<MatTable<any>>;
  @ViewChildren('asFoundTable') asFoundTables: MatTable<any> | QueryList<MatTable<any>>;
  @ViewChildren('asLeftTable') asLeftTables: MatTable<any> | QueryList<MatTable<any>>;
  @ViewChildren('uncertaintiesTable') uncertaintiesTables: MatTable<any> | QueryList<MatTable<any>>;
  @ViewChildren('patternsTable') patternsTables: MatTable<any> | QueryList<MatTable<any>>;

  @ViewChildren('asLeftTableMultiChannel') asLeftTableMultiChannel: MatTable<any> | QueryList<MatTable<any>>;
  @ViewChildren('asFoundTableMultiChannel') asFoundTableMultiChannel: MatTable<any> | QueryList<MatTable<any>>;

  @ViewChild(CalibrationEditAuditComponent) audit: CalibrationEditAuditComponent;

  calibration: Calibration;
  equipment: InternalEquipment;

  public equipmentAutoComplete: InternalEquipment[];

  currentUser: User;

  statuses: GenericClass[];
  procedures: Procedure[];
  places: GenericClassTranslate[];
  clients: Client[];
  clientsFiltered: Client[];

  variables: Variable[];
  variableUnits: VariableUnit[];
  variableUnitsExtra: VariableUnit[];
  variablesSelected: Variable[];

  idClientSelected: number;

  clientName: string;
  clientAddress: string;

  criteriaAceptationParticles = 0;

  equipmentCalibration: InternalEquipmentPerformances = new InternalEquipmentPerformances();
  calibrationVMultichannel: CalibrationVariableMultichannel[] = [];

  customDatepickerHeader = CustomDatepickerHeaderComponent;

  configCols = ['range', 'resolution', 'tolerance', 'uncertaintyResolution', 'values', 'edit', 'delete'];
  asFoundCols = ['number', 'point', 'pattern', 'equipment', 'correction', 'edit', 'delete'];
  asLeftCols = ['number', 'point', 'pattern', 'value', 'average', 'correction', 'U', 'cPlusU', 'edit'];
  uncertaintiesCols = ['number', 'point', 'uRep', 'uResol', 'uPattern', 'uUnif', 'uEstab', 'uc', 'neff', 'k', 'U', 'measureUnit'];
  patternsCols = ['name', 'equipment', 'maker', 'model', 'serialNum', 'uncertainty', 'edit', 'delete'];

  maxDateCalibration = new Date();
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private internalEquipmentService: InternalEquipmentService,
    private calibrationService: CalibrationService,
    private calibrationStatusService: CalibrationStatusService,
    private userService: UserService,
    private variableTypeService: VariableTypeService,
    private procedureService: ProcedureService,
    private calibrationPlaceService: CalibrationPlaceService,
    private manageGroupsService: ManageGroupsService,
    private clientService: ClientService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    this.spinnerService.show();

    this.currentUser = this.userService.currentProfile;

    this.equipment = new InternalEquipment();
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const id = params[Constants.FIELD_ID] as number;
      const idEquipment = params[Constants.FIELD_ID_EQUIPMENT] as number;

      if (id == null || isNaN(+id)) {
        this.cancel();
      }

      this.calibration = new Calibration();

      this.calibration.calibrationDate = moment().toDate();
      this.calibration.actionType = 1;
      if (+id === 0) {
        this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((clients: Client[]) => {
          this.clients = clients;
          this.clientsFiltered = this.clients;
        });

        if (idEquipment != null) {
          this.spinnerService.show();
          this.internalEquipmentService.findOne(idEquipment).pipe(takeUntil(this.destroy$)).subscribe((eq: InternalEquipment) => {
            this.onEquipmentChange(eq);
            if (this.equipment.performances?.length > 0) {
              const rCalibration = this.equipment.performances.find(t => t.idActionType === 1);
              if (rCalibration) {
                this.equipmentCalibration = rCalibration;
                if (rCalibration && rCalibration?.frequency?.match('^[0-9]+$')) {
                  this.equipmentCalibration.expirationDate = _.cloneDeep(this.calibration.calibrationDate);
                  this.equipmentCalibration.expirationDate.setMonth(this.calibration.expirationDate.getMonth() + Number(rCalibration.frequency));
                } else {
                  if (!this.calibration.expirationDate) {
                    this.calibration.expirationDate = _.cloneDeep(this.calibration.calibrationDate);
                    this.calibration.expirationDate.setFullYear(this.calibration.expirationDate.getFullYear() + 1);
                  }
                }
              }
            }
            this.idClientSelected = eq.internal ? -1 : eq.idClient;
            this.spinnerService.hide();
          }, () => this.spinnerService.hide());
        } else {
          this.spinnerService.hide();
        }
        this.calibration.multichannel = false;
      } else {
        this.calibrationService.findOne(id).pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
          if (result != null) {
            this.calibration = result;
            this.calibration.calibrationDate = DateUtils.anyToDate(this.calibration.calibrationDate);
            this.calibration.expirationDate = DateUtils.anyToDate(this.calibration.expirationDate);

            this.calibration.variables?.forEach((v: CalibrationVariable, vIndex: number) => {

              const variable = this.variables.find(va => va.id === v.idVariable);

              if (variable != null) {
                this.variablesSelected.push(variable);
              }

              if (!this.calibration.multichannel) {
                v.asFound = v.asFound?.map(value => CalibrationUtils.objectToAsFound(value)).sort((a1, a2) => a1.id - a2.id);

                v.config?.forEach(c => {
                  c.asLeft = c.asLeft?.map(valueConfig => CalibrationUtils.objectToAsLeft(valueConfig));
                });

                this.configurateAsLeft(vIndex);
              } else {
                v.valuesMultichannel?.forEach(c => {
                  c.asLeft = c.asLeft?.map(valueConfig => CalibrationUtils.objectToAsLeft(valueConfig));

                  c.asFound = c.asFound?.map(value => CalibrationUtils.objectToAsFound(value)).sort((a1, a2) => a1.id - a2.id);

                });

                this.configurateAsLeftMulticanal(vIndex);
                this.calculateResultMulticanal();
              }
            });

            this.onVariableTabChange(0);

            internalEquipmentService.findOne(this.calibration.idEquipment).pipe(takeUntil(this.destroy$))
              .subscribe((item: InternalEquipment) => {
                this.equipment = item;

                if (this.equipment.performances?.length > 0) {
                  const rCalibration = this.equipment.performances.find(t => t.idActionType === 1);
                  if (rCalibration) {
                    this.equipmentCalibration = rCalibration;
                  }
                }
                this.calculateClientData();
              }, () => this.spinnerService.hide());
          } else {
            this.calibration = new Calibration();
          }
        }, () => {
          this.calibration = new Calibration();
          this.spinnerService.hide();
        }, () => {
          if (this.calibration == null) {
            this.cancel();
          }
        });
      }

    });

  }

  ngOnInit(): void {
    this.statuses = [];
    this.procedures = [];
    this.places = [];
    this.variables = [];
    this.variablesSelected = [];
    const disallowedVariables: number[] = [VariableTypeEnum.LETHALITY];

    this.variableTypeService.findAllCalibrates().pipe(takeUntil(this.destroy$)).subscribe((data: Variable[]) => {
      this.variables = data.filter(v => !disallowedVariables.includes(v.id));
      if (this.calibration && this.calibration?.variables) {
        this.calibration.variables.forEach((v: CalibrationVariable, vIndex: number) => {

          const variable = this.variables.find(va => va.id === v.idVariable);

          if (variable != null) {
            this.variablesSelected.push(variable);
          }
        });
      }
    });

    this.calibrationStatusService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.statuses = data);
    this.procedureService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Procedure[]) => this.procedures = data);
    this.calibrationPlaceService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClassTranslate[]) => this.places = data);
    this.criteriaAceptationParticles = ParticlesCalibraton.CRITERIA_ACEPTATION_PARTICLES;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showExtraField(variable: CalibrationVariable): boolean {
    return CalibrationUtils.showExtraField(variable);
  }

  getExtraFieldName(variable: CalibrationVariable): string {
    return CalibrationUtils.getExtraFieldName(variable);
  }

  lookupEquipment($event): void {
    let results = this.equipmentAutoComplete;

    const filter = new EquipmentAutocompleteFilter();

    filter.query = $event.target.value as string;
    filter.pageIndex = 0;
    filter.pageSize = 10;
    filter.sortBy = 'name';
    filter.sortDirection = 'asc';
    filter.forCalibration = true;

    if (this.idClientSelected != null) {
      if (typeof this.idClientSelected === 'string') {
        if (this.idClientSelected !== '-1') {
          filter.idClient = Number(this.idClientSelected);
        } else {
          filter.internalEquipment = true;
        }
      } else {
        if (this.idClientSelected !== -1) {
          filter.idClient = this.idClientSelected;
        } else {
          filter.internalEquipment = true;
        }
      }
    }

    this.internalEquipmentService.findAutocomplete(filter).pipe(takeUntil(this.destroy$)).subscribe(item => {
      results = item.content;
      this.equipmentAutoComplete = results;
    }, () => {
      results = [];
      this.equipmentAutoComplete = results;
    });
  }

  createEquipment(): void {
    this.dialog.open(CalibrationEditCreateEquipmentComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        idType: this.idClientSelected === -1 ? CalibrationResponsibleEnum.INTERNAL : CalibrationResponsibleEnum.EXTERNAL,
        idClient: this.idClientSelected === -1 ? null : this.idClientSelected
      }
    });
  }

  onSensorChange($event: any, trigger: MatAutocompleteTrigger): void {
    const equipmentSelected = $event.option.value as InternalEquipment;

    this.onEquipmentChange(equipmentSelected);

    event.stopPropagation();
    trigger.closePanel();
  }

  getAsLeftList(idVariable: number): CalibrationAsLeft[] {
    const res: CalibrationAsLeft[] = [];

    if (this.calibration.variables[idVariable]?.config) {
      this.calibration.variables[idVariable].config?.forEach((config, indexConfig) => {
        config.asLeft.forEach(aL => {
          aL.indexConfig = indexConfig;

          res.push(aL);
        });
      });
    }

    return res.sort((a1, a2) => a1.id - a2.id);
  }

  displayFn(eq?: InternalEquipment | string | { name: string }): string {
    if (!eq) {
      return null;
    }

    let res: string = null;

    if (typeof eq === 'string') {
      res = eq;
    } else if (eq instanceof InternalEquipment || eq.name != null) {
      res = eq.name;
    }

    return res;
  }

  configurateAsLeft(indexVariable: number): void {
    const variable = this.calibration.variables[indexVariable];

    variable?.config?.forEach(config => {

      config.asLeft?.forEach(aL => {
        const cfg = this.findConfig(indexVariable, aL.point);

        if (cfg != null) {
          aL.resolution = cfg.resolution;
        }

        const patterns = variable.patterns?.map(p => {
          if (ArrayUtils.isEmpty(p.values)) {
            return null;
          }

          const res = p.values?.filter(point => point.value === aL.point);

          if (ArrayUtils.isEmpty(res)) {
            return null;
          } else {
            return res[0];
          }

        })?.filter(p => p != null).map(p => p.uncertainty);

        aL.patterns = patterns;

        this.updateTable(this.asLeftTables);
      });
    });

  }

  joinListValuesConfig(item: CalibrationVariableConfig): string {
    if (ArrayUtils.isEmpty(item.asLeft)) {
      return '';
    }

    const array: number[] = item.asLeft.map(v => v.point);

    const totalDecimals = this.getDecimalsResolutionConfig(item);

    return this.joinList(array, totalDecimals);
  }

  joinListValues(idVariable: number, item: CalibrationAsLeft): string {
    if (ArrayUtils.isEmpty(item.values)) {
      return '';
    }

    const array: number[] = item.values.map(v => v.value);

    const totalDecimals = this.getDecimalsResolution(idVariable, item.point);

    return this.joinList(array, totalDecimals);
  }

  joinListUP(idVariable: number, item: CalibrationAsLeft): string {
    if (ArrayUtils.isEmpty(item.patterns)) {
      return '';
    }

    const array: number[] = item.patterns;

    const totalDecimals = this.getDecimalsUncertainty(idVariable, item.point);

    return this.joinList(array, totalDecimals);
  }

  getWorstUncertainty(item: CalibrationPattern): any {
    if (ArrayUtils.isEmpty(item.values)) {
      return null;
    }

    if (item.equipment?.name.includes('QDE-')) {
      return this.translate.instant('common.notApplicable') as string;
    }

    return Math.max(...item.values.map(v => v.uncertainty));
  }

  getDecimalsResolution(idVariable: number, point: number): number {
    const config = this.findConfig(idVariable, point);

    return this.getDecimalsResolutionConfig(config);
  }

  getDecimalsResolutionConfig(config: CalibrationVariableConfig): number {
    let res = 2;
    if (config != null) {
      const resolution = config.resolution;
      if (resolution === 1) {
        res = 0;
      } else {
        res = NumberUtils.countDecimals(resolution);
      }
    }

    return res;
  }

  getDecimalsUncertainty(idVariable: number, point: number): number {
    const config = this.findConfig(idVariable, point);

    let res = 2;

    if (config != null) {
      const uncertaintyResolution = config.uncertaintyResolution;
      const uncertaintyDecimals = NumberUtils.countDecimals(uncertaintyResolution);

      if (uncertaintyDecimals === 0) {
        res = uncertaintyResolution;
      } else {
        res = uncertaintyDecimals;
      }
    }

    return res;
  }

  getDecimalsPattern(pattern: number): number {
    return NumberUtils.countDecimals(pattern);
  }

  styleCPlusU(indexVariable: number, point: number, value: number): string {
    let res = '';

    const config = this.findConfig(indexVariable, point);
    if (config == null) {
      return res;
    }

    const tolerance = config.tolerance;

    if (tolerance == null || isNaN(+tolerance) || +tolerance === 0) {
      return res;
    }

    res = (+tolerance) < value ? 'invalidValue' : 'validValue';
    return res;
  }

  calculateResult(): void {

    const configs = [].concat(...this.calibration.variables.map(v => v.config)) as CalibrationVariableConfig[];

    const tolerances = configs.map(c => +c.tolerance).filter(v => v != null && !isNaN(v) && v !== 0);

    if (!ArrayUtils.isEmpty(tolerances)) {
      let res = true;

      this.calibration.variables?.forEach((variable, vIndex) => {

        variable.config?.forEach(c => {
          const styles = c.asLeft.map(aL => this.styleCPlusU(vIndex, aL.point, aL.cPlusU)).filter(v => v != null && v === 'validValue');

          res = res && (styles.length === c.asLeft.length);
        });
      });

      this.calibration.correct = res;
    } else {
      this.calibration.correct = true;
    }
  }

  saveDate(field: string, event: any): void {
    let value: Date | string = event.value;

    if (value != null) {

      if (value instanceof Date) {
        value = moment(value).format('DD/MM/YYYY');
      }

      const date = moment(value + ' 12:00:00 + 0:00', 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }

      this.calibration[field] = date;

      if (field === 'calibrationDate') {
        if (this.calibration.expirationDate === null) {
          if (this.equipmentCalibration.frequency && this.equipmentCalibration.frequency?.match('^[0-9]+$')) {
            this.calibration.expirationDate = _.cloneDeep(this.calibration.calibrationDate);
            this.calibration.expirationDate.setMonth(this.calibration.expirationDate.getMonth() + Number(this.equipmentCalibration.frequency));
          } else {
            this.calibration.expirationDate = _.cloneDeep(this.calibration.calibrationDate);
            this.calibration.expirationDate.setFullYear(this.calibration.expirationDate.getFullYear() + 1);
          }
        }
      }

    } else {
      this.calibration[field] = null;
    }

  }

  newConfigRow(idVariable: number): void {
    const calibration = this.calibration.variables[idVariable];

    const dialogRef = this.dialog.open(CalibrationEditConfigDialogComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        item: new CalibrationVariableConfig()
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CalibrationVariableConfig) => {

      if (result != null) {
        if (!calibration.config) {
          calibration.config = [];
        }
        calibration.config?.push(result);
        // his.calibration.variables[idVariable]?.config?.push(result);

        if (this.calibration.multichannel) {
          calibration.valuesMultichannel?.forEach(a => {
            if (!a.asLeft) {
              a.asLeft = [];
            }

            result.asLeft?.forEach(ras => {
              ras.uuidConfig = result.uuidConfig;
              ras.idCalibrationConfig = result.id;
              a.asLeft.push(_.cloneDeep(ras));
            })

            this.calculateResultMulticanal();

            this.updateTable(this.configTables);
            this.updateTable(this.asLeftTables);
            this.updateTable(this.patternsTables);
          });
        } else {
          this.calculateResult();
          this.updateTable(this.configTables);
          this.updateTable(this.asLeftTables);
          this.updateTable(this.patternsTables);
        }
      }
    });
  }

  editConfigRow(idVariable: number, index: number): void {
    const calibration = this.calibration.variables[idVariable];

    const row = calibration.config[index];

    const dialogRef = this.dialog.open(CalibrationEditConfigDialogComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        item: _.cloneDeep(row)
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CalibrationVariableConfig) => {

      if (result != null) {
        calibration.config[index] = result;

        if (this.calibration.multichannel) {
          calibration.valuesMultichannel?.forEach(a => {
            if (!a.asLeft) {
              a.asLeft = [];
            }

            a.asLeft = a.asLeft.filter(aasl => result.id && aasl.uuidConfig !== result.uuidConfig);

            result.asLeft.forEach(ras => {
              a.asLeft.push(_.cloneDeep(ras));
            })
            this.calculateResultMulticanal();
            this.configurateAsLeftMulticanal(idVariable);

            this.updateTable(this.asLeftTableMultiChannel);
          })
        } else {
          this.calculateResult();
          this.updateTable(this.configTables);
          this.updateTable(this.asLeftTables);
          this.updateTable(this.patternsTables);
        }

      }
    });
  }

  deleteConfigRow(idVariable: number, index: number): void {
    const calibration = this.calibration.variables[idVariable];
    const config = _.cloneDeep(calibration.config);
    calibration.config.splice(index, 1);

    if (this.calibration.multichannel) {
      const data = config[index];
      calibration.valuesMultichannel.find(e => {
        const dataLeft = e.asLeft.filter(eas => eas.uuidConfig !== data.uuidConfig);

        e.asLeft = _.cloneDeep(dataLeft);
        this.calculateResultMulticanal();
      })
    } else {
      this.calculateResult();
    }
    this.updateTable(this.configTables);
    this.updateTable(this.asLeftTables);
    this.updateTable(this.patternsTables);
  }

  newAsFoundRow(idVariable: number): void {
    const variable = this.calibration.variables[idVariable];

    const dialogRef = this.dialog.open(CalibrationEditAsFoundDialogComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        item: new CalibrationAsFound(),
        variable
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CalibrationAsFound) => {

      if (result != null) {
        variable.asFound.push(result);

        this.updateTable(this.asFoundTables);
      }
    });
  }

  editAsFoundRow(idVariable: number, index: number): void {
    const variable = this.calibration.variables[idVariable];

    const row = variable.asFound[index];

    const dialogRef = this.dialog.open(CalibrationEditAsFoundDialogComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        item: _.cloneDeep(row),
        variable
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CalibrationAsFound) => {

      if (result != null) {
        variable.asFound[index] = result;

        this.updateTable(this.asFoundTables);
      }
    });
  }

  deleteAsFoundRow(idVariable: number, index: number): void {
    const calibration = this.calibration.variables[idVariable];
    calibration.asFound.splice(index, 1);

    this.updateTable(this.asFoundTables);
  }

  editAsLeftRow(indexVariable: number, indexConfig: number, index: number): void {
    const variable = this.calibration.variables[indexVariable];
    const configs = this.getAsLeftList(indexVariable);
    const config = variable.config[indexConfig];

    const row = configs[index];
    const rowIndex = config.asLeft.indexOf(row);

    const emptyList: CalibrationAsLeft[] = [];
    const asLeftList = emptyList.concat(...variable.config?.map(c => c.asLeft));
    const minValues = Math.max(...asLeftList.map(aL => ArrayUtils.isEmpty(aL.values) ? 0 : aL.values.length));

    const dialogRef = this.dialog.open(CalibrationEditAsLeftDialogComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        item: _.cloneDeep(row),
        minValues
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CalibrationAsLeft) => {

      if (result != null) {
        config.asLeft[rowIndex] = result;
        this.configurateAsLeft(indexVariable);

        this.calculateResult();

        this.updateTable(this.configTables);
        this.updateTable(this.asLeftTables);
        this.updateTable(this.patternsTables);
      }
    });
  }

  newPatternRow(idVariable: number): void {
    const variable = this.calibration.variables[idVariable];

    const points = this.getAsLeftList(idVariable).map(aL => aL.point);

    const dialogRef = this.dialog.open(CalibrationEditPatternsDialogComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        item: new CalibrationPattern(),
        variable,
        points,
        units: this.variableUnits
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CalibrationPattern) => {

      if (result != null) {
        variable.patterns.push(result);

        if (this.calibration.multichannel) {
          variable.valuesMultichannel.forEach(() => {
            this.configurateAsLeftMulticanal(idVariable);
          });

          this.calculateResultMulticanal();

          this.updateTable(this.configTables);
          this.updateTable(this.asLeftTables);
          this.updateTable(this.patternsTables);
        } else {
          variable.config.forEach(() => {
            this.configurateAsLeft(idVariable);
          });

          this.calculateResult();

          this.updateTable(this.configTables);
          this.updateTable(this.asLeftTables);
          this.updateTable(this.patternsTables);
        }
      }
    });
  }

  editPatternRow(idVariable: number, index: number): void {
    const variable = this.calibration.variables[idVariable];

    const row = variable.patterns[index];

    const points = this.getAsLeftList(idVariable).map(aL => aL.point);

    const dialogRef = this.dialog.open(CalibrationEditPatternsDialogComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        item: _.cloneDeep(row),
        variable,
        points,
        units: this.variableUnits
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CalibrationPattern) => {

      if (result != null) {
        variable.patterns[index] = result;

        if (this.calibration.multichannel) {
          this.configurateAsLeftMulticanal(idVariable);

          this.calculateResultMulticanal();

          this.updateTable(this.configTables);
          this.updateTable(this.asLeftTables);
          this.updateTable(this.patternsTables);
        } else {
          this.configurateAsLeft(idVariable);

          this.calculateResult();

          this.updateTable(this.configTables);
          this.updateTable(this.asLeftTables);
          this.updateTable(this.patternsTables);
        }
      }
    });
  }

  deletePatternRow(idVariable: number, index: number): void {
    const calibration = this.calibration.variables[idVariable];
    calibration.patterns.splice(index, 1);

    if (this.calibration.multichannel) {
      this.calculateResultMulticanal();

      this.updateTable(this.asLeftTableMultiChannel);
    } else {
      this.updateTable(this.configTables);
      this.updateTable(this.asLeftTables);
      this.updateTable(this.patternsTables);
    }
  }

  saveCalibration(): void {
    const errors = this.checkCalibration(true, false);

    if (errors.length !== 0) {
      this.snackBarService.sendError(errors.join('\n'));

      return;
    }

    this.performSave();
  }

  manualSign(): void {
    const errors = this.checkCalibration(false, true);

    if (errors.length !== 0) {
      this.snackBarService.sendError(errors.join('\n'));

      return;
    }

    this.prepareToSave();

    const dialogRef = this.dialog.open(CalibrationEditManualSignReportComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {
        calibration: this.calibration,
        reason: this.calibration.reason
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(res => {

      if (res != null) {
        this.findClient();
      }
      this.spinnerService.hide();
    });
  }

  automaticSign(): void {
    const errors = this.checkCalibration(false, true);

    if (errors.length !== 0) {
      this.snackBarService.sendError(errors.join('\n'));

      return;
    }

    this.prepareToSave();

    const dialogRef = this.dialog.open(CalibrationEditAutomaticSignReportComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {
        calibration: this.calibration,
        equipment: this.equipment
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(res => {

      if (res != null) {

        this.findClient();

      }
      this.spinnerService.hide();
    });
  }

  reloadPage(idCalibration: number): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    let url: string;
    if (this.router.url.includes('calibrates')) {
      url = `calibrates/calibration?id=${idCalibration}`
    } else if (this.router.url.includes('thermal')) {
      url = `thermal/calibration?id=${idCalibration}`
    }
    void this.router.navigateByUrl(url);
  }

  onChangeVariableCheck(event: MatOptionSelectionChange): void {
    if (!event.isUserInput) {
      return;
    }

    const variable = event.source.value as Variable;

    if (this.calibration.variables == null) {
      this.calibration.variables = [];
    }

    if (event.source.selected) {
      if (this.calibration.multichannel) {
        if (this.calibration.variables?.length === 0) {
          const tab = new CalibrationVariable();
          tab.idVariable = variable.id;
          tab.variableTranslation = variable.translation;

          this.calibration.variables.push(tab);
        }
      } else {
        const tab = new CalibrationVariable();
        tab.idVariable = variable.id;
        tab.variableTranslation = variable.translation;

        this.calibration.variables.push(tab);
      }
    } else {
      this.calibration.variables = this.calibration.variables.filter(item => item.idVariable !== variable.id);
    }
  }

  downloadPdf(): void {

    const errors = this.checkCalibration(false, false);

    if (errors.length !== 0) {
      this.snackBarService.sendError(errors.join('\n'));

      return;
    }

    if (this.calibration.idStatus !== CalibrationStatus.FIRMADO) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('calibrateEquipmentEdit.dialog.generateReport.confirmSaved') as string
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
        if (result === true) {
          this.openDialogDownload();
        }
      });
    } else {
      this.openDialogDownload();
    }
  }

  cancel(): void {
    let url: string;
    if (this.router.url.includes('calibrates')) {
      url = 'calibrates/calibrations';
    } else if (this.router.url.includes('thermal')) {
      url = 'thermal/calibrations';
    }
    void this.router.navigateByUrl(url);
  }

  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 1) {
      this.audit.getAudit();
    }
  }

  onVariableTabChange(event: MatTabChangeEvent | number): void {
    let index: number;

    if (event instanceof MatTabChangeEvent) {
      index = event.index;
    } else {
      index = event;
    }

    const tab = this.calibration.variables[index];

    this.variableUnits = [];
    this.variableUnitsExtra = [];

    if (tab != null && tab.idVariable != null) {
      this.variableTypeService.findUnits(tab.idVariable).pipe(takeUntil(this.destroy$)).subscribe((res: VariableUnit[]) => {
        this.variableUnits = res;

        if (tab.idUnit == null || !this.variableUnits.map(v => v.id).includes(tab.idUnit)) {
          this.onUnitChange(index, res[0]);
        }
        if (tab.idUnitInput == null || !this.variableUnits.map(v => v.id).includes(tab.idUnitInput)) {
          tab.idUnitInput = tab.idUnit;
        }
      });

      if (tab.idVariable === VariableTypeEnum.SPEED) {
        this.variableTypeService.findUnits(VariableTypeEnum.DIMENSIONAL).pipe(takeUntil(this.destroy$)).subscribe((res: VariableUnit[]) => {
          this.variableUnitsExtra = res;

          if (tab.idUnitExtra == null || !this.variableUnitsExtra.map(v => v.id).includes(tab.idUnitExtra)) {
            tab.idUnitExtra = this.variableUnitsExtra[0]?.id;
          }
        });
      }
    }

  }

  onUnitChange(indexVariable: number, event: MatSelectChange | VariableUnit): void {
    const variable = this.calibration.variables[indexVariable];

    let unit: VariableUnit;

    if (event instanceof MatSelectChange) {
      unit = this.variableUnits.find(v => v.id === event.value);
    } else {
      unit = event;
    }

    if (variable != null && unit != null) {
      variable.idUnit = unit.id;
      variable.unitName = unit.unit;

      this.calibration.variables[indexVariable] = variable;
    }
  }

  userCanModify(): boolean {
    const profile = this.userService.currentProfile;

    if (profile == null || this.calibration.idStatus === CalibrationStatus.NO_VALIDO) {
      return false;
    }

    return true;
  }

  calculateAdvance(): void {
    this.spinnerService.show();

    const errors = this.checkCalibration(false, true);

    if (errors.length !== 0) {
      this.spinnerService.hide();
      this.snackBarService.sendError(errors.join('\n'));

      return;
    }

    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    this.spinnerService.hide();

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
      this.spinnerService.show();

      this.calibrationService.save(this.calibration, token).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.calibrationService.advance(this.calibration, token).pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.spinnerService.hide();
          this.reloadPage(this.calibration.id);
        });
      });

    }, error => {
      if (error != null && error.error != null && typeof error.error === 'string') {
        this.snackBarService.sendError(error.error as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.status.advance.error.generic') as string);
      }
    });
  }

  calculateBack(): void {
    const errors = this.checkCalibration(false, false);

    if (errors.length !== 0) {
      this.snackBarService.sendError(errors.join('\n'));

      return;
    }

    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
      this.calibrationService.back(this.calibration, token).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.spinnerService.hide();
        this.reloadPage(this.calibration.id);
      }
      );

    }, error => {
      if (error != null && error.error != null && typeof error.error === 'string') {
        this.snackBarService.sendError(error.error as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.status.back.error.generic') as string);
      }
    });
  }

  revertSign(): void {
    const errors = this.checkCalibration(false, false);

    if (errors.length !== 0) {
      this.snackBarService.sendError(errors.join('\n'));

      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.translate.instant('calibrateEquipmentEdit.form.revertSign.confirm') as string
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result === true) {
        this.requestReason((reason) => {
          const dialogRefConfirmation = this.dialog.open(ActionConfirmPasswordComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {}
          });

          dialogRefConfirmation.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
            this.calibrationService.revertSign(this.calibration, reason, token).pipe(takeUntil(this.destroy$)).subscribe(() => {
              this.spinnerService.hide();
              this.reloadPage(this.calibration.id);
            }
            );

          }, error => {
            if (error != null && error.error != null && typeof error.error === 'string') {
              this.snackBarService.sendError(error.error as string);
            } else {
              this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.status.back.error.generic') as string);
            }
          });
        });
      }
    });

  }

  showAutomaticSign(): boolean {
    const current = this.currentUser;

    return this.showSign() && current.automaticSign;
  }

  showManualSign(): boolean {
    const current = this.currentUser;

    return this.showSign() && current.manualSign;
  }

  showDownloadButton(): boolean {
    return this.calibration.id != null && this.calibration.hasOwnProperty('id')
      && this.calibration.idStatus !== CalibrationStatus.EN_EJECUCION && this.calibration.idStatus !== CalibrationStatus.NO_VALIDO;
  }

  showAdvance(): boolean {
    return this.calibration.id != null && this.calibration.idStatus === CalibrationStatus.EN_EJECUCION;
  }

  showBack(): boolean {
    return this.calibration.id != null && this.calibration.idStatus === CalibrationStatus.PENDIENTE_FIRMA;
  }

  showRevertSign(): boolean {
    const resultRol = this.currentUser?.profiles?.find(e => e.idRole === RoleEnum.MANAGER || e.idRole === RoleEnum.ADMIN);
    return this.calibration.id != null && this.calibration.idStatus === CalibrationStatus.FIRMADO
      && (resultRol != null && resultRol !== undefined);
  }

  showInvalidate(): boolean {
    return this.calibration.id != null && this.calibration.idStatus !== CalibrationStatus.NO_VALIDO;
  }

  isEditable(): boolean {
    return this.calibration.idStatus === CalibrationStatus.FIRMADO || this.calibration.idStatus === CalibrationStatus.NO_VALIDO;
  }

  invalidate(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.translate.instant('calibrateEquipmentEdit.form.invalidate.confirm') as string
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result === true) {
        this.requestReason((reason) => {

          const dialogPass = this.dialog.open(ActionConfirmPasswordComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {}
          });

          dialogPass.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
            if (token != null) {
              this.spinnerService.show();

              this.calibrationService.invalidate(this.calibration.id, token, reason).pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.reloadPage(this.calibration.id);
              });
              this.spinnerService.hide();
            }
          }, error => {
            if (error != null && error.error != null && typeof error.error === 'string') {
              this.snackBarService.sendError(error.error as string);
            } else {
              this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.status.back.error.generic') as string);
            }
          });
        });
      }
    });
  }

  showAmbientConditions(id: number): boolean {
    const variable = this.calibration.variables[id];

    if (variable?.idVariable) {
      // const procedureResult = this.procedures.find(p => p.id === variable.idProcedure);

      if (this.equipment != null && this.equipment.equipment?.includes('GENERADOR DE AEROSOL') && variable?.idVariable === VariableTypeEnum.PORCENTAJE) {
        if (!variable.ambientConditions) {
          variable.ambientConditions = new CalibrationAmbientConditions();
          variable.ambientConditions.oilUsed = null;
          variable.ambientConditions.pressureUsed = null;
          variable.ambientConditions.temperature = null;
        }
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  openAmbientCalibration(indexVariable: number): void {
    const dialogRef = this.dialog.open(CalibrationEditDialogParticlesComponent, {
      minWidth: '80%',
      maxHeight: '100vh',
      data: {
        calibration: this.calibration,
        variable: _.cloneDeep(this.calibration.variables[indexVariable])
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CalibrationVariable) => {
      if (result != null) {
        if (result.valuesParticles?.length > 0) {
          this.calibration.variables[indexVariable].valuesParticles = result.valuesParticles;
          this.calibration.variables[indexVariable].ambientConditions.idParticlesExcel = result.ambientConditions.idParticlesExcel;
          this.calibration.variables[indexVariable].ambientConditions.serialNumber = result.ambientConditions.serialNumber;

          const count = CalibrationUtils.getTotalParticlesBySize(result.valuesParticles[0]);
          if (count >= ParticlesCalibraton.CRITERIA_ACEPTATION_PARTICLES) {
            this.calibration.correct = true;
          } else {
            this.calibration.correct = false;
          }
        } else {
          this.calibration.variables[indexVariable].valuesParticles = [];
          this.calibration.variables[indexVariable].ambientConditions.idParticlesExcel = null;
          this.calibration.variables[indexVariable].ambientConditions.serialNumber = null;
          this.calibration.correct = false;
        }
      }
    });
  }

  getValidateAmbientParticleNum(indexVarible: number): string {
    const rowCount = this.calibration.variables[indexVarible]?.valuesParticles[0];
    if (rowCount) {
      let count = 0;
      count = CalibrationUtils.getTotalParticlesBySize(rowCount);

      return count >= 92 ? 'essayAccording' : 'essayNotAccording bold';
    }
    return '';
  }

  getSizeAmbientParticleNum(indexVarible: number): number {
    const rowCount = this.calibration.variables[indexVarible]?.valuesParticles[0];
    if (rowCount) {
      return CalibrationUtils.getTotalParticlesBySize(rowCount);
    }
    return 0;
  }

  onCountMultichannel(indexVariable: number, event: number): void {
    if (event) {
      this.calibration.channelNumber = event;
      if (!this.calibration.variables[indexVariable]?.valuesMultichannel) {
        this.calibration.variables[indexVariable].valuesMultichannel = [];
      }
      let cantidadOriginal = this.calibration.variables[indexVariable]?.valuesMultichannel?.length;

      if (cantidadOriginal) {
        if (cantidadOriginal >= this.calibration.channelNumber) {
          cantidadOriginal = cantidadOriginal - this.calibration.channelNumber;

          this.calibration.variables[indexVariable]?.valuesMultichannel?.splice(this.calibration.channelNumber, cantidadOriginal);
        } else {
          cantidadOriginal = this.calibration.channelNumber - cantidadOriginal;

          for (let i = 0; i < Number(cantidadOriginal); i++) {
            this.calibration.variables[indexVariable]?.valuesMultichannel?.push(new CalibrationVariableMultichannel());
          }
        }
      } else {
        cantidadOriginal = this.calibration.channelNumber;

        for (let i = 0; i < Number(cantidadOriginal); i++) {
          this.calibration.variables[indexVariable]?.valuesMultichannel?.push(new CalibrationVariableMultichannel());
        }
      }
    }

  }

  openDialogMultichannel(indexVariable: number): void {
    const isEdit = this.calibration.variables[indexVariable]?.valuesMultichannel?.length > 0;
    const dialogRefConfirm = this.dialog.open(CalibrationEditMultichannelComponent, {
      data: {
        data: this.calibration.variables[indexVariable]?.valuesMultichannel,
        isEdit
      }
    });

    dialogRefConfirm.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CalibrationVariableMultichannel[]) => {
      if (result) {
        if (!this.calibration.variables[indexVariable].valuesMultichannel) {
          this.calibration.variables[indexVariable].valuesMultichannel = [];

        }
        this.calibration.variables[indexVariable].valuesMultichannel = result;
        const config = this.calibration.variables[indexVariable]?.config;
        this.calibration.variables[indexVariable].valuesMultichannel?.forEach(a => {
            a.asLeft = [];
            if (config) {
              if (a.id == null) {
                config?.forEach(cg => {
                  cg.asLeft?.forEach(cgsl => {
                    cgsl.id = null;
                    cgsl.idCalibrationConfig = cg.id;
                    cgsl.uuidConfig = cg.uuidConfig;
                    a.asLeft.push(_.cloneDeep(cgsl));
                    this.configurateAsLeftMulticanal(indexVariable);
                  })
                })
              } else {
                config?.forEach(cg => {
                  if (a.asLeft?.length === 0) {
                    cg.asLeft?.forEach(cgsl => {
                      cgsl.id = null;
                      cgsl.idCalibrationConfig = cg.id;
                      cgsl.uuidConfig = cg.uuidConfig;
                      a.asLeft.push(_.cloneDeep(cgsl));
                      this.configurateAsLeftMulticanal(indexVariable);
                    })
                  }
                });
              }
            }

            this.calculateResultMulticanal();
            this.updateTable(this.asLeftTableMultiChannel);
      });
    }
    });
  }

  // Multicanal
  configurateAsLeftMulticanal(indexVariable: number): void {
    const variable = this.calibration.variables[indexVariable];

    variable.valuesMultichannel?.forEach(multi => {

      multi?.asLeft?.forEach(aL => {
        const cfg = this.findConfig(indexVariable, aL.point);

        if (cfg != null) {
          aL.resolution = cfg.resolution;
        }

        const patterns = variable.patterns?.map(p => {
          if (ArrayUtils.isEmpty(p.values)) {
            return null;
          }

          const res = p.values.filter(point => point.value === aL.point);

          if (ArrayUtils.isEmpty(res)) {
            return null;
          } else {
            return res[0];
          }

        }).filter(p => p != null).map(p => p.uncertainty);

        aL.patterns = patterns;
      });
    });
  }

  configurateAsLeftMulticanalSelected(indexVariable: number, indexChannel: number): void {
    const variable = this.calibration.variables[indexVariable];

    variable.valuesMultichannel[indexChannel].asLeft?.forEach(aL => {
      const cfg = this.findConfig(indexVariable, aL.point);

      if (cfg != null) {
        aL.resolution = cfg.resolution;
      }

      const patterns = variable.patterns?.map(p => {
        if (ArrayUtils.isEmpty(p.values)) {
          return null;
        }

        const res = p.values.filter(point => point.value === aL.point);

        if (ArrayUtils.isEmpty(res)) {
          return null;
        } else {
          return res[0];
        }
      }).filter(p => p != null).map(p => p.uncertainty);
      aL.patterns = patterns;
    });
  }


  calculateResultMulticanal(): void {

    const configs = [].concat(...this.calibration.variables.map(v => v.config)) as CalibrationVariableConfig[];

    const tolerances = configs.map(c => +c.tolerance).filter(v => v != null && !isNaN(v) && v !== 0);

    if (!ArrayUtils.isEmpty(tolerances)) {
      let res = true;

      this.calibration.variables?.forEach((variable, vIndex) => {

        variable.valuesMultichannel?.forEach(ml => {
          const styles = ml.asLeft?.map(aL => this.styleCPlusU(vIndex, aL.point, aL.cPlusU)).filter(v => v != null && v === 'validValue');

          if (styles) {
            res = res && (styles.length === ml.asLeft?.length);
          } else {
            res = false;
          }
        });
      });

      this.calibration.correct = res;
    } else {
      this.calibration.correct = true;
    }
  }

  calculateResultMulticanalSelected(indexChannel: number): void {

    const configs = [].concat(...this.calibration.variables.map(v => v.config)) as CalibrationVariableConfig[];

    const tolerances = configs.map(c => +c.tolerance).filter(v => v != null && !isNaN(v) && v !== 0);

    if (!ArrayUtils.isEmpty(tolerances)) {
      let res = true;

      this.calibration.variables?.forEach((variable, vIndex) => {

        const valueMultichannel = variable.valuesMultichannel[indexChannel];
        const styles = valueMultichannel?.asLeft?.map(aL => this.styleCPlusU(vIndex, aL.point, aL.cPlusU)).filter(v => v != null && v === 'validValue');

        res = res && (styles.length === valueMultichannel.asLeft?.length);
      });

      this.calibration.correct = res;
    } else {
      this.calibration.correct = true;
    }
  }

  getAsLeftListMultichannel(idVariable: number, indexChannel: number): CalibrationAsLeft[] {
    const res: CalibrationAsLeft[] = [];

    if (this.calibration.variables[idVariable]?.valuesMultichannel) {
      this.calibration.variables[idVariable].valuesMultichannel[indexChannel].asLeft?.forEach(aL => {
        res.push(aL);
      });

    }

    return res.sort((a1, a2) => a1.id - a2.id);
  }

  editAsLeftRowMultichannel(indexVariable: number, indexChannel: number, index: number): void {
    const variable = this.calibration.variables[indexVariable];
    const configs = this.getAsLeftListMultichannel(indexVariable, indexChannel);
    const channel = variable.valuesMultichannel[indexChannel];

    const row = configs[index];
    const rowIndex = channel.asLeft.indexOf(row);

    const emptyList: CalibrationAsLeft[] = [];
    const asLeftList = emptyList.concat(...channel.asLeft);
    const minValues = Math.max(...asLeftList.map(aL => ArrayUtils.isEmpty(aL.values) ? 0 : aL.values.length));

    const dialogRef = this.dialog.open(CalibrationEditAsLeftDialogComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        item: _.cloneDeep(row),
        minValues
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CalibrationAsLeft) => {

      if (result != null) {
        channel.asLeft[rowIndex] = result;
        this.configurateAsLeftMulticanalSelected(indexVariable, indexChannel);

        this.calculateResultMulticanal();

        this.updateTable(this.configTables);
        this.updateTable(this.asLeftTableMultiChannel);
        this.updateTable(this.patternsTables);
      }
    });
  }

  onVariableTabChangeMultichannel(event: MatTabChangeEvent | number): void {
    this.updateTable(this.asLeftTableMultiChannel);
  }


  newAsFoundRowMultichannel(idVariable: number, indexChannel: number): void {
    const variable = this.calibration.variables[idVariable].valuesMultichannel[indexChannel];

    const dialogRef = this.dialog.open(CalibrationEditAsFoundDialogComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        item: new CalibrationAsFound(),
        variable
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CalibrationAsFound) => {

      if (result != null) {
        if (!variable.asFound) {
          variable.asFound = [];
        }
        variable.asFound.push(_.cloneDeep(result));

        this.updateTable(this.asFoundTableMultiChannel);
      }
    });
  }

  editAsFoundRowMultichannel(idVariable: number, index: number, indexChannel: number): void {
    const variable = this.calibration.variables[idVariable].valuesMultichannel[indexChannel];

    const row = variable.asFound[index];

    const dialogRef = this.dialog.open(CalibrationEditAsFoundDialogComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        item: _.cloneDeep(_.cloneDeep(row)),
        variable
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CalibrationAsFound) => {

      if (result != null) {
        variable.asFound[index] = _.cloneDeep(result);

        this.updateTable(this.asFoundTableMultiChannel);
      }
    });
  }

  deleteAsFoundRowMultichannel(idVariable: number, index: number, indexChannel: number): void {
    const calibration = this.calibration.variables[idVariable].valuesMultichannel[indexChannel];
    calibration.asFound.splice(index, 1);

    this.updateTable(this.asFoundTableMultiChannel);
  }

  private showSign() {
    return this.calibration.idStatus === CalibrationStatus.PENDIENTE_FIRMA;
  }

  private findConfig(indexVariable: number, point: number): CalibrationVariableConfig {
    const configs = this.calibration.variables[indexVariable]?.config;

    let config: CalibrationVariableConfig = null;

    if (!ArrayUtils.isEmpty(configs)) {
      if (point != null) {
        config = configs.find(c => NumberUtils.isBetweenEq(point, c.rangeInit, c.rangeEnd));
      }

      if (config == null && point != null) {
        config = configs.find(c => c.asLeft.map(aL => aL.point).includes(point));
      }

      if (config == null) {
        config = configs[0];
      }
    }

    return config;
  }

  private performSave() {
    if (this.calibration.id == null) {
      this.save(true);
    } else {
      const showReason = +this.calibration.signedVersion !== 0;

      if (!showReason) {
        this.save(false);
        return;
      }

      const dialogRef = this.dialog.open(CalibrationEditConfirmSaveComponent, {
        minWidth: '30vw',
        data: {
          calibration: this.calibration,
          reason: this.calibration.reason,
          showReason
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: DialogDataConfirmSave) => {
        if (result != null) {

          if (result.showReason) {
            this.calibration.reason = result.reason;
          }

          this.save(false);
        }
      });

    }

  }

  private checkCalibration(isForSave: boolean, isForAdvance: boolean): string[] {
    const errors: string[] = [];

    if (this.calibration.idEquipment == null) {
      errors.push(this.translate.instant('calibrateEquipmentEdit.general.name.error') as string);
    }

    if (StringUtils.isEmpty(this.calibration.certificateNum)) {
      errors.push(this.translate.instant('calibrateEquipmentEdit.general.certificateNum.error') as string);
    }

    if (this.calibration.calibrationDate == null) {
      errors.push(this.translate.instant('calibrateEquipmentEdit.general.calibrateDate.error') as string);
    }

    if (isForAdvance) {
      if (!isForSave && this.calibration.id != null && this.calibration.idPlace == null) {
        errors.push(this.translate.instant('calibrateEquipmentEdit.calibrationData.place.error') as string);
      }

      if (this.calibration.id != null) {
        if (ArrayUtils.isEmpty(this.calibration.variables)) {
          errors.push(this.translate.instant('calibrateEquipmentEdit.form.variables.atLeastOne') as string);
        } else {
          this.calibration.variables.forEach(v => {
            const variableName = this.translate.instant('variable.' + v.variableTranslation) as string;

            if (!isForSave && v.idProcedure == null) {
              errors.push(
                this.translate.instant('calibrateEquipmentEdit.form.variables.procedure.error', { variable: variableName }) as string);
            }

            if (this.equipment != null && this.equipment.equipment?.includes('GENERADOR DE AEROSOL') && v?.idVariable === VariableTypeEnum.PORCENTAJE) {
              if (v.ambientConditions.oilUsed === null) {
                errors.push(
                  this.translate.instant('calibrateEquipmentEdit.ambientConditions.oilUsed.error', { variable: variableName }) as string);
              }
              if (v.ambientConditions.pressureUsed === null) {
                errors.push(
                  this.translate.instant('calibrateEquipmentEdit.ambientConditions.pressureUsed.error', { variable: variableName }) as string);
              }
              if (v.ambientConditions.temperature === null) {
                errors.push(
                  this.translate.instant('calibrateEquipmentEdit.ambientConditions.temperature.error', { variable: variableName }) as string);
              }

              if (!v.valuesParticles || v.valuesParticles?.length === 0) {
                errors.push(
                  this.translate.instant('calibrateEquipmentEdit.ambientConditions.particles.error', { variable: variableName }) as string);
              }
            } else {
              if (!isForSave && ArrayUtils.isEmpty(v.config)) {
                errors.push(
                  this.translate.instant('calibrateEquipmentEdit.form.variables.config.atLeastOne', { variable: variableName }) as string);
              } else {
                if (this.calibration.multichannel) {
                  if (!isForSave && ArrayUtils.isEmpty(v.valuesMultichannel)) {
                    errors.push(
                      this.translate.instant('calibrateEquipmentEdit.form.variables.multichannel.error', { variable: variableName }) as string);
                  } else {
                    const asLeftList = [].concat(...v.valuesMultichannel.map(c => c.asLeft));

                    asLeftList.filter((aL: CalibrationAsLeft) => aL.pattern == null).forEach((aL: CalibrationAsLeft) => {
                      errors.push(this.translate.instant('calibrateEquipmentEdit.form.variables.asLeft.error',
                        { point: aL.point, variable: variableName }) as string);
                    });

                    const countValues = asLeftList.map((aL: CalibrationAsLeft) => ArrayUtils.isEmpty(aL.values) ? 0 : aL.values.length)
                      .filter(ArrayUtils.unique).filter(l => l !== 0);
                    if (countValues.length > 1) {
                      errors.push(
                        this.translate.instant('calibrateEquipmentEdit.form.variables.asLeft.sameValues', { variable: variableName }) as string);
                    }

                  }
                } else {
                  const asLeftList = [].concat(...v.config.map(c => c.asLeft));

                  asLeftList.filter((aL: CalibrationAsLeft) => aL.pattern == null).forEach((aL: CalibrationAsLeft) => {
                    errors.push(this.translate.instant('calibrateEquipmentEdit.form.variables.asLeft.error',
                      { point: aL.point, variable: variableName }) as string);
                  });

                  const countValues = asLeftList.map((aL: CalibrationAsLeft) => ArrayUtils.isEmpty(aL.values) ? 0 : aL.values.length)
                    .filter(ArrayUtils.unique).filter(l => l !== 0);
                  if (countValues.length > 1) {
                    errors.push(
                      this.translate.instant('calibrateEquipmentEdit.form.variables.asLeft.sameValues', { variable: variableName }) as string);
                  }
                }

                if (!isForSave && ArrayUtils.isEmpty(v.patterns)) {
                  errors.push(
                    this.translate.instant('calibrateEquipmentEdit.form.variables.patterns.atLeastOne', { variable: variableName }) as string);
                }
              }
            }
          });
        }
      }
    }

    return errors;
  }

  private prepareToSave() {
    this.calibration.expirationDate = moment(this.calibration.expirationDate, 'DD/MM/YYYY').toDate();
    this.calibration.calibrationDate = moment(this.calibration.calibrationDate, 'DD/MM/YYYY').toDate();

    this.calibration.variables?.filter(v => !this.showExtraField(v))?.forEach(v => {
      v.idUnitExtra = null;
      v.idUnitInput = null;
      v.extraField = null;
    });
  }

  private save(isNew: boolean, callback: (() => void) = null) {

    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {

      if (token != null) {
        this.spinnerService.show();

        this.prepareToSave();

        if (this.calibration.multichannel === null || this.calibration.multichannel === undefined) {
          this.calibration.multichannel = false;
        }
        this.calibrationService.save(this.calibration, token).pipe(takeUntil(this.destroy$)).subscribe((res: Calibration) => {
          this.calibration = res;

          this.calibration.calibrationDate = DateUtils.anyToDate(this.calibration.calibrationDate);
          this.calibration.expirationDate = DateUtils.anyToDate(this.calibration.expirationDate);

          this.calibration.variables?.forEach((v: CalibrationVariable, vIndex: number) => {

            const variable = this.variables.find(va => va.id === v.idVariable);

            if (variable != null) {
              this.variablesSelected.push(variable);
            }
            if (this.calibration.multichannel) {
              v.valuesMultichannel?.forEach(vm => {
                vm.asLeft = vm.asLeft.map(aL => CalibrationUtils.objectToAsLeft(aL));

                this.configurateAsLeftMulticanal(vIndex);
              })
            } else {
              v.config?.forEach(conf => {
                conf.asLeft = conf.asLeft.map(aL => CalibrationUtils.objectToAsLeft(aL));
              });

              this.configurateAsLeft(vIndex);
            }
          });

          if (isNew) {
            this.snackBarService.sendSuccess(this.translate.instant('calibrateEquipmentEdit.form.create.ok') as string);
          } else {
            this.snackBarService.sendSuccess(this.translate.instant('calibrateEquipmentEdit.form.update.ok') as string);
          }

          if (callback) {
            callback();
          } else {
            this.reloadPage(this.calibration.id);
          }

          this.spinnerService.hide();
        }, error => {
          if (error != null && error.error != null && typeof error.error === 'string') {
            this.snackBarService.sendError(error.error as string);
          } else if (isNew) {
            this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.create.error') as string);
          } else {
            this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.update.error') as string);
          }
          this.spinnerService.hide();
        });

      } else {
        this.spinnerService.hide();
      }
    });

  }

  private updateTable(table: MatTable<any> | QueryList<MatTable<any>>) {
    if (table instanceof QueryList) {
      table?.forEach(t => t.renderRows());
    } else {
      table?.renderRows();
    }
  }

  private openDialogDownload() {
    this.dialog.open(CalibrationEditGenerateReportComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {
        calibration: this.calibration,
        toSign: false
      }
    });
  }

  private requestReason(callback: (reason: string) => void) {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

  private onEquipmentChange(equipmentSelected: InternalEquipment) {

    this.equipment = equipmentSelected;
    if (this.equipment.performances?.length > 0) {
      const rCalibration = this.equipment.performances.find(t => t.idActionType === 1);
      if (rCalibration) {
        this.equipmentCalibration = rCalibration;

        if (this.equipmentCalibration?.frequency &&  this.equipmentCalibration?.frequency?.match('^[0-9]+$')) {
          this.calibration.expirationDate = _.cloneDeep(this.calibration.calibrationDate);
          this.calibration.expirationDate.setMonth(this.calibration.expirationDate.getMonth() + Number(this.equipmentCalibration.frequency));
        } else {
          this.calibration.expirationDate = _.cloneDeep(this.calibration.calibrationDate);
          this.calibration.expirationDate.setFullYear(this.calibration.expirationDate.getFullYear() + 1);
        }
      }
    }
    this.calibration.idEquipment = equipmentSelected.id;
    this.calculateClientData();
  }

  private calculateClientData() {
    if (this.equipment.client != null) {
      this.clientName = this.equipment.client.name;
      this.clientAddress = this.equipment.client.addressLine1 + '\n' + this.equipment.client.addressLine2;

      this.spinnerService.hide();
    } else {
      this.manageGroupsService.findCurrent().pipe(takeUntil(this.destroy$)).subscribe((g: Group) => {
        g = GroupUtils.objectToGroup(g);
        this.clientName = g.name;
        this.clientAddress = g.getFullAddress();

        this.spinnerService.hide();
      }, () => this.spinnerService.hide());
    }
  }

  private joinList(list: number[], totalDecimals: number): string {
    let res: string = null;

    if (!ArrayUtils.isEmpty(list)) {
      res = list.filter(d => d != null).map(d => d.toFixed(totalDecimals)).join(', ');
    }

    return res;
  }

  private cloneRow(id: number, idGroup: number): Promise<void> {

    return new Promise<void>((resolve) => {

      const dialogRefConfirm = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('generic.clone.sign') as string
        }
      });

      dialogRefConfirm.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: boolean) => {
        if (result === true) {

          this.spinnerService.show();

          this.calibrationService.checkCopyToGroup(id, idGroup).subscribe((res: GenericWarnError) => {
            this.spinnerService.hide();

            const dialogRef = this.dialog.open(CheckWarnsErrorsComponent, {
              minWidth: '20%',
              maxHeight: '95vh',
              data: {
                warns: res.warns,
                errors: res.errors
              }
            });

            dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((canContinue: boolean) => {
              if (canContinue === true) {
                this.spinnerService.show();
                if (!this.equipment.internal) {
                  this.internalEquipmentService.getAllByGroup(idGroup, this.calibration.idEquipment).subscribe((resFolder: InternalEquipmentFolder[]) => {
                    this.spinnerService.hide();
                    if (resFolder?.length > 0) {
                      const dialogRefFolders = this.dialog.open(InternalEquipmentSelectFolderComponent, {
                        minWidth: '20%',
                        maxHeight: '95vh',
                        data: {
                          folders: resFolder
                        }
                      });
                      dialogRefFolders.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((idFolder: number) => {
                        if (idFolder) {
                          this.spinnerService.show();
                          this.calibrationService.copyToGroupWithFolder(id, idGroup, idFolder).subscribe(() => {
                            this.spinnerService.hide();
                            this.snackBarService.sendSuccess(this.translate.instant('isothermalCharacterizationEdit.form.clone.ok') as string);
                            resolve();
                          }, () => {
                            this.spinnerService.hide();
                            this.snackBarService.sendError(this.translate.instant('isothermalCharacterizationEdit.form.clone.error') as string);
                            resolve();
                          });
                        } else {
                          this.spinnerService.show();
                          this.calibrationService.copyToGroup(id, idGroup).subscribe(() => {
                            this.spinnerService.hide();
                            this.snackBarService.sendSuccess(this.translate.instant('calibrateEquipmentEdit.form.clone.ok') as string);
                            resolve();
                          }, () => {
                            this.spinnerService.hide();
                            this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.clone.error') as string);
                            resolve();
                          });
                        }
                      });
                    } else {
                      this.spinnerService.show();
                      this.calibrationService.copyToGroup(id, idGroup).subscribe(() => {
                        this.spinnerService.hide();
                        this.snackBarService.sendSuccess(this.translate.instant('calibrateEquipmentEdit.form.clone.ok') as string);
                        resolve();
                      }, () => {
                        this.spinnerService.hide();
                        this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.clone.error') as string);
                        resolve();
                      });
                    }
                  });
                } else {
                  this.spinnerService.show();
                  this.calibrationService.copyToGroup(id, idGroup).subscribe(() => {
                    this.spinnerService.hide();
                    this.snackBarService.sendSuccess(this.translate.instant('calibrateEquipmentEdit.form.clone.ok') as string);
                    resolve();
                  }, () => {
                    this.spinnerService.hide();
                    this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.clone.error') as string);
                    resolve();
                  });
                }
              } else {
                resolve();
              }
            });
          }, () => {
            this.spinnerService.hide();
            this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.clone.error') as string);
            resolve();
          });
        } else {
          resolve();
        }
      });

    });
  }

  private findClient(): void {
    if (this.equipment.internal) {
      this.reloadPage(this.calibration.id);
    } else {
      this.clientService.findOne(this.equipment.client.id).subscribe((client: Client) => {

        if (client.idGroup != null) {
          void this.cloneRow(this.calibration.id, client.idGroup).then(() => this.reloadPage(this.calibration.id));
        } else {
          this.reloadPage(this.calibration.id);
        }

      });
    }
  }

}
