/* eslint-disable max-len */
import * as _ from 'lodash-es';

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GenericUpload, GenericWarnError } from 'src/app/model/genericClass';
import { InternalEquipment, InternalEquipmentFilter, InternalEquipmentFilterPerformance } from 'src/app/model/internalEquipment';
import { Location, ViewportScroller } from '@angular/common';
import { Observable, Subject, merge } from 'rxjs';
import { Router, Scroll } from '@angular/router';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { AttachmentThermalService } from 'src/app/services/attachmentThermal.service';
import { CheckWarnsErrorsComponent } from '../../shared/check-warns-errors/check-warns-errors.component';
import { ExcelUtils } from 'src/app/utils/excelUtils';
import { InternalEquipmentDataSource } from 'src/app/model/internalEquipmentDataSource';
import { InternalEquipmentService } from 'src/app/services/internalEquipment.service';
import { InternalEquipmentStatusEnum } from 'src/app/model/internalEquipmentStatus';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RoleEnum } from 'src/app/model/roleUser';
import { SelectGroupToCloneComponent } from '../../shared/select-group-to-clone/select-group-to-clone.component';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ThemeService } from 'src/app/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { VariableTypeService } from 'src/app/services/variableType.service';
import { saveAs } from 'file-saver';
import { InternalEquipmentCustomFieldsService } from '../../../services/internalEquipmentCustomFields.service';
import { InternalEquipmentFolderService } from '../../../services/internalEquipmentFolderService';
import { OnlineService } from '../../../services/online.service';
import { ReasonDialogComponent } from '../../shared/reason-dialog/reason-dialog.component';
import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { JsonUtil } from 'src/app/utils/jsonUtil';
import { InternalEquipmentFolder } from '../../../model/internalEquipmentFolder';
import { InternalEquipmentSelectFolderComponent } from '../../shared/internal-equipment-select-folder/internal-equipment-select-folder.component';
import { InternalEquipmentPerformancesDataSource } from '../../../model/internalEquipmentPerformancesDataSource';
import { InternalEquipmentPerformancesShort } from '../../../model/internalEquipmentPerformancesShort';
import { InternalEquipmentUsedComponent } from '../internal-equipment-used/internal-equipment-used.component';

@Component({
  selector: 'app-internal-equipment-list',
  templateUrl: './internal-equipment-list.component.html'
})
export class InternalEquipmentListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('massiveUpload', { static: true }) uploadMassiveInput: ElementRef;
  @ViewChild('massiveCertificateUpload', { static: true }) uploadCertificateMassiveInput: ElementRef;

  dataSource: InternalEquipmentDataSource;
  dataSourcePerformance: InternalEquipmentPerformancesDataSource;

  filter: InternalEquipmentFilterPerformance = new InternalEquipmentFilterPerformance();

  hasFiltered = false;

  displayedColumns: string[] = ['name', 'equipment', 'maker', 'model', 'serialNum', 'certificateNum', 'status', 'currentDate','nextPerformance',
    'dateNextPerformance', 'download', 'edit', 'activate', 'clone', 'more'
  ];

  columnDefinitions: any = [
    { def: 'name', showMobile: true },
    { def: 'equipment', showMobile: false }
  ];

  isExternal = false;
  isInternal = false;

  isOnline = true;

  currentUser: User;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private location: Location,
    private internalEquipmentService: InternalEquipmentService,
    private attachmentThermalService: AttachmentThermalService,
    private variableTypeService: VariableTypeService,
    private userService: UserService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private themeService: ThemeService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private viewportScroller: ViewportScroller,
    private internalEquipmentCustomFieldsService: InternalEquipmentCustomFieldsService,
    private internalEquipmentFolderService: InternalEquipmentFolderService,
    private onlineService: OnlineService) {
    this.currentUser = this.userService.currentProfile;

    this.router.events.pipe(filter(e => e instanceof Scroll)).pipe(takeUntil(this.destroy$)).subscribe((e: Scroll) => {
      setTimeout(() => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
    });
  }

  get pageTitle(): string {
    const msg = this.isInternal ? 'internalEquipmentList.title.my.internal' : 'internalEquipmentList.title.external';
    return this.translate.instant(msg) as string;
  }

  ngOnInit(): void {
    this.onlineService.online$.subscribe(online => this.isOnline = online);

    this.isExternal = this.router.url.toLowerCase().includes('external');
    this.isInternal = this.router.url.toLowerCase().includes('internal');

    const excludedCols: string[] = [];

    if (this.isExternal) {
      excludedCols.push('status');
      excludedCols.push('activate');
      excludedCols.push('supplier');
      excludedCols.push('group');
    }

    if (this.isInternal) {
      excludedCols.push('client');
    }

    if (!this.isInternal && !this.isExternal) {
      excludedCols.push('edit');
      excludedCols.push('download');
    }

    if (!this.currentUser.showClone) {
      excludedCols.push('clone');
    }

    excludedCols.push('maker');
    excludedCols.push('model');

    this.displayedColumns = this.displayedColumns.filter(c => !excludedCols.includes(c));

    const filterResult = localStorage.getItem('filter-equipment');

    if (filterResult) {
      this.hasFiltered = true;
      this.filter = new InternalEquipmentFilterPerformance();
      this.filter = JSON.parse(filterResult) as InternalEquipmentFilterPerformance;
      const customFields = localStorage.getItem('customFields');
       if (customFields) {
        const resultObject = JSON.parse(customFields) as object;
        this.filter.customFields = new Map(Object.entries(resultObject));
       } else {
        this.filter.customFields = new Map<string, number>();
      }
    }
    this.loadEquipmentList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadEquipmentList())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  new(): void {
    const type = this.getTypeEquipment();
    let url: string;
    if (this.router.url.includes('calibrates')){
      url = `calibrates/${type}Equipment?id=0`;
    } else if (this.router.url.includes('thermal')) {
      url = `thermal/${type}Equipment?id=0`;
    } else if(this.router.url.includes('air')) {
      url = `air/${type}Equipment?id=0`;
    }
    void this.router.navigateByUrl(url);
  }

  editRow(id: number): void {
    const type = this.getTypeEquipment();
    let url: string;
    if (this.router.url.includes('calibrates')){
      url = `calibrates/${type}Equipment?id=${id}`;
    } else if (this.router.url.includes('thermal')) {
      url = `thermal/${type}Equipment?id=${id}`;
    } else if(this.router.url.includes('air')) {
      url = `air/${type}Equipment?id=${id}`;
    }
    void this.router.navigateByUrl(url);
  }

  cloneRow(id: number): void {
    const dialogRefSelectGroupToClone = this.dialog.open(SelectGroupToCloneComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRefSelectGroupToClone.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((idGroup: number) => {
      if (idGroup != null) {

        this.spinnerService.show();

        this.internalEquipmentService.checkCopyToGroup(id, idGroup).subscribe((res: GenericWarnError) => {
          this.spinnerService.hide();

          const dialogRef = this.dialog.open(CheckWarnsErrorsComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {
              warns: res.warns,
              errors: res.errors
            }
          });

          dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((canContinue: boolean) => {
            if (canContinue === true) {
              if (!this.isInternal) {
                this.spinnerService.show();
                this.internalEquipmentService.getAllByGroup(idGroup, id).subscribe((resFolder: InternalEquipmentFolder[]) => {
                  this.spinnerService.hide();
                  if (resFolder?.length > 0) {
                    const dialogRefFolders = this.dialog.open(InternalEquipmentSelectFolderComponent, {
                      minWidth: '20%',
                      maxHeight: '95vh',
                      data: {
                        folders: resFolder
                      }
                    });
                    dialogRefFolders.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((idFolder: number) => {
                      if (idFolder) {
                        this.spinnerService.show();
                        this.internalEquipmentService.copyToGroupWithIdFolder(id, idGroup, idFolder).subscribe(() => {
                          this.spinnerService.hide();
                          this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.clone.ok') as string);
                        }, () => {
                          this.spinnerService.hide();
                          this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.clone.error') as string);
                        });
                      } else {
                        this.spinnerService.show();
                        this.internalEquipmentService.copyToGroup(id, idGroup).subscribe(() => {
                          this.spinnerService.hide();
                          this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.clone.ok') as string);
                        }, () => {
                          this.spinnerService.hide();
                          this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.clone.error') as string);
                        });
                      }
                    });
                  } else {
                    this.spinnerService.show();
                    this.internalEquipmentService.copyToGroup(id, idGroup).subscribe(() => {
                      this.spinnerService.hide();

                      this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.clone.ok') as string);
                    }, () => {
                      this.spinnerService.hide();
                      this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.clone.error') as string);
                    });
                  }
                }, () => {
                  this.spinnerService.hide();
                  this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.clone.error') as string);
                });
              } else {
                this.spinnerService.show();
                this.internalEquipmentService.copyToGroup(id, idGroup).subscribe(() => {
                  this.spinnerService.hide();
                  this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.clone.ok') as string);
                }, () => {
                  this.spinnerService.hide();
                  this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.clone.error') as string);
                });
              }
            }
          });
        }, () => {
          this.spinnerService.hide();
          this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.clone.error') as string);
        });
      }
    });
  }

  getThemeIconPath(): string {
    const url = this.router.url;
    return this.themeService.getThemeIconPathSelection(url);
  }

  isDeactivate(eq: InternalEquipmentPerformancesShort): boolean {
    return eq.idStatus === InternalEquipmentStatusEnum.FUERA_DE_USO || eq.idStatus === InternalEquipmentStatusEnum.CADUCADO;
  }

  activate(eq: InternalEquipmentPerformancesShort): void {
    this.requestReason((reason) => {
      this.requestSign(() => {
        this.internalEquipmentService.activate(eq.id, true, reason).pipe(takeUntil(this.destroy$)).subscribe((obs: Observable<void>) => {
          obs.subscribe(() => {
            this.snackBarService.sendSuccess('Se ha activado el equipo interno ' + eq.name);

            this.loadEquipmentList();
          }, () => {
            this.snackBarService.sendError('Ha ocurrido un error al activar el equipo interno ' + eq.name);
          });
        }, () => {
          this.snackBarService.sendError('Ha ocurrido un error al activar el equipo interno ' + eq.name);
        });
      });
    });
  }

  deactivate(eq: InternalEquipmentPerformancesShort): void {
    this.requestReason((reason) => {
      this.requestSign(() => {
        this.internalEquipmentService.activate(eq.id, false, reason).pipe(takeUntil(this.destroy$)).subscribe((obs: Observable<void>) => {
          obs.subscribe(() => {
            this.snackBarService.sendSuccess('Se ha desactivado el equipo interno ' + eq.name);

            this.loadEquipmentList();
          }, () => {
            this.snackBarService.sendError('Ha ocurrido un error al desactivar el equipo interno ' + eq.name);
          });
        }, () => {
          this.snackBarService.sendError('Ha ocurrido un error al desactivar el equipo interno ' + eq.name);
        });
      });
    });
  }

  highlight(eq: InternalEquipmentPerformancesShort): string {
    let $res: string;
    if (eq.documentNum) {
      if (this.isDeactivate(eq) || eq.expired) {
        $res = 'deactivate';
      } else if (eq.nearToExpire) {
        $res = 'warning';
      } else if (eq.idStatus === InternalEquipmentStatusEnum.EXTENDIDO) {
        $res = 'warning';
      }
    }
    return $res;
  }

  doFilter(event: InternalEquipmentFilterPerformance): void {
    this.filter = event;
    if (typeof this.filter.customFields) {
      this.filter.customFields?.forEach((value: string, key: number) => {
        if(value === 'false') {
          this.filter.customFields.delete(key);
        }
      });
    }

    this.hasFiltered = true;
    this.paginator.pageIndex = 0;
    this.saveSearchFilter(event);
    this.loadEquipmentList();
  }

  loadEquipmentList(): void {
    this.dataSourcePerformance = new InternalEquipmentPerformancesDataSource(this.internalEquipmentService);

    this.filter.sortBy = this.sort.active || 'documentCode';
    this.filter.sortDirection = this.sort.direction || 'asc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 10;

    
    this.filter.internal = this.isInternal;
    this.filter.external = this.isExternal;

    if (this.hasFiltered) {
    
      this.saveSearchFilter(this.filter);
      this.dataSourcePerformance.loadInternalEquipmentPerformance(this.filter);
    }
    this.dataSourcePerformance.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  uploadMassive(event): void {
    this.spinnerService.show();

    const file = event.target.files[0] as File;

    void ExcelUtils.excelToInternalEquipmentCustomField(file, this.variableTypeService, this.translate,
      this.internalEquipmentCustomFieldsService, this.internalEquipmentFolderService).then(data => {
       if (data?.length > 0) {
        this.internalEquipmentService.uploadMassive(data).pipe(takeUntil(this.destroy$)).subscribe((item: GenericUpload) => {

          if (item.totalOk > 0) {
            this.snackBarService.sendSuccess(`Se han subido correctamente ${item.totalOk} equipos.`);
          }
  
          if (item.totalKo > 0) {
            this.snackBarService.sendWarn(`No se ha podido crear ${item.totalKo} equipos.`);
          }
  
          this.spinnerService.hide();
  
          this.loadEquipmentList();
        }, () => {
          this.snackBarService.sendError('Ha ocurrido un error al subir los equipos.');
          this.spinnerService.hide();
        });
       } else {
        this.snackBarService.sendError('Ha ocurrido un error al subir los equipos.');
        this.spinnerService.hide();
       }
    });

    this.uploadMassiveInput.nativeElement.value = '';
  }

  uploadCertMassive(event): void {
    this.spinnerService.show();
    const promises = [];
    let totalOk = 0;
    const errors = [];

    for (const file of event.target.files as File[]) {
      const fileName = file.name;
      let eqName = fileName.substring(0, file.name.indexOf(' '));

      if (eqName === '') {
        eqName = fileName.substring(0, fileName.lastIndexOf('.'));
      }

      // eslint-disable-next-line @typescript-eslint/no-shadow
      promises.push(new Promise<void>((resolve) => {
        this.internalEquipmentService.findOneByName(eqName).pipe(takeUntil(this.destroy$)).subscribe((res: InternalEquipment) => {
          this.attachmentThermalService.uploadCertificateToInternalEquipmentMassive(res.id, file).pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              totalOk++;
              resolve();
            }, () => {
              errors.push(fileName);
              resolve();
            });
        }, () => {
          errors.push(fileName);

          resolve();
        });
      }));
    }

    void Promise.all(promises).then(() => {

      if (totalOk > 0) {
        this.snackBarService.sendSuccess(`Se han subido ${totalOk} certificados correctamente`);
      }

      if (errors.length > 0) {
        this.snackBarService.sendWarn('Ha ocurrido un error al subir los ficheros: ' + errors.join(', ')
          + '. Compruebe si están en el formato correcto');
      }

      this.spinnerService.hide();

      this.loadEquipmentList();
    });

    this.uploadCertificateMassiveInput.nativeElement.value = '';
  }

  canSave(): boolean {
    const profile = this.userService.currentProfile;
    const rolesCanSaveInternal = [RoleEnum.ADMIN, RoleEnum.MANAGER, RoleEnum.RESPONSABLE];

    return this.isExternal || rolesCanSaveInternal.includes(profile.idActiveRole);
  }

  downloadPdf(equipment: InternalEquipmentPerformancesShort): void {
    this.spinnerService.show();

    const id = equipment.id;
    this.internalEquipmentService.downloadPdf(id).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name = equipment.name;
      saveAs(res, name + '.pdf');
      this.spinnerService.hide();
    }, (err) => {
      console.error(err);
      this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.downloadPdf.error') as string);
      this.spinnerService.hide();
    });
  }

  exportTable(): void {
    this.filter.cols = this.displayedColumns;
    this.spinnerService.show();
    this.internalEquipmentService.getExcel(this.filter).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name = this.pageTitle + '.xlsx';
      saveAs(res, name);
      this.spinnerService.hide();
    }, error => {
      console.error(error);
      this.spinnerService.hide();
    });
  }

  cancel(): void {
    this.location.back();
  }

  getDisplayedColumns(): string[] {
    const isMobile = true;
    return this.columnDefinitions.filter(cd => !isMobile || cd.showMobile).map(cd => cd.def) as string[];
  }

  saveSearchFilter(eq: InternalEquipmentFilterPerformance): void {
    localStorage.setItem('filter-equipment', JSON.stringify(eq));

    const clonedFilter = _.cloneDeep(eq);
    const customResult = (clonedFilter.customFields as Map<number, string>);
    localStorage.setItem('customFields', JSON.stringify(customResult));
  }


  showInternalEquipmetUsed() {
    const dialogRef = this.dialog.open(InternalEquipmentUsedComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe();
  }

  private getTypeEquipment() {
    let res = '';

    if (this.isExternal) {
      res = 'external';
    } else if (this.isInternal) {
      res = 'internal';
    }

    return res;
  }

  private requestReason(callback: (reason: string) => void) {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

  private requestSign(callback: (token: string) => void) {
    this.spinnerService.hide();

    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }
}
