import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentAir } from 'src/app/model/equipment';
import { EquipmentType } from 'src/app/model/equipmentType';

export interface DialogDataEquipmentDuplicate {
  equipment: EquipmentAir;
  equipmentTypes: EquipmentType[];
}

@Component({
  selector: 'app-protocol-edit-dialog-duplicate-equipment',
  templateUrl: './protocol-edit-dialog-duplicate-equipment.component.html'
})
export class ProtocolEditDialogDuplicateEquipmentComponent {

  tituloDialog: string;
  form: UntypedFormGroup;

  constructor(
    fb: UntypedFormBuilder,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<ProtocolEditDialogDuplicateEquipmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataEquipmentDuplicate) {

    this.tituloDialog = this.translate.instant('protocolEdit.dialog.area.title.create') as string;


    this.form = fb.group({
      equipment: [this.data.equipment, Validators.required]
    });

  }

  onOkClick(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.data.equipment);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
