import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, map } from 'rxjs';
import { DexieService } from './dexieService';
import { OnlineService } from './online.service';
import { environment } from 'src/environments/environment';
import { HttpUtils } from '../utils/httpUtils';
import { GenericClassTranslate } from '../model/genericClass';

@Injectable({
  providedIn: 'root'
})
export class ActionTypeService {

  constructor(private http: HttpClient,
    private dexieService: DexieService,
    private onlineService: OnlineService) {}


    findAll(): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + '/actionType/';

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.processType.toArray().timeout(30000));
        }
    }

    saveProcessTypeToDexie(): Promise<void> {
        return new Promise<void>((resolve) => this.findAll().subscribe((res: GenericClassTranslate[]) => {
            if (res) {
                void this.dexieService.actionType.clear().then(() => {
                    res.forEach(type => {
                        void this.dexieService.actionType.put(type, type.id).then(() => resolve());
                    });
                });
            }
        }));
    }
}
