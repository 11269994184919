import { Component, Inject } from '@angular/core';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from 'src/app/services/snackBar.service';

export interface DialogDataConfirmSave {
  reason: string;
  showReason: boolean;
}

@Component({
  selector: 'app-isothermal-characterization-edit-dialog-confirm-save',
  templateUrl: 'isothermal-characterization-edit-dialog-confirmSave.component.html'
})
export class IsothermalCharacterizationEditConfirmSaveComponent {

  matcher = new MyErrorStateMatcher();
  form: UntypedFormGroup;
  inSignState: boolean;

  constructor(
    fb: UntypedFormBuilder,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<IsothermalCharacterizationEditConfirmSaveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataConfirmSave) {

    this.form = fb.group({
      reason: [this.data.reason, this.data.showReason ? [Validators.required] : []]
    });
  }

  onOkClick(): void {

    if (!this.form.valid) {
      this.snackBarService.sendError(
        this.translate.instant('isothermalCharacterizationEdit.dialog.confirmSave.form.error.reason') as string);
      return;
    }

    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
