export const environment = {
  production: true,
  development: true,
  versionCheckURL : `https://${location.hostname}/version.json`,
  coreUrl: `https://${location.hostname}:8123/core`,
  thermalUrl: `https://${location.hostname}:8123/thermal`,
  calibratesUrl: `https://${location.hostname}:8123/calibrates`,
  airUrl: `https://${location.hostname}:8123/air`,
  timeStamp: '3.1.0',
  stripeKey: 'pk_test_51H2g2TFQmNGfNDY5whQKXCuhDf8pXKOipwVU6R2POXovSaByPIEILpNmCUpdLxpywXOyGLGuHEicRgsabTZ2as6P00UEOllOGj',
  hideLangs: false,
  broadcastChannel: 'qloud-desa'
};
