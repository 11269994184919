import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { ClientFilter } from 'src/app/model/client';
import { CustomDatepickerHeaderComponent } from '../../shared/datepicker-custom-header/datepicker-custom-header.component';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';

@Component({
  selector: 'app-client-list-filter',
  templateUrl: './client-list-filter.component.html'
})
export class ClientListFilterComponent {

  @Output() filterEmitter = new EventEmitter<ClientFilter>();

  filter: ClientFilter;

  expandedFilter = true;

  matcher = new MyErrorStateMatcher();
  form: UntypedFormGroup;

  customDatepickerHeader = CustomDatepickerHeaderComponent;

  constructor(
    fb: UntypedFormBuilder) {
    this.cleanFilter();

    this.form = fb.group({

      name: [this.filter.name],
      address: [this.filter.address],
      postalCode: [this.filter.postalCode],
      city: [this.filter.city],
      state: [this.filter.state],
      country: [this.filter.country]
    });
  }

  doFilter(): void {
    this.expandedFilter = false;

    this.filterEmitter.emit(this.filter);
  }

  cleanFilter(): void {
    this.filter = new ClientFilter();
  }

}
