import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginAccess, LoginAccessFilter } from 'src/app/model/loginAccess';
import { Subject, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { AttachmentThermalService } from 'src/app/services/attachmentThermal.service';
import { Constants } from 'src/app/utils/constants';
import { CreateUser } from 'src/app/model/user';
import { EditUserRoleDialogComponent } from './edit-user-dialog-assignRole.component';
import { LoginAccessDataSource } from 'src/app/model/loginAccessDataSource';
import { ManageUsersService } from 'src/app/services/manageUsers.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { RoleUser } from 'src/app/model/roleUser';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { requiredIf } from 'src/app/utils/required-if.validator';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html'
})
export class EditUserComponent implements OnDestroy, AfterViewInit {

  @ViewChild('rolesTable') roleUserTable: MatTable<any>;
  @ViewChild('loginAccessSort') loginAccessSort: MatSort;
  @ViewChild('loginAccessPaginator') loginAccessPaginator: MatPaginator;

  isEdit: boolean;

  matcher = new MyErrorStateMatcher();
  userMainForm: UntypedFormGroup;
  user: CreateUser;

  loginAccesses: LoginAccess[] = [];
  displayedColsLoginAccess = ['date', 'ip', 'correct'];
  roles: RoleUser[] = [];
  displayedColsRoles = ['groupName', 'appName', 'role', 'delete', 'more'];

  loginAccessDataSource: LoginAccessDataSource;
  loginAccessFilter = new LoginAccessFilter();

  private destroy$ = new Subject<void>();

  constructor(
    fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private manageUsersService: ManageUsersService,
    private attachmentThermalService: AttachmentThermalService,
    public dialog: MatDialog,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    this.spinnerService.show();
    this.loginAccessDataSource = new LoginAccessDataSource(this.manageUsersService);

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const username = params[Constants.FIELD_USERNAME] as string;

      this.user = new CreateUser();
      if (username == null) {
        this.user.active = true;

        this.isEdit = false;

        this.spinnerService.hide();
      } else {
        this.loadUser(username);
      }

    });

    this.userMainForm = fb.group({
      username: [{ value: this.user.username, disabled: this.isEdit }],
      password: this.user.password,
      fullName: [{ value: this.user.fullName, disabled: this.isEdit }, requiredIf({ value: !this.isEdit })],
      email: [this.user.email, [Validators.required, Validators.pattern(Constants.EMAIL_PATTERN)]],
      position: [this.user.position],
      active: [this.user.active]
    });

    if (this.isEdit) {
      this.userMainForm.get(Constants.FIELD_USERNAME).setValidators([Validators.pattern(Constants.USERNAME_PATTERN)]);
      this.userMainForm.get(Constants.FIELD_PASSWORD).setValidators([Validators.pattern(Constants.PASSWORD_PATTERN)]);
    } else {
      this.userMainForm.get(Constants.FIELD_USERNAME).setValidators([Validators.pattern(Constants.USERNAME_PATTERN), Validators.required]);
      this.userMainForm.get(Constants.FIELD_PASSWORD).setValidators([Validators.pattern(Constants.PASSWORD_PATTERN), Validators.required]);
    }

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting

    if (this.loginAccessSort) {
      this.loginAccessSort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.loginAccessPaginator.pageIndex = 0);

      merge(this.loginAccessSort.sortChange, this.loginAccessPaginator.page)
        .pipe(tap(() => this.loadLogins())).pipe(takeUntil(this.destroy$)).subscribe();
    }

  }

  saveUser(): void {
    if (!this.userMainForm.valid) {
      this.snackBarService.sendError(this.translate.instant('userEdit.dialog.form.invalid') as string);
      return;
    } else if (ArrayUtils.isEmpty(this.roles)) {
      this.snackBarService.sendError(this.translate.instant('userEdit.dialog.role.error') as string);
      return;
    }

    this.spinnerService.show();

    if (this.isEdit) {

      this.manageUsersService.save(this.user).pipe(takeUntil(this.destroy$)).subscribe((item: CreateUser) => {
        this.assignRoles(() => {
          const username: string = item.username;

          if (username) {
            this.loadUser(username);
          }

          this.snackBarService.sendSuccess(this.translate.instant('userEdit.dialog.form.save.ok') as string);
          this.spinnerService.hide();
          this.loadUser(this.user.username);
        });
      }, err => {
        let message = err.error as string;
        if (message == null) {
          message = this.translate.instant('userEdit.dialog.form.save.error') as string;
        }
        this.snackBarService.sendError(message);
        this.spinnerService.hide();
      });

    } else {

      this.manageUsersService.create(this.user).pipe(takeUntil(this.destroy$)).subscribe((item: CreateUser) => {
        this.assignRoles(() => {
          const username: string = item.username;

          if (username) {
            void this.router.navigateByUrl(`/admin/user?username=${username}`);
            this.ngAfterViewInit();
            this.loadUser(username);
          }
          this.snackBarService.sendSuccess(this.translate.instant('userEdit.dialog.form.create.ok') as string);
          this.spinnerService.hide();
        });
      }, err => {
        let message = err.error as string;
        if (message == null) {
          message = this.translate.instant('userEdit.dialog.form.create.error') as string;
        }
        this.snackBarService.sendError(message);
        this.spinnerService.hide();
      });
    }

  }

  cancel(): void {
    void this.router.navigateByUrl('/admin/manageUsers');
  }

  openRolesDialog(): void {
    const dialogRef = this.dialog.open(EditUserRoleDialogComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        roleUser: new RoleUser(),
        isEdit: false,
        currentRoles: this.roles
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: RoleUser[]) => {

      if (result != null) {

        result.forEach(r => {
          r.username = this.user.username;
          this.roles.push(r);
        });

        this.fixRolesDupes();

        this.roleUserTable.renderRows();
      }
    });
  }

  removeRole(index: number): void {
    this.roles.splice(index, 1);

    this.roleUserTable.renderRows();
  }

  downloadAuditPdf(): void {
    this.spinnerService.show();

    const username = this.user.username;
    this.manageUsersService.downloadAuditPdf(username).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      saveAs(res, this.translate.instant('userEdit.files.auditUser', { username }) as string);
      this.spinnerService.hide();
    }, error => {
      console.error(error);
      this.spinnerService.hide();
    });
  }

  uploadCertificateToUser(event): void {

    const file = event.target.files[0] as File;

    this.spinnerService.show();
    this.attachmentThermalService.uploadCertificateToUser(this.user.username, file).pipe(takeUntil(this.destroy$))
      .subscribe((item: number) => {
        this.user.idCertificate = item;

        this.spinnerService.hide();

        this.snackBarService.sendSuccess(this.translate.instant('userEdit.dialog.form.uploadCertificate.ok') as string);
      }, () => {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('userEdit.dialog.form.uploadCertificate.error') as string);
      });
  }

  downloadCertificatePdf(): void {
    this.spinnerService.show();

    const username = this.user.username;
    this.attachmentThermalService.downloadCertificateUser(username).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      saveAs(res, this.translate.instant('userEdit.files.certificateUser', { username }) as string);
      this.spinnerService.hide();
    }, error => {
      console.error(error);
      this.spinnerService.hide();
    });
  }

  unlockUser(): void {

    this.spinnerService.show();
    this.manageUsersService.unlockUser(this.user.username).pipe(takeUntil(this.destroy$)).subscribe((item: CreateUser) => {
      this.user = item;

      this.spinnerService.hide();

      this.snackBarService.sendSuccess(this.translate.instant('userEdit.dialog.form.unlockUser.ok') as string);
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('userEdit.dialog.form.unlockUser.error') as string);
    });
  }

  caducatePasswordUser(): void {

    this.spinnerService.show();
    this.manageUsersService.caducatePasswordUser(this.user.username).pipe(takeUntil(this.destroy$)).subscribe((item: CreateUser) => {
      this.user = item;

      this.spinnerService.hide();

      this.snackBarService.sendSuccess(this.translate.instant('userEdit.dialog.form.caducatePasswordUser.ok') as string);
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('userEdit.dialog.form.caducatePasswordUser.error') as string);
    });
  }

  private assignRoles(callback: () => void): void {
    this.manageUsersService.assignRoles(this.user.username, this.roles).subscribe(() => callback(), () => {
      this.snackBarService.sendError('Se ha superado el límite de usuarios para un rol, por lo que se ha eliminado automáticamente');
      callback();
    });
  }

  private loadUser(username: string): void {
    this.isEdit = true;

    this.manageUsersService.findOne(username).pipe(takeUntil(this.destroy$)).subscribe((result: CreateUser) => {
      this.user = result;

      this.loadLogins();
      this.loadRoles(this.user.username);

      this.spinnerService.hide();
    }, () => {
      this.cancel();
    });
  }

  private loadLogins(): void {
    this.loginAccessDataSource = new LoginAccessDataSource(this.manageUsersService);

    this.loginAccessFilter.username = this.user.username;
    this.loginAccessFilter.sortBy = this.loginAccessSort.active || 'date';
    this.loginAccessFilter.sortDirection = this.loginAccessSort.direction || 'desc';
    this.loginAccessFilter.pageIndex = this.loginAccessPaginator.pageIndex || 0;
    this.loginAccessFilter.pageSize = this.loginAccessPaginator.pageSize || 5;

    this.loginAccessDataSource.loadLogins(this.loginAccessFilter);
    this.loginAccessDataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  private loadRoles(username: string): void {
    this.manageUsersService.getRoles(username).pipe(takeUntil(this.destroy$)).subscribe((data: RoleUser[]) => this.roles = data);
  }

  private fixRolesDupes(): void {
    const roles: RoleUser[] = [];

    this.roles.forEach(r => {
      const existing = roles.find(ro => ro.idApp === r.idApp && ro.idGroup === r.idGroup);

      if (!existing) {
        roles.push(r);
      }
    });

    this.roles = roles;
  }

}
