import * as moment from 'moment';

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { AppTypeEnum } from 'src/app/model/appType';
import { Client } from 'src/app/model/client';
import { ClientService } from 'src/app/services/client.service';
import { Constants } from 'src/app/utils/constants';
import { CustomDatepickerHeaderComponent } from '../../shared/datepicker-custom-header/datepicker-custom-header.component';
import { EquipmentType } from 'src/app/model/equipmentType';
import { EquipmentTypeAirService } from 'src/app/services/equipmentTypeAir.service';
import { ExecutionFilter } from 'src/app/model/execution';
import { ExecutionStatusService } from 'src/app/services/executionStatus.service';
import { GenericClass } from 'src/app/model/genericClass';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { ProcessService } from 'src/app/services/process.service';
import { Subject } from 'rxjs';
import { User } from 'src/app/model/user';
import { UsersService } from 'src/app/services/users.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-execution-list-filter',
  templateUrl: './execution-list-filter.component.html'
})
export class ExecutionListFilterComponent implements OnInit {

  @Output() filterEmitter = new EventEmitter<ExecutionFilter>();

  filter: ExecutionFilter;

  clients: Client[];
  clientsFiltered: Client[];

  equipmentTypes: EquipmentType[];
  statuses: GenericClass[];
  processes: GenericClass[];
  users: User[];
  projectManagers: User[];

  expandedFilter = true;

  matcher = new MyErrorStateMatcher();
  form: UntypedFormGroup;

  customDatepickerHeader = CustomDatepickerHeaderComponent;

  private destroy$ = new Subject<void>();

  constructor(
    fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private serviceStatusService: ExecutionStatusService,
    private processService: ProcessService,
    private usersService: UsersService,
    private equipmentTypeService: EquipmentTypeAirService) {
    this.cleanFilter();

    this.form = fb.group({
      idClient: [this.filter.idClient],
      idProcess: [this.filter.idProcess],
      projectNo: [this.filter.projectNo],
      documentCode: [this.filter.documentCode],
      clientDocumentCode: [this.filter.clientDocumentCode],
      documentCodeProtocol: [this.filter.documentCodeProtocol],
      idStatus: [this.filter.idStatus],

      areaName: [this.filter.areaName],

      regUser: [this.filter.regUser],
      projectManager: [this.filter.projectManager],

      regFcStart: [this.filter.regFcStart],
      modFcStart: [this.filter.modFcStart],
      regFcEnd: [this.filter.regFcEnd],
      modFcEnd: [this.filter.modFcEnd],

      workStartFcStart: [this.filter.workStartFcStart],
      workStartFcEnd: [this.filter.workStartFcEnd],
      workEndFcStart: [this.filter.workEndFcStart],
      workEndFcEnd: [this.filter.workEndFcEnd],

      realizationFcStart: [this.filter.realizationFcStart],
      realizationFcEnd: [this.filter.realizationFcEnd],

      equipment: [this.filter.equipment],
      equipmentMaker: [this.filter.equipmentMaker],
      equipmentModel: [this.filter.equipmentModel],
      equipmentSerialNum: [this.filter.equipmentSerialNum],
      equipmentInternalId: [this.filter.equipmentInternalId],
      equipmentLocation: [this.filter.equipmentLocation]
    });
  }

  ngOnInit(): void {
    this.clients = [];
    this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Client[]) => {
      const empty = new Client();
      empty.id = null;
      empty.name = '---';

      data = [empty].concat(data);
      this.clients = data;
      this.clientsFiltered = this.clients.slice();
    });

    this.statuses = [];
    this.serviceStatusService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.statuses = data);

    this.processes = [];
    this.processService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.processes = data);

    this.users = [];
    this.usersService.findAllFromGroupAppType(AppTypeEnum.AIR).pipe(takeUntil(this.destroy$))
      .subscribe((data: User[]) => this.users = data);

    this.projectManagers = [];
    this.usersService.findAllRespFromGroup().pipe(takeUntil(this.destroy$)).subscribe((data: User[]) => this.projectManagers = data);

    this.equipmentTypes = [];
    this.equipmentTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: EquipmentType[]) => this.equipmentTypes = res);

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.filter.idPhase = +params[Constants.FIELD_ID_PHASE];
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  doFilter(): void {
    this.expandedFilter = false;

    this.filterEmitter.emit(this.filter);
  }

  cleanFilter(): void {
    this.filter = new ExecutionFilter();
  }

  saveDate(field: string, event: any): void {

    let value: Date | string = event.target.value;
    if (value != null) {

      if (value instanceof Date) {
        value = moment(value).format('DD/MM/YYYY');
      }

      const date = moment(value + ' 12:00:00 + 0:00', 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }

      this.filter[field] = date;
    } else {
      this.filter[field] = null;
    }

  }

}
