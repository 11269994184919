<h1 mat-dialog-title>{{ 'internalEquipmentEdit.calibrations.body.title'.concat(isEdit ? '.edit' : '.create') | translate }}</h1>
<form>

  <div mat-dialog-content [class]="getTheme()">
    <div fxLayout="row">
      <div fxFlex="50">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'internalEquipmentEdit.general.actionTypes.label' | translate }}</mat-label>
          <mat-select [(ngModel)]="data.item.actionType" name="idsActionHistorical" (selectionChange)="onChangeActionType()">
            <mat-option *ngFor="let item of actionTypes" checkboxPosition="before" [value]="item.id">
              {{ 'internalEquipmentEdit.'+ item.translation | translate }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ 'internalEquipmentEdit.general.actionTypes.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column" class="flexwrap" *ngIf="data.item.actionType">

      <div fxFlex>
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ (data.item.actionType === 4 ? 'calibrateEquipmentEdit.general.protocolNum.label': 'calibrateEquipmentEdit.general.certificateNum.label') | translate }}"
            name="certificateNum" [(ngModel)]="data.item.certificateNum">
          <mat-error>
            {{ (data.item.actionType === 4 ? 'calibrateEquipmentEdit.general.protocolNum.error': 'calibrateEquipmentEdit.general.certificateNum.error') | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex *ngIf="data.item.actionType === 4">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'calibrateEquipmentEdit.general.executionNum.label' | translate }}"
            name="certificateNum" [(ngModel)]="data.item.executionNum">
          <mat-error>
            {{ (data.item.actionType === 4 ? 'calibrateEquipmentEdit.general.executionNum.error': 'calibrateEquipmentEdit.general.certificateNum.error') | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex>
        <mat-form-field style="width: 95%;">
          <input matInput [matDatepicker]="calibrateDate" name="calibrationDate" [(ngModel)]="data.item.calibrationDate"
            [max]="maxDateCalibration"
            (blur)="saveDate('calibrationDate', $event)" (dateChange)="saveDate('calibrationDate', $event)"
            placeholder="{{ data.item.actionType === 1 ? ('calibrateEquipmentEdit.general.calibrateDate.label' | translate ):
            data.item.actionType === 2 ? ('calibrateEquipmentEdit.general.verificationDate.label' | translate ): 
            data.item.actionType === 3 ? ('internalEquipmentEdit.general.maintenanceCalibrateDate.label' | translate ):
            data.item.actionType === 4 ? ('calibrateEquipmentEdit.general.qualificationDate.label' | translate ): '' }}">
          <mat-datepicker-toggle matSuffix [for]="calibrateDate"></mat-datepicker-toggle>
          <mat-datepicker #calibrateDate [calendarHeaderComponent]="customDatepickerHeader">
          </mat-datepicker>
          <mat-error>
            {{ 'calibrateEquipmentEdit.general.calibrateDate.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex>
        <mat-form-field style="width: 95%;">
          <input matInput [matDatepicker]="expirationDate" name="expirationDate" [(ngModel)]="data.item.expirationDate"
            (blur)="saveDate('expirationDate', $event)" (dateChange)="saveDate('expirationDate', $event)"
            placeholder="{{ data.item.actionType === 1 ? ('calibrateEquipmentEdit.general.expirationDate.label ' | translate ) :
            data.item.actionType === 2 ? ('calibrateEquipmentEdit.general.verificationExpirationDate.label' | translate ) : 
            data.item.actionType === 3 ? ('calibrateEquipmentEdit.general.maintenanceExpirationDate.label' | translate ) :
            data.item.actionType === 4 ? ('calibrateEquipmentEdit.general.qualificationExpirationDate.label' | translate) : '' }}">
          <mat-datepicker-toggle matSuffix [for]="expirationDate"></mat-datepicker-toggle>
          <mat-datepicker #expirationDate [calendarHeaderComponent]="customDatepickerHeader">
          </mat-datepicker>
          <mat-error>
            {{ 'calibrateEquipmentEdit.general.expirationDate.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-tab-group (selectedTabChange)="onVariableTabChange($event)" *ngIf="data.item.actionType === 1 || data.item.actionType === 2">
      <mat-tab *ngFor="let variable of data.item.variables; let indexVariable = index" style="min-height: 50vh;"
        label="{{'variable.' + variable.variableTranslation | translate }}">

        <div fxLayout="row">
          <div fxFlex="33" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <mat-label>{{ 'calibrateEquipmentEdit.calibrationData.unit.label' | translate }}</mat-label>
              <mat-select name="unit{{variable.idVariable}}" [(ngModel)]="variable.idUnit" required
                (selectionChange)="onUnitChange(indexVariable, $event)">
                <mat-option *ngFor="let item of variableUnits" [value]="item.id">
                  {{item.unit}}
                </mat-option>
              </mat-select>
              <mat-error>
                {{ 'calibrateEquipmentEdit.calibrationData.unit.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row">
          <div fxFlex></div>
          <div>
            <button mat-raised-button (click)="newValueRow(indexVariable)" color="accent">
              {{ 'button.new' | translate }}</button>
          </div>
        </div>

        <table #valuesTable mat-table [dataSource]="variable.values" style="width: 100%;">

          <!-- Number Column -->
          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{ (i + 1) }} </td>
          </ng-container>

          <!-- Point Column -->
          <ng-container matColumnDef="point">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'calibrateEquipmentEdit.asLeft.cols.point.label' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.point }} </td>
          </ng-container>

          <!-- Average Column -->
          <ng-container matColumnDef="average">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'calibrateEquipmentEdit.asLeft.cols.average.label' | translate }} </th>
            <td mat-cell *matCellDef="let element">
              <span [matTooltip]="element.average">
                {{ element.average }}
              </span>
            </td>
          </ng-container>

          <!-- Correction Column -->
          <ng-container matColumnDef="correction">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'calibrateEquipmentEdit.asLeft.cols.correction.label' | translate }} </th>
            <td mat-cell *matCellDef="let element">
              {{ element.correction }}
            </td>
          </ng-container>

          <!-- U Column -->
          <ng-container matColumnDef="U">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'calibrateEquipmentEdit.uncertainties.cols.U.label' | translate }} </th>
            <td mat-cell *matCellDef="let element">
              {{ element.uncertainty }}
            </td>
          </ng-container>

          <!-- Button edit Column -->
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <button mat-icon-button (click)="editValueRow(indexVariable, i)">
                <mat-icon>mode_edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Button delete Column -->
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <button mat-icon-button (click)="deleteValueRow(indexVariable, i)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="valuesCols"></tr>
          <tr mat-row *matRowDef="let row; columns: valuesCols;"></tr>
        </table>

        <div *ngIf="variable.values?.length == 0">
          {{ 'common.noResults' | translate }}
        </div>

      </mat-tab>
    </mat-tab-group>

    <div>
      <button mat-raised-button (click)="uploadCertificate.click()" color="accent">
        {{ 'button.upload-certificate' | translate }}</button>

      <div fxFlex="5px"></div>
      <button mat-raised-button *ngIf="data.item.id != null && data.item.idDocument != null"
        (click)="downloadCertificateEquipment()" color="accent">{{ 'button.download-certificate' | translate }}</button>
        <span style="margin: 10px;" *ngIf="data?.item?.uploadFile">Archivo Adjunto: {{data?.item?.uploadFile?.name}}</span>
        <input accept="application/pdf" hidden type="file" (change)="uploadCertificateToEquipment($event)"
        #uploadCertificate>
    </div>


  </div>
  <div mat-dialog-actions [class]="getTheme()">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()" cdkFocusInitial>
      {{ 'button.add' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>