/* eslint-disable max-len */
import * as _ from 'lodash-es';

import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { CalibrationPlanEditCustomFieldComponent } from './calibration-plan-edit-custom-field.component';
import { CalibrationPlanEditFolderComponent } from './calibration-plan-edit-folder.component';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { Constants } from 'src/app/utils/constants';
import { EquipmentAutocompleteFilter } from 'src/app/model/autocompleteFilter';
import { FieldType } from 'src/app/model/fieldType';
import { InternalEquipmentCustomField } from 'src/app/model/internalEquipmentCustomField';
import { InternalEquipmentCustomFieldsService } from 'src/app/services/internalEquipmentCustomFields.service';
import { InternalEquipmentFolder } from 'src/app/model/internalEquipmentFolder';
import { InternalEquipmentFolderService } from 'src/app/services/internalEquipmentFolderService';
import { Location } from '@angular/common';
import { ManageNotificationDataSource } from 'src/app/model/manageNotificationDataSource';
import { ManageNotificationFilter } from 'src/app/model/notification';
import { ManageNotificationsService } from 'src/app/services/manageNotifications.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { CalibrationService } from '../../../services/calibration.service';
import { saveAs } from 'file-saver';
import { CalibrationPlanEditGenerateDialogComponent } from './calibration-plan-edit-generate-dialog/calibration-plan-edit-generate-dialog.component';
import { InternalEquipmentCustomFieldsValueService } from 'src/app/services/internalEquipmentCustomFieldsValue.service';

@Component({
  selector: 'app-calibration-plan-edit',
  templateUrl: './calibration-plan-edit.component.html'
})
export class CalibrationPlanEditComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('folderPaginator', { static: true }) folderPaginator: MatPaginator;

  filter: ManageNotificationFilter = new ManageNotificationFilter();
  currentUser: User;

  dataSource: ManageNotificationDataSource;
  fieldsDatasource: MatTableDataSource<InternalEquipmentCustomField>;
  folderDatasource: MatTableDataSource<InternalEquipmentFolder>;

  fieldTypes: FieldType[];

  showCanCalibrationPlanAnnual = false;

  displayedColsFolders = ['username', 'father', 'edit', 'delete'];
  displayedColsFields = ['username', 'type', 'filtrable', 'edit', 'delete'];

  private destroy$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private internalEquipmentFolderService: InternalEquipmentFolderService,
    private internalEquipmentCustomFieldService: InternalEquipmentCustomFieldsService,
    private internalEquipmentCustomFieldValueService: InternalEquipmentCustomFieldsValueService,
    private location: Location,
    private notificationsService: ManageNotificationsService,
    private spinnerService: SpinnerService,
    private translate: TranslateService,
    private userService: UserService,
    private calibrationService: CalibrationService) {
    this.spinnerService.show();
  }

  ngOnInit(): void {

    this.currentUser = this.userService.currentProfile;

    if (!this.currentUser.showCanCalibrationPlanAnnual) {
      this.showCanCalibrationPlanAnnual = true;
    }

    this.loadFolders();
    this.loadFields();
    this.loadNotificationList();
  }

  cancel(): void {
    this.location.back();
  }

  loadNotificationList(): void {
    this.dataSource = new ManageNotificationDataSource(this.notificationsService);

    this.filter.sortBy = this.sort.active || Constants.FIELD_ID;
    this.filter.sortDirection = this.sort.direction || 'asc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 5;

    this.dataSource.loadNotifications(this.filter);
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  newFolder(): void {
    const dialogRef = this.dialog.open(CalibrationPlanEditFolderComponent, {
      minWidth: '60%',
      maxHeight: '600vh',
      data: {
        folder: new InternalEquipmentFolder(),
        isEdit: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.loadFolders();
    });
  }

  editFolder(item: InternalEquipmentFolder): void {
    this.internalEquipmentFolderService.findById(item.id).subscribe((folder: InternalEquipmentFolder) => {
      const dialogRef = this.dialog.open(CalibrationPlanEditFolderComponent, {
        minWidth: '60%',
        maxHeight: '600vh',
        data: {
          folder,
          isEdit: true
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(res => {
        this.loadFolders();
      });
    });
  }

  deleteFolder(item: InternalEquipmentFolder): void {
    const equipment = item.equipments.length;
    const message = this.translate.instant('calibrationPlan.dialog.folder.form.confirmDelete', { equipment }) as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {

      if (response) {
        this.spinnerService.show();
        this.internalEquipmentFolderService.delete(item).subscribe(res => {
          this.loadFolders();
        });
      }
    });
  }

  newField(): void {
    const dialogRef = this.dialog.open(CalibrationPlanEditCustomFieldComponent, {
      minWidth: '60%',
      maxHeight: '600vh',
      data: {
        field: new InternalEquipmentCustomField(),
        isEdit: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.loadFields();
    });
  }

  editField(field: InternalEquipmentCustomField): void {
    const dialogRef = this.dialog.open(CalibrationPlanEditCustomFieldComponent, {
      minWidth: '60%',
      maxHeight: '600vh',
      data: {
        field: _.cloneDeep(field),
        isEdit: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.loadFields();
    });
  }

  deleteField(field: InternalEquipmentCustomField): void {
    this.internalEquipmentCustomFieldValueService.findAllByType(field.id).subscribe(res => {
      let message = null;
      if (res > 0) {
        message = this.translate.instant('calibrationPlan.dialog.customField.usedEquipment.form.confirmDelete', {equipment: res}) as string;
      } else {
        message = this.translate.instant('calibrationPlan.dialog.customField.form.confirmDelete') as string;
      }

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });
    
        dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
          if (response) {
            this.spinnerService.show();
            this.internalEquipmentCustomFieldService.delete(field).subscribe(res => {
              this.loadFields();
            });
          }
        });
    });
    
  }

  getFatherName(folder: InternalEquipmentFolder): string {
    return folder.idFather != null ? folder.nameFather : '---';
  }

  getTypeName(folder: InternalEquipmentCustomField): string {
    const type = this.fieldTypes?.find(a => folder.idType === a.id);

    return type != null ? this.translate.instant('customField.'.concat(type.name).concat('.type')) as string : '---';
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  downloadExcelTemplate() {
    this.spinnerService.show();
    this.calibrationService.getExcelTemplate().pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name =  'template.xlsx';
      saveAs(res, name);
      this.spinnerService.hide();
    }, error => {
      console.error(error);
      this.spinnerService.hide();
    });
  }

  createPlanAnnualCalibration(): void {
    const dialogRef = this.dialog.open(CalibrationPlanEditGenerateDialogComponent, {
      minWidth: '70%',
      maxHeight: '600vh'
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.loadFields();
    });
  }

  private loadFields(): void {
    this.internalEquipmentCustomFieldService.findAll().subscribe(res => {
      this.fieldsDatasource = new MatTableDataSource<InternalEquipmentCustomField>(res.content as InternalEquipmentCustomField[]);
      this.spinnerService.hide();
    });

    this.internalEquipmentCustomFieldService.findAllTypes().subscribe((res: FieldType[]) => {
      const typeAloweds = Constants.CALIBRATION_PLAN_ALLOWED_FIELD_TYPES;

      this.fieldTypes = res.filter((type) => typeAloweds.includes(type.id));
    });
  }

  private loadFolders(): void {
    const filter = new EquipmentAutocompleteFilter();
    this.internalEquipmentFolderService.findAll(filter).subscribe(res => {
      this.folderDatasource = new MatTableDataSource<InternalEquipmentFolder>(res.content as InternalEquipmentFolder[]);
      this.folderDatasource.paginator = this.folderPaginator;
      this.spinnerService.hide();
    });
  }
}
