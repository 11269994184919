import { GenericClass, GenericFilter } from './genericClass';

export class Group extends GenericClass {
    companyName: string;

    address: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;

    showLogo: boolean;
    idLogo: number;

    idAppPlanType: number;

    idsPhasesAllowed: number[];
    idsRolesNeedCertificate: number[];
    idsVariablesCalibrates: number[];

    maxUsers: number;
    maxExecutions: number;
    canGeneratePreReport: boolean;
    canManageUsers: boolean;

    automaticSign: boolean;
    manualSign: boolean;

    showCharacterization: boolean;
    showExternalEquipments: boolean;

    showClone: boolean;
	requireProcessApproval: boolean;
    showCanCloneExecutions: boolean;
    showCanCloneCharacterization: boolean;
	showCanCalibrationPlanAnnual: boolean;
    groupDocumentCoding: GroupDocumentCoding[];

    getFullAddress(): string {
        return this.getAddressLine1() + '\n' + this.getAddressLine2();
    }

    getAddressLine1(): string {
        return [this.address].filter(f => f != null).join(', ');
    }

    getAddressLine2(): string {
        return [this.postalCode, this.city, this.state, this.country].filter(f => f != null).join(', ');
    }

}

export class GroupFilter extends GenericFilter {
    name: string;
}

export class GroupDocumentCoding {
    id: number;
    idGroup: number;
    idAppType: number;
    coding: string;
    documentType: number;
}