import { GenericClass } from './genericClass';

export class AppType extends GenericClass {
}

export enum AppTypeEnum {
    THERMAL = 1,
    CALIBRATES = 2,
    AIR = 3
}
