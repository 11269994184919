import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpUtils } from "../utils/httpUtils";

@Injectable()
export class InternalEquipmentCustomFieldsValueService {
    constructor(private http: HttpClient) {
    }

    findAllByType(idType: number): Observable<any> {
        const url = environment.thermalUrl + `/internalEquipmentCustomFieldValue/${idType}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}