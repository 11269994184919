import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Constants } from 'src/app/utils/constants';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnDestroy {

  matcher = new MyErrorStateMatcher();
  changePasswordForm: UntypedFormGroup;

  currentPassword: string;
  newPassword: string;
  confirmPassword: string;

  private destroy$ = new Subject<void>();

  constructor(
    fb: UntypedFormBuilder,
    private router: Router,
    private userService: UserService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private cookieService: CookieService) {

    this.changePasswordForm = fb.group({
      currentPassword: [this.currentPassword, [Validators.required]],
      newPassword: [this.newPassword, [Validators.required, Validators.pattern(Constants.PASSWORD_PATTERN)]],
      confirmPassword: [this.confirmPassword, [Validators.required, Validators.pattern(Constants.PASSWORD_PATTERN)]]
    }, { validator: this.checkPasswords });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkPasswords(group: UntypedFormGroup): any { // here we have the 'passwords' group
    const pass = group.controls.newPassword.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notMatch: true };
  }

  changePassword(): void {
    if (!this.changePasswordForm.valid) {
      if (this.changePasswordForm.errors[Constants.FIELD_NO_MATCH]) {
        this.snackBarService.sendError(this.translate.instant('changePassword.form.error.notMatch') as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('changePassword.form.error.invalid') as string);
      }

      return;
    }

    this.spinnerService.show();
    this.userService.changePassword(this.currentPassword, this.newPassword).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.snackBarService.sendSuccess(this.translate.instant('changePassword.form.ok') as string);

      this.userService.updateProfileSidebar(() => {
        const profile = JSON.parse(localStorage.getItem('profile')) as User;
        if (profile.idActiveRole != null && profile.idActiveRole == 6) {
          void this.router.navigate(['/portal-client']);
        } else {
          void this.router.navigate(['/']);
        }
        this.spinnerService.hide();
      });

    }, err => {
      this.snackBarService.sendError(err.error as string);
      this.spinnerService.hide();
    });
  }

}
