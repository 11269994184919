export enum InternalEquipmentStatusEnum {
    EN_USO = 1,
    FUERA_DE_USO = 3,
    PENDING_REVIEW = 4,
    CADUCADO=2,
    EXTENDIDO=5,
    PENDING_CERTIFICATE=6,
    CONFORME=7,
    NO_CONFORME=8,
}
