import { ArrayUtils } from '../utils/arrayUtils';
import { Criteria } from './criteria';
import { Equipment } from './equipment';
import { GenericFilter } from './genericClass';
import { NumberUtils } from '../utils/numberUtils';
import { Procedure } from './procedure';
import { ReferenceDocument } from './referenceDocument';
import { SensorData } from './sensorData';

export class IsothermalCharacterization {
    id: number;

    idClient: number;
    documentCode: string;
    clientDocumentCode: string;

    currentVersion: string;
    signedVersion: string;

    idStatus: number;
    statusTranslation: string;

    projectNo: string;

    temperature: string;
    humidity: string;
    pressure: string;

    rehearsalTime = '0;0;0';
    frequency = '1;0';
    workingConditions: string;

    idPhoto: number;
    idDisposal: number;

    regUser: string;
    modUser: string;

    regFc: Date;
    modFc: Date;

    initWorkDate: Date;
    endWorkDate: Date;

    realizationDate: Date;

    observations: string;
    according: boolean;
    accordingReason: string;
    reason: string;

    department: string;

    procediments: Procedure[];
    equipments: Equipment[];
    referenceDocs: ReferenceDocument[];
    variables: IsothermalCharacterizationVariable[];

    createPerformance: boolean;
}

export class IsothermalCharacterizationCalibration {
    id: number;

    idEquipmentCalibration: number;
    equipmentName: string;
    serialNum: string;

    calibrationName: string;

    resolution: string;
    tolerance: string;
    pattern: string;
    uncertainty: string;
    values: IsothermalCharacterizationCalibrationValue[];

    cPlusU: number;  // Incertidumbre corregida

    uRep: number; // Incertidumbre de repetibilidad
    uResol: number; // Incertidumbre por resolución
    uUnif: number; // Incertidumbre por falta de uniformidad
    uEstab: number; // Incertidumbre de estabilidad
    uC: number; // Incertidumbre típica
    neff: number; // Grados de libertad
    k: number; // Constante
    U: number; // Incertidumbre expandida

    get average(): number {
        if (ArrayUtils.isEmpty(this.values)) {
            return null;
        } else {
            return ArrayUtils.calcAvg(this.values.map(item => +item.value)) || null;
        }
    }

    get correction(): number {
        if (this.pattern == null || this.average == null) {
            return null;
        }

        return NumberUtils.fixPrecision((+this.pattern) - this.average);
    }

    public recalculateValues(): void {

        let values: number[] = [];
        if (!ArrayUtils.isEmpty(this.values)) {
            values = this.values.map(v => +v.value);
        }

        const patterns: number[] = [];

        if (this.uncertainty != null && this.uncertainty !== '') {
            patterns.push(+this.uncertainty / 2);
        }

        // Reseteamos todos los valores antes de empezar
        this.uRep = null;
        this.uResol = null;
        this.uEstab = null;
        this.uC = null;
        this.k = null;
        this.U = null;
        this.cPlusU = null;

        // Si algún valor es nulo no recalculamos los valores y así evitamos errores
        if (ArrayUtils.isEmpty(values) || this.resolution == null || ArrayUtils.isEmpty(patterns)) {
            return;
        }

        const standarDevs = NumberUtils.standardDeviation(values);
        const totalValues = values.length;
        this.uRep = NumberUtils.fixPrecision(standarDevs / Math.sqrt(totalValues));

        this.uResol = (+this.resolution) / Math.sqrt(12);

        this.uEstab = (Math.max(...values) - Math.min(...values)) / (2) / (Math.sqrt(3));

        const dataUC: number[] = [];
        dataUC.push(this.uRep);
        dataUC.push(this.uResol);
        dataUC.push(this.uUnif);
        dataUC.push(this.uEstab);

        if (patterns != null) {
            patterns.filter(d => d != null).forEach(d => dataUC.push(d));
        }

        let counterUC = 0.0;
        dataUC.filter(d => d != null).forEach(d => counterUC += Math.pow(d, 2));

        this.uC = Math.sqrt(counterUC);

        if (this.uRep === 0) {
            this.neff = Infinity;
        } else {
            const dataNeff = [this.uRep, this.uResol, this.uUnif, ...patterns, this.uEstab];

            let counterNeff = 0.0;
            dataNeff.filter(d => d != null).forEach(d => counterNeff += (Math.pow(d, 4)) / (totalValues - 1));

            this.neff = (Math.pow(this.uC, 4) / counterNeff);
        }

        let resultK: number;

        if (this.neff < 10) {
            resultK = 2.37;
        } else if (this.neff < 20) {
            resultK = 2.28;
        } else if (this.neff < 50) {
            resultK = 2.13;
        } else {
            resultK = 2;
        }

        this.k = resultK;

        this.U = this.uC * this.k;

        this.cPlusU = Math.abs(this.correction) + this.U;
    }
}

export class IsothermalCharacterizationCalibrationValue {
    id: number;
    value: string;
}

export class IsothermalCharacterizationSensor {
    id: number;

    trayNum: number;
    sensorTray: number;

    location: string;

    idExcel: number;
    idPrimaryDataFile: number;

    idEquipment: number;
    equipmentName: string;
    serialNum: string;

    uncertainty: number;

    evaluate = true;
    evaluateReason: string;

    outer: boolean;

    data: SensorData[] = [];

    corrected: boolean;
}

export class IsothermalSummaryData {
    average: number;
    uniformity: number;
    stability: number;

    averageTypicalUncertainty: number;
    uniformityTypicalUncertainty: number;
    stabilityTypicalUncertainty: number;

    averageExpandedUncertainty: number;
    uniformityExpandedUncertainty: number;
    stabilityExpandedUncertainty: number;

    min: number;
    max: number;

    minSensor: string;
    maxSensor: string;

    avgMax: number;
    avgMin: number;
    dispersion: number;

    uniformityValid: boolean;
    stabilityValid: boolean;

}

export class IsothermalCharacterizationAudit {
    id: number;
    username: string;

    idAction: number;
    actionTranslation: string;

    idStatus: number;
    statusTranslation: string;

    date: Date;

    currentVersion: string;
    signedVersion: string;

    changes: string;

    canDownload: boolean;
}

export class IsothermalCharacterizationFilter extends GenericFilter {
    documentCode: string;
    clientDocumentCode: string;

    projectNo: string;

    idClient: number;
    idStatus: number;

    regUser: string;
    modUser: string;

    regFcStart: Date;
    regFcEnd: Date;

    modFcStart: Date;
    modFcEnd: Date;

    workStartFcStart: Date;
    workStartFcEnd: Date;

    workEndFcStart: Date;
    workEndFcEnd: Date;

    realizationFcStart: Date;
    realizationFcEnd: Date;

    equipment: string;
    equipmentMaker: string;
    equipmentModel: string;
    equipmentSerialNum: string;
    equipmentIdInternal: string;

    department: string;

    cols: string[];
}

export class IsothermalCharacterizationAuditFilter extends GenericFilter {
}

export enum IsothermalCharacterizationActionEnum {
    CREATE = 1,
    SAVE = 2,
    EXECUTE = 3,
    VALIDATED = 4,
    INVALIDATE = 5,
    UPLOAD_EXCEL_SENSOR = 6,
    UPLOAD_PRIMARY_DATA_SENSOR = 7,
    MODIFY_ESSAY = 8,
    MODIFY_SENSOR = 9,
    MODIFY_FIELD = 10,
    MODIFY_CRITERIA = 11
}

export enum IsothermalCharacterizationStatus {
    EN_PREPARACION = 1,
    PENDIENTE_FIRMA = 2,
    FIRMADO = 3
}

export class IsothermalCharacterizationVariable {
    id: number;

    idVariable: number;
    translationVariable: string;

    idUnit: number;
    unitName: string;

    fcStartEssay: any;
    fcEndEssay: any;

    setpoint: string;
    copyFromTemperature: boolean;

    reasonModifyExpositionTime: string;

    locationByTrays: boolean;

    enableCalibration: boolean;

    sensors: IsothermalCharacterizationSensor[] = [];
    calibration: IsothermalCharacterizationCalibration;
    calibrations: IsothermalCharacterizationCalibration[] = [];

    criterias: Criteria[];
}
