<form>
    <mat-accordion>
        <mat-expansion-panel [expanded]="expandedFilter" (opened)="expandedFilter = true"
            (closed)="expandedFilter = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'internalEquipmentList.filter.title' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <input matInput name="name" [(ngModel)]="filter.name"
                            placeholder="{{ 'internalEquipmentList.filter.name.label' | translate }}">
                    </mat-form-field>
                </div>

                <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%" *ngIf="isInternal">
                    <mat-form-field class="campoFormulario">
                      <mat-label>{{ 'internalEquipmentList.filter.status.label' | translate }}</mat-label>
                      <mat-select multiple name="idStatus" [(ngModel)]="filter.idStatus" [ngModelOptions]="{standalone: true}">
                        <mat-option *ngFor="let item of statuses" [value]="item.id" checkboxPosition="before">
                          {{ 'internalEquipmentStatus.' + item.translation | translate }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%" *ngIf="isExternal">
                    <mat-form-field class="campoFormulario">
                        <mat-label>{{ 'internalEquipmentList.filter.client.label' | translate }}</mat-label>
                        <mat-select #clientSelect name="idClient" [(ngModel)]="filter.idClient">
                            <mat-select-filter *ngIf="clientSelect.focused" [displayMember]="'name'" [array]="clients"
                                (filteredReturn)="clientsFiltered =$event"
                                [noResultsMessage]="'common.noResults' | translate"
                                [placeholder]="'common.selectFilterPlaceholder' | translate"></mat-select-filter>
                            <mat-option *ngFor="let client of clientsFiltered" [value]="client.id">
                                {{client.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <input matInput placeholder="{{ 'internalEquipmentList.filter.serialNum.label' | translate }}"
                            name="serialNum" [(ngModel)]="filter.serialNum">
                    </mat-form-field>
                </div>

                <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="isInternal">
                    <mat-form-field class="campoFormulario">
                        <input matInput placeholder="{{ 'internalEquipmentList.filter.group.label' | translate }}"
                            name="group" [(ngModel)]="filter.group">
                    </mat-form-field>

                </div>

                <mat-accordion>
                    <mat-expansion-panel expanded="false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ 'internalEquipmentList.filter.advanced.title' | translate }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                                <mat-form-field class="campoFormulario">
                                    <input matInput name="type" [(ngModel)]="filter.type"
                                        placeholder="{{ 'internalEquipmentList.filter.type.label' | translate }}">
                                </mat-form-field>
                            </div>

                            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                                <mat-form-field class="campoFormulario">
                                    <input matInput name="maker" [(ngModel)]="filter.maker"
                                        placeholder="{{ 'internalEquipmentList.filter.maker.label' | translate }}">
                                </mat-form-field>
                            </div>

                            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                                <mat-form-field class="campoFormulario">
                                    <input matInput name="model" [(ngModel)]="filter.model"
                                        placeholder="{{ 'internalEquipmentList.filter.model.label' | translate }}">
                                </mat-form-field>
                            </div>

                            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                                <mat-form-field class="campoFormulario">
                                    <input matInput name="certificateNum" [(ngModel)]="filter.certificateNum"
                                        placeholder="{{ 'internalEquipmentList.filter.certificateNum.label' | translate }}">
                                </mat-form-field>
                            </div>

                            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                                <mat-form-field class="datepicker">
                                    <input matInput [matDatepicker]="calibrateDateStartPicker" name="calibrateDateStart"
                                        [ngModel]="filter.realizationDateStart"
                                        (blur)="saveDate('calibrateDateStart', $event)"
                                        (dateChange)="saveDate('calibrateDateStart', $event)"
                                        placeholder="{{ 'internalEquipmentList.filter.calibrateDateStart.label' | translate }}">
                                    <mat-datepicker-toggle matSuffix [for]="calibrateDateStartPicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #calibrateDateStartPicker
                                        [calendarHeaderComponent]="customDatepickerHeader">
                                    </mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                                <mat-form-field class="datepicker">
                                    <input matInput [matDatepicker]="calibrateDateEndPicker" name="calibrateDateEnd"
                                        [ngModel]="filter.realizationDateEnd"
                                        (blur)="saveDate('calibrateDateEnd', $event)"
                                        (dateChange)="saveDate('calibrateDateEnd', $event)"
                                        placeholder="{{ 'internalEquipmentList.filter.calibrateDateEnd.label' | translate }}">
                                    <mat-datepicker-toggle matSuffix [for]="calibrateDateEndPicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #calibrateDateEndPicker
                                        [calendarHeaderComponent]="customDatepickerHeader">
                                    </mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                                <mat-form-field class="datepicker">
                                    <input matInput [matDatepicker]="expirationDateStartPicker"
                                        name="expirationDateStart" [ngModel]="filter.expirationDateStart"
                                        (blur)="saveDate('expirationDateStart', $event)"
                                        (dateChange)="saveDate('expirationDateStart', $event)"
                                        placeholder="{{ 'internalEquipmentList.filter.expirationDateStart.label' | translate }}">
                                    <mat-datepicker-toggle matSuffix [for]="expirationDateStartPicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #expirationDateStartPicker
                                        [calendarHeaderComponent]="customDatepickerHeader">
                                    </mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                                <mat-form-field class="datepicker">
                                    <input matInput [matDatepicker]="expirationDateEndPicker" name="expirationDateEnd"
                                        [ngModel]="filter.expirationDateEnd"
                                        (blur)="saveDate('expirationDateEnd', $event)"
                                        (dateChange)="saveDate('expirationDateEnd', $event)"
                                        placeholder="{{ 'internalEquipmentList.filter.expirationDateEnd.label' | translate }}">
                                    <mat-datepicker-toggle matSuffix [for]="expirationDateEndPicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #expirationDateEndPicker
                                        [calendarHeaderComponent]="customDatepickerHeader">
                                    </mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div fxFlex="25" fxFlex.lt-md="100%">
                                <span> {{ 'internalEquipmentList.filter.variable.label' | translate }} </span>
                                <mat-select multiple [(ngModel)]="filter.idsVariable" name="idsVariable"
                                    [ngModelOptions]="{standalone: true}">
                                    <mat-option *ngFor="let item of variables" [value]="item.id">
                                        {{ 'variable.' + item.translation | translate }}
                                    </mat-option>
                                </mat-select>
                            </div>

                            <div fxFlex="25" fxFlex.lt-md="100%">
                                <span> {{ 'internalEquipmentList.filter.appType.label' | translate }} </span>
                                <mat-select multiple [(ngModel)]="filter.idsApp" name="idsApp"
                                    [ngModelOptions]="{standalone: true}">
                                    <mat-option *ngFor="let item of appTypes" [value]="item.id">
                                        {{ item.name }}
                                    </mat-option>
                                </mat-select>
                            </div>

                            <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="isInternal">
                                <mat-form-field class="campoFormulario">
                                    <input matInput name="supplier" [(ngModel)]="filter.supplier"
                                        placeholder="{{ 'internalEquipmentList.filter.supplier.label' | translate }}">
                                </mat-form-field>
                            </div>

                            <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="isInternal">
                                <mat-form-field class="campoFormulario">
                                    <input matInput name="equipmentRange" [(ngModel)]="filter.equipmentRange"
                                        placeholder="{{ 'internalEquipmentList.filter.equipmentRange.label' | translate }}">
                                </mat-form-field>
                            </div>

                            <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="isInternal">
                                <mat-form-field class="campoFormulario">
                                    <input matInput name="useRange" [(ngModel)]="filter.useRange"
                                        placeholder="{{ 'internalEquipmentList.filter.useRange.label' | translate }}">
                                </mat-form-field>
                            </div>

                            <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="isInternal">
                                <mat-form-field class="campoFormulario">
                                    <input matInput name="resolution" [(ngModel)]="filter.resolution"
                                        placeholder="{{ 'internalEquipmentList.filter.resolution.label' | translate }}">
                                </mat-form-field>
                            </div>

                            <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="isInternal">
                                <mat-form-field class="campoFormulario">
                                    <input matInput name="tolerance" [(ngModel)]="filter.tolerance"
                                        placeholder="{{ 'internalEquipmentList.filter.tolerance.label' | translate }}">
                                </mat-form-field>
                            </div>

                            <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="isInternal">
                                <mat-form-field class="campoFormulario">
                                    <input matInput name="calibrationFrequency"
                                        [(ngModel)]="filter.calibrationFrequency"
                                        placeholder="{{ 'internalEquipmentList.filter.calibrationFrequency.label' | translate }}">
                                </mat-form-field>
                            </div>

                            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                                <mat-form-field class="datepicker">
                                    <input matInput [matDatepicker]="acquisitionDateStartPicker"
                                        name="acquisitionDateStart" [ngModel]="filter.acquisitionDateStart"
                                        (blur)="saveDate('acquisitionDateStart', $event)"
                                        (dateChange)="saveDate('acquisitionDateStart', $event)"
                                        placeholder="{{ 'internalEquipmentList.filter.acquisitionDateStart.label' | translate }}">
                                    <mat-datepicker-toggle matSuffix [for]="acquisitionDateStartPicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #acquisitionDateStartPicker
                                        [calendarHeaderComponent]="customDatepickerHeader">
                                    </mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                                <mat-form-field class="datepicker">
                                    <input matInput [matDatepicker]="acquisitionDateEndPicker" name="acquisitionDateEnd"
                                        [ngModel]="filter.acquisitionDateEnd"
                                        (blur)="saveDate('acquisitionDateEnd', $event)"
                                        (dateChange)="saveDate('acquisitionDateEnd', $event)"
                                        placeholder="{{ 'internalEquipmentList.filter.acquisitionDateEnd.label' | translate }}">
                                    <mat-datepicker-toggle matSuffix [for]="acquisitionDateEndPicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #acquisitionDateEndPicker
                                        [calendarHeaderComponent]="customDatepickerHeader">
                                    </mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="isInternal">
                                <span> {{ 'internalEquipmentList.filter.folder.label' | translate }} </span>
                                <mat-select name="idFolder" [(ngModel)]="filter.idFolder">
                                    <mat-option [value]="null"> --- </mat-option>
                                    <mat-option *ngFor="let item of folders" [value]="item.id">
                                        {{ getFolderName(item) }}
                                    </mat-option>
                                </mat-select>
                            </div>

                            <div fxFlex="25" fxFlex.lt-md="100%" *ngFor="let field of customFields">

                                <!-- STRING o ÁREA -->
                                <div *ngIf="field.idType == 1 || field.idType == 9">
                                    <mat-form-field class="campoFormulario">
                                        <input matInput placeholder="{{ field.name }}" [name]="field.name"
                                            [ngModel]="field.value" (ngModelChange)="setValue(field, -1, $event)">
                                    </mat-form-field>
                                </div>

                                <!-- NUMÉRICO -->
                                <div *ngIf="field.idType == 2">
                                    <mat-form-field class="campoFormulario">
                                        <input matInput placeholder="{{ field.name }}" [name]="field.name"
                                            [(ngModel)]="field.value" (ngModelChange)="setValue(field, -1, $event)"
                                            type="number" [required]="field.required">
                                    </mat-form-field>
                                </div>

                                <!-- BOOLEAN -->
                                <div *ngIf="field.idType == 8">
                                    <mat-checkbox [value]="field.value" [checked]="field.value == 'true'"
                                        (change)="setValue(field, -1, $event)">
                                        {{ field.name }}
                                    </mat-checkbox>
                                </div>

                                <div *ngIf="field.idType == 6">
                                    <mat-form-field class="campoFormulario">
                                      <mat-label>{{ field.name }}</mat-label>
                                      <mat-select [name]="'idSelectCustom'+field.id" [(ngModel)]="field.value" (ngModelChange)="setValue(field, -1, $event)">
                                        <mat-option *ngFor="let sel of field.valueSelect" [value]="sel.value">
                                          {{sel.value}}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>

                            </div>

                            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                                <mat-form-field class="campoFormulario">
                                    <mat-label>{{ 'internalEquipmentList.filter.resposible.label' | translate
                                        }}</mat-label>
                                    <mat-select name="idResposible" [(ngModel)]="filter.idResposible">
                                        <mat-option [value]="null"> --- </mat-option>
                                        <mat-option *ngFor="let item of calibrationResponsibles" [value]="item.id">
                                            {{ 'calibrationResponsibleType.' + item.translation | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                                <mat-form-field class="campoFormulario">
                                  <mat-label>{{ 'internalEquipmentEdit.general.resposible.equipment.label' | translate }}</mat-label>
                                  <mat-select #responsableSelect name="idResponsibleEquipment" [(ngModel)]="filter.responsibleEquipment">
                                    <mat-select-filter *ngIf="responsableSelect.focused" [displayMember]="'fullName'" [array]="users"
                                      (filteredReturn)="usersFiltered =$event" [noResultsMessage]="'common.noResults' | translate"
                                      [placeholder]="'common.selectFilterPlaceholder' | translate"></mat-select-filter>
                                    <mat-option [value]="null"> --- </mat-option>
                                    <mat-option *ngFor="let user of usersFiltered" [value]="user.username">
                                      {{user.fullName}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

                <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
                    <button mat-raised-button (click)="doFilter()" color="primary">
                        {{ 'button.filter' | translate }}</button>
                    <button mat-button (click)="cleanWithoutFilter()">{{ 'button.clean' | translate }}</button>
                </div>

            </div>

        </mat-expansion-panel>
    </mat-accordion>
</form>