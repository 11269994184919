<h1 mat-dialog-title>{{'calibration-plan-annual.title' | translate}}</h1>
<form>
    <div mat-dialog-content class="air-theme" fxLayout="column">

        <div fxLayout="row" class="flexwrap">
            <div fxFlex="45" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput [(ngModel)]="calibrationAnnualPlan.year" name="name"
                        placeholder="{{ 'calibration-plan-annual.year.label' | translate }}">
                </mat-form-field>
            </div>
            <div fxFlex="30" fxFlex.lt-md="100%">
                <button mat-raised-button (click)="prepareSave()" matTooltip="{{ 'button.save' | translate }}">
                    {{'button.save' | translate}}
                </button>
            </div>
        </div>
        <div >

            <mat-card class="card">
                <mat-card-header>
                    <mat-card-title>
                        <mat-toolbar color="secondary" style="width: 95vw; display: contents;">
                            {{ 'calibration-plan-annual.historical.title' | translate }}
                        </mat-toolbar>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-accordion>
                        <mat-expansion-panel
                            *ngFor="let calibration of annualPlanDatasource; let indexCalibration = index"
                            (opened)="setCalibrationOpened(indexCalibration, true)"
                            [expanded]="isCalibrationOpened(indexCalibration)"
                            (closed)="setCalibrationOpened(indexCalibration, false)">

                            <mat-expansion-panel-header [id]="'cal-header' + indexCalibration">
                                <mat-panel-title [id]="'cal' + indexCalibration">
                                   <div fxFlex="80">
                                    {{ calibration.year }}
                                   </div>

                                    <div fxFlex="20" style="text-align: end;">
                                        <button mat-raised-button color="primary" *ngIf="isDefinitiveForVersion(calibration.calibrationAnnualPlans)"
                                            (click)="newVersion(calibration.year)">
                                            {{ 'button.version' | translate }}</button>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div fxLayout="row" *ngFor="let plan of calibration.calibrationAnnualPlans" style="padding: 10px;">

                                <div fxFlex="50" fxLayout="column">
                                    <span fxFlex>
                                        {{ 'calibration-plan-annual.version' | translate }} {{ ': v.' +
                                        plan.currentVersion }}<span *ngIf="plan.definitive" class="bold"> - {{'calibration-plan-annual.definitive' | translate}}</span>
                                    </span>
                                    
                                </div>
                                <div fxFlex="20" style="text-align: end;">
                                    <button mat-raised-button (click)="uploadPdfInput.click()">
                                    {{ 'button.uploadManual' | translate }}</button>
                                    <input accept="application/pdf" hidden type="file" (change)="uploadPdfManual($event, plan)"
                                    #uploadPdfInput>
                                </div>
                                <div fxFlex="20" style="text-align: end;">
                                    <button mat-raised-button (click)="preprateGenerate(plan)">
                                    {{ 'button.generateDoc' | translate }}</button>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </mat-card-content>
            </mat-card>



        </div>

    </div>
    <div mat-dialog-actions class="air-theme">
        <div fxFlex></div>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
      </div>
</form>