<form>

  <mat-card class="card">
    <mat-card-content>
      <mat-toolbar color="secondary">
        {{ 'internalEquipmentEdit.equipment.data.title' | translate }}
      </mat-toolbar>

      <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

        <div fxFlex="50" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput name="nameEq" [(ngModel)]="equipment.name" required
              placeholder="{{ 'internalEquipmentEdit.general.name.label' | translate }}">
            <mat-error>
              {{ 'internalEquipmentEdit.general.name.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
          <mat-form-field class="campoFormulario">
            <input matInput name="group" [(ngModel)]="equipment.group"
              placeholder="{{ 'internalEquipmentEdit.general.group.label' | translate }}">
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="!isInternal"></div>

        <div fxFlex="50" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput name="equipment" [(ngModel)]="equipment.equipment" required
              placeholder="{{ 'internalEquipmentEdit.general.equipment.label' | translate }}">
            <mat-error>
              {{ 'internalEquipmentEdit.general.equipment.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput name="maker" [(ngModel)]="equipment.maker" required
              placeholder="{{ 'internalEquipmentEdit.general.maker.label' | translate }}">
            <mat-error>
              {{ 'internalEquipmentEdit.general.maker.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput name="model" [(ngModel)]="equipment.model" required
              placeholder="{{ 'internalEquipmentEdit.general.model.label' | translate }}">
            <mat-error>
              {{ 'internalEquipmentEdit.general.model.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput name="serialNum" [(ngModel)]="equipment.serialNum" required
              placeholder="{{ 'internalEquipmentEdit.general.serialNum.label' | translate }}">
            <mat-error>
              {{ 'internalEquipmentEdit.general.serialNum.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isExternal">
          <mat-form-field class="campoFormulario">
            <mat-label>{{ 'internalEquipmentEdit.general.client.label' | translate }}</mat-label>
            <mat-select #clientSelect name="idClient" [(ngModel)]="equipment.idClient" [required]="isExternal">
              <mat-select-filter *ngIf="clientSelect.focused" [displayMember]="'name'" [array]="clients"
                (filteredReturn)="clientsFiltered =$event" [noResultsMessage]="'common.noResults' | translate"
                [placeholder]="'common.selectFilterPlaceholder' | translate"></mat-select-filter>
              <mat-option *ngFor="let client of clientsFiltered" [value]="client.id">
                {{client.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput name="location" [(ngModel)]="equipment.location" required
              placeholder="{{ 'internalEquipmentEdit.general.location.label' | translate }}">
            <mat-error>
              {{ 'internalEquipmentEdit.general.location.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%">
          <mat-form-field style="width: 95%;" matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
            [matTooltipDisabled]="hasCalibrationPlan()">
            <input matInput [matDatepicker]="acquisitionDate" (focus)="acquisitionDate?.open()" name="acquisitionDate"
              [(ngModel)]="equipment.acquisitionDate" (blur)="saveDateField('acquisitionDate', $event)"
              (dateChange)="saveDateField('acquisitionDate', $event)"
              placeholder="{{ 'internalEquipmentEdit.general.acquisitionDate.label' | translate }}"
              [disabled]="!hasCalibrationPlan()">
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="acquisitionDate"></mat-datepicker-toggle>
            <mat-datepicker #acquisitionDate [calendarHeaderComponent]="customDatepickerHeader">
            </mat-datepicker>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
          <mat-form-field class="campoFormulario">
            <mat-label>{{ 'internalEquipmentEdit.general.status.label' | translate }}</mat-label>
            <mat-select #statusSelect name="idStatus" [(ngModel)]="equipment.idStatus" [required]="isInternal">
              <mat-option *ngFor="let item of statuses" [value]="item.id">
                {{ 'internalEquipmentStatus.' + item.translation | translate }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{ 'internalEquipmentEdit.general.status.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
          {{ 'internalEquipmentEdit.general.appTypes.label' | translate }}:
          <mat-select multiple [(ngModel)]="equipment.idsApps" name="idsApps">
            <mat-option *ngFor="let item of appTypes" checkboxPosition="before" [value]="item.id">
              {{ item.name | translate }}
            </mat-option>
          </mat-select>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%">
          {{ 'internalEquipmentEdit.general.variables.label' | translate }}:
          <mat-select multiple [(ngModel)]="equipment.idsVariables" name="idsVariables">
            <mat-option *ngFor="let item of variables" checkboxPosition="before" [value]="item.id">
              {{ 'variable.' + item.translation | translate }}
            </mat-option>
          </mat-select>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%">
          {{ 'internalEquipmentEdit.general.folders.label' | translate }}:
          <mat-select multiple [(ngModel)]="equipment.idsFolder" name="idsFolder">
            <mat-option *ngFor="let item of folders" checkboxPosition="before" [value]="item.id">
              {{ getFolderName(item) }}
            </mat-option>
          </mat-select>
        </div>
        <div fxFlex="50" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <mat-label>{{ 'internalEquipmentEdit.general.actionTypes.label' | translate }}</mat-label>
            <mat-select multiple [(ngModel)]="idsActionSelected" name="idsAction"
              (ngModelChange)="onChangeActionType()">
              <mat-option *ngFor="let item of actionTypes" checkboxPosition="before" [value]="item.id">
                {{ 'internalEquipmentEdit.'+ item.translation | translate }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{ 'internalEquipmentEdit.general.actionTypes.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isManager && !pendingReview">
          <div fxFlex="60" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <mat-label>{{ 'internalEquipmentEdit.general.resposible.equipment.label' | translate }}</mat-label>
              <mat-select #responsableSelect name="idResponsibleEquipment" [(ngModel)]="equipment.responsibleEquipment">
                <mat-select-filter *ngIf="responsableSelect.focused" [displayMember]="'fullName'" [array]="users"
                  (filteredReturn)="usersFiltered =$event" [noResultsMessage]="'common.noResults' | translate"
                  [placeholder]="'common.selectFilterPlaceholder' | translate"></mat-select-filter>
                <mat-option *ngFor="let user of usersFiltered" [value]="user.username">
                  {{user.fullName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="40" fxFlex.lt-md="100%" *ngIf="isManager && equipment.responsibleEquipment">
            <mat-checkbox [value]="equipment.notificationResposibleEq" [checked]="equipment.notificationResposibleEq"
              (change)="onChangeNotificationResponsible($event)">
              {{ 'internalEquipmentEdit.general.notification.resposible.equipment.label' | translate }}
            </mat-checkbox>
          </div>
        </div>
      </div>

    </mat-card-content>
  </mat-card>

  <mat-card class="card" *ngIf="showCalibrationPlan()">
    <mat-card-content>
      <mat-toolbar color="secondary">
        {{ 'internalEquipmentEdit.other.data.title' | translate }}
      </mat-toolbar>

      <div fxLayout="row" fxLayout.xs="column" class="flexwrap" [hidden]="equipment.customFields == null">
        <div fxFlex="50" fxFlex.lt-md="100%" *ngFor="let field of equipment.customFields; let indexField = index">

          <!-- STRING -->
          <div *ngIf="field.idType == 1">
            <mat-form-field class="campoFormulario">
              <input matInput placeholder="{{ field.name }}" [name]="field.name" [(ngModel)]="field.value"
                [errorStateMatcher]="matcher" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
          </div>

          <!-- NUMÉRICO -->
          <div *ngIf="field.idType == 2">
            <mat-form-field class="campoFormulario">
              <input matInput placeholder="{{ field.name }}" [name]="field.name" [(ngModel)]="field.value"
                [ngModelOptions]="{standalone: true}" [errorStateMatcher]="matcher"
                (ngModelChange)="setValue(field, -1, $event)" type="number" [required]="field.required">
            </mat-form-field>
          </div>

          <!-- TOLERANCIA -->
          <div *ngIf="field.idType == 3">
            <div fxFlex="40%" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ field.name }}" [name]="field.name" [ngModelOptions]="{standalone: true}"
                  (ngModelChange)="setValue(field, 0, $event)" [errorStateMatcher]="matcher" type="number">
              </mat-form-field>
            </div>
            <div fxFlex="40%" fxFlex.lt-md="100%">
              ±
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'protocolEdit.dialog.essay.valueTolerance.label' | translate }}"
                  [name]="field.name + 'Tolerance'" (ngModelChange)="setValue(field, 1, $event)"
                  [errorStateMatcher]="matcher" type="number" [ngModelOptions]="{standalone: true}">
              </mat-form-field>
            </div>
          </div>

          <!-- BOOLEAN -->
          <div *ngIf="field.idType == 8">
            <mat-checkbox [value]="field.value" [checked]="field.value == 'true'"
              (change)="setValue(field, -1, $event)">
              {{ field.name }}
            </mat-checkbox>
          </div>

          <!-- SELECT ONE -->
          <div *ngIf="field.idType == 6">
            <mat-form-field class="campoFormulario">
              <mat-label>{{ field.name }}</mat-label>
              <mat-select [name]="'idSelectCustom'+field.id" [(ngModel)]="field.value">
                <mat-option *ngFor="let sel of field.valueSelect" [value]="sel.value">
                  {{sel.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="equipment.customFields?.length == 0"
          style="margin-top: 2em;margin-bottom: 2em;">
          {{ 'protocolEdit.dialog.essay.fields.empty' | translate }}
        </div>
      </div>
    </mat-card-content>

  </mat-card>

  <div>
    <mat-card class="card" *ngIf="isEdit || isInternal">
      <mat-card-content>
        <mat-toolbar color="secondary">
          {{ 'internalEquipmentEdit.dataGeneral.title' | translate }}
        </mat-toolbar>

        <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
          <mat-accordion style="width: 100%;">
            <mat-expansion-panel *ngIf="isCalibration" (opened)="isDataCalibrationOpened = true"
              (closed)="isDataCalibrationOpened = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ 'internalEquipmentEdit.calibrationData.title' | translate}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
                  <mat-form-field class="campoFormulario"
                    matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                    [matTooltipDisabled]="hasCalibrationPlan()">
                    <input matInput name="equipmentRange" [(ngModel)]="equipmentCalibration.equipmentRange"
                      placeholder="{{ 'internalEquipmentEdit.general.equipmentRange.label' | translate }}"
                      [disabled]="!hasCalibrationPlan()">
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
                  <mat-form-field class="campoFormulario"
                    matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                    [matTooltipDisabled]="hasCalibrationPlan()">
                    <input matInput name="useRange" [(ngModel)]="equipmentCalibration.useRange"
                      placeholder="{{ 'internalEquipmentEdit.general.useRange.label' | translate }}"
                      [disabled]="!hasCalibrationPlan()">
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
                  <mat-form-field class="campoFormulario"
                    matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                    [matTooltipDisabled]="hasCalibrationPlan()">
                    <input matInput name="resolution" [(ngModel)]="equipmentCalibration.resolution"
                      placeholder="{{ 'internalEquipmentEdit.general.resolution.label' | translate }}"
                      [disabled]="!hasCalibrationPlan()">
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
                  <mat-form-field class="campoFormulario"
                    matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                    [matTooltipDisabled]="hasCalibrationPlan()">
                    <input matInput name="tolerance" [(ngModel)]="equipmentCalibration.tolerance"
                      placeholder="{{ 'internalEquipmentEdit.general.tolerance.label' | translate }}"
                      [disabled]="!hasCalibrationPlan()">
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
                  <mat-form-field class="campoFormulario"
                    matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                    [matTooltipDisabled]="hasCalibrationPlan()">
                    <input type="number" matInput name="calibrationFrequency"
                      [(ngModel)]="equipmentCalibration.frequency" required
                      placeholder="{{ 'internalEquipmentEdit.general.calibrationFrequency.label' | translate }}"
                      [disabled]="!hasCalibrationPlan()">
                  </mat-form-field>
                </div>

                <!--<div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isEdit">
                  <mat-form-field style="width: 95%;" matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                    [matTooltipDisabled]="hasCalibrationPlan()">
                    <input matInput [matDatepicker]="acquisitionDate" name="acquisitionDate"
                      [(ngModel)]="equipmentCalibration.acquisitionDate" (change)="saveDate('acquisitionDate', $event)"
                      placeholder="{{ 'internalEquipmentEdit.general.acquisitionDate.label' | translate }}"
                      [disabled]="!hasCalibrationPlan()">
                    <mat-datepicker-toggle matSuffix [for]="acquisitionDate"></mat-datepicker-toggle>
                    <mat-datepicker #acquisitionDate [calendarHeaderComponent]="customDatepickerHeader">
                    </mat-datepicker>
                  </mat-form-field>
                </div>-->

                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
                  <mat-form-field class="campoFormulario"
                    matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                    [matTooltipDisabled]="hasCalibrationPlan()">
                    <mat-label>{{ 'internalEquipmentEdit.general.calibrationResponsible.label' | translate
                      }}</mat-label>
                    <mat-select name="calibrationResponsibles" [(ngModel)]="equipmentCalibration.responsible"
                      [required]="isInternal" (selectionChange)="onChangeResponsibleCheck($event, 1)"
                      [disabled]="!hasCalibrationPlan()">
                      <mat-option *ngFor="let item of calibrationResponsibles" [value]="item.id">
                        {{ 'calibrationResponsibleType.' + item.translation | translate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
                  <mat-form-field class="campoFormulario"
                    matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                    [matTooltipDisabled]="hasCalibrationPlan()">
                    <input matInput name="supplier" [(ngModel)]="equipmentCalibration.supplier"
                      [disabled]="disablesSupplier()"
                      placeholder="{{ 'internalEquipmentEdit.general.supplier.label' | translate }}">
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
                  <mat-form-field class="campoFormulario">
                    <input matInput name="fee" [(ngModel)]="equipmentCalibration.fee" type="number" min="0"
                      placeholder="{{ 'internalEquipmentEdit.general.fee.label' | translate }}"
                      matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                      [matTooltipDisabled]="hasCalibrationPlan()" [disabled]="!hasCalibrationPlan()">
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="isVerification" (opened)="isDataVerificationOpened = true"
              (closed)="isDataVerificationOpened = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ 'internalEquipmentEdit.verificationData.title' | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
                  <mat-form-field class="campoFormulario"
                    matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                    [matTooltipDisabled]="hasCalibrationPlan()">
                    <input matInput name="equipmentRangeVerification" [(ngModel)]="equipmentVerification.equipmentRange"
                      placeholder="{{ 'internalEquipmentEdit.general.equipmentRange.label' | translate }}"
                      [disabled]="!hasCalibrationPlan()">
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
                  <mat-form-field class="campoFormulario"
                    matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                    [matTooltipDisabled]="hasCalibrationPlan()">
                    <input matInput name="useRangeVerification" [(ngModel)]="equipmentVerification.verificationPoint"
                      placeholder="{{ 'internalEquipmentEdit.general.pointVerificacion.label' | translate }}"
                      [disabled]="!hasCalibrationPlan()">
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
                  <mat-form-field class="campoFormulario"
                    matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                    [matTooltipDisabled]="hasCalibrationPlan()">
                    <input matInput name="resolutionVerification" [(ngModel)]="equipmentVerification.resolution"
                      placeholder="{{ 'internalEquipmentEdit.general.resolution.label' | translate }}"
                      [disabled]="!hasCalibrationPlan()">
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
                  <mat-form-field class="campoFormulario"
                    matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                    [matTooltipDisabled]="hasCalibrationPlan()">
                    <input matInput name="toleranceVerification" [(ngModel)]="equipmentVerification.tolerance"
                      placeholder="{{ 'internalEquipmentEdit.general.tolerance.label' | translate }}"
                      [disabled]="!hasCalibrationPlan()">
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
                  <mat-form-field class="campoFormulario"
                    matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                    [matTooltipDisabled]="hasCalibrationPlan()">
                    <input type="number" matInput name="verificationFrequency"
                      [(ngModel)]="equipmentVerification.frequency" required
                      placeholder="{{ 'internalEquipmentEdit.general.verificationFrequency.label' | translate }}"
                      [disabled]="!hasCalibrationPlan()">
                  </mat-form-field>
                </div>

                <!--<div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isEdit">
                  <mat-form-field style="width: 95%;" matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                    [matTooltipDisabled]="hasCalibrationPlan()">
                    <input matInput [matDatepicker]="acquisitionDate" name="acquisitionDateVerification"
                      [(ngModel)]="equipmentVerification.acquisitionDate" (change)="saveDate('acquisitionDate', $event)"
                      placeholder="{{ 'internalEquipmentEdit.general.acquisitionDate.label' | translate }}"
                      [disabled]="!hasCalibrationPlan()">
                    <mat-datepicker-toggle matSuffix [for]="acquisitionDate"></mat-datepicker-toggle>
                    <mat-datepicker #acquisitionDate [calendarHeaderComponent]="customDatepickerHeader">
                    </mat-datepicker>
                  </mat-form-field>
                </div>-->

                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
                  <mat-form-field class="campoFormulario"
                    matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                    [matTooltipDisabled]="hasCalibrationPlan()">
                    <mat-label>{{ 'internalEquipmentEdit.general.verificationResponsible.label' | translate
                      }}</mat-label>
                    <mat-select name="verificationResponsibles" [(ngModel)]="equipmentVerification.responsible"
                      [required]="isInternal" (selectionChange)="onChangeResponsibleCheck($event, 2)"
                      [disabled]="!hasCalibrationPlan()">
                      <mat-option *ngFor="let item of calibrationResponsibles" [value]="item.id">
                        {{ 'calibrationResponsibleType.' + item.translation | translate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
                  <mat-form-field class="campoFormulario"
                    matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                    [matTooltipDisabled]="hasCalibrationPlan()">
                    <input matInput name="supplierVerification" [(ngModel)]="equipmentVerification.supplier"
                      [disabled]="disablesSupplier()"
                      placeholder="{{ 'internalEquipmentList.filter.verificationSupplier.label' | translate }}">
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
                  <mat-form-field class="campoFormulario">
                    <input matInput name="verificationFee" [(ngModel)]="equipmentVerification.fee" type="number" min="0"
                      placeholder="{{ 'internalEquipmentEdit.general.verificationFee.label' | translate }}"
                      matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
                      [matTooltipDisabled]="hasCalibrationPlan()" [disabled]="!hasCalibrationPlan()">
                  </mat-form-field>
                </div>

              </div>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="isMaintenance" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{'internalEquipmentEdit.maintenanceData.title' | translate}}
                </mat-panel-title>
                <mat-panel-description>
                  {{'internalEquipmentEdit.notApplicable.dataGeneral.title' | translate}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <!-- <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isEdit" >
                  <mat-form-field style="width: 95%;">
                    <input matInput [matDatepicker]="maintenanceDate" name="maintenanceProcessDate"
                      [ngModel]="equipmentMaintenance.processDate"
                      (ngModelChange)="saveDateMaintenance('processDate', $event)"
                      placeholder="{{ 'internalEquipmentEdit.general.maintenanceCalibrateDate.label' | translate }}">
                    <mat-datepicker-toggle matSuffix [for]="expirationMaintenanceDate"></mat-datepicker-toggle>
                    <mat-datepicker #expirationMaintenanceDate [calendarHeaderComponent]="customDatepickerHeader">
                    </mat-datepicker>
                  </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isEdit">
                  <mat-form-field style="width: 95%;">
                    <input matInput [matDatepicker]="maintenanceExpirationDate" name="maintenanceExpirationDate"
                      [ngModel]="equipmentMaintenance.expirationDate"
                      (ngModelChange)="saveDateMaintenance('expirationDate', $event)"
                      placeholder="{{ 'internalEquipmentEdit.general.maintenanceExpirationDate.label' | translate }}">
                    <mat-datepicker-toggle matSuffix [for]="maintenanceExpirationDate"></mat-datepicker-toggle>
                    <mat-datepicker #maintenanceExpirationDate [calendarHeaderComponent]="customDatepickerHeader">
                    </mat-datepicker>
                  </mat-form-field>
                </div>
              </div>-->
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="isQualification" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{'internalEquipmentEdit.qualificacioneData.title' | translate}}
                </mat-panel-title>
                <mat-panel-description>
                  {{'internalEquipmentEdit.notApplicable.dataGeneral.title' | translate}}
                </mat-panel-description>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="isCharacterization" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{'internalEquipmentEdit.characterizationData.title' | translate}}
                </mat-panel-title>
                <mat-panel-description>
                  {{'internalEquipmentEdit.notApplicable.dataGeneral.title' | translate}}
                </mat-panel-description>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <mat-card class="card">
    <mat-card-content>
      <mat-toolbar color="secondary">
        {{ 'internalEquipmentEdit.equipment.title' | translate }}

        <button mat-icon-button color="accent" (click)="newExternalCalibration()"
          matTooltip="{{ 'internalEquipmentEdit.register.body.title.create' | translate }}"
          *ngIf="equipment.id != null && canSave()">
          <mat-icon>add</mat-icon>
        </button>
      </mat-toolbar>

      <div *ngIf="!calibrations || calibrations?.length == 0">
        {{ 'common.noResults' | translate }}
      </div>

      <mat-accordion>
        <mat-expansion-panel *ngFor="let calibration of calibrations; let indexCalibration = index"
          (opened)="setCalibrationOpened(indexCalibration, true)" [expanded]="isCalibrationOpened(indexCalibration)"
          (closed)="setCalibrationOpened(indexCalibration, false)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon color="warn" *ngIf="isNotValid(calibration)">close</mat-icon>
              {{ (calibration.actionType === 1 ? 'internalEquipmentEdit.calibrations.title.title' :
              calibration.actionType === 2 ? 'internalEquipmentEdit.verification.title.title' :
              calibration.actionType === 3 ? 'internalEquipmentEdit.maintenance.title.title':
              calibration.actionType === 4 ? 'internalEquipmentEdit.qualificacion.title.title' :
              calibration.actionType === 5 ? 'internalEquipmentEdit.characterization.title.title' : '') | translate : {
              date: (calibration.calibrationDate | fixDate) } }}
              ({{calibration.internal ? ('common.internal' | translate) : ('common.external' | translate)}})

              <div *ngIf="isCalibrationOpened(indexCalibration)">
                <button mat-icon-button (click)="editExternalCalibration(calibration.id)" color="accent"
                  matTooltip="{{ 'internalEquipmentEdit.register.body.title.edit' | translate }}" class="centerButton"
                  *ngIf="!calibration.internal">
                  <mat-icon>mode_edit</mat-icon>
                </button>
                <button mat-icon-button (click)="goToCalibration(calibration.id, calibration.actionType)" color="accent"
                  matTooltip="{{ 'internalEquipmentEdit.register.body.title.edit' | translate }}" class="centerButton"
                  *ngIf="calibration.internal">
                  <mat-icon>mode_edit</mat-icon>
                </button>
                <button mat-icon-button (click)="deleteExternalCalibrationWithReason(calibration.id)" color="accent"
                  matTooltip="{{ 'internalEquipmentEdit.register.body.title.delete' | translate }}" class="centerButton"
                  *ngIf="!calibration.internal">
                  <mat-icon>delete</mat-icon>
                </button>
                <button mat-icon-button *ngIf="calibration.idDocument != null || calibration.idCertificate != null"
                  (click)="downloadCertificateEquipment(indexCalibration)" color="accent"
                  matTooltip="{{ 'button.download-certificate' | translate }}" class="centerButton">
                  <mat-icon>cloud_download</mat-icon>
                </button>
                <button mat-icon-button
                  *ngIf="calibration.internal && calibration.idDocument == null && calibration.idCertificate == null"
                  (click)="downloadCertificateLastCertificateCalibration(indexCalibration)" color="accent"
                  matTooltip="{{ 'button.download-certificate' | translate }}" class="centerButton">
                  <mat-icon>cloud_download</mat-icon>
                </button>

                <button mat-icon-button (click)="uploadCertificateCalibration.click()" color="accent"
                  *ngIf="isInternal && equipment.id != null" matTooltip="{{ 'button.upload-certificate' | translate }}"
                  class="centerButton">
                  <mat-icon>cloud_upload</mat-icon>
                </button>

                <button mat-icon-button (click)="deleteCertificateToCalibration(indexCalibration)" color="accent"
                  *ngIf="isInternal && equipment.id != null && (calibration.idCertificate != null || calibration.idDocument != null)"
                  matTooltip="{{ 'button.delete-certificate' | translate }}" class="centerButton">
                  <mat-icon>cloud_off</mat-icon>
                </button>
              </div>

              <input accept="application/pdf" hidden type="file"
                (change)="uploadCertificateToCalibration(indexCalibration, $event)" #uploadCertificateCalibration>

            </mat-panel-title>
            <mat-panel-description>
              {{ (calibration.actionType === 1 ? (calibration.regUser  ? 'internalEquipmentEdit.calibrations.title.subtitle' : 'internalEquipmentEdit.calibrations.without.user.title.subtitle'):
              calibration.actionType === 2 ? (calibration.regUser  ? 'internalEquipmentEdit.verification.title.subtitle': 'internalEquipmentEdit.verification.without.user.title.subtitle'):
              calibration.actionType === 3 ? (calibration.regUser  ? 'internalEquipmentEdit.maintenance.title.subtitle': 'internalEquipmentEdit.maintenance.without.user.title.subtitle'):
              calibration.actionType === 4 ? (calibration.regUser  ? 'internalEquipmentEdit.qualificacion.title.subtitle': 'internalEquipmentEdit.qualificacion.without.user.title.subtitle'):
              calibration.actionType === 5 ? (calibration.regUser  ? 'internalEquipmentEdit.characterization.title.subtitle' : 'internalEquipmentEdit.characterization.without.user.title.subtitle'):'') | translate: {
              certificateNum:
              calibration.certificateNum, regUser: calibration.regUser} }}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div fxLayout="column">

            <span fxFlex>{{ calibration.actionType === 1 ? ('internalEquipmentEdit.calibrations.body.calibrationDate' |
              translate) :
              calibration.actionType === 2 ? ('internalEquipmentEdit.verification.body.calibrationDate' | translate) :
              calibration.actionType === 3 ? ('internalEquipmentEdit.maintenance.body.calibrationDate' | translate) :
              calibration.actionType === 4 ? ('internalEquipmentEdit.qualificacion.body.calibrationDate' | translate) :
              calibration.actionType === 5 ? ('internalEquipmentEdit.characterization.body.calibrationDate' | translate)
              : '' }}:
              {{ calibration.calibrationDate | fixDate }}</span>
            <span fxFlex>{{ calibration.actionType === 1 ? ('internalEquipmentEdit.calibrations.body.expirationDate' |
              translate ) :
              calibration.actionType === 2 ? ('internalEquipmentEdit.verification.body.expirationDate' | translate) :
              calibration.actionType === 3 ? ('internalEquipmentEdit.maintenance.body.expirationDate' | translate) :
              calibration.actionType === 4 ? ('internalEquipmentEdit.qualificacion.body.expirationDate' | translate) :
              calibration.actionType === 5 ? ('internalEquipmentEdit.characterization.body.expirationDate' | translate)
              :''}}:
              {{ calibration.expirationDate | fixDate }}</span>
            <span *ngIf="calibration.certificateNum " fxFlex>{{
              calibration.actionType === 4 ?
              ('internalEquipmentEdit.qualificacion.body.certificateNum' | translate) :
              calibration.actionType === 5 ?
              ('internalEquipmentEdit.characterization.body.certificateNum' | translate) :
              ('internalEquipmentEdit.calibrations.body.certificateNum' | translate) }}:
              {{ calibration.certificateNum }}</span>
            <span *ngIf="calibration.executionNum " fxFlex>{{ calibration.actionType === 4 ?
              ('internalEquipmentEdit.qualificacion.body.executionNum' | translate) :
              ('internalEquipmentEdit.calibrations.body.certificateNum' | translate) }}:
              {{ calibration.executionNum }}</span>

          </div>

          <span *ngIf="calibration.internal">
            <br />
          </span>

          <div fxLayout="column" *ngIf="calibration.actionType === 1">
            <span fxFlex *ngIf="calibration.internal">
              {{ 'internalEquipmentEdit.calibrations.body.temperature' | translate }}:
              {{ calibration.temperature != null ? calibration.temperature : ('common.notApplicable' | translate) }}
            </span>
            <span fxFlex *ngIf="calibration.internal">
              {{ 'internalEquipmentEdit.calibrations.body.humidity' | translate }}:
              {{ calibration.humidity != null ? calibration.humidity : ('common.notApplicable' | translate) }}
            </span>
            <span fxFlex *ngIf="calibration.internal">
              {{ 'internalEquipmentEdit.calibrations.body.pressure' | translate }}:
              {{ calibration.pressure != null ? calibration.pressure : ('common.notApplicable' | translate) }}
            </span>
          </div>

          <div fxLayout="column" *ngIf="calibration.actionType === 1">

            <div *ngFor="let variable of calibration.variables">
              <br />
              <strong>
                {{ 'internalEquipmentEdit.calibrations.body.results.title' | translate: { variable: ('variable.' +
                variable.variableTranslation | translate), measurementUnit: variable.unitName ? variable.unitName : ''} }}
              </strong>

              <div *ngIf="!calibration.multichannel">
                <table #asLeftTable mat-table
                  [dataSource]="calibration.internal ? getAsLeftList(variable) : variable.values" style="width: 100%;">

                  <!-- Number Column -->
                  <ng-container matColumnDef="number">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element; let i = index"> {{ (i + 1) }} </td>
                  </ng-container>

                  <!-- Point Column -->
                  <ng-container matColumnDef="point">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ 'calibrateEquipmentEdit.asLeft.cols.point.label' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{ element.point }} </td>
                  </ng-container>

                  <!-- Pattern Column -->
                  <ng-container matColumnDef="pattern">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ 'calibrateEquipmentEdit.asLeft.cols.pattern.label' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{ calibration.internal ? element.pattern : null }} </td>
                  </ng-container>

                  <!-- Average Column -->
                  <ng-container matColumnDef="average">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ 'calibrateEquipmentEdit.asLeft.cols.average.label' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      <span [matTooltip]="element.average">
                        {{ calibration.internal ?
                        ( element.average | number:'1.' + (getDecimalsResolution(variable.resolution) + 1) + '-' +
                        (getDecimalsResolution(variable.resolution) + 1) ) :
                        (element.average) }}
                      </span>
                    </td>
                  </ng-container>

                  <!-- Correction Column -->
                  <ng-container matColumnDef="correction">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ 'calibrateEquipmentEdit.asLeft.cols.correction.label' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      {{ calibration.internal ?
                      ( element.correction | number:'1.' + getDecimalsPattern(element.pattern) + '-' +
                      getDecimalsPattern(element.pattern) ) :
                      (element.correction) }}
                    </td>
                  </ng-container>

                  <!-- U Column -->
                  <ng-container matColumnDef="U">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ 'calibrateEquipmentEdit.uncertainties.cols.U.label' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      {{ calibration.internal ?
                      ( element.U | number:'1.' + getDecimalsUncertainty(element.uncertaintyResolution) + '-' +
                      getDecimalsUncertainty(element.uncertaintyResolution) ) :
                      (element.uncertainty) }}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="(calibration.internal ? asLeftColsInt : asLeftColsExt)"></tr>
                  <tr mat-row *matRowDef="let row; columns: (calibration.internal ? asLeftColsInt : asLeftColsExt);">
                  </tr>
                </table>

              </div>
              <div *ngIf="calibration.multichannel">
                <mat-card>
                  <mat-card-content>

                    <mat-tab-group style="width: 100%;">

                      <mat-tab *ngFor="let multichannel of variable.valuesMultichannel; let indexChannel = index"
                        style="min-height: 50vh;" label="{{ multichannel.description }}">
                        <mat-card class="card">
                          <mat-card-content>
                            <div fxLayout="column" class="flexwrap">
                              <div fxFlex>
                                <table #asLeftTableMultiChannel mat-table
                                  [dataSource]="getAsLeftListMultichannel(variable, indexChannel)" style="width: 100%;">

                                  <!-- Number Column -->
                                  <ng-container matColumnDef="number">
                                    <th mat-header-cell *matHeaderCellDef> </th>
                                    <td mat-cell *matCellDef="let element; let i = index"> {{ (i + 1) }} </td>
                                  </ng-container>

                                  <!-- Point Column -->
                                  <ng-container matColumnDef="point">
                                    <th mat-header-cell *matHeaderCellDef>
                                      {{ 'calibrateEquipmentEdit.asLeft.cols.point.label' | translate }} </th>
                                    <td mat-cell *matCellDef="let element"> {{ element.point }} </td>
                                  </ng-container>

                                  <!-- Pattern Column -->
                                  <ng-container matColumnDef="pattern">
                                    <th mat-header-cell *matHeaderCellDef>
                                      {{ 'calibrateEquipmentEdit.asLeft.cols.pattern.label' | translate }} </th>
                                    <td mat-cell *matCellDef="let element"> {{ element.pattern }} </td>
                                  </ng-container>

                                  <!-- Values Column -->
                                  <ng-container matColumnDef="value">
                                    <th mat-header-cell *matHeaderCellDef>
                                      {{ 'calibrateEquipmentEdit.asLeft.cols.value.label' | translate }} </th>
                                    <td mat-cell *matCellDef="let element"> {{ joinListValues(indexVariable, element) }}
                                    </td>
                                  </ng-container>

                                  <!-- Average Column -->
                                  <ng-container matColumnDef="average">
                                    <th mat-header-cell *matHeaderCellDef>
                                      {{ 'calibrateEquipmentEdit.asLeft.cols.average.label' | translate }} </th>
                                    <td mat-cell *matCellDef="let element">
                                      <span [matTooltip]="element.average">
                                        {{ element.average | number:'1.' + (getDecimalsResolution(variable.resolution) +
                                        1) + '-' +
                                        (getDecimalsResolution(variable.resolution) + 1) }}
                                      </span>
                                    </td>
                                  </ng-container>

                                  <!-- Correction Column -->
                                  <ng-container matColumnDef="correction">
                                    <th mat-header-cell *matHeaderCellDef>
                                      {{ 'calibrateEquipmentEdit.asLeft.cols.correction.label' | translate }} </th>
                                    <td mat-cell *matCellDef="let element">
                                      <span [matTooltip]="element.correction">
                                        {{ element.correction | number:'1.' + getDecimalsPattern(element.pattern) + '-'
                                        +
                                        getDecimalsPattern(element.pattern) }}
                                      </span>
                                    </td>
                                  </ng-container>

                                  <!-- U Column -->
                                  <ng-container matColumnDef="U">
                                    <th mat-header-cell *matHeaderCellDef>
                                      {{ 'calibrateEquipmentEdit.uncertainties.cols.U.label' | translate }} </th>
                                    <td mat-cell *matCellDef="let element">
                                      <span [matTooltip]="element.U">
                                        {{element.U | number:'1.' +
                                        getDecimalsUncertainty(element.uncertaintyResolution) + '-' +
                                        getDecimalsUncertainty(element.uncertaintyResolution)}}
                                      </span>
                                    </td>
                                  </ng-container>

                                  <tr mat-header-row *matHeaderRowDef="asLeftColsInt"></tr>
                                  <tr mat-row *matRowDef="let row; columns: asLeftColsInt;"></tr>
                                </table>

                              </div>

                            </div>

                          </mat-card-content>
                        </mat-card>
                      </mat-tab>
                    </mat-tab-group>
                  </mat-card-content>
                </mat-card>
              </div>

            </div>
          </div>

        </mat-expansion-panel>

      </mat-accordion>

    </mat-card-content>
  </mat-card>
  <mat-card class="card" *ngIf="isEdit && isInternal">
    <mat-card-content>
      <mat-toolbar color="secondary">
        {{ 'internalEquipmentEdit.status.title' | translate }}
      </mat-toolbar>

      <div fxLayout="column" fxLayout.xs="column" class="flexwrap" *ngIf="isEdit || isInternal">
        <div fxLayout="row" class="status-action-title">
          <div fxFlex="20">
            <span></span>
          </div>
          <div fxFlex="20" style="text-align: center;">
            <span>{{'internalEquipmentEdit.status.certificate.title' | translate}}</span>
          </div>
          <div fxFlex="20" style="text-align: center;">
            <span>{{'internalEquipmentEdit.status.realizationDate.title' | translate}}</span>
          </div>
          <div fxFlex="20" style="text-align: center;">
            <span>{{'internalEquipmentEdit.status.nextDate.title' | translate}}</span>
          </div>
          <div fxFlex="20" style="text-align: center;">
            <span>{{'internalEquipmentEdit.status.dateExpired.title' | translate}}</span>
          </div>
          <div fxFlex="20" style="text-align: center;">
            <span>{{'internalEquipmentEdit.status.actionStaus.title' | translate}}</span>
          </div>
          <div fxFlex="10" style="text-align: center;"></div>
        </div>
        <div *ngIf="isCalibration && equipmentCalibration" class="status-action"
          [ngClass]="highlight(equipmentCalibration)">
          <div fxFlex="20">
            <span>{{'internalEquipmentEdit.status.calibration.title' | translate}}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentCalibration.documentNumber ? equipmentCalibration.documentNumber: '--'}}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentCalibration.documentNumber ?  (equipmentCalibration.processDate | fixDateUTC) : '--'}}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentCalibration.documentNumber ?  (equipmentCalibration.expirationDate | fixDateUTC) : (equipmentCalibration.processDate | fixDateUTC) }}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentCalibration.documentNumber ? (calculateNextActivity(1) | fixDateUTC) : (equipmentCalibration.expirationDate | fixDateUTC) }}</span>
          </div>
          <div fxFlex="20">
            <span>{{descriptionStatusActions(equipmentCalibration.idStatus)}}</span>
          </div>
          <div fxFlex="10">
            <button mat-icon-button *ngIf="equipmentCalibration.documentNumber == null" (click)="editAndDeleteRowPerfomances(1, true)" matTooltip="{{ 'button.edit' | translate }}">
              <mat-icon>mode_edit</mat-icon>
            </button>
          </div>
        </div>

        <div *ngIf="isVerification && equipmentVerification" class="status-action"
          [ngClass]="highlight(equipmentVerification)">
          <div fxFlex="20">
            <span>{{'internalEquipmentEdit.status.verification.title' | translate}}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentVerification.documentNumber ? equipmentVerification.documentNumber: '--' }}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentVerification.documentNumber ? (equipmentVerification.processDate | fixDateUTC): '--'}}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentVerification.documentNumber ?  (equipmentVerification.expirationDate | fixDateUTC) : (equipmentVerification.processDate | fixDateUTC) }}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentVerification.documentNumber ? (calculateNextActivity(2) | fixDateUTC) : (equipmentVerification.expirationDate | fixDateUTC) }}</span>
          </div>
          <div fxFlex="20">
            <span>{{descriptionStatusActions(equipmentVerification.idStatus)}}</span>
          </div>
          <div fxFlex="10">
            <button mat-icon-button *ngIf="equipmentCalibration.documentNumber == null" (click)="editAndDeleteRowPerfomances(2, true)" matTooltip="{{ 'button.edit' | translate }}">
              <mat-icon>mode_edit</mat-icon>
            </button>
          </div>
        </div>

        <div *ngIf="isMaintenance && equipmentMaintenance" class="status-action"
          [ngClass]="highlight(equipmentMaintenance)">
          <div fxFlex="20">
            <span>{{'internalEquipmentEdit.status.maintenance.title' | translate}}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentMaintenance.documentNumber ? equipmentMaintenance.documentNumber: '--' }}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentMaintenance.documentNumber ? (equipmentMaintenance.processDate | fixDateUTC) : '--'}}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentMaintenance.documentNumber ?  (equipmentMaintenance.expirationDate | fixDateUTC) : (equipmentMaintenance.processDate | fixDateUTC) }}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentMaintenance.documentNumber ? (calculateNextActivity(3) | fixDateUTC) : (equipmentMaintenance.expirationDate | fixDateUTC) }}</span>
          </div>
          <div fxFlex="20">
            <span>{{descriptionStatusActions(equipmentMaintenance.idStatus)}}</span>
          </div>
          <div fxFlex="10">
            <button mat-icon-button *ngIf="equipmentMaintenance.documentNumber == null" (click)="editAndDeleteRowPerfomances(3, true)" matTooltip="{{ 'button.edit' | translate }}">
              <mat-icon>mode_edit</mat-icon>
            </button>
          </div>
        </div>

        <div *ngIf="isQualification && equipmentQualification" class="status-action"
          [ngClass]="highlight(equipmentQualification)">
          <div fxFlex="20">
            <span>{{'internalEquipmentEdit.status.qualificacion.title' | translate}}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentQualification.documentNumber ? equipmentQualification.documentNumber : '--' }}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentQualification.documentNumber ? (equipmentQualification.processDate | fixDateUTC) : '--'}}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentQualification.documentNumber ?  (equipmentQualification.expirationDate | fixDateUTC) : (equipmentQualification.processDate | fixDateUTC) }}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentQualification.documentNumber ? (calculateNextActivity(4) | fixDateUTC) : (equipmentQualification.expirationDate | fixDateUTC) }}</span>
          </div>
          <div fxFlex="20">
            <span>{{descriptionStatusActions(equipmentQualification.idStatus)}}</span>
          </div>
          <div fxFlex="10">
            <button mat-icon-button *ngIf="equipmentQualification.documentNumber == null" (click)="editAndDeleteRowPerfomances(4, true)" matTooltip="{{ 'button.edit' | translate }}">
              <mat-icon>mode_edit</mat-icon>
            </button>
          </div>
        </div>

        <div *ngIf="isCharacterization && equipmentCharacterization" class="status-action"
          [ngClass]="highlight(equipmentQualification)">
          <div fxFlex="20">
            <span>{{'internalEquipmentEdit.status.characterization.title' | translate}}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentCharacterization.documentNumber ? equipmentCharacterization.documentNumber : '--'}}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentCharacterization.documentNumber ? (equipmentCharacterization.processDate | fixDateUTC) : '--'}}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentCharacterization.documentNumber ? (equipmentCharacterization.expirationDate | fixDateUTC) : (equipmentCharacterization.processDate | fixDateUTC) }}</span>
          </div>
          <div fxFlex="20">
            <span>{{equipmentCharacterization.documentNumber ? (calculateNextActivity(5) | fixDateUTC) : (equipmentCharacterization.expirationDate | fixDateUTC) }}</span>
          </div>
          <div fxFlex="20">
            <span>{{descriptionStatusActions(equipmentCharacterization.idStatus)}}</span>
          </div>
          <div fxFlex="10">
            <button mat-icon-button *ngIf="equipmentCharacterization.documentNumber == null" (click)="editAndDeleteRowPerfomances(5, true)" matTooltip="{{ 'button.edit' | translate }}">
              <mat-icon>mode_edit</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="card">
    <mat-card-content>
      <mat-toolbar color="secondary">
        {{ 'internalEquipmentEdit.observations.title' | translate }}
      </mat-toolbar>

      <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

        <div fxFlex="33" fxFlex.lt-md="100%">
          <textarea matInput placeholder="{{ 'internalEquipmentEdit.general.observations.label' | translate }}"
            [(ngModel)]="equipment.observations" name="observations" style="resize: none;">
            </textarea>
        </div>

      </div>

    </mat-card-content>
  </mat-card>



  <mat-card class="card">
    <mat-card-actions>
      <div fxFlex></div>
      <button mat-raised-button color="primary" *ngIf="pendingReview" (click)="reviewEquipment()">
        {{ 'button.approve' | translate }}</button>
      <button mat-raised-button color="primary" *ngIf="pendingReview" (click)="decline()">
        {{ 'button.decline' | translate }}</button>
      <button mat-raised-button color="primary" *ngIf="canSave()" (click)="saveEquipment()">
        {{ 'button.save' | translate }}</button>
      <button mat-raised-button color="accent" *ngIf="equipment.idCertificate != null && !pendingReview"
        (click)="downloadPdf()">
        {{ 'button.download-certificate' | translate }}</button>
      <button mat-raised-button color="accent" (click)="downloadAuditPdf()" *ngIf="equipment.id != null">
        {{ 'button.download-audit' | translate }}</button>
      <button mat-raised-button color="secondary" (click)="cancel()">{{ 'button.cancel' | translate }}</button>
    </mat-card-actions>
  </mat-card>

</form>