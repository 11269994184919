<mat-tab-group (selectedTabChange)="onTabChange($event)">
  <mat-tab label="{{ 'calibrateEquipmentEdit.calibrationData.title' | translate }}">

    <form>

      <mat-card class="card">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'calibrateEquipmentEdit.calibrationData.title' | translate }}
          </mat-toolbar>

          <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

            <div fxFlex="33" fxFlex.lt-md="100%">
              <div fxLayout="column" class="flexwrap">

                <div fxFlex *ngIf="calibration.id == null">
                  <mat-form-field class="campoFormulario">
                    <mat-label>{{ 'calibrateEquipmentList.filter.client.label' | translate }}</mat-label>
                    <mat-select #clientSelect name="idClientSelected" [(ngModel)]="idClientSelected">
                      <mat-select-filter *ngIf="clientSelect.focused" [displayMember]="'name'" [array]="clients"
                        (filteredReturn)="clientsFiltered =$event" [noResultsMessage]="'common.noResults' | translate"
                        [placeholder]="'common.selectFilterPlaceholder' | translate"></mat-select-filter>
                      <mat-option value="-1"> -- {{ 'calibrationResponsibleType.internal' | translate | uppercase }} --
                      </mat-option>
                      <mat-option *ngFor="let client of clientsFiltered" [value]="client.id">
                        {{client.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div fxFlex *ngIf="calibration.id == null && idClientSelected != null">
                  <mat-form-field class="campoFormulario">
                    <input matInput [ngModel]="equipment.name" #trigger="matAutocompleteTrigger"
                      [matAutocomplete]="auto" (keyup)="lookupEquipment($event)" [ngModelOptions]="{standalone: true}"
                      placeholder="{{ 'calibrateEquipmentEdit.patterns.dialog.equipment.label' | translate }}">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
                      (optionSelected)="onSensorChange($event, trigger)">
                      <mat-option *ngFor="let item of equipmentAutoComplete; let index = index" [value]="item">
                        {{ item.name}} ({{item.serialNum}})
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error>
                      {{ 'calibrateEquipmentEdit.patterns.dialog.equipment.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex *ngIf="calibration.id == null && idClientSelected != null">
                  <a class="under-card-link" style="cursor: pointer;margin: inherit;" (click)="createEquipment()">
                    {{ 'calibrateEquipmentEdit.general.equipment.notExists' | translate }}
                  </a>
                </div>

                <div fxFlex *ngIf="calibration.id != null">
                  <mat-form-field class="campoFormulario">
                    <input matInput placeholder="{{ 'calibrateEquipmentEdit.general.name.label' | translate }}"
                      name="name" [(ngModel)]="equipment.name" disabled>
                    <mat-error>
                      {{ 'calibrateEquipmentEdit.general.name.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex *ngIf="equipment.id != null">
                  <mat-form-field class="campoFormulario">
                    <input matInput placeholder="{{ 'calibrateEquipmentEdit.general.equipment.label' | translate }}"
                      name="equipment" [(ngModel)]="equipment.equipment" disabled>
                    <mat-error>
                      {{ 'calibrateEquipmentEdit.general.equipment.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex *ngIf="equipment.id != null">
                  <mat-form-field class="campoFormulario">
                    <input matInput placeholder="{{ 'calibrateEquipmentEdit.general.maker.label' | translate }}"
                      name="maker" [(ngModel)]="equipment.maker" disabled>
                    <mat-error>
                      {{ 'calibrateEquipmentEdit.general.maker.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex *ngIf="equipment.id != null">
                  <mat-form-field class="campoFormulario">
                    <input matInput placeholder="{{ 'calibrateEquipmentEdit.general.model.label' | translate }}"
                      name="model" [(ngModel)]="equipment.model" disabled>
                    <mat-error>
                      {{ 'calibrateEquipmentEdit.general.model.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex *ngIf="equipment.id != null">
                  <mat-form-field class="campoFormulario">
                    <input matInput placeholder="{{ 'calibrateEquipmentEdit.general.serialNum.label' | translate }}"
                      name="serialNum" [(ngModel)]="equipment.serialNum" disabled>
                    <mat-error>
                      {{ 'calibrateEquipmentEdit.general.serialNum.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>

              </div>
            </div>

            <div fxFlex="33" fxFlex.lt-md="100%">

              <div fxLayout="column" class="flexwrap">

                <div fxFlex>
                  <mat-form-field class="campoFormulario">
                    <input matInput
                      placeholder="{{ 'calibrateEquipmentEdit.general.certificateNum.label' | translate }}"
                      name="certificateNum" [(ngModel)]="calibration.certificateNum" [disabled]="isEditable()">
                    <mat-error>
                      {{ 'calibrateEquipmentEdit.general.certificateNum.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex>
                  <mat-form-field style="width: 95%;">
                    <input matInput [matDatepicker]="calibrateDate" name="calibrationDate"
                      [ngModel]="calibration.calibrationDate" (blur)="saveDate('calibrationDate', $event)"
                      (dateChange)="saveDate('calibrationDate', $event)" [disabled]="isEditable()"
                      [max]="maxDateCalibration"
                      placeholder="{{ 'calibrateEquipmentEdit.general.calibrateDate.label' | translate }}">
                    <mat-datepicker-toggle matSuffix [for]="calibrateDate"></mat-datepicker-toggle>
                    <mat-datepicker #calibrateDate [calendarHeaderComponent]="customDatepickerHeader">
                    </mat-datepicker>
                    <mat-error>
                      {{ 'calibrateEquipmentEdit.general.calibrateDate.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex>
                  <mat-form-field style="width: 95%;">
                    <input matInput [matDatepicker]="expirationDate" name="expirationDate"
                      [ngModel]="calibration.expirationDate" (blur)="saveDate('expirationDate', $event)"
                      (dateChange)="saveDate('expirationDate', $event)" [disabled]="isEditable()"
                      placeholder="{{ 'calibrateEquipmentEdit.general.expirationDate.label' | translate }}">
                    <mat-datepicker-toggle matSuffix [for]="expirationDate"></mat-datepicker-toggle>
                    <mat-datepicker #expirationDate [calendarHeaderComponent]="customDatepickerHeader">
                    </mat-datepicker>
                    <mat-error>
                      {{ 'calibrateEquipmentEdit.general.expirationDate.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex *ngIf="calibration.id != null">
                  <mat-checkbox name="showExpirationDate" [(ngModel)]="calibration.showExpirationDate">
                    {{'calibrateEquipmentEdit.general.showExpirationDate.label' | translate }}
                  </mat-checkbox>
                </div>

                <div fxFlex>
                  <mat-form-field class="campoFormulario">
                    <input matInput placeholder="{{ 'calibrateEquipmentEdit.general.projectNumber.label' | translate }}"
                      name="projectNo" [(ngModel)]="calibration.projectNo" [disabled]="isEditable()">
                  </mat-form-field>
                </div>

                <div fxFlex *ngIf="calibration.id != null">
                  <mat-form-field class="campoFormulario">
                    <mat-label>{{ 'calibrateEquipmentEdit.general.status.label' | translate }}</mat-label>
                    <mat-select name="idStatus" [(ngModel)]="calibration.idStatus" disabled>
                      <mat-option *ngFor="let item of statuses" [value]="item.id">
                        {{ 'calibrationStatus.' + item.translation | translate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div fxFlex *ngIf="calibration.currentVersion != '0' && calibration.currentVersion != null">
                  <div fxLayout="row">
                    <div fxFlex="50">
                      <mat-form-field class="campoFormulario">
                        <input matInput
                          placeholder="{{ 'calibrateEquipmentEdit.general.currentVersion.label' | translate }}"
                          name="currentVersion" [ngModel]="calibration.currentVersion" disabled>
                      </mat-form-field>
                    </div>
                    <div fxFlex="50">
                      <mat-form-field class="campoFormulario">
                        <input matInput
                          placeholder="{{ 'calibrateEquipmentEdit.general.signedVersion.label' | translate }}"
                          name="signedVersion" [ngModel]="calibration.signedVersion" disabled>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

              </div>

            </div>

            <div fxFlex="33" fxFlex.lt-md="100%" *ngIf="equipment.id != null">
              <div fxLayout="column" class="flexwrap">

                <div fxFlex>
                  <mat-form-field style="width: 95%;">
                    <input matInput name="clientName" [value]="clientName" disabled
                      placeholder="{{ 'calibrateEquipmentEdit.client.name.label' | translate }}">
                  </mat-form-field>
                </div>

                <div fxFlex>
                  <textarea matInput placeholder="{{ 'calibrateEquipmentEdit.client.address.label' | translate }}"
                    [value]="clientAddress" name="clientAddress" style="resize: none;" disabled> </textarea>
                </div>
              </div>

            </div>

          </div>

        </mat-card-content>
      </mat-card>

      <mat-card class="card" *ngIf="calibration.id != null">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'calibrateEquipmentEdit.environmentalConditions.title' | translate }}
          </mat-toolbar>

          <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

            <div fxFlex="20" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <mat-label>{{ 'calibrateEquipmentEdit.calibrationData.place.label' | translate }}</mat-label>
                <mat-select required name="idPlace" [(ngModel)]="calibration.idPlace" [disabled]="isEditable()">
                  <mat-option *ngFor="let item of places" [value]="item.id">
                    {{ 'calibrationPlace.' + item.translation | translate }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{ 'calibrateEquipmentEdit.calibrationData.place.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="20" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput
                  placeholder="{{ 'calibrateEquipmentEdit.environmentalConditions.temperature.label' | translate }}"
                  name="temperature" [(ngModel)]="calibration.temperature" [disabled]="isEditable()">
                <mat-error>
                  {{ 'calibrateEquipmentEdit.environmentalConditions.temperature.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="20" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput
                  placeholder="{{ 'calibrateEquipmentEdit.environmentalConditions.humidity.label' | translate }}"
                  name="humidity" [(ngModel)]="calibration.humidity" [disabled]="isEditable()">
                <mat-error>
                  {{ 'calibrateEquipmentEdit.environmentalConditions.humidity.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="20" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput
                  placeholder="{{ 'calibrateEquipmentEdit.environmentalConditions.pressure.label' | translate }}"
                  name="pressure" [(ngModel)]="calibration.pressure" [disabled]="isEditable()">
                <mat-error>
                  {{ 'calibrateEquipmentEdit.environmentalConditions.pressure.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="30" fxFlex.lt-md="100%">
              {{ 'calibrateEquipmentEdit.calibrationData.variables.label' | translate }}:
              <mat-select multiple [(ngModel)]="variablesSelected" name="idsVariable" [disabled]="isEditable()">
                <mat-option *ngFor="let item of variables" checkboxPosition="before" [value]="item"
                  (onSelectionChange)="onChangeVariableCheck($event)">
                  {{ 'variable.' + item.translation | translate }}
                </mat-option>
              </mat-select>
            </div>

            <div fxFlex="20" fxFlex.lt-md="100%" style="align-content: center; padding: 10px;">
              <mat-checkbox name="multichannel" [(ngModel)]="calibration.multichannel" [disabled]="isEditable()">
                {{'calibrateEquipmentEdit.general.multichannel.label' | translate }}
              </mat-checkbox>
            </div>
          </div>

        </mat-card-content>
      </mat-card>

      <mat-card class="card" *ngIf="calibration.id != null">
        <mat-card-content>

          <mat-tab-group style="width: 100%;" (selectedTabChange)="onVariableTabChange($event)">

            <mat-tab *ngFor="let variable of calibration.variables; let indexVariable = index" style="min-height: 50vh;"
              label="{{'variable.' + variable.variableTranslation | translate }}">

              <mat-card class="card">
                <mat-card-content>
                  <mat-toolbar color="secondary">
                    {{ 'calibrateEquipmentEdit.general.title' | translate : {variable: ('variable.' +
                    variable.variableTranslation | translate) } }}
                  </mat-toolbar>

                  <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                    <div fxFlex="33" fxFlex.lt-md="100%">
                      <mat-form-field class="campoFormulario">
                        <mat-label>{{ 'calibrateEquipmentEdit.calibrationData.unit.label' | translate }}</mat-label>
                        <mat-select name="unit{{variable.idVariable}}" [(ngModel)]="variable.idUnit" required
                          (selectionChange)="onUnitChange(indexVariable, $event)" [disabled]="isEditable()">
                          <mat-option *ngFor="let item of variableUnits" [value]="item.id">
                            {{item.unit}}
                          </mat-option>
                        </mat-select>
                        <mat-error>
                          {{ 'calibrateEquipmentEdit.calibrationData.unit.error' | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxFlex="33" fxFlex.lt-md="100%">
                      <mat-form-field class="campoFormulario">
                        <mat-label>{{ 'calibrateEquipmentEdit.calibrationData.procedure.label' | translate }}
                        </mat-label>
                        <mat-select required name="idProcedure{{variable.idVariable}}"
                          [(ngModel)]="variable.idProcedure" [disabled]="isEditable()">
                          <mat-option *ngFor="let item of procedures" [value]="item.id">
                            {{ item.name }} ({{ item.description }})
                          </mat-option>
                        </mat-select>
                        <mat-error>
                          {{ 'calibrateEquipmentEdit.calibrationData.procedure.error' | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                  </div>

                  <div fxLayout="row" fxLayout.xs="column" class="flexwrap" *ngIf="showExtraField(variable)">

                    <div fxFlex="33" fxFlex.lt-md="100%">
                      <mat-form-field class="campoFormulario">
                        <mat-label>{{ 'calibrateEquipmentEdit.calibrationData.idUnitInput.label' | translate }}
                        </mat-label>
                        <mat-select name="idUnitInput{{variable.idVariable}}" [(ngModel)]="variable.idUnitInput"
                          required [disabled]="isEditable()">
                          <mat-option *ngFor="let item of variableUnits" [value]="item.id">
                            {{item.unit}}
                          </mat-option>
                        </mat-select>
                        <mat-error>
                          {{ 'calibrateEquipmentEdit.calibrationData.idUnitInput.error' | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxFlex="17" fxFlex.lt-md="100%">
                      <mat-form-field class="campoFormulario">
                        <input matInput name="extraField{{variable.idVariable}}" [(ngModel)]="variable.extraField"
                          placeholder="{{ 'calibrateEquipmentEdit.calibrationData.' + getExtraFieldName(variable) + '.label' | translate }}"
                          [disabled]="isEditable()">
                        <mat-error>
                          {{ 'calibrateEquipmentEdit.calibrationData.' + getExtraFieldName(variable) + '.error' |
                          translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxFlex="16" fxFlex.lt-md="100%">
                      <mat-form-field class="campoFormulario">
                        <mat-label>{{ 'calibrateEquipmentEdit.calibrationData.idUnitExtra.label' | translate }}
                        </mat-label>
                        <mat-select name="idUnitExtra{{variable.idVariable}}" [(ngModel)]="variable.idUnitExtra"
                          required [disabled]="isEditable()">
                          <mat-option *ngFor="let item of variableUnitsExtra" [value]="item.id">
                            {{item.unit}}
                          </mat-option>
                        </mat-select>
                        <mat-error>
                          {{ 'calibrateEquipmentEdit.calibrationData.idUnitExtra.error' | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card class="card" *ngIf="calibration.multichannel">
                <mat-card-content>
                  <mat-toolbar color="secondary">
                    {{ 'calibrateEquipmentEdit.multichannel.general.title' | translate }}
                  </mat-toolbar>

                  <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                    <div fxFlex="100" fxFlex.lt-md="100%">
                      <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
                        <div fxFlex="33" fxFlex.lt-md="100%" *ngIf="calibration.multichannel">
                          <mat-form-field class="campoFormulario">
                            <input matInput type="number"
                              placeholder="{{ 'calibrateEquipmentEdit.environmentalConditions.channelNumber.label' | translate }}"
                              name="channelNumber" [ngModel]="calibration.channelNumber" [disabled]="isEditable()"
                              (ngModelChange)="onCountMultichannel(indexVariable, $event)" (blur)="onCountMultichannel(indexVariable)">
                            <mat-error>
                              {{ 'calibrateEquipmentEdit.environmentalConditions.channelNumber.error' | translate }}
                            </mat-error>
                          </mat-form-field>
                        </div>
    
                        <div fxFlex="10" fxFlex.lt-md="100%" *ngIf="variable?.valuesMultichannel?.length > 0">
                          <button mat-icon-button (click)="openDialogMultichannel(indexVariable)" *ngIf="!isEditable()">
                            <mat-icon>mode_edit</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card class="card" *ngIf="showAmbientConditions(indexVariable)">
                <mat-card-content>
                  <mat-toolbar color="secondary">
                    {{ 'calibrateEquipmentEdit.ambientConditions.title' | translate }}
                  </mat-toolbar>

                  <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
                    <div fxFlex="20" fxFlex.lt-md="100%">
                      <mat-form-field class="campoFormulario">
                        <input matInput
                          placeholder="{{ 'calibrateEquipmentEdit.ambientConditions.pressureUsed.label' | translate }}"
                          name="pressureUsed" [(ngModel)]="variable.ambientConditions.pressureUsed"
                          [disabled]="isEditable()">
                        <mat-error>
                          {{ 'calibrateEquipmentEdit.ambientConditions.pressureUsed.error' | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxFlex="20" fxFlex.lt-md="100%">
                      <mat-form-field class="campoFormulario">
                        <input matInput
                          placeholder="{{ 'calibrateEquipmentEdit.ambientConditions.temperature.label' | translate }}"
                          name="particleTemperature" [(ngModel)]="variable.ambientConditions.temperature"
                          [disabled]="isEditable()">
                        <mat-error>
                          {{ 'calibrateEquipmentEdit.ambientConditions.temperature.error' | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxFlex="20" fxFlex.lt-md="100%">
                      <mat-form-field class="campoFormulario">
                        <input matInput
                          placeholder="{{ 'calibrateEquipmentEdit.ambientConditions.oilUsed.label' | translate }}"
                          name="oilUsed" [(ngModel)]="variable.ambientConditions.oilUsed" [disabled]="isEditable()">
                        <mat-error>
                          {{ 'calibrateEquipmentEdit.ambientConditions.oilUsed.error' | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card class="card" *ngIf="!showAmbientConditions(indexVariable) || !calibration.multichannel">
                <mat-card-content>
                  <mat-toolbar color="secondary">
                    {{ 'calibrateEquipmentEdit.calibrationData.title' | translate }}

                    <button mat-icon-button color="accent" (click)="newConfigRow(indexVariable)" *ngIf="!isEditable()">
                      <mat-icon>add</mat-icon>
                    </button>
                  </mat-toolbar>

                  <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                    <table #configTable mat-table matSort [dataSource]="variable.config" style="width: 100%;">

                      <!-- range Column -->
                      <ng-container matColumnDef="range">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ 'calibrateEquipmentEdit.config.cols.range' | translate }} </th>
                        <td mat-cell *matCellDef="let item">
                          <div style="white-space: pre-line;">
                            <span *ngIf="item.rangeInit != null && item.rangeEnd != null">
                              {{ item.rangeInit }} - {{ item.rangeEnd }} {{variable.unitName}}
                            </span>
                            <span *ngIf="item.rangeInit != null && item.rangeEnd == null">
                              {{ item.rangeInit }} {{variable.unitName}}
                            </span>
                          </div>
                        </td>
                      </ng-container>

                      <!-- resolution Column -->
                      <ng-container matColumnDef="resolution">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ 'calibrateEquipmentEdit.config.cols.resolution' | translate }} </th>
                        <td mat-cell *matCellDef="let item">
                          <div style="white-space: pre-line;"> {{ item.resolution }} </div>
                        </td>
                      </ng-container>

                      <!-- tolerance Column -->
                      <ng-container matColumnDef="tolerance">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ 'calibrateEquipmentEdit.config.cols.tolerance' | translate }} </th>
                        <td mat-cell *matCellDef="let item">
                          <div style="white-space: pre-line;"> {{ item.tolerance }} </div>
                        </td>
                      </ng-container>

                      <!-- uncertaintyResolution Column -->
                      <ng-container matColumnDef="uncertaintyResolution">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ 'calibrateEquipmentEdit.config.cols.uncertaintyResolution' | translate }} </th>
                        <td mat-cell *matCellDef="let item">
                          <div style="white-space: pre-line;"> {{ item.uncertaintyResolution }} </div>
                        </td>
                      </ng-container>

                      <!-- Values Column -->
                      <ng-container matColumnDef="values">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ 'calibrateEquipmentEdit.asLeft.cols.value.label' | translate }} </th>
                        <td mat-cell *matCellDef="let element"> {{ joinListValuesConfig(element) }} </td>
                      </ng-container>

                      <!-- Button edit Column -->
                      <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
                        <td mat-cell *matCellDef="let element; let i = index">
                          <button mat-icon-button (click)="editConfigRow(indexVariable, i)" *ngIf="!isEditable()">
                            <mat-icon>mode_edit</mat-icon>
                          </button>
                        </td>
                      </ng-container>

                      <!-- Button delete Column -->
                      <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
                        <td mat-cell *matCellDef="let element; let i = index">
                          <button mat-icon-button (click)="deleteConfigRow(indexVariable, i)" *ngIf="!isEditable()">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="configCols"></tr>
                      <tr mat-row *matRowDef="let row; columns: configCols;"></tr>

                    </table>

                  </div>

                </mat-card-content>
              </mat-card>

              <mat-card class="card" *ngIf="!showAmbientConditions(indexVariable) || !calibration.multichannel">
                <mat-card-content>
                  <mat-toolbar color="secondary">
                    {{ 'calibrateEquipmentEdit.pattern.title' | translate }}

                    <button mat-icon-button color="accent" (click)="newPatternRow(indexVariable)" *ngIf="!isEditable()">
                      <mat-icon>add</mat-icon>
                    </button>
                  </mat-toolbar>

                  <div fxLayout="column" class="flexwrap">
                    <div fxFlex>

                      <table #patternsTable mat-table matSort [dataSource]="variable.patterns" style="width: 100%;">

                        <!-- name Column -->
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.pattern.cols.name' | translate }} </th>
                          <td mat-cell *matCellDef="let item">
                            <div style="white-space: pre-line;"> {{ item.equipment.name }} </div>
                          </td>
                        </ng-container>

                        <!-- equipment Column -->
                        <ng-container matColumnDef="equipment">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.pattern.cols.equipment' | translate }}
                          </th>
                          <td mat-cell *matCellDef="let item">
                            <div style="white-space: pre-line;"> {{ item.equipment.equipment }} </div>
                          </td>
                        </ng-container>

                        <!-- maker Column -->
                        <ng-container matColumnDef="maker">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'calibrateEquipmentEdit.pattern.cols.maker' | translate }}
                          </th>
                          <td mat-cell *matCellDef="let item"> {{item.equipment.maker}} </td>
                        </ng-container>

                        <!-- model Column -->
                        <ng-container matColumnDef="model">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'calibrateEquipmentEdit.pattern.cols.model' | translate }} </th>
                          <td mat-cell *matCellDef="let item"> {{item.equipment.model}} </td>
                        </ng-container>

                        <!-- serialNum Column -->
                        <ng-container matColumnDef="serialNum">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.pattern.cols.serialNum' | translate }}
                          </th>
                          <td mat-cell *matCellDef="let item">{{ item.equipment.serialNum }} </td>
                        </ng-container>

                        <!-- uncertainty Column -->
                        <ng-container matColumnDef="uncertainty">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.pattern.cols.uncertainty' | translate }}
                          </th>
                          <td mat-cell *matCellDef="let item">{{ getWorstUncertainty(item) }} </td>
                        </ng-container>

                        <!-- Button edit Column -->
                        <ng-container matColumnDef="edit">
                          <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <button mat-icon-button (click)="editPatternRow(indexVariable, i)" *ngIf="!isEditable()">
                              <mat-icon>mode_edit</mat-icon>
                            </button>
                          </td>
                        </ng-container>

                        <!-- Button delete Column -->
                        <ng-container matColumnDef="delete">
                          <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <button mat-icon-button (click)="deletePatternRow(indexVariable, i)" *ngIf="!isEditable()">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="patternsCols"></tr>
                        <tr mat-row *matRowDef="let row; columns: patternsCols;"></tr>

                      </table>
                    </div>

                  </div>

                </mat-card-content>
              </mat-card>

              <mat-card class="card" *ngIf="!showAmbientConditions(indexVariable) && !calibration.multichannel">
                <mat-card-content>
                  <mat-toolbar color="secondary">
                    {{ 'calibrateEquipmentEdit.asFound.title' | translate }}

                    <button mat-icon-button color="accent" (click)="newAsFoundRow(indexVariable)" *ngIf="!isEditable()">
                      <mat-icon>add</mat-icon>
                    </button>
                  </mat-toolbar>

                  <div fxLayout="column" class="flexwrap">

                    <div fxFlex>
                      <table #asFoundTable mat-table [dataSource]="variable.asFound" style="width: 100%;">

                        <!-- Number Column -->
                        <ng-container matColumnDef="number">
                          <th mat-header-cell *matHeaderCellDef> </th>
                          <td mat-cell *matCellDef="let element; let i = index"> {{ (i + 1) }} </td>
                        </ng-container>

                        <!-- Point Column -->
                        <ng-container matColumnDef="point">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.asFound.cols.point.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element"> {{ element.point }} </td>
                        </ng-container>

                        <!-- Pattern Column -->
                        <ng-container matColumnDef="pattern">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.asFound.cols.pattern.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element"> {{ element.pattern }} </td>
                        </ng-container>

                        <!-- Equipment Column -->
                        <ng-container matColumnDef="equipment">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.asFound.cols.equipment.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.equipment | number:'1.' + getDecimalsResolution(indexVariable, element.point) +
                            '-' + getDecimalsResolution(indexVariable, element.point) }}
                          </td>
                        </ng-container>

                        <!-- Correction Column -->
                        <ng-container matColumnDef="correction">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.asFound.cols.correction.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element"> {{ element.correction }} </td>
                        </ng-container>

                        <!-- Button edit Column -->
                        <ng-container matColumnDef="edit">
                          <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <button mat-icon-button (click)="editAsFoundRow(indexVariable, i)" *ngIf="!isEditable()">
                              <mat-icon>mode_edit</mat-icon>
                            </button>
                          </td>
                        </ng-container>

                        <!-- Button delete Column -->
                        <ng-container matColumnDef="delete">
                          <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <button mat-icon-button (click)="deleteAsFoundRow(indexVariable, i)" *ngIf="!isEditable()">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="asFoundCols"></tr>
                        <tr mat-row *matRowDef="let row; columns: asFoundCols;"></tr>
                      </table>
                    </div>

                  </div>

                </mat-card-content>
              </mat-card>

              <mat-card class="card" *ngIf="!showAmbientConditions(indexVariable) && !calibration.multichannel">
                <mat-card-content>
                  <mat-toolbar color="secondary">
                    {{ 'calibrateEquipmentEdit.asLeft.title' | translate }}
                  </mat-toolbar>

                  <div fxLayout="column" class="flexwrap">
                    <div fxFlex>
                      <table #asLeftTable mat-table [dataSource]="getAsLeftList(indexVariable)" style="width: 100%;">

                        <!-- Number Column -->
                        <ng-container matColumnDef="number">
                          <th mat-header-cell *matHeaderCellDef> </th>
                          <td mat-cell *matCellDef="let element; let i = index"> {{ (i + 1) }} </td>
                        </ng-container>

                        <!-- Point Column -->
                        <ng-container matColumnDef="point">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.asLeft.cols.point.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element"> {{ element.point }} </td>
                        </ng-container>

                        <!-- Pattern Column -->
                        <ng-container matColumnDef="pattern">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.asLeft.cols.pattern.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element"> {{ element.pattern }} </td>
                        </ng-container>

                        <!-- Values Column -->
                        <ng-container matColumnDef="value">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.asLeft.cols.value.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element"> {{ joinListValues(indexVariable, element) }} </td>
                        </ng-container>

                        <!-- Average Column -->
                        <ng-container matColumnDef="average">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.asLeft.cols.average.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element">
                            <span [matTooltip]="element.average">
                              {{ element.average | number:'1.' + (getDecimalsResolution(indexVariable, element.point) +
                              1) + '-' + (getDecimalsResolution(indexVariable, element.point) + 1) }}
                            </span>
                          </td>
                        </ng-container>

                        <!-- Correction Column -->
                        <ng-container matColumnDef="correction">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.asLeft.cols.correction.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element">
                            <span [matTooltip]="element.correction">
                              {{ element.correction | number:'1.' + getDecimalsPattern(element.pattern) + '-' +
                              getDecimalsPattern(element.pattern) }}
                            </span>
                          </td>
                        </ng-container>

                        <!-- U Column -->
                        <ng-container matColumnDef="U">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.uncertainties.cols.U.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element">
                            <span [matTooltip]="element.U">
                              {{ element.U | number:'1.' + getDecimalsUncertainty(indexVariable, element.point) + '-' +
                              getDecimalsUncertainty(indexVariable, element.point) }}
                            </span>
                          </td>
                        </ng-container>

                        <!-- cPlusU Column -->
                        <ng-container matColumnDef="cPlusU">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.asLeft.cols.cPlusU.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element">
                            <span [class]="styleCPlusU(indexVariable, element.point, element.cPlusU)"
                              [matTooltip]="element.cPlusU">
                              {{ element.cPlusU | number:'1.' + getDecimalsUncertainty(indexVariable, element.point) +
                              '-' + getDecimalsUncertainty(indexVariable, element.point) }}
                            </span>
                          </td>
                        </ng-container>

                        <!-- Button see Column -->
                        <ng-container matColumnDef="edit">
                          <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <button mat-icon-button *ngIf="!isEditable()"
                              (click)="editAsLeftRow(indexVariable, element.indexConfig, i)">
                              <mat-icon>mode_edit</mat-icon>
                            </button>
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="asLeftCols"></tr>
                        <tr mat-row *matRowDef="let row; columns: asLeftCols;"></tr>
                      </table>

                    </div>

                  </div>

                </mat-card-content>
              </mat-card>

              <mat-card class="card" *ngIf="!showAmbientConditions(indexVariable)&& !calibration.multichannel">
                <mat-card-content>
                  <mat-toolbar color="secondary">
                    {{ 'calibrateEquipmentEdit.uncertainties.title' | translate }}
                  </mat-toolbar>

                  <div fxLayout="column" class="flexwrap">
                    <div fxFlex>
                      <table #uncertaintiesTable mat-table [dataSource]="getAsLeftList(indexVariable)"
                        style="width: 100%;">

                        <!-- Number Column -->
                        <ng-container matColumnDef="number">
                          <th mat-header-cell *matHeaderCellDef> </th>
                          <td mat-cell *matCellDef="let element; let i = index"> {{ (i + 1) }} </td>
                        </ng-container>

                        <!-- Point Column -->
                        <ng-container matColumnDef="point">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.uncertainties.cols.average.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <span [matTooltip]="element.average">
                              {{ element.average | number:'1.' + getDecimalsResolution(indexVariable, element.point) +
                              '-' + getDecimalsResolution(indexVariable, element.point) }}
                            </span>
                          </td>
                        </ng-container>

                        <!-- uRep Column -->
                        <ng-container matColumnDef="uRep">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.uncertainties.cols.uRep.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element">
                            <span [matTooltip]="element.uRep">
                              {{ element.uRep | number:'1.' + getDecimalsUncertainty(indexVariable, element.point) + '-'
                              + getDecimalsUncertainty(indexVariable, element.point) }}
                            </span>
                          </td>
                        </ng-container>

                        <!-- uResol Column -->
                        <ng-container matColumnDef="uResol">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.uncertainties.cols.uResol.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element">
                            <span [matTooltip]="element.uResol">
                              {{ element.uResol | number:'1.' + getDecimalsUncertainty(indexVariable, element.point) +
                              '-' + getDecimalsUncertainty(indexVariable, element.point) }}
                            </span>
                          </td>
                        </ng-container>

                        <!-- uPattern Column -->
                        <ng-container matColumnDef="uPattern">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.uncertainties.cols.uPattern.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element"> {{ joinListUP(indexVariable, element) }} </td>
                        </ng-container>

                        <!-- uUnif Column -->
                        <ng-container matColumnDef="uUnif">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.uncertainties.cols.uUnif.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element">
                            <span [matTooltip]="element.uUnif">
                              {{ element.uUnif != null ? element.uUnif : 'common.notApplicable' | translate }}
                            </span>
                          </td>
                        </ng-container>

                        <!-- uEstab Column -->
                        <ng-container matColumnDef="uEstab">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.uncertainties.cols.uEstab.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element">
                            <span [matTooltip]="element.uEstab">
                              {{ element.uEstab | number:'1.' + getDecimalsUncertainty(indexVariable, element.point) +
                              '-' + getDecimalsUncertainty(indexVariable, element.point) }}
                            </span>
                          </td>
                        </ng-container>

                        <!-- uc Column -->
                        <ng-container matColumnDef="uc">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.uncertainties.cols.uc.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element">
                            <span [matTooltip]="element.uC">
                              {{ element.uC | number:'1.' + getDecimalsUncertainty(indexVariable, element.point) + '-' +
                              getDecimalsUncertainty(indexVariable, element.point) }}
                            </span>
                          </td>
                        </ng-container>

                        <!-- neff Column -->
                        <ng-container matColumnDef="neff">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.uncertainties.cols.neff.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element">
                            <span [matTooltip]="element.neff">
                              {{ element.neff | number:'1.' + getDecimalsUncertainty(indexVariable, element.point) + '-'
                              + getDecimalsUncertainty(indexVariable, element.point) }}
                            </span>
                          </td>
                        </ng-container>

                        <!-- k Column -->
                        <ng-container matColumnDef="k">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.uncertainties.cols.k.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element">
                            <span [matTooltip]="element.k">
                              {{ element.k }}
                            </span>
                          </td>
                        </ng-container>

                        <!-- U Column -->
                        <ng-container matColumnDef="U">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.uncertainties.cols.U.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element">
                            <span [matTooltip]="element.U">
                              {{ element.U | number:'1.' + getDecimalsUncertainty(indexVariable, element.point) + '-' +
                              getDecimalsUncertainty(indexVariable, element.point) }}
                            </span>
                          </td>
                        </ng-container>

                        <!-- measureUnit Column -->
                        <ng-container matColumnDef="measureUnit">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ 'calibrateEquipmentEdit.uncertainties.cols.measureUnit.label' | translate }} </th>
                          <td mat-cell *matCellDef="let element"> {{ variable.unitName }} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="uncertaintiesCols"></tr>
                        <tr mat-row *matRowDef="let row; columns: uncertaintiesCols;"></tr>
                      </table>
                    </div>

                  </div>

                </mat-card-content>
              </mat-card>

              <mat-card class="card" *ngIf="showAmbientConditions(indexVariable) && !calibration.multichannel">
                <mat-card-content>
                  <mat-toolbar color="secondary">
                    {{ 'calibrateEquipmentEdit.ambientConditions.particleContainer.title' | translate }}
                  </mat-toolbar>

                  <div fxLayout="column">
                    <div fxFlex>
                      <div>
                        <button mat-raised-button color="primary" (click)="openAmbientCalibration(indexVariable)">
                          {{ 'button.upload-excel' | translate }} </button>
                      </div>
                    </div>
                    <div *ngIf="variable.valuesParticles != null && variable?.valuesParticles.length > 0">
                      <br />
                      <strong>{{ 'calibrateEquipmentEdit.ambientConditions.dataParticlesAmbient.result' | translate
                        }}</strong>
                      <div *ngFor="let size of variable?.valuesParticles"
                        [class]="getValidateAmbientParticleNum(indexVariable)">
                        {{ '0.3' }}: {{ size?.zeroThree }}
                        <br />
                        {{ '1.0' }}: {{ size?.oneZero }}
                        <br />
                        {{ 'calibrateEquipmentEdit.ambientConditions.table.porcentaje' | translate }}: {{
                        getSizeAmbientParticleNum(indexVariable) | number: '1.2-2'}} %
                        <br />
                        {{ 'calibrateEquipmentEdit.ambientConditions.table.criteriaAceptation' | translate }}: {{
                        criteriaAceptationParticles }}%

                      </div>
                    </div>

                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card *ngIf="calibration.multichannel && variable.config">
                <mat-card-content>
                  <mat-toolbar color="secondary">
                    {{ 'calibrateEquipmentEdit.multichannel.title' | translate }}
                  </mat-toolbar>

                  <mat-tab-group style="width: 100%;" (selectedTabChange)="onVariableTabChangeMultichannel($event)">

                    <mat-tab *ngFor="let multichannel of variable.valuesMultichannel; let indexChannel = index"
                      style="min-height: 50vh;" label="{{ multichannel.description }}">

                      <mat-card class="card" *ngIf="calibration.multichannel">
                        <mat-card-content>
                          <mat-toolbar color="secondary">
                            {{ 'calibrateEquipmentEdit.asFound.title' | translate }}

                            <button mat-icon-button color="accent" (click)="newAsFoundRowMultichannel(indexVariable, indexChannel)"
                              *ngIf="!isEditable()">
                              <mat-icon>add</mat-icon>
                            </button>
                          </mat-toolbar>

                          <div fxLayout="column" class="flexwrap">

                            <div fxFlex>
                              <table #asFoundTableMultiChannel mat-table [dataSource]="multichannel.asFound" style="width: 100%;">

                                <!-- Number Column -->
                                <ng-container matColumnDef="number">
                                  <th mat-header-cell *matHeaderCellDef> </th>
                                  <td mat-cell *matCellDef="let element; let i = index"> {{ (i + 1) }} </td>
                                </ng-container>

                                <!-- Point Column -->
                                <ng-container matColumnDef="point">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.asFound.cols.point.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element"> {{ element.point }} </td>
                                </ng-container>

                                <!-- Pattern Column -->
                                <ng-container matColumnDef="pattern">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.asFound.cols.pattern.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element"> {{ element.pattern }} </td>
                                </ng-container>

                                <!-- Equipment Column -->
                                <ng-container matColumnDef="equipment">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.asFound.cols.equipment.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element">
                                    {{ element.equipment | number:'1.' + getDecimalsResolution(indexVariable,
                                    element.point)
                                    +
                                    '-' + getDecimalsResolution(indexVariable, element.point) }}
                                  </td>
                                </ng-container>

                                <!-- Correction Column -->
                                <ng-container matColumnDef="correction">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.asFound.cols.correction.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element"> {{ element.correction }} </td>
                                </ng-container>

                                <!-- Button edit Column -->
                                <ng-container matColumnDef="edit">
                                  <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
                                  <td mat-cell *matCellDef="let element; let i = index">
                                    <button mat-icon-button (click)="editAsFoundRowMultichannel(indexVariable, i, indexChannel)"
                                      *ngIf="!isEditable()">
                                      <mat-icon>mode_edit</mat-icon>
                                    </button>
                                  </td>
                                </ng-container>

                                <!-- Button delete Column -->
                                <ng-container matColumnDef="delete">
                                  <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
                                  <td mat-cell *matCellDef="let element; let i = index">
                                    <button mat-icon-button (click)="deleteAsFoundRowMultichannel(indexVariable, i, indexChannel)"
                                      *ngIf="!isEditable()">
                                      <mat-icon>delete</mat-icon>
                                    </button>
                                  </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="asFoundCols"></tr>
                                <tr mat-row *matRowDef="let row; columns: asFoundCols;"></tr>
                              </table>
                            </div>

                          </div>

                        </mat-card-content>
                      </mat-card>

                      <mat-card class="card">
                        <mat-card-content>
                          <mat-toolbar color="secondary">
                            {{ 'calibrateEquipmentEdit.asLeft.title' | translate }}
                          </mat-toolbar>

                          <div fxLayout="column" class="flexwrap">
                            <div fxFlex>
                              <table #asLeftTableMultiChannel mat-table
                                [dataSource]="getAsLeftListMultichannel(indexVariable, indexChannel)"
                                style="width: 100%;">

                                <!-- Number Column -->
                                <ng-container matColumnDef="number">
                                  <th mat-header-cell *matHeaderCellDef> </th>
                                  <td mat-cell *matCellDef="let element; let i = index"> {{ (i + 1) }} </td>
                                </ng-container>

                                <!-- Point Column -->
                                <ng-container matColumnDef="point">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.asLeft.cols.point.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element"> {{ element.point }} </td>
                                </ng-container>

                                <!-- Pattern Column -->
                                <ng-container matColumnDef="pattern">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.asLeft.cols.pattern.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element"> {{ element.pattern }} </td>
                                </ng-container>

                                <!-- Values Column -->
                                <ng-container matColumnDef="value">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.asLeft.cols.value.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element"> {{ joinListValues(indexVariable, element) }}
                                  </td>
                                </ng-container>

                                <!-- Average Column -->
                                <ng-container matColumnDef="average">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.asLeft.cols.average.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element">
                                    <span [matTooltip]="element.average">
                                      {{ element.average | number:'1.' + (getDecimalsResolution(indexVariable,
                                      element.point) +
                                      1) + '-' + (getDecimalsResolution(indexVariable, element.point) + 1) }}
                                    </span>
                                  </td>
                                </ng-container>

                                <!-- Correction Column -->
                                <ng-container matColumnDef="correction">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.asLeft.cols.correction.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element">
                                    <span [matTooltip]="element.correction">
                                      {{ element.correction | number:'1.' + getDecimalsPattern(element.pattern) + '-' +
                                      getDecimalsPattern(element.pattern) }}
                                    </span>
                                  </td>
                                </ng-container>

                                <!-- U Column -->
                                <ng-container matColumnDef="U">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.uncertainties.cols.U.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element">
                                    <span [matTooltip]="element.U">
                                      {{ element.U | number:'1.' + getDecimalsUncertainty(indexVariable, element.point)
                                      + '-' +
                                      getDecimalsUncertainty(indexVariable, element.point) }}
                                    </span>
                                  </td>
                                </ng-container>

                                <!-- cPlusU Column -->
                                <ng-container matColumnDef="cPlusU">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.asLeft.cols.cPlusU.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element">
                                    <span [class]="styleCPlusU(indexVariable, element.point, element.cPlusU)"
                                      [matTooltip]="element.cPlusU">
                                      {{ element.cPlusU | number:'1.' + getDecimalsUncertainty(indexVariable,
                                      element.point) +
                                      '-' + getDecimalsUncertainty(indexVariable, element.point) }}
                                    </span>
                                  </td>
                                </ng-container>

                                <!-- Button see Column -->
                                <ng-container matColumnDef="edit">
                                  <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
                                  <td mat-cell *matCellDef="let element; let i = index">
                                    <button mat-icon-button *ngIf="!isEditable()"
                                      (click)="editAsLeftRowMultichannel(indexVariable, indexChannel, i)">
                                      <mat-icon>mode_edit</mat-icon>
                                    </button>
                                  </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="asLeftCols"></tr>
                                <tr mat-row *matRowDef="let row; columns: asLeftCols;"></tr>
                              </table>

                            </div>

                          </div>

                        </mat-card-content>
                      </mat-card>

                      <mat-card class="card">
                        <mat-card-content>
                          <mat-toolbar color="secondary">
                            {{ 'calibrateEquipmentEdit.uncertainties.title' | translate }}
                          </mat-toolbar>

                          <div fxLayout="column" class="flexwrap">
                            <div fxFlex>
                              <table #uncertaintiesTable mat-table
                                [dataSource]="getAsLeftListMultichannel(indexVariable, indexChannel)"
                                style="width: 100%;">

                                <!-- Number Column -->
                                <ng-container matColumnDef="number">
                                  <th mat-header-cell *matHeaderCellDef> </th>
                                  <td mat-cell *matCellDef="let element; let i = index"> {{ (i + 1) }} </td>
                                </ng-container>

                                <!-- Point Column -->
                                <ng-container matColumnDef="point">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.uncertainties.cols.average.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element; let i = index">
                                    <span [matTooltip]="element.average">
                                      {{ element.average | number:'1.' + getDecimalsResolution(indexVariable,
                                      element.point) +
                                      '-' + getDecimalsResolution(indexVariable, element.point) }}
                                    </span>
                                  </td>
                                </ng-container>

                                <!-- uRep Column -->
                                <ng-container matColumnDef="uRep">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.uncertainties.cols.uRep.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element">
                                    <span [matTooltip]="element.uRep">
                                      {{ element.uRep | number:'1.' + getDecimalsUncertainty(indexVariable,
                                      element.point) + '-'
                                      + getDecimalsUncertainty(indexVariable, element.point) }}
                                    </span>
                                  </td>
                                </ng-container>

                                <!-- uResol Column -->
                                <ng-container matColumnDef="uResol">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.uncertainties.cols.uResol.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element">
                                    <span [matTooltip]="element.uResol">
                                      {{ element.uResol | number:'1.' + getDecimalsUncertainty(indexVariable,
                                      element.point) +
                                      '-' + getDecimalsUncertainty(indexVariable, element.point) }}
                                    </span>
                                  </td>
                                </ng-container>

                                <!-- uPattern Column -->
                                <ng-container matColumnDef="uPattern">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.uncertainties.cols.uPattern.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element"> {{ joinListUP(indexVariable, element) }} </td>
                                </ng-container>

                                <!-- uUnif Column -->
                                <ng-container matColumnDef="uUnif">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.uncertainties.cols.uUnif.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element">
                                    <span [matTooltip]="element.uUnif">
                                      {{ element.uUnif != null ? element.uUnif : 'common.notApplicable' | translate }}
                                    </span>
                                  </td>
                                </ng-container>

                                <!-- uEstab Column -->
                                <ng-container matColumnDef="uEstab">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.uncertainties.cols.uEstab.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element">
                                    <span [matTooltip]="element.uEstab">
                                      {{ element.uEstab | number:'1.' + getDecimalsUncertainty(indexVariable,
                                      element.point) +
                                      '-' + getDecimalsUncertainty(indexVariable, element.point) }}
                                    </span>
                                  </td>
                                </ng-container>

                                <!-- uc Column -->
                                <ng-container matColumnDef="uc">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.uncertainties.cols.uc.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element">
                                    <span [matTooltip]="element.uC">
                                      {{ element.uC | number:'1.' + getDecimalsUncertainty(indexVariable, element.point)
                                      + '-' +
                                      getDecimalsUncertainty(indexVariable, element.point) }}
                                    </span>
                                  </td>
                                </ng-container>

                                <!-- neff Column -->
                                <ng-container matColumnDef="neff">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.uncertainties.cols.neff.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element">
                                    <span [matTooltip]="element.neff">
                                      {{ element.neff | number:'1.' + getDecimalsUncertainty(indexVariable,
                                      element.point) + '-'
                                      + getDecimalsUncertainty(indexVariable, element.point) }}
                                    </span>
                                  </td>
                                </ng-container>

                                <!-- k Column -->
                                <ng-container matColumnDef="k">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.uncertainties.cols.k.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element">
                                    <span [matTooltip]="element.k">
                                      {{ element.k }}
                                    </span>
                                  </td>
                                </ng-container>

                                <!-- U Column -->
                                <ng-container matColumnDef="U">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.uncertainties.cols.U.label' | translate }} </th>
                                  <td mat-cell *matCellDef="let element">
                                    <span [matTooltip]="element.U">
                                      {{ element.U | number:'1.' + getDecimalsUncertainty(indexVariable, element.point)
                                      + '-' +
                                      getDecimalsUncertainty(indexVariable, element.point) }}
                                    </span>
                                  </td>
                                </ng-container>

                                <!-- measureUnit Column -->
                                <ng-container matColumnDef="measureUnit">
                                  <th mat-header-cell *matHeaderCellDef>
                                    {{ 'calibrateEquipmentEdit.uncertainties.cols.measureUnit.label' | translate }}
                                  </th>
                                  <td mat-cell *matCellDef="let element"> {{ variable.unitName }} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="uncertaintiesCols"></tr>
                                <tr mat-row *matRowDef="let row; columns: uncertaintiesCols;"></tr>
                              </table>
                            </div>

                          </div>

                        </mat-card-content>
                      </mat-card>
                    </mat-tab>
                  </mat-tab-group>
                </mat-card-content>
              </mat-card>

            </mat-tab>
          </mat-tab-group>

        </mat-card-content>
      </mat-card>

      <mat-card class="card" *ngIf="calibration.id != null">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'calibrateEquipmentEdit.result.title' | translate }}
          </mat-toolbar>

          <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

            <div fxFlex="80" fxFlex.lt-md="100%">
              <mat-radio-group [(ngModel)]="calibration.correct" name="correct" [disabled]="isEditable()">
                <mat-radio-button [value]="true"> {{'common.correct' | translate}} </mat-radio-button>
                <mat-radio-button [value]="false"> {{'common.incorrect' | translate}} </mat-radio-button>
              </mat-radio-group>
            </div>

          </div>

        </mat-card-content>
      </mat-card>

      <mat-card class="card">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'calibrateEquipmentEdit.observations.title' | translate }}
          </mat-toolbar>

          <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

            <div fxFlex="80" fxFlex.lt-md="100%">
              <textarea matInput placeholder="{{ 'calibrateEquipmentEdit.observations.label' | translate }}"
                [(ngModel)]="calibration.observations" name="observations" style="resize: none;">
          </textarea>
            </div>

          </div>

        </mat-card-content>
      </mat-card>

      <mat-card class="card">
        <mat-card-actions>
          <div fxFlex></div>
          <button mat-raised-button color="primary" *ngIf="showRevertSign()" (click)="revertSign()">
            {{ 'button.revertSign' | translate }} </button>
          <button mat-raised-button color="primary" *ngIf="showBack()" (click)="calculateBack()">
            {{ 'button.retreat' | translate }}</button>
          <button mat-raised-button color="primary" (click)="saveCalibration()" *ngIf="userCanModify()">
            {{ 'button.save' | translate }}</button>
          <button mat-raised-button color="primary" *ngIf="showAdvance()" (click)="calculateAdvance()">
            {{ 'button.advance' | translate }}</button>
          <button mat-raised-button color="primary" (click)="manualSign()" *ngIf="showManualSign()">
            {{ 'button.sign-manual' | translate }}</button>
          <button mat-raised-button color="primary" (click)="automaticSign()" *ngIf="showAutomaticSign()">
            {{ 'button.sign-automatic' | translate }}</button>
          <button mat-raised-button color="accent" *ngIf="showDownloadButton()" (click)="downloadPdf() ">
            {{ 'button.download-certificate' | translate }}</button>
          <button mat-raised-button color="primary" (click)="invalidate()" *ngIf="showInvalidate()">
            {{ 'button.invalidate' | translate }}</button>
          <button mat-raised-button color="secondary" (click)="cancel()">{{ 'button.cancel' | translate }}</button>
        </mat-card-actions>
      </mat-card>

    </form>

  </mat-tab>
  <mat-tab label="{{ 'calibrateEquipmentEdit.historical.title' | translate }}" *ngIf="calibration.id != null">
    <app-calibration-edit-audit [idCalibration]="calibration.id" [documentCode]="calibration.certificateNum"
      [calibration]="calibration" [equipment]="equipment">
    </app-calibration-edit-audit>
  </mat-tab>
</mat-tab-group>