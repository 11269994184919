import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpUtils } from '../utils/httpUtils';

@Injectable({
  providedIn: 'root'
})
export class InternalEquipmentUsedUsersService {

  baseUrl = `${environment.thermalUrl}/internalEquipmentUsedUsers`;
  constructor(private http: HttpClient) {
  }

  validateUser(code: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/validate-users/${code}`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }
}
