<mat-card class="card" *ngIf="isEdit">
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="50" fxFlex.lt-md="100%">
        <h1 style="margin: 0 auto; display: table;">
          {{ group.name }}
        </h1>
      </div>
    </div>

  </mat-card-content>
</mat-card>

<mat-card class="card">
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="50" fxFlex.lt-md="100%">
        <div fxLayout="column">
          <div fxFlex="100" *ngIf="!isEdit">
            <mat-form-field class="campoFormulario">
              <input matInput placeholder="{{'groupEdit.name.label' | translate }}" name="username"
                [(ngModel)]="group.name" [errorStateMatcher]="matcher"
                [disabled]="!currentUser.admin || currentUser.manager">
              <mat-error>
                {{ 'groupEdit.name.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="100">
            <mat-form-field class="campoFormulario">
              <input matInput placeholder="{{'groupEdit.companyName.label' | translate }}" name="companyName"
                [(ngModel)]="group.companyName">
              <mat-error>
                {{'groupEdit.companyName.label' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>

<mat-card class="card" *ngIf="isEdit">
  <mat-card-header>
    <mat-card-title>
      <mat-toolbar color="secondary" style="width: 95vw;">
        {{ 'groupEdit.logo.title' | translate }}

        <button mat-icon-button (click)="uploadLogo.click()">
          <mat-icon>camera_alt</mat-icon>
        </button>
        <input accept="image/*" hidden type="file" (change)="onLogoUpload($event)" #uploadLogo>
      </mat-toolbar>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
      <div fxFlex="20" fxFlex.lt-md="100%">

        <p> {{ 'groupEdit.logo.showLogo' | translate }} </p>
        <mat-radio-group [(ngModel)]="group.showLogo" name="showLogo">
          <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
          <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
        </mat-radio-group>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="image != null">
        <span>{{ 'groupEdit.logo.currentLogo' | translate }}</span>
        <br />
        <img class="companyLogo" [src]="showImage()">
      </div>
    </div>

  </mat-card-content>
</mat-card>

<mat-card class="card">
  <mat-card-content>
    <mat-toolbar color="secondary">
      {{ 'groupEdit.address.title' | translate }}
    </mat-toolbar>

    <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

      <div fxFlex="50%" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'groupEdit.address.address.label' | translate }}" name="address"
            [(ngModel)]="group.address" [errorStateMatcher]="matcher" required>
          <mat-error>
            {{ 'groupEdit.address.address.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50%" fxFlex.lt-md="0%">
      </div>

      <div fxFlex="40%" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'groupEdit.address.city.label' | translate }}" name="city"
            [(ngModel)]="group.city" [errorStateMatcher]="matcher">
          <mat-error>
            {{ 'groupEdit.address.city.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="8%" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'groupEdit.address.postalCode.label' | translate }}" name="postalCode"
            [(ngModel)]="group.postalCode" [errorStateMatcher]="matcher">
          <mat-error>
            {{ 'groupEdit.address.postalCode.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="30%" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'groupEdit.address.state.label' | translate }}" name="state"
            [(ngModel)]="group.state" [errorStateMatcher]="matcher">
          <mat-error>
            {{ 'groupEdit.address.state.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="20%" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label> {{ 'groupEdit.address.country.label' | translate }} </mat-label>
          <mat-select #countrySelect name="country" [(ngModel)]="group.country">
            <mat-select-filter [displayMember]="'name'" [array]="countries" (filteredReturn)="countriesFiltered =$event"
              [noResultsMessage]="'common.noResults' | translate"
              [placeholder]="'common.selectFilterPlaceholder' | translate"> </mat-select-filter>
            <mat-option [value]="null"> -- </mat-option>
            <mat-option *ngFor="let country of countriesFiltered" [value]="country.id">
              {{ country.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>

  </mat-card-content>
</mat-card>

<mat-card class="card" *ngIf="isEdit && currentUser.admin && !currentUser.manager">
  <mat-card-header>
    <mat-card-title>
      <mat-toolbar color="secondary" style="width: 95vw;">
        {{ 'groupEdit.administration.title' | translate }}
      </mat-toolbar>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <mat-accordion>
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'groupEdit.administration.general.title' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
          <div fxFlex="20" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <mat-label>{{ 'groupEdit.administration.appPlan.label' | translate }}</mat-label>
              <mat-select required name="appPlanType" [(ngModel)]="group.idAppPlanType"
                (selectionChange)="onAppPlanTypeChange($event)">
                <mat-option *ngFor="let item of appPlanTypes" [value]="item.id">
                  {{ item.name | translate }}
                </mat-option>
              </mat-select>
              <mat-error>
                {{ 'groupEdit.administration.appPlan.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="20" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput placeholder="{{ 'groupEdit.administration.maxUsers.label' | translate }}"
                [name]="maxUsers" [(ngModel)]="group.maxUsers" [errorStateMatcher]="matcher" type="number" required
                min="-1">
              <mat-error>
                {{ 'groupEdit.administration.maxUsers.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="20" fxFlex.lt-md="100%" class="container-check-group">
            <span> {{ 'groupEdit.administration.canManageUsers.label' | translate }} </span>
            <mat-radio-group class="btn-check-group" [(ngModel)]="group.canManageUsers" name="canManageUsers">
              <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
              <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
            </mat-radio-group>
          </div>

          <div fxFlex="20" fxFlex.lt-md="100%" class="container-check-group">
            <span> {{ 'groupEdit.administration.showClone.label' | translate }} </span>
            <mat-radio-group class="btn-check-group" [(ngModel)]="group.showClone" name="showClone">
              <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
              <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
            </mat-radio-group>
          </div>

          <div fxFlex="20" fxFlex.lt-md="100%" class="container-check-group">
            <span> {{ 'groupEdit.administration.requireProcessApproval.label' | translate }} </span>
            <mat-radio-group class="btn-check-group" [(ngModel)]="group.requireProcessApproval" name="requireProcessApproval">
              <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
              <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
            </mat-radio-group>
          </div>

        </div>

        <div fxFlex="20" fxFlex.lt-md="100%" class="container-check-group">
          <span> {{ 'groupEdit.administration.canCloneExecutions.label' | translate }} </span>
          <mat-radio-group  class="btn-check-group" [(ngModel)]="group.showCanCloneExecutions" name="showCanCloneExecutions">
            <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
            <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
          </mat-radio-group>
        </div>

        <div fxFlex="20" fxFlex.lt-md="100%" class="container-check-group">
          <span> {{ 'groupEdit.administration.canCloneCharacterizacion.label' | translate }} </span>
          <mat-radio-group class="btn-check-group" [(ngModel)]="group.showCanCloneCharacterization" name="showCanCloneCharacterization">
            <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
            <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel expanded="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'groupEdit.administration.thermal.title' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

          <div fxFlex="30" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput placeholder="{{ 'groupEdit.administration.maxExecutions.label' | translate }}"
                [name]="maxExecutions" [(ngModel)]="group.maxExecutions" [errorStateMatcher]="matcher" type="number"
                required min="-1">
              <mat-error>
                {{ 'groupEdit.administration.maxExecutions.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>


          <div fxFlex="30" fxFlex.lt-md="100%" class="container-check-group">
            <span> {{ 'groupEdit.administration.canGeneratePreReport.label' | translate }} </span>
            <mat-radio-group class="btn-check-group" [(ngModel)]="group.canGeneratePreReport" name="canGeneratePreReport">
              <mat-radio-button class="btn-check-group" [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
              <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
            </mat-radio-group>
          </div>

          <div fxFlex="30" fxFlex.lt-md="100%" class="container-check-group">
            <span> {{ 'groupEdit.administration.canUserCharacterization.label' | translate }} </span>
            <mat-radio-group class="btn-check-group" [(ngModel)]="group.showCharacterization" name="showCharacterization">
              <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
              <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
            </mat-radio-group>
          </div>

          <div fxFlex="30" fxFlex.lt-md="100%" class="container-check-group">
            <span> {{ 'groupEdit.administration.canAutomaticSign.label' | translate }} </span>
            <mat-radio-group class="btn-check-group" [(ngModel)]="group.automaticSign" name="automaticSign">
              <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
              <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
            </mat-radio-group>
          </div>

          <div fxFlex="30" fxFlex.lt-md="100%" class="container-check-group">
            <span> {{ 'groupEdit.administration.canManualSign.label' | translate }} </span>
            <mat-radio-group class="btn-check-group" [(ngModel)]="group.manualSign" name="manualSign">
              <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
              <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
            </mat-radio-group>
          </div>

          <div fxFlex="20" fxFlex.lt-md="0%"><br /><br /></div>

          <div fxFlex="30" fxFlex.lt-md="100%" class="container-check-group">
            <span> {{ 'groupEdit.administration.idsPhasesAllowed.label' | translate }} </span>
            <mat-selection-list class="btn-check-group" [(ngModel)]="group.idsPhasesAllowed" name="idsPhasesAllowed">
              <mat-list-option *ngFor="let item of phases" checkboxPosition="before" [value]="item.id">
                {{ 'phase.' + item.translation | translate }}
              </mat-list-option>
            </mat-selection-list>
          </div>

          <div fxFlex="20" fxFlex.lt-md="0%"><br /><br /></div>

          <div fxFlex="30" fxFlex.lt-md="100%" class="container-check-group">
            <span> {{ 'groupEdit.administration.idsRolesNeedCertificate.label' | translate }} </span>
            <mat-selection-list class="btn-check-group" [(ngModel)]="group.idsRolesNeedCertificate" name="idsRolesNeedCertificate">
              <mat-list-option *ngFor="let item of roles" checkboxPosition="before" [value]="item.id">
                {{ 'role.' + item.translation | translate }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>

      </mat-expansion-panel>

      <mat-expansion-panel expanded="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'groupEdit.administration.calibrates.title' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

          <div fxFlex="35" fxFlex.lt-md="100%">
            <span> {{ 'groupEdit.administration.showExternalEquipments.label' | translate }} </span>
            <mat-radio-group class="btn-check-group" [(ngModel)]="group.showExternalEquipments" name="showExternalEquipments">
              <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
              <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
            </mat-radio-group>
          </div>

          <div fxFlex="35" fxFlex.lt-md="100%">
            <span> {{ 'groupEdit.administration.calibrationPlan.title' | translate }} </span>
            <mat-radio-group class="btn-check-group" [(ngModel)]="group.showCalibrationPlan" name="showCalibrationPlan">
              <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
              <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
            </mat-radio-group>
          </div>

          <div fxFlex="25" fxFlex.lt-md="100%">
            {{ 'groupEdit.administration.variablesApplyCalibrates.label' | translate }}:
            <mat-select multiple [(ngModel)]="group.idsVariablesCalibrates" name="idsVariablesCalibrates">
              <mat-option *ngFor="let item of variableTypes" checkboxPosition="before" [value]="item.id">
                {{ 'variable.' + item.translation | translate }}
              </mat-option>
            </mat-select>
          </div>

          <div fxFlex="20" fxFlex.lt-md="100%" class="container-check-group">
            <span> {{ 'groupEdit.administration.canCalibrationPlanAnnual.label' | translate }} </span>
            <mat-radio-group class="btn-check-group" [(ngModel)]="group.showCanCalibrationPlanAnnual" name="showCanCalibrationPlanAnnual">
              <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
              <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
            </mat-radio-group>
          </div>

        </div>

      </mat-expansion-panel>

      <mat-expansion-panel expanded="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'groupEdit.administration.air.title' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
          {{ 'protocolEdit.dialog.essay.fields.empty' | translate }}
        </div>

      </mat-expansion-panel>


      
    </mat-accordion>

  </mat-card-content>
</mat-card>

<mat-card class="card">
  <mat-card-header>
    <mat-card-title>
      <mat-toolbar color="secondary" style="width: 95vw;">
        {{ 'groupEdit.coding.title' | translate }}
        
        <button mat-icon-button color="accent" (click)="addOrEditGroupCoding(null, false)">
          <mat-icon>add</mat-icon>
        </button>
      </mat-toolbar>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <table mat-table #codingTable [dataSource]="group?.groupDocumentCoding" style="width: 100%;">

      <!-- code Column -->
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef> {{ 'groupEdit.coding.cols.code' | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          {{item.coding}}
        </td>
      </ng-container>

      <!-- code Column -->
      <ng-container matColumnDef="document">
        <th mat-header-cell *matHeaderCellDef> {{ 'groupEdit.coding.cols.document' | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          {{getDocumentTypeDescription(item.documentType)}}
        </td>
      </ng-container>

       <!-- Button edit Column -->
       <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item" class="action">
          <button mat-icon-button (click)="addOrEditGroupCoding(item, true)"
            matTooltip="{{ 'button.edit' | translate }}">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

       <!-- Button delete Column -->
       <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item; let indexCoding = index" class="action">
          <button mat-icon-button (click)="deleteGroupCoding(indexCoding)"
            matTooltip="{{ 'button.delete' | translate }}">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColsCoding"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColsCoding;"></tr>

    </table>

  </mat-card-content>
</mat-card>
<mat-card class="card">
  <mat-card-actions>
    <div fxFlex></div>
    <button mat-raised-button (click)="save()" color="primary">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="cancel()">{{ 'button.cancel' | translate }}</button>
  </mat-card-actions>
</mat-card>

<mat-card class="card" [hidden]="!isEdit">
  <mat-card-header>
    <mat-card-title>
      <mat-toolbar color="secondary" style="width: 95vw;">
        {{ 'groupEdit.users.title' | translate }}
      </mat-toolbar>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <table #usersTable mat-table [dataSource]="usersDatasource" style="width: 100%;">

      <!-- username Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef> {{ 'groupEdit.users.cols.username' | translate }}
        </th>
        <td mat-cell *matCellDef="let item"> {{item.username}} </td>
      </ng-container>

      <!-- role Column -->
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef> {{ 'groupEdit.users.cols.role' | translate }}
        </th>
        <td mat-cell *matCellDef="let item"> {{ getRolesOfUser(item) }}</td>
      </ng-container>

      <!-- app Column -->
      <ng-container matColumnDef="app">
        <th mat-header-cell *matHeaderCellDef> {{ 'groupEdit.users.cols.app' | translate }}
        </th>
        <td mat-cell *matCellDef="let item"> {{ getAppsOfUser(item) }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColsUsers"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColsUsers;"></tr>

    </table>

    <div fxLayout="row" class="flexwrap">
      <div fxFlex>
        <mat-paginator #usersPaginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons lang="es">
        </mat-paginator>
      </div>
      <div fxFlex="45px">
        <button mat-icon-button (click)="downloadAuditPdf()" class="downloadAudit">
          <mat-icon>picture_as_pdf</mat-icon>
        </button>
      </div>
    </div>

  </mat-card-content>
</mat-card>

<mat-card class="card">
  <mat-card-header>
    <mat-card-title>
      <mat-toolbar color="secondary" style="width: 95vw;">
        {{ 'groupEdit.subscriptions.title' | translate }}
      </mat-toolbar>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <table mat-table [dataSource]="subscriptions" style="width: 100%;">

      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> {{ 'groupEdit.subscriptions.cols.name' | translate }}
        </th>
        <td mat-cell *matCellDef="let item"> {{item.name}} </td>
      </ng-container>

      <!-- nextInvoiceDate Column -->
      <ng-container matColumnDef="nextInvoiceDate">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'groupEdit.subscriptions.cols.nextInvoiceDate' | translate }}
        </th>
        <td mat-cell *matCellDef="let item"> {{item.nextInvoiceDate | fixDate}} </td>
      </ng-container>

      <!-- subscriptionEndDate Column -->
      <ng-container matColumnDef="subscriptionEndDate">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'groupEdit.subscriptions.cols.subscriptionEndDate' | translate }}
        </th>
        <td mat-cell *matCellDef="let item"> {{item.subscriptionEndDate | fixDate}} </td>
      </ng-container>

      <!-- amount Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> {{ 'groupEdit.subscriptions.cols.amount' | translate }}
        </th>
        <td mat-cell *matCellDef="let item"> {{item.amount | number:'1.2-2'}} € </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColsSubscriptions"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColsSubscriptions;"></tr>

    </table>

  </mat-card-content>
</mat-card>

<mat-card class="card">
  <mat-card-header>
    <mat-card-title>
      <mat-toolbar color="secondary" style="width: 95vw;">
        {{ 'groupEdit.payments.title' | translate }}
      </mat-toolbar>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <table mat-table [dataSource]="payments" style="width: 100%;">

      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> {{ 'groupEdit.payments.cols.name' | translate }}
        </th>
        <td mat-cell *matCellDef="let item"> {{item.name}} </td>
      </ng-container>

      <!-- date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> {{ 'groupEdit.payments.cols.date' | translate }}
        </th>
        <td mat-cell *matCellDef="let item"> {{item.date | fixDate}} </td>
      </ng-container>

      <!-- amount Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> {{ 'groupEdit.payments.cols.amount' | translate }}
        </th>
        <td mat-cell *matCellDef="let item"> {{item.amount | number:'1.2-2'}} € </td>
      </ng-container>

      <!-- downloadUrl Column -->
      <ng-container matColumnDef="downloadUrl">
        <th mat-header-cell *matHeaderCellDef> {{ 'groupEdit.payments.cols.downloadUrl' | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          <button mat-icon-button (click)="openDownloadLink(item.downloadUrl)" *ngIf="item.downloadUrl">
            <mat-icon>call_made</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColsPayments"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColsPayments;"></tr>

    </table>

  </mat-card-content>
</mat-card>

<mat-card class="card">
  <mat-card-header>
    <mat-card-title>
      <mat-toolbar color="secondary" style="width: 95vw;">
        {{ 'groupEdit.invoices.title' | translate }}
      </mat-toolbar>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <table mat-table [dataSource]="invoices" style="width: 100%;">

      <!-- date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> {{ 'groupEdit.invoices.cols.date' | translate }}
        </th>
        <td mat-cell *matCellDef="let item"> {{item.date | fixDate}} </td>
      </ng-container>

      <!-- total Column -->
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef> {{ 'groupEdit.invoices.cols.total' | translate }}
        </th>
        <td mat-cell *matCellDef="let item"> {{item.total | number:'1.2-2'}} € </td>
      </ng-container>

      <!-- downloadUrl Column -->
      <ng-container matColumnDef="downloadUrl">
        <th mat-header-cell *matHeaderCellDef> {{ 'groupEdit.invoices.cols.downloadUrl' | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          <button mat-icon-button (click)="openDownloadLink(item.downloadUrl)" *ngIf="item.downloadUrl">
            <mat-icon>cloud_download</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColsInvoices"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColsInvoices;"></tr>

    </table>

  </mat-card-content>
</mat-card>