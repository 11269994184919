<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form>
  <div mat-dialog-content class="thermal-theme">

    <div fxLayout="row" class="flexwrap">

      <div fxFlex="100%">
        <mat-form-field class="campoFormulario">
            <mat-label>{{ 'protocolEdit.dialog.equipment.type.label' | translate }}</mat-label>
            <mat-select required name="idTypeProtocol" [(ngModel)]="data.equipment.idType">
                <mat-option *ngFor="let item of equipmentTypes" [value]="item.id">
                    {{ 'equipmentType.' + item.translation | translate }}
                </mat-option>
            </mat-select>
            <mat-error>
                {{ 'protocolEdit.dialog.equipment.type.error' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput [ngModel]="data.equipment.internalId" #trigger="matAutocompleteTrigger" [matAutocomplete]="auto"
            placeholder="{{ 'protocolEdit.dialog.equipment.equipment.label' | translate }}" name="equipment"
            (keyup)="lookupEquipment($event)" required>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
            (optionSelected)="onEquipmentChange($event, trigger)">
            <mat-option *ngFor="let item of equipmentAutoComplete; let index = index" [value]="item">
              {{ item.name}} ({{item.serialNum}})
            </mat-option>
          </mat-autocomplete>
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.equipment.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50">
        <a class="under-card-link" style="cursor: pointer;margin: inherit;" (click)="createEquipment()">
          {{ 'calibrateEquipmentEdit.general.equipment.notExists' | translate }}
        </a>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.maker.label' | translate }}"
            disabled [(ngModel)]="data.equipment.maker" name="maker">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.maker.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.model.label' | translate }}"
            disabled [(ngModel)]="data.equipment.model" name="model">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.model.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.serialNum.label' | translate }}"
            disabled [(ngModel)]="data.equipment.serialNum" name="serialNum">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.serialNum.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.internalId.label' | translate }}"
            disabled [(ngModel)]="data.equipment.internalId" name="internalId">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.internalId.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.location.label' | translate }}"
            disabled [(ngModel)]="data.equipment.location" required name="location">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.location.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

    </div>

  </div>
  <div mat-dialog-actions class="thermal-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>