import { GenericFilter } from './genericClass';

export class EquipmentAutocompleteFilter extends GenericFilter {
    query: string;

    idClient: number;
    internalEquipment = false;

    forExecution = false;
    forCalibration = false;
    forCalibrationPattern = false;

    allStatus = false;
    idTypeAction: number;
}
