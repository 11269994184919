<mat-tab-group #generalTabs (selectedTabChange)="onTabChange($event)">
    <mat-tab #generalTab label="{{ 'executionEdit.general.title' | translate }}">

        <ng-template mat-tab-label>
            {{ 'executionEdit.general.title' | translate }}
        </ng-template>

        <form>
            <mat-card class="card">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'executionEdit.general.title' | translate }}
                    </mat-toolbar>

                    <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
                        <div fxFlex>

                            <div fxLayout="column">
                                <div fxFlex *ngIf="execution.documentCode != null">

                                    <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
                                        <div fxFlex>
                                            <mat-form-field class="campoFormulario">
                                                <input matInput
                                                    placeholder="{{ 'protocolEdit.general.documentCode.label' | translate }}"
                                                    name="documentCode" [(ngModel)]="execution.documentCode" disabled>
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex="65px" fxFlex.lt-md="50px"
                                            *ngIf="canEditDocumentCode() && canAdd()">
                                            <button mat-icon-button (click)="editDocumentCode()">
                                                <mat-icon>mode_edit</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>


                                <div fxFlex [hidden]="execution?.id != null">
                                    <span> {{ 'executionEdit.protocol.useExisting' | translate }} </span>
                                    <mat-radio-group [ngModel]="useExistingProtocol" name="useExistingProtocol"
                                        (change)="onUseExistingProtocolChange($event)">
                                        <mat-radio-button [value]="true"> {{'common.yes' | translate}}
                                        </mat-radio-button>
                                        <div fxFlex="15px"></div>
                                        <mat-radio-button [value]="false"> {{'common.no' | translate}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div fxFlex [hidden]="execution.id != null || !useExistingProtocol">
                                    <mat-form-field class="campoFormulario">
                                        <input matInput required [ngModel]="execution.idProtocol"
                                            [disabled]="execution.id != null"
                                            placeholder="{{ 'executionEdit.general.protocol.label' | translate }}"
                                            #protocolAutocomplete #trigger="matAutocompleteTrigger"
                                            [matAutocomplete]="auto" (keyup)="lookupProtocol($event)"
                                            [ngModelOptions]="{standalone: true}">
                                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                                            [displayWith]="displayProtocol" (optionSelected)="onProtocolChange($event)">

                                            <mat-option *ngIf="loadingAutocomplete$ | async">
                                                <mat-spinner diameter="50" class="centerIcon"></mat-spinner>
                                            </mat-option>

                                            <ng-container *ngIf="!(loadingAutocomplete$ | async)">
                                                <mat-option *ngFor="let prot of protocolsFiltered" [value]="prot">
                                                    {{ displayProtocol(prot) }}
                                                </mat-option>
                                            </ng-container>

                                        </mat-autocomplete>
                                        <mat-error>
                                            {{ 'executionEdit.general.protocol.error' | translate }}
                                        </mat-error>

                                        <button matSuffix mat-icon-button color="accent" (click)="openSearchProtocol()">
                                            <mat-icon style="font-size: large;">search</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </div>

                                <div fxFlex *ngIf="execution.id != null && protocol?.id != null">
                                    <div fxFlex *ngIf="protocol">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput
                                                placeholder="{{ 'executionEdit.general.protocol.label' | translate }}"
                                                name="protocolDocumentCode" [ngModel]="protocol?.documentCode" disabled>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="65px" fxFlex.lt-md="50px">
                                        <button mat-icon-button (click)="downloadProtocolPdf()">
                                            <mat-icon class="hidden-sm">cloud_download</mat-icon>
                                        </button>
                                    </div>
                                </div>

                                <div fxFlex="50">
                                    <mat-form-field class="campoFormulario">
                                        <input matInput
                                            placeholder="{{ 'protocolEdit.general.clientDocumentCode.label' | translate }}"
                                            name="clientDocumentCode" [(ngModel)]="execution.clientDocumentCode">
                                    </mat-form-field>
                                </div>

                                <div fxFlex="50">
                                    <mat-form-field class="campoFormulario" *ngIf="protocol?.id != null">
                                        <mat-label>{{ 'executionEdit.protocol.process.label' | translate }}</mat-label>
                                        <mat-select name="idProcess" [(ngModel)]="execution.idProcess"
                                            [disabled]="!enableEditArea()">
                                            <mat-option *ngFor="let item of processes" [value]="item.id">
                                                {{ 'processType.' + item.translation | translate }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div fxFlex>
                            <div fxLayout="row" fxLayout.xs="column" class="flexwrap" [hidden]="execution.id == null">

                                <div fxFlex="50" fxFlex.lt-md="100%">
                                    <mat-form-field class="campoFormulario">
                                        <input matInput
                                            placeholder="{{ 'protocolEdit.general.regUser.label' | translate }}"
                                            name="regUser" disabled [(ngModel)]="execution.regUser">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="50" fxFlex.lt-md="100%">
                                    <mat-form-field class="campoFormulario">
                                        <input matInput
                                            placeholder="{{ 'protocolEdit.general.modUser.label' | translate }}"
                                            name="modUser" disabled [(ngModel)]="execution.modUser">
                                    </mat-form-field>
                                </div>

                                <div fxFlex="50" fxFlex.lt-md="100%">
                                    <mat-form-field class="campoFormulario">
                                        <input matInput
                                            placeholder="{{ 'protocolEdit.general.regFc.label' | translate }}"
                                            name="regFc" disabled [ngModel]="execution.regFc | fixDate">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="50" fxFlex.lt-md="100%">
                                    <mat-form-field class="campoFormulario">
                                        <input matInput
                                            placeholder="{{ 'protocolEdit.general.modFc.label' | translate }}"
                                            name="modFc" disabled [ngModel]="execution.modFc | fixDate">
                                    </mat-form-field>
                                </div>

                                <div fxFlex="50" fxFlex.lt-md="100%">
                                    <div fxLayout="row" fxLayout.xs="column" class="flexwrap"
                                        [hidden]="execution.id == null">
                                        <div fxFlex fxFlex.lt-md="100%">
                                            <mat-form-field class="campoFormulario">
                                                <input matInput name="status" disabled
                                                    value="{{ execution.id != null ? ('executionStatus.' + execution.statusTranslation | translate) : '' }}"
                                                    placeholder="{{ 'protocolEdit.general.status.label' | translate }}">
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex fxFlex.lt-md="100%" *ngIf="execution.according != null">
                                            <mat-form-field class="campoFormulario">
                                                <input matInput name="according" disabled
                                                    placeholder="{{ 'protocolEdit.general.according.label' | translate }}"
                                                    [ngModel]="isExecutionAccording() ? ('common.yes' | translate) : ('common.no' | translate)">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <div fxFlex="50">
                                    <mat-form-field class="campoFormulario">
                                        <input matInput
                                            placeholder="{{ 'protocolEdit.general.currentVersion.label' | translate }}"
                                            name="currentVersion" [ngModel]="execution.currentVersion" disabled>
                                    </mat-form-field>
                                </div>

                            </div>
                        </div>
                    </div>

                </mat-card-content>
            </mat-card>
            <!-- Doc reference -->
            <mat-card class="card" [hidden]="execution.id == null">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'executionEdit.referenceDocs.title' | translate }}

                        <button mat-icon-button color="accent" (click)="openNewRefDoc()" *ngIf="canAdd()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </mat-toolbar>

                    <br />

                    <div fxLayout="row">

                        <div fxFlex fxFlex.lt-md="100%">

                            <table mat-table matSort #refDocTable [dataSource]="execution.referenceDocs"
                                style="width: 95%;">

                                <!-- code Column -->
                                <ng-container matColumnDef="code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        {{ 'executionEdit.referenceDocs.code.label' | translate }}
                                    </th>
                                    <td mat-cell *matCellDef="let item"> {{ item.code }} </td>
                                </ng-container>

                                <!-- name Column -->
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        {{ 'executionEdit.referenceDocs.name.label' | translate }}
                                    </th>
                                    <td mat-cell *matCellDef="let item" style="white-space: pre;"> {{ item.name }} </td>
                                </ng-container>

                                <!-- Button edit Column -->
                                <ng-container matColumnDef="edit">
                                    <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
                                    <td mat-cell *matCellDef="let element; let refDocIndex = index">
                                        <button mat-icon-button (click)="editRefDoc(refDocIndex)" *ngIf="canAdd()">
                                            <mat-icon>mode_edit</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <!-- Button delete Column -->
                                <ng-container matColumnDef="delete">
                                    <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
                                    <td mat-cell *matCellDef="let element; let refDocIndex = index">
                                        <button mat-icon-button (click)="removeRefDoc(refDocIndex)" *ngIf="canAdd()">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColsRefDoc"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColsRefDoc;"></tr>

                            </table>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="card" *ngIf="!useExistingProtocol || protocol?.id != null">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ (useExistingProtocol || protocol?.id != null ? 'executionEdit.protocol.title' :
                        'executionEdit.execution.title') | translate }}
                        <div fxFlex="65px" fxFlex.lt-md="50px" *ngIf="canEditDocumentCode()">
                            <button mat-icon-button (click)="editDataExecution()">
                                <mat-icon>mode_edit</mat-icon>
                                </button>
                        </div>
                    </mat-toolbar>

                    <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                        <div fxFlex="33" fxFlex.lt-md="100%">
                            <mat-form-field class="campoFormulario" *ngIf="!useExistingProtocol">
                                <mat-label>{{ 'executionEdit.protocol.client.label' | translate }}</mat-label>
                                <mat-select #clientSelect name="idClient" [(ngModel)]="execution.idClient"
                                    [disabled]="execution.id != null">
                                    <mat-select-filter *ngIf="clientSelect.focused" [displayMember]="'name'"
                                        [array]="clientsShort" (filteredReturn)="clientsShortFiltered =$event"
                                        [noResultsMessage]="'common.noResults' | translate"
                                        [placeholder]="'common.selectFilterPlaceholder' | translate">
                                    </mat-select-filter>
                                    <mat-option *ngFor="let client of clientsShortFiltered" [value]="client.id">
                                        {{client.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    {{ 'executionEdit.protocol.client.error' | translate }}
                                </mat-error>
                            </mat-form-field>

                            <div *ngIf="protocol?.id != null">
                                <mat-label>{{ 'executionEdit.protocol.client.label' | translate }}</mat-label>
                                <mat-select disabled name="idClient" [(ngModel)]="protocol.idClient">
                                    <mat-option *ngFor="let item of clients" [value]="item.id">
                                        {{item.name}} ({{item.address}})
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>

                        <div fxFlex="33" fxFlex.lt-md="100%">
                            <mat-form-field class="campoFormulario" *ngIf="!useExistingProtocol">
                                <mat-label>{{ 'executionEdit.protocol.process.label' | translate }}</mat-label>
                                <mat-select name="idProcess" [(ngModel)]="execution.idProcess" required>
                                    <mat-option *ngFor="let item of processes" [value]="item.id">
                                        {{ 'processType.' + item.translation | translate }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    {{ 'executionEdit.protocol.process.error' | translate }}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="campoFormulario" *ngIf="protocol?.id != null">
                                <mat-label>{{ 'executionEdit.protocol.process.label' | translate }}</mat-label>
                                <mat-select disabled name="idProcessProtocol" [(ngModel)]="protocol.idProcess">
                                    <mat-option *ngFor="let item of processes" [value]="item.id">
                                        {{ 'processType.' + item.translation | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxFlex="33" fxFlex.lt-md="100%">
                            <mat-form-field class="campoFormulario" *ngIf="!useExistingProtocol">
                                <input matInput placeholder="{{ 'executionEdit.protocol.projectNo.label' | translate }}"
                                    name="projectNo" [(ngModel)]="execution.projectNo" required
                                    [disabled]="execution?.id != null">
                                <mat-error>
                                    {{ 'executionEdit.protocol.projectNo.error' | translate }}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="campoFormulario" *ngIf="protocol?.id != null">
                                <input matInput placeholder="{{ 'executionEdit.protocol.projectNo.label' | translate }}"
                                    name="projectNo" [(ngModel)]="execution.projectNo" disabled>
                            </mat-form-field>
                        </div>

                        <div fxFlex="33" fxFlex.lt-md="100%" *ngIf="!useExistingProtocol && protocol?.id == null">
                            <mat-form-field class="campoFormulario">
                                <mat-label>{{ 'protocolEdit.general.projectManager.label' | translate }}</mat-label>
                                <mat-select name="projectManager" [(ngModel)]="execution.usernameProjectManager"
                                    [disabled]="execution?.id != null">
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let item of respField" [value]="item.username">
                                        {{ item.fullName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </div>

                </mat-card-content>
            </mat-card>

            <!-- Areas -->
            <mat-card class="card" [hidden]="execution?.id == null">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'protocolEdit.area.title' | translate }}

                        <button mat-icon-button color="accent" (click)="openDialogArea()" *ngIf="canAdd()"
                            [disabled]="!enableEditArea()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </mat-toolbar>

                    <mat-tab-group #areaTabs (selectedTabChange)="onAreaTabChanged($event)">
                        <mat-tab #areaTab *ngFor="let area of areasToShow; let indexArea = index">

                            <ng-template mat-tab-label>
                                {{ area.name }}

                                <button mat-icon-button color="accent" *ngIf="showAreaButtons(indexArea)"
                                    (click)="openDialogEditArea(indexArea)" [disabled]="!enableEditArea()">
                                    <mat-icon>mode_edit</mat-icon>
                                </button>

                                <button mat-icon-button color="accent" *ngIf="showAreaButtons(indexArea)"
                                    (click)="deleteArea(indexArea)" [disabled]="!enableEditArea()">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </ng-template>

                            <br />

                            <mat-toolbar color="secondary">
                                {{ 'protocolEdit.equipment.title' | translate }}

                                <button mat-icon-button color="accent" (click)="openDialogEquipment(indexArea)"
                                    *ngIf="canAdd()" [disabled]="!enableEditArea()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </mat-toolbar>

                            <!-- equipos area -->
                            <mat-accordion>
                                <mat-expansion-panel
                                    *ngFor="let equipment of area.equipments; let indexEquipment = index"
                                    (opened)="setEquipmentOpened(indexEquipment, true)"
                                    [expanded]="isEquipmentOpened(indexEquipment)"
                                    (closed)="setEquipmentOpened(indexEquipment, false)">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <div style="margin: auto 0;display: block;"
                                                [class]="checkAccordanceEquipment(indexArea, indexEquipment)">
                                                {{ showEquipment(equipment) }}
                                                -
                                                {{ ('equipmentType.' + getEquipmentType(equipment)) | translate }}
                                            </div>

                                            <button mat-icon-button color="accent"
                                                *ngIf="isEquipmentOpened(indexEquipment)"
                                                (click)="openDialogEditEquipment(indexArea, indexEquipment)">
                                                <mat-icon style="font-size: 20px;">mode_edit</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" [disabled]="!enableEditArea()"
                                                *ngIf="isEquipmentOpened(indexEquipment) && canAdd()"
                                                (click)="deleteEquipment(indexArea, indexEquipment)">
                                                <mat-icon style="font-size: 20px;">delete</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="isEquipmentOpened(indexEquipment)"
                                                (click)="moveToTopEquipment(indexArea, indexEquipment)"
                                                [disabled]="indexEquipment === 0 && !enableEditArea()">
                                                <mat-icon style="transform: rotate(90deg);font-size: 20px;">first_page
                                                </mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="isEquipmentOpened(indexEquipment)"
                                                (click)="movePreviousEquipment(indexArea, indexEquipment)"
                                                [disabled]="indexEquipment === 0 && !enableEditArea()">
                                                <mat-icon style="font-size: 20px;">keyboard_arrow_up</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="isEquipmentOpened(indexEquipment)"
                                                (click)="moveNextEquipment(indexArea, indexEquipment)"
                                                [disabled]="indexEquipment === (area.equipments.length - 1) && !enableEditArea()">
                                                <mat-icon style="font-size: 20px;">keyboard_arrow_down</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="isEquipmentOpened(indexEquipment)"
                                                (click)="moveToBottomEquipment(indexArea, indexEquipment)"
                                                [disabled]="indexEquipment === (area.equipments.length - 1) && !enableEditArea()">
                                                <mat-icon style="transform: rotate(90deg);font-size: 20px;">last_page
                                                </mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" [disabled]="!enableEditArea()"
                                                *ngIf="isEquipmentOpened(indexEquipment) && canAdd()"
                                                (click)="openDialogEssayEquipment(indexEquipment, indexArea)">
                                                <mat-icon style="font-size: 20px;">add</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                *ngIf="isEquipmentOpened(indexEquipment)"
                                                (click)="downloadDatasheetEquipment(indexEquipment, indexArea)">
                                                <mat-icon style="font-size: 20px;">cloud_download</mat-icon>
                                            </button>

                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
                                        <div fxFlex="25">
                                            <mat-form-field class="campoFormulario">
                                                <input matInput name="alarmType{{indexEquipment}}{{indexArea}}"
                                                    [(ngModel)]="equipment.alarmType"
                                                    placeholder="{{ 'executionEdit.equipment.alarmType.label' | translate }}"
                                                    [disabled]="!enableEditArea()">
                                            </mat-form-field>
                                        </div>

                                        <div fxFlex="25">
                                            <mat-form-field class="campoFormulario">
                                                <input matInput name="equipmentStatus{{indexEquipment}}{{indexArea}}"
                                                    [(ngModel)]="equipment.equipmentStatus"
                                                    placeholder="{{ 'executionEdit.equipment.equipmentStatus.label' | translate }}"
                                                    [disabled]="!enableEditArea()">
                                            </mat-form-field>
                                        </div>

                                        <div fxFlex="25">
                                            <mat-form-field class="campoFormulario">
                                                <input matInput name="workingTime{{indexEquipment}}{{indexArea}}"
                                                    [(ngModel)]="equipment.workingTime"
                                                    placeholder="{{ 'executionEdit.equipment.workingTime.label' | translate }}"
                                                    [disabled]="!enableEditArea()">
                                            </mat-form-field>
                                        </div>

                                        <div fxFlex="25">
                                            <mat-form-field class="campoFormulario">
                                                <input matInput name="variatorPosition{{indexEquipment}}{{indexArea}}"
                                                    [(ngModel)]="equipment.variatorPosition"
                                                    placeholder="{{ 'executionEdit.equipment.variatorPosition.label' | translate }}"
                                                    [disabled]="!enableEditArea()">
                                            </mat-form-field>
                                        </div>

                                    </div>

                                    <!-- TABLA DE ENSAYOS CREADOS -->
                                    <table mat-table matSort #filtersTable [dataSource]="[equipment]">

                                        <!-- essay Column -->
                                        <ng-container matColumnDef="essay">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'protocolEdit.essays.title.generic' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item">
                                                {{ 'protocolEdit.essays.criteria.label' | translate }}
                                            </td>
                                        </ng-container>

                                        <!-- formolizatioEquipment Column -->
                                        <ng-container matColumnDef="formolizatioEquipment">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.formolizatioEquipment' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'formolizatioEquipment')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'formolizatioEquipment')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'formolizatioEquipment') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- defPresassure Column -->
                                        <ng-container matColumnDef="defPresassure">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.defPresassure' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'defPresassure')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'defPresassure')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'defPresassure') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- airSpeed Column -->
                                        <ng-container matColumnDef="airSpeed">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.airSpeed' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'airSpeed')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'airSpeed')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'airSpeed') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- hepaFilterIntegrity Column -->
                                        <ng-container matColumnDef="hepaFilterIntegrity">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.hepaFilterIntegrity' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'hepaFilterIntegrity')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'hepaFilterIntegrity')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'hepaFilterIntegrity') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- renFlowAndRate Column -->
                                        <ng-container matColumnDef="renFlowAndRate">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.renFlowAndRate' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'renFlowAndRate')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'renFlowAndRate')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'renFlowAndRate') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- particleCountRepose Column -->
                                        <ng-container matColumnDef="particleCountRepose">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.particleCountRepose' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'particleCountRepose')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'particleCountRepose')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'particleCountRepose') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- particleCountWorking Column -->
                                        <ng-container matColumnDef="particleCountWorking">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.particleCountWorking' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'particleCountWorking')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'particleCountWorking')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'particleCountWorking') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- wasteOfLoad Column -->
                                        <ng-container matColumnDef="wasteOfLoad">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.wasteOfLoad' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'wasteOfLoad')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'wasteOfLoad')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'wasteOfLoad') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- smokeEssayRepose Column -->
                                        <ng-container matColumnDef="smokeEssayRepose">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.smokeEssayRepose' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'smokeEssayRepose')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'smokeEssayRepose')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'smokeEssayRepose') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- smokeEssayWorking Column -->
                                        <ng-container matColumnDef="smokeEssayWorking">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.smokeEssayWorking' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'smokeEssayWorking')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'smokeEssayWorking')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'smokeEssayWorking') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- temperature Column -->
                                        <ng-container matColumnDef="temperature">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.temperature' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'temperature')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'temperature')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'temperature') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- humidity Column -->
                                        <ng-container matColumnDef="humidity">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.humidity' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'humidity')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'humidity')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'humidity') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- light Column -->
                                        <ng-container matColumnDef="light">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.light' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'light')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'light')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'light') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- noise Column -->
                                        <ng-container matColumnDef="noise">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.noise' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'noise')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'noise')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'noise') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- recoveryEssay Column -->
                                        <ng-container matColumnDef="recoveryEssay">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.recoveryEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'recoveryEssay')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'recoveryEssay')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'recoveryEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- uvIntensity Column -->
                                        <ng-container matColumnDef="uvIntensity">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.uvIntensity' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'uvIntensity')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'uvIntensity')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'uvIntensity') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- introductionEssay Column -->
                                        <ng-container matColumnDef="introductionEssay">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.introductionEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'introductionEssay')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'introductionEssay')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'introductionEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- electricEssay Column -->
                                        <ng-container matColumnDef="electricEssay">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.electricEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'electricEssay')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'electricEssay')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'electricEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- filterSaturation Column -->
                                        <ng-container matColumnDef="filterSaturation">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.filterSaturation' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'filterSaturation')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'filterSaturation')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'filterSaturation') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- parallelismTest Column -->
                                        <ng-container matColumnDef="parallelismTest">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.parallelismTest' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'parallelismTest')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'parallelismTest')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'parallelismTest') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- microbioEssay Column -->
                                        <ng-container matColumnDef="microbioEssay">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.microbioEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'microbioEssay')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'microbioEssay')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'microbioEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                         <!-- anexICountRepose Column -->
                                         <ng-container matColumnDef="anexCountRepose">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.anexCountRepose' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'anexCountRepose')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'anexCountRepose')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'anexCountRepose') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- anexICountWorking Column -->
                                        <ng-container matColumnDef="anexCountWorking">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.anexCountWorking' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'anexCountWorking')">
                                                <span
                                                    [class]="checkEssayAccordanceEquipment(indexArea, indexEquipment, 'anexCountWorking')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'anexCountWorking') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- others Column -->
                                        <ng-container matColumnDef="others">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.others' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayEquipment(indexArea, indexEquipment, 'others')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'others') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="getDisplayColumns(equipment.essays)"></tr>
                                        <tr mat-row *matRowDef="let row; columns: getDisplayColumns(equipment.essays);">
                                        </tr>

                                    </table>

                                </mat-expansion-panel>
                            </mat-accordion>

                            <br /><br /><br />

                            <!-- salas area -->
                            <mat-toolbar color="secondary">
                                {{ 'protocolEdit.room.title' | translate }}

                                <button mat-icon-button color="accent" (click)="openDialogRoom(indexArea)"
                                    *ngIf="canAdd()" [disabled]="!enableEditArea()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </mat-toolbar>
                            <mat-accordion>
                                <mat-expansion-panel *ngFor="let room of area.rooms; let indexRoom = index"
                                    (opened)="setRoomOpened(indexRoom, true)" [expanded]="isRoomOpened(indexRoom)"
                                    (closed)="setRoomOpened(indexRoom, false)">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <span [class]="checkAccordanceRoom(indexArea, indexRoom)">
                                                {{room.name}} </span>
                                            <button mat-icon-button color="accent" *ngIf="isRoomOpened(indexRoom)"
                                                (click)="openDialogEditRoom(indexArea, indexRoom)"
                                                [disabled]="!enableEditArea()">
                                                <mat-icon style="font-size: 20px;">mode_edit</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" [disabled]="!enableEditArea()"
                                                *ngIf="isRoomOpened(indexRoom) && canAdd()"
                                                (click)="deleteRoom(indexArea, indexRoom)">
                                                <mat-icon style="font-size: 20px;">delete</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="isRoomOpened(indexRoom)"
                                                (click)="moveToTop(indexArea, indexRoom)"
                                                [disabled]="indexRoom === 0 && !enableEditArea()">
                                                <mat-icon style="transform: rotate(90deg);font-size: 20px;">first_page
                                                </mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="isRoomOpened(indexRoom)"
                                                (click)="movePrevious(indexArea, indexRoom)"
                                                [disabled]="indexRoom === 0 && !enableEditArea()">
                                                <mat-icon style="font-size: 20px;">keyboard_arrow_up</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="isRoomOpened(indexRoom)"
                                                (click)="moveNext(indexArea, indexRoom)"
                                                [disabled]="indexRoom === (area.rooms.length - 1) && !enableEditArea()">
                                                <mat-icon style="font-size: 20px;">keyboard_arrow_down</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="isRoomOpened(indexRoom)"
                                                (click)="moveToBottom(indexArea, indexRoom)"
                                                [disabled]="indexRoom === (area.rooms.length - 1) && !enableEditArea()">
                                                <mat-icon style="transform: rotate(90deg);font-size: 20px;">last_page
                                                </mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" [disabled]="!enableEditArea()"
                                                *ngIf="isRoomOpened(indexRoom) && canAdd()"
                                                (click)="openDialogEssayRoom(indexRoom, indexArea)">
                                                <mat-icon style="font-size: 20px;">add</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" *ngIf="isRoomOpened(indexRoom)"
                                                (click)="downloadDatasheetRoom(indexRoom, indexArea)">
                                                <mat-icon style="font-size: 20px;">cloud_download</mat-icon>
                                            </button>

                                        </mat-panel-title>
                                    </mat-expansion-panel-header>


                                    <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                                        <div fxFlex="50">

                                            <div fxLayout="row" fxLayout.xs="column">

                                                <div fxFlex="20">
                                                    <mat-form-field class="campoFormulario">
                                                        <mat-label>
                                                            {{ 'executionEdit.room.idSubtype.label' | translate }}
                                                        </mat-label>
                                                        <mat-select name="idSubtype{{indexRoom}}{{indexArea}}"
                                                            [(ngModel)]="room.idSubtype" required>
                                                            <mat-option *ngFor="let item of roomSubtypes"
                                                                [value]="item.id">
                                                                {{ 'roomSubtype.' + item.translation | translate }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>

                                                <div fxFlex="20">
                                                    <span style="margin-top: 1em; display: block;">
                                                        {{ 'executionEdit.room.repose.label' | translate }}
                                                    </span>
                                                </div>

                                                <div fxFlex="20">
                                                    <mat-form-field class="campoFormulario">
                                                        <input matInput type="number" min="0"
                                                            [(ngModel)]="room.numberPeopleRepose"
                                                            name="numberPeopleRepose{{indexRoom}}{{indexArea}}"
                                                            placeholder="{{ 'executionEdit.room.numberPeopleRepose.label' | translate }}">
                                                    </mat-form-field>
                                                </div>

                                                <div fxFlex="20">
                                                    <mat-form-field class="campoFormulario">
                                                        <input matInput type="number" min="0"
                                                            name="numberStoppedMachinesRepose{{indexRoom}}{{indexArea}}"
                                                            [(ngModel)]="room.numberStoppedMachinesRepose"
                                                            placeholder="{{ 'executionEdit.room.numberStoppedMachinesRepose.label' | translate }}">
                                                    </mat-form-field>
                                                </div>

                                                <div fxFlex="20">
                                                    <mat-form-field class="campoFormulario">
                                                        <input matInput type="number" min="0"
                                                            name="numberRunningMachinesRepose{{indexRoom}}{{indexArea}}"
                                                            [(ngModel)]="room.numberRunningMachinesRepose"
                                                            placeholder="{{ 'executionEdit.room.numberRunningMachinesRepose.label' | translate }}">
                                                    </mat-form-field>
                                                </div>
                                            </div>

                                            <div fxLayout="row" fxLayout.xs="column">
                                                <div fxFlex="20">
                                                    <!-- Vacío queriendo -->
                                                </div>

                                                <div fxFlex="20">
                                                    <span style="margin-top: 1em; display: block;">
                                                        {{ 'executionEdit.room.working.label' | translate }}
                                                    </span>
                                                </div>

                                                <div fxFlex="20">
                                                    <mat-form-field class="campoFormulario">
                                                        <input matInput type="number" min="0"
                                                            [(ngModel)]="room.numberPeopleWorking"
                                                            name="numberPeopleWorking{{indexRoom}}{{indexArea}}"
                                                            placeholder="{{ 'executionEdit.room.numberPeopleWorking.label' | translate }}">
                                                    </mat-form-field>
                                                </div>

                                                <div fxFlex="20">
                                                    <mat-form-field class="campoFormulario">
                                                        <input matInput type="number" min="0"
                                                            name="numberStoppedMachinesWorking{{indexRoom}}{{indexArea}}"
                                                            [(ngModel)]="room.numberStoppedMachinesWorking"
                                                            placeholder="{{ 'executionEdit.room.numberStoppedMachinesWorking.label' | translate }}">
                                                    </mat-form-field>
                                                </div>
                                                <div fxFlex="20">
                                                    <mat-form-field class="campoFormulario">
                                                        <input matInput type="number" min="0"
                                                            name="numberRunningMachinesWorking{{indexRoom}}{{indexArea}}"
                                                            [(ngModel)]="room.numberRunningMachinesWorking"
                                                            placeholder="{{ 'executionEdit.room.numberRunningMachinesWorking.label' | translate }}">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>

                                        <div fxFlex="14" *ngFor="let volume of room.volumes; let indexVolume = index">
                                            <strong>Área {{ indexVolume + 1 }}:</strong><br />
                                            {{ 'protocolEdit.dialog.room.height.label' | translate }}:
                                            {{ volume?.height | number:'1.2-2' }} m <br />
                                            {{ 'protocolEdit.dialog.room.surface.label' | translate }}:
                                            {{ volume?.surface | number:'1.2-2' }} m² <br />
                                            {{ 'protocolEdit.dialog.room.volume.label' | translate }}:
                                            {{ volume?.volume | number:'1.1-1' }} m³
                                        </div>
                                        <div fxFlex="14">
                                            <div fxLayout="row" class="flexwrap">
                                                <div fxFlex>
                                                    <mat-form-field class="campoFormulario">
                                                        <input matInput name="totalSurface{{indexRoom}}{{indexArea}}"
                                                            [ngModel]="getTotalSurface(room) | number:'1.2-2'" disabled
                                                            [ngModelOptions]="{standalone: true}"
                                                            placeholder="{{ 'protocolEdit.dialog.room.totalSurface.label' | translate }}">
                                                    </mat-form-field>
                                                </div>
                                                <div fxFlex>
                                                    <mat-form-field class="campoFormulario">
                                                        <input matInput name="totalVolume{{indexRoom}}{{indexArea}}"
                                                            [ngModel]="getTotalVolume(room) | number:'1.1-1'" disabled
                                                            [ngModelOptions]="{standalone: true}"
                                                            placeholder="{{ 'protocolEdit.dialog.room.totalVolume.label' | translate }}">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- TABLA DE ENSAYOS CREADOS -->
                                    <table mat-table matSort #filtersTable [dataSource]="[room]">

                                        <!-- essay Column -->
                                        <ng-container matColumnDef="essay">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'protocolEdit.essays.title.generic' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item">
                                                {{ 'protocolEdit.essays.criteria.label' | translate }}
                                            </td>
                                        </ng-container>

                                        <!-- defPresassure Column -->
                                        <ng-container matColumnDef="defPresassure">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.defPresassure' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'defPresassure')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'defPresassure')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'defPresassure') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- airSpeed Column -->
                                        <ng-container matColumnDef="airSpeed">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.airSpeed' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'airSpeed')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'airSpeed')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'airSpeed') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- hepaFilterIntegrity Column -->
                                        <ng-container matColumnDef="hepaFilterIntegrity">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.hepaFilterIntegrity' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'hepaFilterIntegrity')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'hepaFilterIntegrity')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'hepaFilterIntegrity') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- renFlowAndRate Column -->
                                        <ng-container matColumnDef="renFlowAndRate">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.renFlowAndRate' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'renFlowAndRate')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'renFlowAndRate')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'renFlowAndRate') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- particleCountRepose Column -->
                                        <ng-container matColumnDef="particleCountRepose">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.particleCountRepose' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'particleCountRepose')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'particleCountRepose')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'particleCountRepose') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- particleCountWorking Column -->
                                        <ng-container matColumnDef="particleCountWorking">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.particleCountWorking' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'particleCountWorking')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'particleCountWorking')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'particleCountWorking') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- wasteOfLoad Column -->
                                        <ng-container matColumnDef="wasteOfLoad">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.wasteOfLoad' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'wasteOfLoad')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'wasteOfLoad')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'wasteOfLoad') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- smokeEssayRepose Column -->
                                        <ng-container matColumnDef="smokeEssayRepose">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.smokeEssayRepose' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'smokeEssayRepose')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'smokeEssayRepose')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'smokeEssayRepose') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- smokeEssayWorking Column -->
                                        <ng-container matColumnDef="smokeEssayWorking">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.smokeEssayWorking' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'smokeEssayWorking')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'smokeEssayWorking')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'smokeEssayWorking') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- temperature Column -->
                                        <ng-container matColumnDef="temperature">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.temperature' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'temperature')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'temperature')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'temperature') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- humidity Column -->
                                        <ng-container matColumnDef="humidity">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.humidity' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'humidity')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'humidity')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'humidity') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- light Column -->
                                        <ng-container matColumnDef="light">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.light' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'light')">
                                                <span [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'light')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'light') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- noise Column -->
                                        <ng-container matColumnDef="noise">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.noise' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'noise')">
                                                <span [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'noise')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'noise') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- recoveryEssay Column -->
                                        <ng-container matColumnDef="recoveryEssay">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.recoveryEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'recoveryEssay')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'recoveryEssay')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'recoveryEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- uvIntensity Column -->
                                        <ng-container matColumnDef="uvIntensity">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.uvIntensity' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'uvIntensity')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'uvIntensity')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'uvIntensity') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- introductionEssay Column -->
                                        <ng-container matColumnDef="introductionEssay">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.introductionEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'introductionEssay')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'introductionEssay')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'introductionEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- electricEssay Column -->
                                        <ng-container matColumnDef="electricEssay">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.electricEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'electricEssay')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'electricEssay')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'electricEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- filterSaturation Column -->
                                        <ng-container matColumnDef="filterSaturation">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.filterSaturation' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'filterSaturation')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'filterSaturation')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'filterSaturation') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- parallelismTest Column -->
                                        <ng-container matColumnDef="parallelismTest">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.parallelismTest' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'parallelismTest')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'parallelismTest')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'parallelismTest') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- microbioEssay Column -->
                                        <ng-container matColumnDef="microbioEssay">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.microbioEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'microbioEssay')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'microbioEssay')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'microbioEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- formolizatioEquipment Column -->
                                        <ng-container matColumnDef="formolizatioEquipment">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.formolizatioEquipment' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'formolizatioEquipment')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'formolizatioEquipment') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- anexCountRepose Column -->
                                        <ng-container matColumnDef="anexCountRepose">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.anexCountRepose' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'anexCountRepose')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'anexCountRepose')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'anexCountRepose') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- anexCountWorking Column -->
                                        <ng-container matColumnDef="anexCountWorking">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.anexCountWorking' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'anexCountWorking')">
                                                <span
                                                    [class]="checkEssayAccordanceRoom(indexArea, indexRoom, 'anexCountWorking')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'anexCountWorking') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- others Column -->
                                        <ng-container matColumnDef="others">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.others' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayRoom(indexArea, indexRoom, 'others')">
                                                <span
                                                    [innerHtml]="getCriteriaByEssayName(item, 'others') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="getDisplayColumns(room.essays)"></tr>
                                        <tr mat-row *matRowDef="let row; columns: getDisplayColumns(room.essays);">
                                        </tr>

                                    </table>
                                </mat-expansion-panel>
                            </mat-accordion>

                            <br /><br /><br />

                            <!-- gases area -->
                            <mat-toolbar color="secondary">
                                {{ 'protocolEdit.compressedGas.title' | translate }}

                                <button mat-icon-button color="accent" (click)="openDialogCompressedGas(indexArea)"
                                    *ngIf="canAdd()" [disabled]="!enableEditArea()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </mat-toolbar>
                            <mat-accordion>
                                <mat-expansion-panel
                                    *ngFor="let compressedGas of area.compressedGases; let indexCompressedGas = index"
                                    (opened)="setCompressedGasOpened(indexCompressedGas, true)"
                                    [expanded]="isCompressedGasOpened(indexCompressedGas)"
                                    (closed)="setCompressedGasOpened(indexCompressedGas, false)">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <span [class]="checkAccordanceCompressedGas(indexArea, indexCompressedGas)">
                                                {{compressedGas.code}} </span>
                                            <button mat-icon-button color="accent"
                                                *ngIf="isCompressedGasOpened(indexCompressedGas)"
                                                (click)="openDialogEditCompressedGas(indexArea, indexCompressedGas)"
                                                [disabled]="!enableEditArea()">
                                                <mat-icon style="font-size: 20px;">mode_edit</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" [disabled]="!enableEditArea()"
                                                *ngIf="isCompressedGasOpened(indexCompressedGas) && canAdd()"
                                                (click)="deleteCompressedGas(indexArea, indexCompressedGas)">
                                                <mat-icon style="font-size: 20px;">delete</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                *ngIf="isCompressedGasOpened(indexCompressedGas)"
                                                (click)="moveToTopCompressedGas(indexArea, indexCompressedGas)"
                                                [disabled]="indexCompressedGas === 0 && !enableEditArea()">
                                                <mat-icon style="transform: rotate(90deg);font-size: 20px;">first_page
                                                </mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                *ngIf="isCompressedGasOpened(indexCompressedGas)"
                                                (click)="movePreviousCompressedGas(indexArea, indexCompressedGas)"
                                                [disabled]="indexCompressedGas === 0 && !enableEditArea()">
                                                <mat-icon style="font-size: 20px;">keyboard_arrow_up</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                *ngIf="isCompressedGasOpened(indexCompressedGas)"
                                                (click)="moveNextCompressedGas(indexArea, indexCompressedGas)"
                                                [disabled]="indexCompressedGas === (area.compressedGases.length - 1) && !enableEditArea()">
                                                <mat-icon style="font-size: 20px;">keyboard_arrow_down</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                *ngIf="isCompressedGasOpened(indexCompressedGas)"
                                                (click)="moveToBottomCompressedGas(indexArea, indexCompressedGas)"
                                                [disabled]="indexCompressedGas === (area.compressedGases.length - 1) && !enableEditArea()">
                                                <mat-icon style="transform: rotate(90deg);font-size: 20px;">last_page
                                                </mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent" [disabled]="!enableEditArea()"
                                                *ngIf="isCompressedGasOpened(indexCompressedGas) && canAdd()"
                                                (click)="openDialogEssayCompressedGas(indexCompressedGas, indexArea)">
                                                <mat-icon style="font-size: 20px;">add</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                *ngIf="isCompressedGasOpened(indexCompressedGas)"
                                                (click)="downloadDatasheetCompressedGas(indexCompressedGas, indexArea)">
                                                <mat-icon style="font-size: 20px;">cloud_download</mat-icon>
                                            </button>

                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <!-- TABLA DE ENSAYOS CREADOS -->
                                    <table mat-table matSort #filtersTable [dataSource]="[compressedGas]">

                                        <!-- essay Column -->
                                        <ng-container matColumnDef="essay">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'protocolEdit.essays.title.generic' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item">
                                                {{ 'protocolEdit.essays.criteria.label' | translate }}
                                            </td>
                                        </ng-container>

                                        <!-- particleCount Column -->
                                        <ng-container matColumnDef="particleCount">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.particleCount' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'particleCount')">
                                                <span
                                                    [class]="checkEssayAccordanceCompressedGas(indexArea, indexCompressedGas, 'particleCount')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'particleCount') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- microbioEssay Column -->
                                        <ng-container matColumnDef="microbioEssay">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.microbioEssay' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'microbioEssay')">
                                                <span
                                                    [class]="checkEssayAccordanceCompressedGas(indexArea, indexCompressedGas, 'microbioEssay')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'microbioEssay') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- hydrocarbonLevel Column -->
                                        <ng-container matColumnDef="hydrocarbonLevel">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.hydrocarbonLevel' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'hydrocarbonLevel')">
                                                <span
                                                    [class]="checkEssayAccordanceCompressedGas(indexArea, indexCompressedGas, 'hydrocarbonLevel')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'hydrocarbonLevel') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- dewPoint Column -->
                                        <ng-container matColumnDef="dewPoint">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.dewPoint' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'dewPoint')">
                                                <span
                                                    [class]="checkEssayAccordanceCompressedGas(indexArea, indexCompressedGas, 'dewPoint')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'dewPoint') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- linePreasure Column -->
                                        <ng-container matColumnDef="linePreasure">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.linePreasure' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'linePreasure')">
                                                <span
                                                    [class]="checkEssayAccordanceCompressedGas(indexArea, indexCompressedGas, 'linePreasure')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'linePreasure') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- coConcentration Column -->
                                        <ng-container matColumnDef="coConcentration">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.coConcentration' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'coConcentration')">
                                                <span
                                                    [class]="checkEssayAccordanceCompressedGas(indexArea, indexCompressedGas, 'coConcentration')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'coConcentration') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- co2Concentration Column -->
                                        <ng-container matColumnDef="co2Concentration">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.co2Concentration' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'co2Concentration')">
                                                <span
                                                    [class]="checkEssayAccordanceCompressedGas(indexArea, indexCompressedGas, 'co2Concentration')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'co2Concentration') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- noConcentration Column -->
                                        <ng-container matColumnDef="noConcentration">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.noConcentration' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'noConcentration')">
                                                <span
                                                    [class]="checkEssayAccordanceCompressedGas(indexArea, indexCompressedGas, 'noConcentration')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'noConcentration') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- no2Concentration Column -->
                                        <ng-container matColumnDef="no2Concentration">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.no2Concentration' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'no2Concentration')">
                                                <span
                                                    [class]="checkEssayAccordanceCompressedGas(indexArea, indexCompressedGas, 'no2Concentration')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'no2Concentration') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                         <!-- anexCount Column -->
                                         <ng-container matColumnDef="anexCount">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.anexCount' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'anexCount')">
                                                <span
                                                    [class]="checkEssayAccordanceCompressedGas(indexArea, indexCompressedGas, 'anexCount')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'anexCount') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <!-- others Column -->
                                        <ng-container matColumnDef="others">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{ 'essayAir.others' | translate }}
                                            </th>
                                            <td mat-cell *matCellDef="let item" class="tableEssayProtocol"
                                                (click)="openDialogEditEssayCompressedGas(indexArea, indexCompressedGas, 'others')">
                                                <span
                                                    [class]="checkEssayAccordanceCompressedGas(indexArea, indexCompressedGas, 'others')"
                                                    [innerHtml]="getCriteriaByEssayName(item, 'others') | safe: 'html'"></span>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="getDisplayColumns(compressedGas.essays)">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: getDisplayColumns(compressedGas.essays);">
                                        </tr>

                                    </table>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </mat-tab>
                    </mat-tab-group>

                </mat-card-content>
            </mat-card>

            <app-execution-edit-attachments #attachmentsComponent></app-execution-edit-attachments>

            <!-- Deviations -->
            <mat-card class="card" [hidden]="protocol?.id == null">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'executionEdit.deviations.title' | translate }}
                    </mat-toolbar>

                    <div fxLayout="column">
                        <div fxFlex="100%">
                            <mat-form-field class="campoFormulario" style="width: 100%">
                                <textarea rows="5" matAutosizeMinRows="5" matInput style="resize: none;"
                                    placeholder="{{ 'executionEdit.deviations.deviations.label' | translate }}"
                                    [(ngModel)]="execution.deviations" name="deviations"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                </mat-card-content>
            </mat-card>
            <!-- Observations -->
            <mat-card class="card" [hidden]="execution?.id == null">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'executionEdit.observations.title' | translate }}
                    </mat-toolbar>

                    <div fxLayout="column">
                        <div fxFlex="100%" *ngIf="protocol?.id != null">
                            <mat-form-field class="campoFormulario" style="width: 100%">
                                <textarea rows="5" matAutosizeMinRows="5" matInput disabled
                                    [(ngModel)]="protocol.observations"
                                    placeholder="{{ 'executionEdit.observations.protocol.label' | translate }}"
                                    name="observationProtocol" style="resize: none;"></textarea>
                            </mat-form-field>
                        </div>

                        <div fxFlex="100%">
                            <mat-form-field class="campoFormulario" style="width: 100%">
                                <textarea rows="5" matAutosizeMinRows="5" matInput [(ngModel)]="execution.observations"
                                    name="observationExecution" style="resize: none;"
                                    placeholder="{{ 'executionEdit.observations.execution.label' | translate }}"></textarea>
                            </mat-form-field>
                        </div>

                        <div fxFlex="100%" *ngIf="protocol?.id != null">
                            <mat-form-field class="campoFormulario" style="width: 100%">
                                <textarea rows="5" matAutosizeMinRows="5" matInput disabled [(ngModel)]="protocol.notes"
                                    placeholder="{{ 'executionEdit.notes.execution.label' | translate }}"
                                    name="notesExecution" style="resize: none;"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                </mat-card-content>
            </mat-card>
            <!-- Buttons -->
            <mat-card class="card">
                <mat-card-actions>
                    <div fxFlex></div>
                    <button mat-raised-button color="primary" *ngIf="showRevertSign()" (click)="revertSign()">
                        {{ 'button.revertSign' | translate }} </button>
                    <button mat-raised-button color="primary" *ngIf="showBack()" (click)="calculateBack()">
                        {{ 'button.retreat' | translate }} </button>
                    <div fxFlex="5px" *ngIf="showExecute()"></div>
                    <button mat-raised-button color="primary" *ngIf="showExecute()" (click)="execute()">
                        {{ 'button.advance' | translate }}</button>
                    <div fxFlex="5px" *ngIf="showManualSign()"></div>
                    <button mat-raised-button color="primary" *ngIf="showManualSign()" (click)="manualSign()">
                        {{ 'button.sign-manual' | translate }}</button>
                    <div fxFlex="5px" *ngIf="showAutomaticSign()"></div>
                    <button mat-raised-button color="primary" *ngIf="showAutomaticSign()" (click)="automaticSign()">
                        {{ 'button.sign-automatic' | translate }}</button>
                    <div fxFlex="10px"></div>
                    <button mat-raised-button color="primary" (click)="saveExecution()">
                        {{ 'button.save' | translate }} </button>

                    <div matTooltip="{{ 'button.disabled.offline' | translate }}"
                        [matTooltipDisabled]="!disableOfflineButton">
                        <button mat-raised-button color="accent" *ngIf="showDownloadButton()" (click)="downloadPdf()"
                            [disabled]="disableOfflineButton">{{ 'button.generateDoc' | translate }}</button>
                    </div>
                    <button mat-raised-button color="secondary" (click)="cancel()">
                        {{ 'button.cancel' | translate }}</button>
                </mat-card-actions>
            </mat-card>
            <br />
            <br />
            <br />

        </form>
    </mat-tab>
    <mat-tab label="{{ 'executionEdit.historical.title' | translate }}" *ngIf="execution.id != null">
        <app-execution-edit-audit-air [idExecution]="execution.id" [documentCode]="execution.documentCode">
        </app-execution-edit-audit-air>
    </mat-tab>
</mat-tab-group>