import * as _ from 'lodash-es';

import { Execution, ExecutionAttachmentFilter, ExecutionAuditFilter, ExecutionFilter } from '../model/execution';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DateUtils } from '../utils/dateUtils';
import { Essay } from '../model/essay';
import { EssayProtocol } from '../model/essayProtocol';
import { ExecutionReportFilter } from '../model/attachment';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { SensorData } from '../model/sensorData';
import { Sensor } from '../model/sensor';

@Injectable()
export class ExecutionService {

    constructor(private http: HttpClient) {
    }

    findAll(filter: ExecutionFilter): Observable<any> {
        const url = environment.thermalUrl + '/execution/';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getExcel(filter: ExecutionFilter): Observable<any> {
        const url = environment.thermalUrl + '/execution/excel';

        return this.http.post(url, filter, { responseType: 'blob' });
    }

    findCurrentByIdProtocol(idProtocol: number): Observable<any> {
        const url = environment.thermalUrl + `/execution/current/${idProtocol}/`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOne(id: number): Observable<any> {
        const url = environment.thermalUrl + `/execution/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    checkValidation(id: number): Observable<any> {
        const url = environment.thermalUrl + `/execution/${id}/checkValidation`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    checkForDownload(id: number): Observable<any> {
        const url = environment.thermalUrl + `/execution/${id}/check`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOneShort(id: number): Observable<any> {
        const url = environment.thermalUrl + `/execution/${id}/short`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOneEssay(id: number): Observable<any> {
        const url = environment.thermalUrl + `/execution/essayValue/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getEventValue(id: number): Observable<any> {
        const url = environment.thermalUrl + `/execution/essayValue/${id}/event`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getLethalityValue(essay: EssayProtocol): Observable<any> {
        const essaySend = _.cloneDeep(essay);

        delete essaySend.sensors;

        const url = environment.thermalUrl + `/execution/essayValue/${essaySend.id}/lethality`;

        return this.http.post(url, essaySend).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getMinMaxValue(essay: EssayProtocol): Observable<any> {
        const essaySend = _.cloneDeep(essay);

        delete essaySend.sensors;

        const url = environment.thermalUrl + `/execution/essayValue/${essaySend.id}/minMax`;

        return this.http.post(url, essaySend).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getHotColdSpotValue(essay: EssayProtocol): Observable<any> {
        const essaySend = _.cloneDeep(essay);

        delete essaySend.sensors;

        const url = environment.thermalUrl + `/execution/essayValue/${essaySend.id}/hotColdSpot`;

        return this.http.post(url, essaySend).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getDiffAvgValue(essay: EssayProtocol): Observable<any> {
        const essaySend = _.cloneDeep(essay);

        delete essaySend.sensors;

        const url = environment.thermalUrl + `/execution/essayValue/${essaySend.id}/diffAvgValue`;

        return this.http.post(url, essaySend).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    create(exe: Execution, token: string): Observable<any> {
        const url = environment.thermalUrl + '/execution/create';

        return this.http.post(url, {
            execution: exe,
            token
        }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    save(ex: Execution): Observable<any> {
        const url = environment.thermalUrl + `/execution/${ex.id}`;

        const headers = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8'
        });
        const exe = _.cloneDeep(ex);

        exe.essays.forEach(essay => {
            essay.essayValues.forEach(essayValue => {
                if (essayValue.fcStartEssay != null) {
                    essayValue.fcStartEssay = DateUtils.anyToDate(essayValue.fcStartEssay as Date);
                    essayValue.fcStartEssay = new Date(`${essayValue.fcStartEssay.toString().split('GMT')[0]} UTC`).toISOString()
                        .split('.')[0] + '.000+0000';
                }

                if (essayValue.fcEndEssay != null) {
                    essayValue.fcEndEssay = DateUtils.anyToDate(essayValue.fcEndEssay as Date);
                    essayValue.fcEndEssay = new Date(`${essayValue.fcEndEssay.toString().split('GMT')[0]} UTC`).toISOString()
                        .split('.')[0] + '.000+0000';
                }

                essayValue.sensors.forEach(s => {
                    delete s.data;
                });
            });
        });

        const send = JSON.stringify(exe, null, 2);

        return this.http.put(url, send, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    validate(exe: Execution, token: string, forceAccording: boolean, reasonForce: string): Observable<any> {
        const url = environment.thermalUrl + `/execution/${exe.id}/validate/`;

        const body = { forceAccording, reasonForce, token };

        return this.http.put(url, body).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    signInfo(exe: Execution): Observable<any> {
        const url = environment.thermalUrl + `/execution/${exe.id}/signInfo/`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    addSign(exe: Execution, token: string, signTypes: number[], user: string, config: ExecutionReportFilter): Observable<any> {
        const url = environment.thermalUrl + `/execution/${exe.id}/addSign`;

        const form = new FormData();
        form.append('token', token);
        form.append('user', user);
        form.append('signTypes', signTypes.join(';'));
        form.append('config', JSON.stringify(config));

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    refuseToSign(exe: Execution, token: string, reason: string): Observable<any> {
        const url = environment.thermalUrl + `/execution/${exe.id}/refuseToSign`;

        const form = new FormData();
        form.append('token', token);
        form.append('reason', reason);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    manualSign(exe: Execution, token: string, forceAccording: boolean, reasonForce: string, file: File): Observable<any> {
        const url = environment.thermalUrl + `/execution/${exe.id}/manualSign`;

        const form = new FormData();
        form.append('forceAccording', JSON.stringify(forceAccording));
        form.append('reasonForce', reasonForce);
        form.append('reason', exe.reason);
        form.append('token', token);
        form.append('file', file, file.name);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    automaticSign(
        exe: Execution, token: string, forceAccording: boolean, reasonForce: string, signTypes: number[], config: ExecutionReportFilter)
        : Observable<any> {
        const url = environment.thermalUrl + `/execution/${exe.id}/automaticSign`;

        const form = new FormData();
        form.append('forceAccording', JSON.stringify(forceAccording));
        form.append('reasonForce', reasonForce);
        form.append('reason', exe.reason);
        form.append('token', token);
        form.append('signTypes', signTypes.join(';'));
        form.append('config', JSON.stringify(config));

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    invalidateByDoc(exe: Execution, token: string): Observable<any> {
        const url = environment.thermalUrl + `/execution/${exe.id}/invalidateByDoc/`;

        return this.http.put(url, { token }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    delete(id: number): Observable<any> {
        const url = environment.thermalUrl + `/execution/${id}`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadPdf(id: number, config: ExecutionReportFilter = new ExecutionReportFilter()): Observable<any> {
        const url = environment.thermalUrl + `/report/execution/${id}`;

        const headers = new HttpHeaders({
            lang: config.lang
        });

        const data = {
            graphsToInclude: config.graphsToInclude,
            dataTableInclude: config.dataTableInclude,
            reviewBy: config.reviewBy,
            toSign: config.toSign,
            reason: config.reason,
            conclusions: config.conclusions,
            hybride: config.hybride
        };

        return this.http.post(url, data, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadPdfExecutionVersion(execution: Execution): Observable<any> {
        return this.downloadPdfVersion(execution.id, execution.currentVersion);
    }

    downloadPdfVersion(id: number, version: string): Observable<any> {
        const url = environment.thermalUrl + `/report/execution/${id}/version/${version}`;

        return this.http.post(url, {}, { responseType: 'blob' });
    }

    downloadReportFullPdf(id: number, config: ExecutionReportFilter = new ExecutionReportFilter()): Observable<any> {
        const url = environment.thermalUrl + `/report/execution/${id}/full`;

        const data = {
            graphsToInclude: config.graphsToInclude,
            dataTableInclude: config.dataTableInclude
        };

        return this.http.post(url, data).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadPrereportPdf(id: number, config: ExecutionReportFilter = new ExecutionReportFilter()): Observable<any> {
        const url = environment.thermalUrl + `/report/execution/${id}/pre`;

        const headers = new HttpHeaders({
            lang: config.lang
        });

        return this.http.get(url, { responseType: 'blob', headers });
    }

    downloadAuditPdf(id: number): Observable<any> {
        const url = environment.thermalUrl + `/report/execution/${id}/audit`;

        return this.http.get(url, { responseType: 'blob' });
    }

    getAudit(id: number, filter: ExecutionAuditFilter): Observable<any> {
        const url = environment.thermalUrl + `/execution/${id}/audit`;

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getAttachments(id: number, filter: ExecutionAttachmentFilter): Observable<any> {
        const url = environment.thermalUrl + `/execution/${id}/attachment`;

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    moveAttachment(id: number, previousIndex: number, currentIndex: number): Observable<any> {
        const url = environment.thermalUrl + `/execution/${id}/attachment/move`;

        return this.http.post(url, { previousIndex, currentIndex }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    discardTime(idExecution: number, idEssay: number, startDate: Date, endDate: Date): Observable<any> {
        const url = environment.thermalUrl + `/execution/${idExecution}/essay/${idEssay}/discardTime`;

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: { startDate, endDate }
        };

        return this.http.delete(url, options).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    copyToEssay(idExecution: number, idEssay: number, idEssayToCopy: number, dateFrom: Date, dateTo: Date): Observable<any> {
        const url = environment.thermalUrl + `/execution/${idExecution}/copySensors/${idEssay}/${idEssayToCopy}/`;

        const data = { dateFrom, dateTo };

        return this.http.post(url, data).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getConclusions(id: number, lang: string): Observable<any> {
        const url = environment.thermalUrl + `/execution/${id}/conclusions`;

        const headers = new HttpHeaders({
            lang
        });

        return this.http.get(url, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));

    }

    calculateMkt(data: number[], idUnit: number): Observable<any> {
        const url = environment.thermalUrl + '/execution/calculateMkt/';

        return this.http.post(url, { data, idUnit }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    revertSign(id: number, reason: string, token: string): Observable<any> {
        const url = environment.thermalUrl + `/execution/${id}/revertSign`;

        const form = new FormData();
        form.append('token', token);
        form.append('reason', reason);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    correctSensors(id: number, idEssayVariable: number, checks: number[]): Observable<any> {
        const url = environment.thermalUrl + `/execution/${id}/essay/${idEssayVariable}/correctSensors`;

        return this.http.put(url, checks).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    checkCopyToGroup(id: number, idGroup: number): Observable<any> {
        const url = environment.thermalUrl + `/execution/clonate/${id}/toGroup/${idGroup}/check`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    copyToGroup(id: number, idGroup: number): Observable<any> {
        const url = environment.thermalUrl + `/execution/clonate/${id}/toGroup/${idGroup}`;

        return this.http.put(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    checkEssayDiffErrors(id: number): Observable<any> {
        const url = environment.thermalUrl + `/execution/essayValue/${id}/sensorAvg`;
        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }


    checkEssayDiffAveragesErrors(id: number): Observable<any> {
        const url = environment.thermalUrl + `/execution/essayValue/${id}/diffAvaranges`;
        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    checkDiffCalculateProbeWithRespectMeanErrors(id: number): Observable<any> {
        const url = environment.thermalUrl + `/execution/essayValue/${id}/diffCalculateProbeWithRespectMean`;
        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    historicalEquipment(id: number): Observable<any> {
        const url = environment.thermalUrl + `/execution/historical-equipment/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
