import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalibrationEditComponent } from './calibration-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '../../shared/shared.module';
import { CalibrationEditAsFoundDialogComponent } from './calibration-edit-as-found-dialog.component';
import { CalibrationEditAsLeftDialogComponent } from './calibration-edit-as-left-dialog.component';
import { CalibrationEditPatternsDialogComponent } from './calibration-edit-patterns-dialog.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { CalibrationEditGenerateReportComponent } from './calibration-edit-generate-report.component';
import { CalibrationEditConfirmSaveComponent } from './calibration-edit-dialog-confirmSave.component';
import { CalibrationEditCreateEquipmentComponent } from './calibration-edit-create-equipment.component';
import { CalibrationEditAuditComponent } from './calibration-edit-audit.component';
import { CalibrationEditConfigDialogComponent } from './calibration-edit-config-dialog.component';
import { CalibrationEditAutomaticSignReportComponent } from './calibration-edit-automatic-sign-report.component';
import { CalibrationEditManualSignReportComponent } from './calibration-edit-manual-sign-report.component';
import { CalibrationEditDialogParticlesComponent } from './calibration-edit-dialog-particles.component';
import { CalibrationEditMultichannelComponent } from './calibration-edit-multichannel/calibration-edit-multichannel.component';

@NgModule({
    declarations: [
        CalibrationEditComponent, CalibrationEditAsFoundDialogComponent,
        CalibrationEditAsLeftDialogComponent, CalibrationEditPatternsDialogComponent,
        CalibrationEditGenerateReportComponent, CalibrationEditConfirmSaveComponent,
        CalibrationEditCreateEquipmentComponent, CalibrationEditAuditComponent, CalibrationEditConfigDialogComponent,
        CalibrationEditAutomaticSignReportComponent, CalibrationEditManualSignReportComponent, CalibrationEditDialogParticlesComponent, CalibrationEditMultichannelComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        /* Custom modules */
        AngularMaterialModule,
        SharedModule,
        PipesModule,
        /* Otros */
        FlexLayoutModule
    ],
    exports: [CalibrationEditComponent]
})
export class CalibrationEditModule { }
