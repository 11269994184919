import { Component, Inject } from '@angular/core';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Equipment } from '../../../model/equipment';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { InternalEquipment } from '../../../model/internalEquipment';
import { Subject, takeUntil } from 'rxjs';
import { EquipmentAutocompleteFilter } from '../../../model/autocompleteFilter';
import { InternalEquipmentService } from '../../../services/internalEquipment.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import * as moment from 'moment';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { CalibrationEditCreateEquipmentComponent } from '../calibration-edit/calibration-edit-create-equipment.component';
import { CalibrationResponsibleEnum } from '../../../model/calibration';
import { EquipmentPerformances } from '../../../utils/equipmentUtils';

export interface DialogDataEquipment {
  equipment: Equipment;
  isEdit: boolean;
  idClient: number;
  isDisabled: boolean;
}

@Component({
  selector: 'app-isothermal-characterization-edit-dialog-equipment',
  templateUrl: 'isothermal-characterization-edit-dialog-equipment.html'
})
export class IsothermalCharacterizationEditEquipmentDialogComponent {

  public equipmentAutoComplete: InternalEquipment[];
  matcher = new MyErrorStateMatcher();
  tituloDialog: string;
  form: UntypedFormGroup;
  private destroy$ = new Subject<void>();

  constructor(
    fb: UntypedFormBuilder,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<IsothermalCharacterizationEditEquipmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataEquipment,
    private internalEquipmentService: InternalEquipmentService,
    public dialog: MatDialog) {
    if (data.isEdit) {
      this.tituloDialog = this.translate.instant('protocolEdit.dialog.equipment.title.edit', { name: data.equipment.equipment });
    } else {
      this.tituloDialog = this.translate.instant('protocolEdit.dialog.equipment.title.create');
    }

    this.form = fb.group({
      equipment: [this.data.equipment.equipment, [Validators.required]],
      maker: [this.data.equipment.maker],
      model: [this.data.equipment.model],
      serialNum: [this.data.equipment.serialNum],
      internalId: [this.data.equipment.internalId],
      location: [this.data.equipment.location, [Validators.required]]
    });
  }

  onOkClick(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.data.equipment);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onEquipmentChange($event: any, trigger: MatAutocompleteTrigger): void {
    const equipmentSelected = $event.option.value as InternalEquipment;

    if (equipmentSelected.nearToExpire || equipmentSelected.expired) {
      const expirationDate = moment(equipmentSelected.expirationDate).format('DD/MMM/YYYY').replace('./', '/').toUpperCase();


      let message = this.translate.instant('executionEdit.essays.sensor.form.error.nearToExpire', { expirationDate }) as string;

      if (equipmentSelected.expired) {
        message = this.translate.instant('executionEdit.essays.sensor.form.error.expired') as string;
      }

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
        if (result === true) {
          this.data.equipment.idEquipment = equipmentSelected.id;
          this.data.equipment.equipment = equipmentSelected.equipment;
          this.data.equipment.model = equipmentSelected.model;
          this.data.equipment.maker = equipmentSelected.maker;
          this.data.equipment.serialNum = equipmentSelected.serialNum;
          this.data.equipment.equipment = equipmentSelected.equipment;
          this.data.equipment.internalId = equipmentSelected.name;
          this.data.equipment.location = equipmentSelected.location;
        }
      });
    } else {
      this.data.equipment.model = equipmentSelected.model;
      this.data.equipment.maker = equipmentSelected.maker;
      this.data.equipment.serialNum = equipmentSelected.serialNum;
      this.data.equipment.equipment = equipmentSelected.equipment;
      this.data.equipment.internalId = equipmentSelected.name;
      this.data.equipment.location = equipmentSelected.location;
      this.data.equipment.idEquipment = equipmentSelected.id;
    }
  }

  lookupEquipment($event): void {
    let results = this.equipmentAutoComplete;

    const filter = new EquipmentAutocompleteFilter();

    filter.query = $event.target.value;
    filter.pageIndex = 0;
    filter.pageSize = 10;
    filter.sortBy = 'name';
    filter.sortDirection = 'asc';
    filter.forCalibrationPattern = true;
    filter.idTypeAction = EquipmentPerformances.CHARACTERIZATION;

    this.internalEquipmentService.findAutocomplete(filter).pipe(takeUntil(this.destroy$)).subscribe(item => {
      results = item.content;
      this.equipmentAutoComplete = results;
    }, () => {
      results = [];
      this.equipmentAutoComplete = results;
    });
  }

  createEquipment(): void {
    this.dialogRef.close();
    this.dialog.open(CalibrationEditCreateEquipmentComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        idType: CalibrationResponsibleEnum.EXTERNAL,
        idClient: this.data.idClient
      }
    });
  }

  displayFn(eq?: InternalEquipment | string | { name: string }): string {
    if (!eq) {
      return null;
    }

    let res: string = null;

    if (typeof eq === 'string') {
      res = eq;
    } else if (eq instanceof InternalEquipment || eq.name != null) {
      res = eq.name;
    }

    return res;
  }

}
