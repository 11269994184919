import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpUtils } from '../utils/httpUtils';
import { environment } from 'src/environments/environment';
import { InternalEquipmentUsedFilter } from '../model/internalEquipmentUsed';

@Injectable({
  providedIn: 'root'
})
export class InternalEquipmentUsedService {

  baseUrl = `${environment.thermalUrl}/internalEquipmentUsed`;
  constructor(private http: HttpClient) {
  }

  findAllAndExcel(filter: InternalEquipmentUsedFilter): Observable<any> {
    return this.http.post(`${this.baseUrl}/excel`, filter, { responseType: 'blob' }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }
}
