import { Component, ElementRef, ViewChild } from '@angular/core';
import { User, UserFilter } from 'src/app/model/user';
import { map, startWith } from 'rxjs/operators';

import { UntypedFormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { ManageNotificationsService } from 'src/app/services/manageNotifications.service';
import { ManageUsersService } from 'src/app/services/manageUsers.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { SendNotification } from 'src/app/model/notification';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-send-notifications',
  templateUrl: './send-notifications.component.html'
})
export class SendNotificationsComponent {

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;

  removable = true;

  notification: SendNotification;

  users: User[];
  filteredUsers: Observable<User[]>;
  currentUser: User;

  userCtrl = new UntypedFormControl();

  constructor(
    private location: Location,
    private translate: TranslateService,
    private userService: UserService,
    private usersService: ManageUsersService,
    private notificationsService: ManageNotificationsService,
    private snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    this.notification = new SendNotification();
    this.currentUser = this.userService.currentProfile;

    const filter = new UserFilter();
    filter.idGroup = this.currentUser.idActiveProfile;

    this.usersService.findAll(filter).subscribe(res => {
      this.users = res.content as User[];

      this.filteredUsers = this.userCtrl.valueChanges.pipe(startWith(null as string),
        map((user: string | null) => user ? this._filter(user) : this.users.slice())
      );
    });
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.notification.notifiedUsers.push(value);
    }

    event.input.value = '';

    this.userCtrl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.notification.notifiedUsers.push(event.option.value as string);
    this.userInput.nativeElement.value = '';
    this.userCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.notification.notifiedUsers.indexOf(fruit);

    if (index >= 0) {
      this.notification.notifiedUsers.splice(index, 1);
    }
  }

  sendNotification(): void {
    this.spinnerService.show();

    this.notificationsService.sendNotification(this.notification).subscribe(() => {
      this.spinnerService.hide();

      this.notification = new SendNotification();

      this.snackBarService.sendSuccess(this.translate.instant('sendNotifications.form.sendMail.ok') as string);
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('sendNotifications.form.sendMail.error.generic') as string);
    });
  }

  clear(): void {
    this.notification = new SendNotification();
  }

  cancel(): void {
    this.location.back();
  }

  private _filter(value: string): User[] {
    const filterValue = value.toLowerCase();

    return this.users.filter(u => u.username.toLowerCase().includes(filterValue))
      .filter(u => !this.notification.notifiedUsers.includes(u.username));
  }

}
