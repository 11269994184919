import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ExecutionAir, ExecutionStatus } from 'src/app/model/execution';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { ExecutionEditGenerateReportComponent } from './execution-edit-generate-report.component';
import { TranslateService } from '@ngx-translate/core';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { takeUntil } from 'rxjs/operators';
import { ProtocolAir } from 'src/app/model/protocol';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ExecutionReportFilter } from 'src/app/model/attachment';
import { StringUtils } from 'src/app/utils/stringUtils';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ExecutionAirService } from 'src/app/services/executionAir.service';
import { EquipmentTypeAirService } from 'src/app/services/equipmentTypeAir.service';
import { EquipmentType } from 'src/app/model/equipmentType';
import { EssayAirService } from 'src/app/services/essayAir.service';
import { EssayAirTypeEnum, EssayType } from 'src/app/model/essayType';
import { AirUtils } from 'src/app/utils/airUtils';

export interface DialogDataValidateExecution {
  execution: ExecutionAir;
  protocol: ProtocolAir;
  documentSigned: File;
}

@Component({
  selector: 'app-execution-edit-validate-execution',
  templateUrl: './execution-edit-validate-execution.component.html'
})
export class ExecutionEditValidateExecutionComponent implements OnInit, OnDestroy {
  mainForm: UntypedFormGroup;

  forceAccording: boolean;
  hasUniformity: boolean;
  reasonForce = '';
  reason: string;
  equipmentTypesAir: EquipmentType[];
  essayTypes: EssayType[];
  errors: string[];
  warns: string[];
  uniformity: any;
  simpleEssay = [EssayAirTypeEnum.NOISE, EssayAirTypeEnum.LIGHT, EssayAirTypeEnum.UV_INTENSITY,
  EssayAirTypeEnum.DEF_PRESSURE, EssayAirTypeEnum.PARALLELISM_TEST, EssayAirTypeEnum.TEMPERATURE, EssayAirTypeEnum.HUMIDITY];
  accorgingEssay = [EssayAirTypeEnum.SMOKE_ESSAY_REPOSE, EssayAirTypeEnum.SMOKE_ESSAY_WORKING, EssayAirTypeEnum.ELECTRIC_ESSAY,
  EssayAirTypeEnum.PARALLELISM_TEST, EssayAirTypeEnum.INDUCTION_ESSAY, EssayAirTypeEnum.FILTER_SATURATION,
  EssayAirTypeEnum.MICROBIO_ESSAY];
  private destroy$ = new Subject<void>();


  constructor(
    fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ExecutionEditValidateExecutionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataValidateExecution,
    private equipmentTypeAirService: EquipmentTypeAirService,
    private essayService: EssayAirService,
    private executionService: ExecutionAirService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    this.mainForm = fb.group({
      forceAccording: [this.forceAccording],
      reasonForce: [this.reasonForce],
      reason: [this.reason]
    });
  }

  ngOnInit(): void {
    this.errors = [];
    this.warns = [];

    this.spinnerService.show();

    const anyArea = ArrayUtils.isNotEmpty(this.data.execution.areas);
    let anyEquipmentRoom = false;
    let anyEssay = false;
    let anyExecutedEssay = false;

    if (anyArea) {
      const areas = this.data.execution.areas;

      const allEquipments = ArrayUtils.flat(areas.map(a => a.equipments));
      const allRooms = ArrayUtils.flat(areas.map(a => a.rooms));
      const allGases = ArrayUtils.flat(areas.map(a => a.compressedGases));

      anyEquipmentRoom = ArrayUtils.isNotEmpty(allEquipments) || ArrayUtils.isNotEmpty(allRooms) || ArrayUtils.isNotEmpty(allGases);

      anyEssay = ArrayUtils.isNotEmpty(ArrayUtils.flat(allEquipments.map(eq => eq.essays)).filter(e => e.active)) ||
      ArrayUtils.isNotEmpty(ArrayUtils.flat(allRooms.map(eq => eq.essays)).filter(e => e.active)) ||
      ArrayUtils.isNotEmpty(ArrayUtils.flat(allGases.map(eq => eq.essays)).filter(e => e.active));

      anyExecutedEssay = ArrayUtils.isNotEmpty(ArrayUtils.flat(allEquipments.map(eq => eq.essays)).filter(e => e.active)
        .filter(e => e.according !== null)) ||
        ArrayUtils.isNotEmpty(ArrayUtils.flat(allRooms.map(eq => eq.essays)).filter(e => e.active).filter(e => e.according !== null)) ||
        ArrayUtils.isNotEmpty(ArrayUtils.flat(allGases.map(eq => eq.essays)).filter(e => e.active).filter(e => e.according !== null));
    }

    this.equipmentTypeAirService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: EquipmentType[]) => {
      this.equipmentTypesAir = res;

      this.data.execution.areas.forEach(area => {
        const areaName = area.name;
        area.equipments.forEach(equipment => {
          const equipmentName = AirUtils.showEquipment(equipment);

          if (StringUtils.isEmpty(equipment.alarmType) || StringUtils.isEmpty(equipment.equipmentStatus)
            || StringUtils.isEmpty(equipment.workingTime) || StringUtils.isEmpty(equipment.variatorPosition)) {
            this.errors.push(this.translate.instant('executionValidate.dialog.error.equipment.incomplete',
              { equipmentName, areaName }) as string);
          }

          this.essayService.findByEquipmentId(equipment.idType).pipe(takeUntil(this.destroy$)).subscribe((essayTypes: EssayType[]) => {
            this.essayTypes = essayTypes;
            equipment.essays.forEach(essayEq => {
              const essay = this.essayTypes.find(e => e.id === essayEq.idEssayType);
              const essayName = this.translate.instant('essayAir.' + essay.translation) as string;

              if (essayEq.active && essayEq.according === false) {
                this.warns.push(this.translate.instant('executionValidate.dialog.warningsEq',
                  { essayName, equipmentName, areaName }) as string);
              }

            });
          });
        });

        this.essayService.findByRoomId(1).pipe(takeUntil(this.destroy$)).subscribe((essayTypes: EssayType[]) => {
          area.rooms.forEach(room => {
            const roomName = room.name;
            this.essayTypes = essayTypes;
            room.essays.forEach(essayRoom => {
              const essay = this.essayTypes.find(e => e.id === essayRoom.idEssayType);
              const essayName = this.translate.instant('essayAir.' + essay.translation) as string;

              if (essayRoom.active && essayRoom.according === false) {
                this.warns.push(this.translate.instant('executionValidate.dialog.warningsRoom',
                  { essayName, roomName, areaName }) as string);
              }
            });
          });
        });

        this.essayService.findByCompressedGasId(1).pipe(takeUntil(this.destroy$)).subscribe((essayTypes: EssayType[]) => {
          area.compressedGases.forEach(gas => {
            const gasName = gas.code;
            this.essayTypes = essayTypes;
            gas.essays.forEach(essayGas => {
              const essay = this.essayTypes.find(e => e.id === essayGas.idEssayType);
              const essayName = this.translate.instant('essayAir.' + essay.translation) as string;

              if (essayGas.active && essayGas.according === false) {
                this.warns.push(this.translate.instant('executionValidate.dialog.warningsCompressedGas',
                  { essayName, gasName, areaName }) as string);
              }
            });
          });
        });
      });

      if (!anyArea) {
        this.errors.push(this.translate.instant('protocolEdit.dialog.essay.form.error.anyArea') as string);
      } else if (!anyEquipmentRoom) {
        this.errors.push(this.translate.instant('protocolEdit.dialog.essay.form.error.anyEquipmentRoom') as string);
      } else if (!anyEssay) {
        this.errors.push(this.translate.instant('protocolEdit.dialog.essay.form.error.anyEssay') as string);
      } else if (!anyExecutedEssay) {
        this.errors.push(this.translate.instant('protocolEdit.dialog.essay.form.error.anyExecutedEssay') as string);
      }

      this.executionService.checkForDownload(this.data.execution.id).pipe(takeUntil(this.destroy$)).subscribe((errors: string[]) => {
        this.errors.push(...errors);

        this.spinnerService.hide();
      });
    });

    const force: boolean = this.data.execution.according != null ? this.data.execution.according.valueOf() : false;
    this.forceAccording = force;
    this.reasonForce = StringUtils.isNotEmpty(this.data.execution.accordingReason) ? this.data.execution.accordingReason : null;
    this.reason = this.data.execution.reason;

    this.spinnerService.show();

    this.errors = [];
    this.warns = [];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showReason(): boolean {
    return (+this.data.execution.currentVersion) > 1;
  }

  isEmpty(array: any[]): boolean {
    return ArrayUtils.isEmpty(array);
  }

  canAccept(): boolean {
    let res = false;

    if (this.errors == null) {
      res = true;
    } else if (!this.forceAccording) {
      res = true;
    } else if (this.reasonForce != null && this.reasonForce !== '') {
      res = true;
    } else if (this.data.execution.idStatus === ExecutionStatus.PENDIENTE_FIRMA) {
      res = true;
    }

    return res;
  }

  canDownloadDocument(): boolean {
    return this.data.execution.idStatus === ExecutionStatus.PENDIENTE_FIRMA;
  }

  downloadDocument(): void {

    const dialogRef = this.dialog.open(ExecutionEditGenerateReportComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        execution: this.data.execution,
        protocol: this.data.protocol,
        reason: this.reason,
        toSign: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionReportFilter) => {
      if (result != null) {
        this.data.execution.conclusions = result.conclusions;
      }
    });
  }

  uploadDocumentToExecution(event): void {
    this.data.documentSigned = event.target.files[0] as File;

    if (this.data.documentSigned != null) {
      this.onOkClick();
    }
  }


  onOkClick(): void {
    if (this.data.execution.idStatus === ExecutionStatus.EN_EJECUCION) {
      this.validateByTec();
    } else if (this.data.execution.idStatus === ExecutionStatus.PENDIENTE_FIRMA) {
      this.sign();
    } else {
      this.snackBarService.sendError(this.translate.instant('executionEdit.form.status.error.notAllowed') as string);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private validateByTec() {

    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {

      if (result != null) {

        this.spinnerService.show();

        this.data.execution.reason = this.reason;

        this.executionService.validate(this.data.execution, result, this.forceAccording, this.reasonForce).pipe(takeUntil(this.destroy$))
          .subscribe(() => this.onFinishOk('validated'), () => {
            this.snackBarService.sendError(this.translate.instant('executionEdit.form.status.advance.error.generic') as string);

            this.spinnerService.hide();
          }
          );

      } else {
        this.spinnerService.hide();
      }
    });

  }

  private sign() {
    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    const reason = this.reason;

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {

      if (result != null) {

        this.spinnerService.show();

        this.data.execution.reason = reason;

        this.executionService.manualSign(this.data.execution, result, this.forceAccording, this.reasonForce, this.data.documentSigned)
          .pipe(takeUntil(this.destroy$)).subscribe(() => this.onFinishOk('finished'), () => {
            this.snackBarService.sendError(this.translate.instant('executionEdit.form.status.advance.error.generic') as string);

            this.spinnerService.hide();
          }
          );

      } else {
        this.spinnerService.hide();
      }
    });

  }

  private onFinishOk(statusTranslation: string) {
    const status = this.translate.instant('executionStatus.' + statusTranslation) as string;
    this.snackBarService.sendSuccess(this.translate.instant('executionEdit.form.status.advance.ok', { status }) as string);

    this.spinnerService.hide();

    this.dialogRef.close(this.data.execution);
  }

}
