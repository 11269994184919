import { Component, Inject, OnInit } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { EquipmentExecution } from 'src/app/model/equipment';
import { EquipmentType } from 'src/app/model/equipmentType';
import { InternalEquipment } from 'src/app/model/internalEquipment';
import { EquipmentTypeService } from 'src/app/services/equipmentType.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { Subject, takeUntil } from 'rxjs';
import { EquipmentAutocompleteFilter } from 'src/app/model/autocompleteFilter';
import { EquipmentPerformances } from 'src/app/utils/equipmentUtils';
import { CalibrationEditCreateEquipmentComponent } from '../../calibrations/calibration-edit/calibration-edit-create-equipment.component';
import { CalibrationResponsibleEnum } from 'src/app/model/calibration';
import { InternalEquipmentService } from 'src/app/services/internalEquipment.service';

export interface DialogDataEquipmentEdit {
  equipment: EquipmentExecution;
  idClient: number;
  isDisabled: boolean;
}

@Component({
  selector: 'app-execution-edit-equipment-edit',
  templateUrl: './execution-edit-equipment-edit.component.html'
})
export class ExecutionEditEquipmentEditComponent implements OnInit {

  public equipmentAutoComplete: InternalEquipment[];

  equipmentTypes: EquipmentType[];

  private destroy$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<ExecutionEditEquipmentEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataEquipmentEdit,
    private translate: TranslateService,
    private equipmentTypeService: EquipmentTypeService,
    public snackBarService: SnackBarService,
    private internalEquipmentService: InternalEquipmentService,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.equipmentTypeService.findAll().subscribe((res: EquipmentType[]) => this.equipmentTypes = res);
  }

  onOkClick(): void {
    const errs = [];

    if (!this.requiredReason()) {
      this.data.equipment.reasonChange = null;
    } else if (this.data.equipment.reasonChange == null || this.data.equipment.reasonChange === '') {
      errs.push(this.translate.instant('common.reasonChangeError'));
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.equipment);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }
  requiredReason(): boolean {
    let res = false;

    if (this.data.equipment.fromProtocol) {
      res = res || this.data.equipment.equipment !== this.data.equipment.equipmentProtocol;
      res = res || this.data.equipment.internalId !== this.data.equipment.internalIdProtocol;
      res = res || this.data.equipment.location !== this.data.equipment.locationProtocol;
      res = res || this.data.equipment.maker !== this.data.equipment.makerProtocol;
      res = res || this.data.equipment.model !== this.data.equipment.modelProtocol;
      res = res || this.data.equipment.serialNum !== this.data.equipment.serialNumProtocol;
      res = res || this.data.equipment.idType !== this.data.equipment.idTypeProtocol;
    }

    return res;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onEquipmentChange($event: any, trigger: MatAutocompleteTrigger): void {
    const equipmentSelected = $event.option.value as InternalEquipment;

    if (equipmentSelected.nearToExpire || equipmentSelected.expired) {
      const expirationDate = moment(equipmentSelected.expirationDate).format('DD/MMM/YYYY').replace('./', '/').toUpperCase();


      let message = this.translate.instant('executionEdit.essays.sensor.form.error.nearToExpire', { expirationDate }) as string;

      if (equipmentSelected.expired) {
        message = this.translate.instant('executionEdit.essays.sensor.form.error.expired') as string;
      }

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
        if (result === true) {
          this.data.equipment.idEquipment = equipmentSelected.id;
          this.data.equipment.equipment = equipmentSelected.equipment;
          this.data.equipment.model = equipmentSelected.model;
          this.data.equipment.maker = equipmentSelected.maker;
          this.data.equipment.serialNum = equipmentSelected.serialNum;
          this.data.equipment.equipment = equipmentSelected.equipment;
          this.data.equipment.internalId = equipmentSelected.name;
          this.data.equipment.location = equipmentSelected.location;
        }
      });
    } else {
      this.data.equipment.model = equipmentSelected.model;
      this.data.equipment.maker = equipmentSelected.maker;
      this.data.equipment.serialNum = equipmentSelected.serialNum;
      this.data.equipment.equipment = equipmentSelected.equipment;
      this.data.equipment.internalId = equipmentSelected.name;
      this.data.equipment.location = equipmentSelected.location;
      this.data.equipment.idEquipment = equipmentSelected.id;
    }
  }

  lookupEquipment($event): void {
    let results = this.equipmentAutoComplete;

    const filter = new EquipmentAutocompleteFilter();

    filter.query = $event.target.value;
    filter.pageIndex = 0;
    filter.pageSize = 10;
    filter.sortBy = 'name';
    filter.sortDirection = 'asc';
    filter.forCalibrationPattern = true;
    filter.idTypeAction = EquipmentPerformances.CALIBRATION;

    this.internalEquipmentService.findAutocomplete(filter).pipe(takeUntil(this.destroy$)).subscribe(item => {
      results = item.content;
      this.equipmentAutoComplete = results;
    }, () => {
      results = [];
      this.equipmentAutoComplete = results;
    });
  }

  createEquipment(): void {
    this.dialogRef.close();
    this.dialog.open(CalibrationEditCreateEquipmentComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        idType: CalibrationResponsibleEnum.EXTERNAL,
        idClient: this.data.idClient
      }
    });
  }

  displayFn(eq?: InternalEquipment | string | { name: string }): string {
    if (!eq) {
      return null;
    }

    let res: string = null;

    if (typeof eq === 'string') {
      res = eq;
    } else if (eq instanceof InternalEquipment || eq.name != null) {
      res = eq.name;
    }

    return res;
  }
}
