/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IsothermalCharacterizationReportFilter } from 'src/app/model/attachment';
import { IsothermalCharacterization } from 'src/app/model/isothermalCharacterization';
import { IsothermalCharacterizationService } from 'src/app/services/isothermalCharacterization.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LangFlag, TranslationService } from 'src/app/services/translation.service';
import { IsothermalCharacterizationUtils } from 'src/app/utils/isothermalCharacterizationUtils';
import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import {
  DialogDataValidateIsothermalCharacterization
} from './isothermal-characterization-edit-validate-isothermal-characterization.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ManageUsersService } from '../../../services/manageUsers.service';
import { GroupEnum } from '../../../utils/groupUtils';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-isothermal-characterization-edit-sign-report',
  templateUrl: './isothermal-characterization-edit-sign-report.component.html'
})
export class IsothermalCharacterizationEditSignReportComponent implements OnInit, OnDestroy {

  forceAccording: boolean;
  reasonForce: string;
  reason: string;

  reportConfig: IsothermalCharacterizationReportFilter;
  langs: LangFlag[];

  checkFullReport: boolean;

  selectedGroup: number;

  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<IsothermalCharacterizationEditSignReportComponent>,
    private translationService: TranslationService,
    public dialog: MatDialog,
    private isothermalCharacterizationService: IsothermalCharacterizationService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataValidateIsothermalCharacterization,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private userService: UserService,
    private manageUsersService: ManageUsersService) {
  }

  ngOnInit(): void {
    this.reportConfig = new IsothermalCharacterizationReportFilter();
    this.reportConfig.lang = this.translationService.currentLang;

    this.langs = this.translationService.configuredLangs;

    this.reason = this.data.isothermalCharacterization.reason;

    this.checkFullReport = false;

    const currentProfile = this.userService.currentProfile;

    this.selectedGroup = currentProfile.profiles.find(p => p.idGroup === currentProfile.idActiveProfile)?.idGroup;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showChangeLangIcon(): boolean {
    return this.langs.length > 1;
  }

  showReason(): boolean {
    return +this.data.isothermalCharacterization.currentVersion > 1;
  }

  onOkClick(): void {
    const dialogRefPassword = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    const dialogRef = this.dialogRef;

    const iso = this.data.isothermalCharacterization;

    dialogRefPassword.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(token => {
      if (token != null) {
        if (iso.idClient) {
          this.manageUsersService.checkIfHasUser(iso.idClient).subscribe(hasUser => {
            if(!hasUser && this.selectedGroup === GroupEnum.QUALIPHARMA){
              this.snackBarService.sendError(this.translate.instant('manageUsers.dialog.clientHasUsers.error') as string);
            } else {
              this.spinnerService.show();

              if (this.showReason()) {
                iso.reason = this.reason;

                this.isothermalCharacterizationService.save(iso).pipe(takeUntil(this.destroy$)).subscribe(() => {
                  this.automaticSign(iso, token, dialogRef);
                });
              } else {
                this.automaticSign(iso, token, dialogRef);
              }
            }});
        } else {
          this.spinnerService.show();
          if (this.showReason()) {
            iso.reason = this.reason;

            this.isothermalCharacterizationService.save(iso).pipe(takeUntil(this.destroy$)).subscribe(() => {
              this.automaticSign(iso, token, dialogRef);
            });
          } else {
            this.automaticSign(iso, token, dialogRef);
          }
        }
      } else {
        this.spinnerService.hide();
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onChangeCheck(event: MatCheckboxChange): void {
    this.checkFullReport = event.checked;
  }

  isFullReportChecked(): boolean {
    return this.checkFullReport;
  }

  private automaticSign(iso: IsothermalCharacterization, token: string, dialogRef: MatDialogRef<any>) {
    const isoId = iso.id;

    // eslint-disable-next-line max-len
    this.isothermalCharacterizationService.automaticSign(isoId, token, this.forceAccording, this.reasonForce, this.checkFullReport, this.reportConfig.dataTableInclude).pipe(takeUntil(this.destroy$))
      .subscribe((item: IsothermalCharacterization) => {

        this.isothermalCharacterizationService.downloadPdfVersion(isoId, iso.currentVersion).pipe(takeUntil(this.destroy$))
          .subscribe(file => {
            const name = IsothermalCharacterizationUtils.getNameForCharacterization(iso, this.translate);
            saveAs(file, name);

            const status = this.translate.instant('isothermalCharacterizationStatus.' + item.statusTranslation) as string;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            this.snackBarService.sendSuccess(this.translate.instant('isothermalCharacterizationEdit.form.status.advance.ok', { status }));

            if (item.createPerformance) {
              this.snackBarService.sendSuccess(this.translate.instant('isothermalCharacterizationEdit.dialog.generateReport.form.sign.createPerformances'));
            }
            dialogRef.close(true);
          });

        this.data.isothermalCharacterization = item;
        this.spinnerService.hide();
      }, () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.snackBarService.sendError(this.translate.instant('isothermalCharacterizationEdit.form.status.advance.error.generic'));
        this.spinnerService.hide();
      });
  }

}
