import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Constants } from 'src/app/utils/constants';
import { LoginService } from 'src/app/services/login.service';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StringUtils } from 'src/app/utils/stringUtils';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html'
})
export class RestorePasswordComponent implements OnDestroy {

  matcher = new MyErrorStateMatcher();
  changePasswordForm: UntypedFormGroup;

  username: string;
  token: string;

  newPassword: string;
  confirmPassword: string;

  error: string;
  message: string;

  private destroy$ = new Subject<void>();

  constructor(
    fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.username = params[Constants.FIELD_USERNAME];
      this.token = params[Constants.FIELD_TOKEN];

      if (StringUtils.isEmpty(this.username) || StringUtils.isEmpty(this.token)) {
        this.backLogin();
      }
    });

    this.changePasswordForm = fb.group({
      username: [{ value: this.username, disabled: true }],
      newPassword: [this.newPassword, [Validators.required, Validators.pattern(Constants.PASSWORD_PATTERN)]],
      confirmPassword: [this.confirmPassword, [Validators.required, Validators.pattern(Constants.PASSWORD_PATTERN)]]
    }, { validator: this.checkPasswords });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkPasswords(group: UntypedFormGroup): any { // here we have the 'passwords' group
    const pass = group.controls.newPassword.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notMatch: true };
  }

  changePassword(): void {
    this.spinnerService.show();

    this.error = null;
    this.message = null;

    if (!this.changePasswordForm.valid) {
      if (this.changePasswordForm.errors[Constants.FIELD_NO_MATCH]) {
        this.error = this.translate.instant('changePassword.form.error.notMatch') as string;
      }

      return;
    }

    this.loginService.changePassword(this.username, this.token, this.newPassword).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.snackBarService.sendSuccess(this.translate.instant('changePassword.form.ok') as string);
      this.spinnerService.hide();

      this.backLogin();

    }, err => {
      this.snackBarService.sendError(err.error as string);
      this.spinnerService.hide();
    });

  }

  backLogin(): void {
    void this.router.navigate(['/login'], { queryParamsHandling: 'merge' });
  }
}
