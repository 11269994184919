import { HTTP_INTERCEPTORS, HttpXsrfTokenExtractor } from '@angular/common/http';

import { AirInterceptor } from './airInterceptor';
import { AuthInterceptor } from './authInterceptor';
import { CalibratesInterceptor } from './calibratesInterceptor';
import { CommonModule } from '@angular/common';
import { HasToChangePasswordInterceptor } from './hasToChangePassInterceptor';
import { IsAdminInterceptor } from './isAdminInterceptor';
import { IsLoggedInterceptor } from './isLoggedInterceptor';
import { LangInterceptor } from './langInterceptor';
import { LoginService } from '../services/login.service';
import { NgModule } from '@angular/core';
import { OldBrowserInterceptor } from './oldBrowserInterceptor';
import { Router } from '@angular/router';
import { ServiceUnavailableInterceptor } from './serviceUnavailableInterceptor';
import { SnackBarService } from '../services/snackBar.service';
import { ThermalInterceptor } from './thermalInterceptor';
import { TimezoneInterceptor } from './timezoneInterceptor';
import { UserService } from '../services/user.service';
import { XSRFInterceptor } from './xsrfInterceptor';

@NgModule({
  declarations: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true, deps: [UserService] },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, deps: [LoginService, SnackBarService] },
    { provide: HTTP_INTERCEPTORS, useClass: ServiceUnavailableInterceptor, multi: true, deps: [LoginService, Router] },
    { provide: HTTP_INTERCEPTORS, useClass: TimezoneInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: XSRFInterceptor, multi: true, deps: [HttpXsrfTokenExtractor] },
    IsLoggedInterceptor, IsAdminInterceptor, HasToChangePasswordInterceptor, OldBrowserInterceptor,
    LangInterceptor, TimezoneInterceptor, ThermalInterceptor, AirInterceptor, CalibratesInterceptor, XSRFInterceptor
  ],
  imports: [
    CommonModule
  ],
  exports: []
})
export class InterceptorsModule { }
