<h1 mat-dialog-title> {{ 'internalEquipment.date.performance.data.title' | translate }}</h1>
<form>
        <div fxLayout="column" fxLayout.xs="column" class="flexwrap">
            <div fxLayout="row" fxLayout.xs="column" class="flexwrap" *ngIf="data.isCalibrationNotDate">
                <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field style="width: 95%;">
                        <input matInput [matDatepicker]="processDateCalibration" 
                            name="processDateCalibration"
                            [(ngModel)]="dataDate.processDateCalibration"
                            (blur)="saveDate('processDateCalibration', $event)"
                            (dateChange)="saveDate('processDateCalibration', $event)"
                            (focus)="processDateCalibration?.open()"
                            placeholder="{{ 'calibrateEquipmentEdit.general.calibrateDate.label' | translate }}">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="processDateCalibration"></mat-datepicker-toggle>
                        <mat-datepicker #processDateCalibration [calendarHeaderComponent]="customDatepickerHeader">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field style="width: 95%;">
                        <input matInput [matDatepicker]="calibrationExpirationDate" name="calibrationExpirationDate"
                            [(ngModel)]="dataDate.expirationDateCalibration" disabled
                            placeholder="{{ 'calibrateEquipmentEdit.general.expirationDate.label' | translate }}">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="calibrationExpirationDate"></mat-datepicker-toggle>
                        <mat-datepicker #calibrationExpirationDate [calendarHeaderComponent]="customDatepickerHeader">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row" fxLayout.xs="column" class="flexwrap" *ngIf="data.isVerificationNotDate">
                <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field style="width: 95%;">
                        <input matInput [matDatepicker]="processDateVerification" name="processDateVerification"
                            [(ngModel)]="dataDate.processDateVerification"
                            (blur)="saveDateVerification('processDateVerification', $event)"
                            (dateChange)="saveDateVerification('processDateVerification', $event)"
                            (focus)="processDateVerification?.open()"
                            placeholder="{{ 'calibrateEquipmentEdit.general.verificationDate.label' | translate }}">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="processDateVerification"></mat-datepicker-toggle>
                        <mat-datepicker #processDateVerification [calendarHeaderComponent]="customDatepickerHeader">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field style="width: 95%;">
                        <input matInput [matDatepicker]="expirationVerification" name="expirationVerification"
                            [(ngModel)]="dataDate.expirationVerification" disabled
                            placeholder="{{ 'calibrateEquipmentEdit.general.verificationExpirationDate.label' | translate }}">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="expirationVerification"></mat-datepicker-toggle>
                        <mat-datepicker #expirationVerification [calendarHeaderComponent]="customDatepickerHeader">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row" fxLayout.xs="column" class="flexwrap" *ngIf="data.isMaintenanceNotDate">
                <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field style="width: 95%;">
                        <input matInput [matDatepicker]="processDateMaintenance" name="processDateMaintenance"
                            [(ngModel)]="dataDate.processDateMaintenance"
                            (blur)="saveDateMaintenance('processDateMaintenance', $event)"
                            (dateChange)="saveDateMaintenance('processDateMaintenance', $event)"
                            (focus)="processDateMaintenance?.open()"
                            placeholder="{{ 'internalEquipmentEdit.general.maintenanceCalibrateDate.label' | translate }}">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="processDateMaintenance"></mat-datepicker-toggle>
                        <mat-datepicker #processDateMaintenance [calendarHeaderComponent]="customDatepickerHeader">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field style="width: 95%;">
                        <input matInput [matDatepicker]="expirationDateMaintenance" name="expirationDateMaintenance"
                            [(ngModel)]="dataDate.expirationDateMaintenance"
                            (blur)="saveDateMaintenance('expirationDateMaintenance', $event)"
                            (dateChange)="saveDateMaintenance('expirationDateMaintenance', $event)"
                            placeholder="{{ 'internalEquipmentEdit.general.maintenanceExpirationDate.label' | translate }}">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="expirationDateMaintenance"></mat-datepicker-toggle>
                        <mat-datepicker #expirationDateMaintenance [calendarHeaderComponent]="customDatepickerHeader">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row" fxLayout.xs="column" class="flexwrap" *ngIf="data.isQualificationNotDate">
                <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field style="width: 95%;">
                        <input matInput [matDatepicker]="processDateQualification" name="processDateQualification"
                            [(ngModel)]="dataDate.processDateQualification"
                            (blur)="saveDateQualification('processDateQualification', $event)"
                            (dateChange)="saveDateQualification('processDateQualification', $event)"
                            (focus)="processDateQualification?.open()"
                            placeholder="{{ 'internalEquipmentEdit.general.qualificacionCalibrateDate.label' | translate }}">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="processDateQualification"></mat-datepicker-toggle>
                        <mat-datepicker #processDateQualification [calendarHeaderComponent]="customDatepickerHeader">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field style="width: 95%;">
                        <input matInput [matDatepicker]="expirationDateQualification" name="expirationDateQualification"
                            [(ngModel)]="dataDate.expirationDateQualification"
                            (blur)="saveDateQualification('expirationDateQualification', $event)"
                            (dateChange)="saveDateQualification('expirationDateQualification', $event)"
                            (focus)="expirationDateQualification?.open()"
                            placeholder="{{ 'internalEquipmentEdit.general.qualificacionExpirationDate.label' | translate }}">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="expirationDateQualification"></mat-datepicker-toggle>
                        <mat-datepicker #expirationDateQualification
                            [calendarHeaderComponent]="customDatepickerHeader">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row" fxLayout.xs="column" class="flexwrap" *ngIf="data.isCharaterizationNotDate">
                <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field style="width: 95%;">
                        <input matInput [matDatepicker]="processDateCharaterization"
                            [(ngModel)]="dataDate.processDateCharaterization" name="processDateCharaterization"
                            (blur)="saveDateCharacterization('processDateCharaterization', $event)"
                            (dateChange)="saveDateCharacterization('processDateCharaterization', $event)"
                            (focus)="processDateCharaterization?.open()"
                            placeholder="{{ 'internalEquipmentEdit.characterization.body.calibrationDate' | translate }}">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="processDateCharaterization"></mat-datepicker-toggle>
                        <mat-datepicker #processDateCharaterization [calendarHeaderComponent]="customDatepickerHeader">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field style="width: 95%;">
                        <input matInput [matDatepicker]="expirationCharaterization" name="expirationCharaterization"
                            [(ngModel)]="dataDate.expirationCharaterization"
                            (blur)="saveDateCharacterization('expirationCharaterization', $event)"
                            (dateChange)="saveDateCharacterization('expirationCharaterization', $event)"
                            (focus)="expirationCharaterization?.open()"
                            placeholder="{{ 'internalEquipmentEdit.characterization.body.expirationDate' | translate }}">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="expirationCharaterization"></mat-datepicker-toggle>
                        <mat-datepicker #expirationCharaterization
                            [calendarHeaderComponent]="customDatepickerHeader">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>

    <div mat-dialog-actions class="calibrates-theme">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()" cdkFocusInitial>{{ 'button.save' | translate
            }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>
</form>