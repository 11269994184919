/* eslint-disable max-len */
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import * as _ from 'lodash-es';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccessoryFilterType } from 'src/app/model/accessoryFilterType';
import { EquipmentAutocompleteFilter } from 'src/app/model/autocompleteFilter';
import { CompressedGas } from 'src/app/model/compressedGasesAir';
import { Criteria } from 'src/app/model/criteria';
import { CriteriaTypeCustomSubValue, CriteriaTypeCustomValue, SpeedTypeCriteria } from 'src/app/model/criteriaType';
import { DataValueEssay } from 'src/app/model/dataValueEssay';
import { EquipmentAir } from 'src/app/model/equipment';
import { EquipmentTypeAirEnum } from 'src/app/model/equipmentType';
import { EssayConfigAir } from 'src/app/model/essayConfigAir';
import { EssayExecutionAirInternalEquipment } from 'src/app/model/essayExecutionAirInternalEquipment';
import { EssayExecutionAirResultParticles, EssayExecutionResultAir, ResultExcelParticles } from 'src/app/model/essayExecutionResultAir';
import { EssayProtocolAir } from 'src/app/model/essayProtocolAir';
import { EssayAirTypeEnum, EssayType } from 'src/app/model/essayType';
import { ExecutionAir, ExecutionStatus } from 'src/app/model/execution';
import { ExecutionEssayDialogResponse } from 'src/app/model/executionEssayDialogResponse';
import { Field } from 'src/app/model/field';
import { FilterAir, FilterAirEnum, FilterAirTypeEnum } from 'src/app/model/filterAir';
import { FilterAirType } from 'src/app/model/filterAirType';
import { InternalEquipment } from 'src/app/model/internalEquipment';
import { RoomAir } from 'src/app/model/roomAir';
import { User } from 'src/app/model/user';
import { DefaultUnit, VariableUnit } from 'src/app/model/variable';
import { AccessoryFilterTypeService } from 'src/app/services/accessoryFilterType.service';
import { AttachmentAirService } from 'src/app/services/attachmentAir.service';
import { AttachmentThermalService } from 'src/app/services/attachmentThermal.service';
import { EssayAirService } from 'src/app/services/essayAir.service';
import { EssayConfigAirService } from 'src/app/services/essayConfigAir.service';
import { ExecutionAirService } from 'src/app/services/executionAir.service';
import { FilterAirTypeService } from 'src/app/services/filterAirType.service';
import { InternalEquipmentService } from 'src/app/services/internalEquipment.service';
import { JosetAirService } from 'src/app/services/josetAirService.service';
import { OnlineService } from 'src/app/services/online.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UserService } from 'src/app/services/user.service';
import { VariableTypeService } from 'src/app/services/variableType.service';
import { AirUtils } from 'src/app/utils/airUtils';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { DateUtils } from 'src/app/utils/dateUtils';
import { EssayUtils } from 'src/app/utils/essayUtils';
import { ExcelUtils } from 'src/app/utils/excelUtils';
import { ExecutionAirUtils } from 'src/app/utils/executionAirUtils';
import { FieldEnum, FieldUtils } from 'src/app/utils/fieldUtils';
import { NumberUtils } from 'src/app/utils/numberUtils';
import { StringUtils } from 'src/app/utils/stringUtils';
import { v4 as uuid } from 'uuid';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { CustomDatepickerHeaderComponent } from '../../shared/datepicker-custom-header/datepicker-custom-header.component';
import { ReasonDialogComponent } from '../../shared/reason-dialog/reason-dialog.component';
import { DialogDataVideoPlayer, VideoPlayerComponent } from '../../shared/video-player/video-player.component';
import { ProtocolEditDialogFilterComponent } from '../protocol-edit/protocol-edit-dialog-filter.component';
import { ProtocolEditEssayCriteriaAirComponent } from '../protocol-edit/protocol-edit-essay-criteria-air.component';
import { ExecutionEditEssayCriteriaComponent } from './execution-edit-essay-criteria.component';
import { ExecutionEditFieldEditComponent } from './execution-edit-field-edit.component';

export interface ExecutionEditDialogEssayData {
  execution: ExecutionAir;
  essay: EssayProtocolAir;
  idConfig: number;
  isNew: boolean;
  equipment: EquipmentAir;
  room: RoomAir;
  compressedGas: CompressedGas;
}

@Component({
  selector: 'app-execution-edit-dialog-essay',
  templateUrl: './execution-edit-dialog-essay.component.html'
})
export class ExecutionEditDialogEssayComponent implements OnInit {

  customDatepickerHeader = CustomDatepickerHeaderComponent;

  currentUser: User;

  public equipmentAutoComplete: InternalEquipment[];
  entityType: string;

  filtersEssay: FilterAir[];
  activeCarbonFilters: FilterAir[] = [];

  customValues: CriteriaTypeCustomValue[];
  customSubValues: CriteriaTypeCustomSubValue[];
  accessoryFilterTypes: AccessoryFilterType[];
  filterAirTypes: FilterAirType[];
  essayTypes: EssayType[];

  units: VariableUnit[] = [];

  conforme: boolean;
  showLocations: boolean;
  showParticleCount: boolean;
  showRecoveryEssay: boolean;
  criteriaSimple: boolean;
  wasteOfLoadRoom: boolean;
  showAccording: boolean;
  showFilterHepa: boolean;
  showSaturation: boolean;
  showIntroduction: boolean;
  showSpeed: boolean;
  showMicrobiological: boolean;
  showDewPoint: boolean;
  renFlowAndRate: boolean;
  heavyHasBothCriterias: boolean;
  integrity: boolean;
  defPressure: boolean;
  filtersHepaFromRoom: boolean;
  showAirSpeedBioII: boolean;
  showHeavyCabin: boolean;
  showIsChanneled: boolean;
  isChanneled: boolean;
  exSurface: number;
  frontSurface: number;
  renFlowAndRatehasBoth = false;
  na = false;
  displayedColumnsParticleCount = ['date', 'location', 'volume', 'zeroOne', 'zeroTwo', 'zeroThree', 'zeroFive', 'oneZero', 'fiveZero'];
  filters = [FilterAirTypeEnum.FILTER, FilterAirTypeEnum.FILTER_WITH_DIFFUSER];
  particleCountEssays = [
    EssayAirTypeEnum.PARTICLE_COUNT_REPOSE, EssayAirTypeEnum.PARTICLE_COUNT_WORKING, EssayAirTypeEnum.RECOVERY_ESSAY,
    EssayAirTypeEnum.PARTICLE_COUNT, EssayAirTypeEnum.ANEXOI_COUNT_REPOSE, EssayAirTypeEnum.ANEXOI_COUNT_WORKING,
    EssayAirTypeEnum.ANEX_COUNT];
  locationEssays = [EssayAirTypeEnum.TEMPERATURE, EssayAirTypeEnum.HUMIDITY];
  accordingEssays = [EssayAirTypeEnum.SMOKE_ESSAY_REPOSE, EssayAirTypeEnum.SMOKE_ESSAY_WORKING,
  EssayAirTypeEnum.ELECTRIC_ESSAY, EssayAirTypeEnum.HYDROCARBON_LEVEL];
  criteriaSimpleEssays = [
    EssayAirTypeEnum.NOISE, EssayAirTypeEnum.LIGHT, EssayAirTypeEnum.WASTE_OF_LOAD, EssayAirTypeEnum.UV_INTENSITY,
    EssayAirTypeEnum.DEF_PRESSURE, EssayAirTypeEnum.PARALLELISM_TEST, EssayAirTypeEnum.CO2_CONCENTRATION,
    EssayAirTypeEnum.CO_CONCENTRATION, EssayAirTypeEnum.NO2_CONCENTRATION, EssayAirTypeEnum.NO_CONCENTRATION,
    EssayAirTypeEnum.LINE_PRESSURE];
  dataEssay = [EssayAirTypeEnum.REN_FLOW_AND_RATE, EssayAirTypeEnum.AIR_SPEED];
  hepaFilterEssays = [EssayAirTypeEnum.HEPA_FILTER_INTEGRITY];
  pointEssay = [EssayAirTypeEnum.DEW_POINT];
  microEssay = [EssayAirTypeEnum.MICROBIO_ESSAY];
  mapJustCaudal: Map<EssayExecutionResultAir, boolean>;

  private destroy$ = new Subject<void>();

  private askForReason: boolean;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChild('uploadExcelParticleCountInput') uploadExcelParticleCountInput: any;

  constructor(
    public dialogRef: MatDialogRef<ExecutionEditDialogEssayComponent>,
    public dialog: MatDialog,
    private accessoryFilterTypeService: AccessoryFilterTypeService,
    private essayService: EssayAirService,
    private essayConfigService: EssayConfigAirService,
    private filterAirTypeService: FilterAirTypeService,
    private translate: TranslateService,
    private internalEquipmentService: InternalEquipmentService,
    public snackBarService: SnackBarService,
    private josetAirService: JosetAirService,
    private executionService: ExecutionAirService,
    private variableTypeService: VariableTypeService,
    private userService: UserService,
    private attachmentAirService: AttachmentAirService,
    private attachmentThermalService: AttachmentThermalService,
    private onlineService: OnlineService,
    @Inject(MAT_DIALOG_DATA) public data: ExecutionEditDialogEssayData,
    private spinnerService: SpinnerService) {

    this.currentUser = this.userService.currentProfile;

    const essaysWithAerosol = [EssayAirTypeEnum.HEPA_FILTER_INTEGRITY];
    const isEssayWithAerosol = essaysWithAerosol.includes(this.data.essay.idEssayType);

    if (isEssayWithAerosol) {
      this.data.essay.values.forEach(v => v.aerosol = v.reference != null);
      this.data.essay.criterias.forEach(c => c.values.forEach(v => v.aerosol = v.reference != null));
    }

    this.entityType = 'equipment';

    if (this.data.room != null) {
      this.entityType = 'room';
    } else if (this.data.compressedGas != null) {
      this.entityType = 'compressedGas';
    }

    this.askForReason = this.data.essay.modUser != null;

    this.data.essay.equipments = [...new Map(this.data.essay.equipments.filter(item => item.idEquipment != null)
      .map(item => [item.idEquipment, item])).values()];
  }

  ngOnInit(): void {
    this.selectCriteriaType();

    this.data.essay.valuesParticles = this.removeDuplicatedParticles(this.data.essay.valuesParticles);

    // Comprobar si la sala está canalizada
    if (this.data.essay.idEssayType === EssayAirTypeEnum.AIR_SPEED &&
      (this.data.equipment?.idType === EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_IIA ||
        this.data.equipment?.idType === EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_IIB)) {
      this.showAirSpeedBioII = true;
      this.data.essay.criterias.forEach(criteria => {
        criteria.values.forEach(value => {
          if (value.reference === 'canalizada') {
            this.isChanneled = true;
          }
        })
      });
    } else if (this.data.essay.idEssayType === EssayAirTypeEnum.AIR_SPEED &&
      this.data.equipment?.idType === EquipmentTypeAirEnum.PROTECTION_HEAVY_CABIN) {
      this.showHeavyCabin = true;

    }
    let idTypeEq = 1;

    // Recuperar los filtros según sea una sala o un equipo
    if (this.data.room != null) {
      this.filtersEssay = this.data.room.filters;
    } else if (this.data.equipment != null) {
      this.filtersEssay = this.data.equipment.filters;

      idTypeEq = this.data.equipment.idType;
    }

    this.units = [];


    this.data.essay.essayValues.forEach(field => {
      if (field.idField === FieldEnum.EXTRACTION_SURFACE) {
        this.exSurface = +field.value;
      }

      if (field.idField === FieldEnum.FRONTAL_WINDOW_SURFACE) {
        this.frontSurface = +field.value;
      }
    });

    let essaysCall: Observable<any>;

    // Search the units by essay id
    if (this.data.compressedGas != null) {
      essaysCall = this.essayService.findByCompressedGasId(idTypeEq);
    } else {
      essaysCall = this.essayService.findByEquipmentId(idTypeEq);
    }

    essaysCall.pipe(takeUntil(this.destroy$)).subscribe((essayTypes: EssayType[]) => {
      this.essayTypes = essayTypes;
      const essay = essayTypes.find(e => e.id === this.data.essay.idEssayType);

      if (essay != null && essay.idVariable != null) {
        this.variableTypeService.findUnits(essay.idVariable).subscribe((units: VariableUnit[]) => {
          this.units = units;

          if (this.data.essay.idUnit == null) {
            this.data.essay.idUnit = DefaultUnit.getDefaultUnitForEssayAir(essay.id);
          }

          if (this.data.essay.idUnit == null) {
            this.data.essay.idUnit = DefaultUnit.getDefaultValues().get(essay.idVariable) || units[0]?.id;
          }
        });
      }
    });

    // Search filters type
    this.filterAirTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: FilterAirType[]) => this.filterAirTypes = res);
    this.accessoryFilterTypeService.findAll().pipe(takeUntil(this.destroy$))
      .subscribe((res: AccessoryFilterType[]) => this.accessoryFilterTypes = res);

    // If the essay hasn´t values it fill with an empty value
    if (ArrayUtils.isEmpty(this.data.essay.values) || this.data.essay.values == null) {
      this.data.essay.values = [new EssayExecutionResultAir()];

      if (this.showDewPoint) {
        const valueDefault = this.getDewPointValues()[0];
        this.data.essay.values[0].reference = valueDefault;
        this.getCriteriaByEssay()
          .forEach(criteria => this.getValuesOfCriteria(criteria).forEach(location => location.reference = valueDefault));
      }
    }

    if (this.filtersEssay != null) {
      this.filtersEssay.forEach(filter => {
        if (filter.id == null && filter.idOffline == null) {
          filter.idOffline = uuid();
        }
      });
    }

    if (this.data.essay.idEssayType === EssayAirTypeEnum.FILTER_SATURATION) {
      this.filtersEssay.forEach(f => {
        if (f.idTypeFilter === FilterAirEnum.ACTIVATED_CARBON) {
          this.activeCarbonFilters.push(f);
        }
      });
    }

    this.data.essay.criterias.forEach(criteria => {
      if (ArrayUtils.isEmpty(criteria.values) || criteria.values == null) {
        criteria.values = this.data.essay.values.filter(v => v.idCriteria === criteria.id);
        if (ArrayUtils.isEmpty(criteria.values)) {
          criteria.values = [new EssayExecutionResultAir()];
        }
      }

      if (this.locationEssays.includes(this.data.essay.idEssayType)) {
        criteria.values.forEach(value => {
          if (value.value != null) {
            const arrayData = value.value.split(';');
            value.data = arrayData.map(data => new DataValueEssay(+data));
          } else {
            value.data = Array(5).fill(new DataValueEssay()) as DataValueEssay[];
          }
        });
      } else if (this.data.essay.idEssayType === EssayAirTypeEnum.REN_FLOW_AND_RATE) {
        // CASO Ensayo Caudal y ren/hora

        // Añadir por cada filtro los resultados que falten
        const filtersRen = this.filtersEssay.filter(filter => this.isRenFilter(filter));
        for (let i = criteria.values.length; i < filtersRen.length; i++) {
          const result = new EssayExecutionResultAir();
          result.idFilter = filtersRen[i].id;
          criteria.values.push(result);
        }
        const valuesFilters = [];
        criteria.values.forEach(value => {
          if (value.idFilter != null) {
            valuesFilters.push(value.idFilter);
          }
        });

        criteria.values.forEach(value => {
          let filt: FilterAir = null;
          if (value.idFilter != null) {
            value.uuidFilter = value.idFilter.toString();
            filt = this.filtersEssay.find(filter => filter.id === value.idFilter);
          } else {
            filt = this.filtersEssay.find(filter => !valuesFilters.includes(filter.id) &&
              !valuesFilters.includes(filter.idOffline));
            if (filt.id != null) {
              value.uuidFilter = filt.id.toString();
              value.idFilter = filt.id;
              valuesFilters.push(filt.id);
            } else {
              value.uuidFilter = filt.idOffline;
              valuesFilters.push(filt.idOffline);
            }
          }
          this.configureValueByFilter(value, filt);
        });
      } else if (this.data.essay.idEssayType === EssayAirTypeEnum.HEPA_FILTER_INTEGRITY) {
        const filtersHepa = this.filtersEssay.filter(filter => this.isHepaFilter(filter));
        for (let i = criteria.values.length; i < filtersHepa.length; i++) {
          const result = new EssayExecutionResultAir();
          result.idFilter = filtersHepa[i].id;
          criteria.values.push(result);
        }

        // TODO: refactorizar a un metodo
        const valuesFilters = [];
        criteria.values.forEach(value => {
          if (value.idFilter != null) {
            valuesFilters.push(value.idFilter);
          }
        });

        criteria.values.forEach(value => {
          if (value.idFilter != null) {
            value.uuidFilter = value.idFilter.toString();
          } else {
            const filt = filtersHepa.find(filter => !valuesFilters.includes(filter.id) &&
              !valuesFilters.includes(filter.idOffline));
            if (filt.id != null) {
              value.uuidFilter = filt.id.toString();
              value.idFilter = filt.id;
              valuesFilters.push(filt.id);
            } else {
              value.uuidFilter = filt.idOffline;
              valuesFilters.push(filt.idOffline);
            }
          }
        });
      } else if (this.data.essay.idEssayType === EssayAirTypeEnum.WASTE_OF_LOAD && this.data.room != null) {
        // Check if the essay is Waste of load and there is a room
        const filtersHepa = this.filtersEssay.filter(filter => this.isHepaFilter(filter));
        for (let i = criteria.values.length; i < filtersHepa.length; i++) {
          const result = new EssayExecutionResultAir();
          result.idFilter = filtersHepa[i].id;
          criteria.values.push(result);
        }
        const valuesFilters = [];
        criteria.values.forEach(value => {
          if (value.idFilter != null) {
            valuesFilters.push(value.idFilter);
          }
        });

        criteria.values.forEach(value => {
          if (value.idFilter != null) {
            value.uuidFilter = value.idFilter.toString();
          } else {
            const filt = filtersHepa.find(filter => !valuesFilters.includes(filter.id) &&
              !valuesFilters.includes(filter.idOffline));
            if (filt.id != null) {
              value.uuidFilter = filt.id.toString();
              value.idFilter = filt.id;
              valuesFilters.push(filt.id);
            } else {
              value.uuidFilter = filt.idOffline;
              valuesFilters.push(filt.idOffline);
            }
          }
        });
      } else if (this.data.essay.idEssayType === EssayAirTypeEnum.MICROBIO_ESSAY) {
        this.updateMicrobiologicalResults(criteria);
      } else if (this.data.essay.idEssayType === EssayAirTypeEnum.AIR_SPEED) {
        if (this.data.equipment != null) {
          // TODO: pasar todo esto a .includes()
          if (this.data.equipment?.idType === EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_I ||
            this.data.equipment?.idType === EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_III ||
            this.data.equipment?.idType === EquipmentTypeAirEnum.CARVING_TABLE ||
            this.data.equipment?.idType === EquipmentTypeAirEnum.EXTRACTION_CABIN ||
            this.data.equipment?.idType === EquipmentTypeAirEnum.EXTRACTOR_DISPLAY_CASE) {
            criteria.values.forEach(value => {
              value.showLocations = true;
              if (value.value != null) {
                const arrayData = value.value.split(';');
                value.data = arrayData.map(data => new DataValueEssay(+data));
                value.uuidFilter = null;
              } else {
                value.data = Array(4).fill(new DataValueEssay()) as DataValueEssay[];
              }
            });
          } else {
            for (let i = criteria.values.length; i < this.filtersEssay.length; i++) {
              const result = new EssayExecutionResultAir();
              criteria.values.push(result);
            }
            const valuesFilters = [];
            criteria.values.forEach(value => {
              if (value.idFilter != null) {
                valuesFilters.push(value.idFilter);
              }
            });

            criteria.values.forEach(value => {
              let filt: FilterAir = null;
              if (value.idFilter != null) {
                value.uuidFilter = value.idFilter.toString();
                filt = this.filtersEssay.find(filter => filter.id === value.idFilter);
              } else {
                filt = this.filtersEssay.find(filter => !valuesFilters.includes(filter.id) &&
                  !valuesFilters.includes(filter.idOffline));
                if (filt.id != null) {
                  value.uuidFilter = filt.id.toString();
                  value.idFilter = filt.id;
                  valuesFilters.push(filt.id);
                } else {
                  value.uuidFilter = filt.idOffline;
                  valuesFilters.push(filt.idOffline);
                }
              }
              this.configureValueByFilterSpeed(value, filt);
            });
          }
        } else if (this.data.room != null) {

          for (let i = criteria.values.length; i < this.filtersEssay.length; i++) {
            const result = new EssayExecutionResultAir();
            criteria.values.push(result);
          }

          const valuesFilters = [];
          criteria.values.forEach(value => {
            if (value.idFilter != null) {
              valuesFilters.push(value.idFilter);
            }
          });

          criteria.values.forEach(value => {
            let filt: FilterAir = null;
            if (value.idFilter != null || value.uuidFilter != null) {
              value.uuidFilter = value.idFilter != null ? value.idFilter.toString() : value.uuidFilter;
              filt = this.filtersEssay.find(filter => filter.id === value.idFilter);
            } else {
              filt = this.filtersEssay.find(filter => !valuesFilters.includes(filter.id) &&
                !valuesFilters.includes(filter.idOffline));
              if (filt.id != null) {
                value.uuidFilter = filt.id.toString();
                value.idFilter = filt.id;
                valuesFilters.push(filt.id);
              } else {
                value.uuidFilter = filt.idOffline;
                valuesFilters.push(filt.idOffline);
              }
            }
            this.configureValueByFilterSpeed(value, filt);
          });
        }

      }
    });

    if (ArrayUtils.isEmpty(this.data.essay.equipments)) {
      this.data.essay.equipments = [new EssayExecutionAirInternalEquipment()];
    } else {
      this.data.essay.equipments.forEach(eq => {
        this.internalEquipmentService.findOne(eq.idEquipment).pipe(takeUntil(this.destroy$))
          .subscribe((result: InternalEquipment | InternalEquipment[]) => {
            if (Array.isArray(result)) {
              eq.equipmentName = result[0]?.name;
            } else {
              eq.equipmentName = result?.name;
            }
          });
      })
    }

    if (this.data.essay.equipmentNA == null) {
      this.data.essay.equipmentNA = false;
    }

    if (!this.data.essay.idOffline) {
      this.data.essay.idOffline = uuid();
    }

    let call: Observable<any>;

    if (this.data.equipment != null) {
      call = this.essayConfigService.findConfigByEssayIdAndEquipmentId(this.data.equipment.idType, this.data.essay.idEssayType)
    } else if (this.data.room != null) {
      call = this.essayConfigService.findConfigByEssayIdAndRoomId(1, this.data.essay.idEssayType)
    } else if (this.data.compressedGas != null) {
      call = this.essayConfigService.findConfigByEssayIdAndCompressedGasId(1, this.data.essay.idEssayType)
    }

    call.pipe(takeUntil(this.destroy$)).subscribe((config: EssayConfigAir) => {

      this.essayService.findFieldsById(config.id).pipe(takeUntil(this.destroy$)).subscribe((fields: Field[]) => {
        fields = fields.map(i => FieldUtils.objectToField(i));

        fields.forEach(f => {
          const originalField = this.data.essay.essayValues?.find(f1 => f1.idField === f.idField);

          if (originalField != null) {
            f.id = originalField.id;
            f._value = originalField._value;
            f.value = originalField.value as string;
            f.valueProtocol = originalField.valueProtocol;
            f.showDiffInDeviations = originalField.showDiffInDeviations;
            f.showDiffInObservations = originalField.showDiffInObservations;
            f.valueChangeReason = originalField.valueChangeReason;
          }
        });

        this.data.essay.essayValues = fields;

        const isEquipment = this.data.equipment != null;

        if (!isEquipment) {
          const fieldOther = this.data.essay.essayValues.find(f => f.idField === FieldEnum.OTHER_INFO);

          if (fieldOther) {
            fieldOther.required = true;
          }
        }

        const fieldVolume = this.data.essay.essayValues.find(f => f.idField === FieldEnum.VOLUME);

        if (fieldVolume) {
          fieldVolume.required = true;
        }

      });
    });

    if ((this.data.essay.values[0].value != null || this.data.essay.according != null)
      && this.data.essay.equipments[0]?.idEquipment == null) {
      this.data.essay.equipmentNA = true;
    }

    if (this.showAccording && this.data.essay.according == null) {
      this.data.essay.according = true;
    }

    if (this.mapJustCaudal == null) {
      this.mapJustCaudal = new Map();

      this.getCriteriaByEssay()?.forEach(criteria => {
        this.getValuesOfCriteria(criteria).forEach(location => {
          if(!location.value?.includes(';')){
            this.mapJustCaudal.set(location, !location.showLocations);
          }
        });
      });
    }
  }

  onOkClick(): void {
    const errors = [];

    let emptyValue = false;
    let emptyAllValues = false;
    this.data.essay.equipments = [...new Map(this.data.essay.equipments.filter(item => item.idEquipment != null)
      .map(item => [item.idEquipment, item])).values()];

    if (!this.data.essay.equipmentNA && ArrayUtils.isEmpty(this.data.essay.equipments)) {
      this.addEquipment();
    }

    // Duplicate results if has renovation rate and flow
    if (this.renFlowAndRatehasBoth) {
      const criteria = this.data.essay.criterias.find(c => c.customValue1 === 'renovationPerHour');
      this.cloneValuesToCriteria(criteria);
    }

    if (this.showAirSpeedBioII) {
      const criteria = this.data.essay.criterias.find(c => c.customValue1 === SpeedTypeCriteria.AVERAGE_SPEED);
      this.cloneValuesToCriteria(criteria);
    }

    if (this.heavyHasBothCriterias) {
      const criteria = this.data.essay.criterias.find(c => c.customValue1 === SpeedTypeCriteria.AVERAGE_SPEED);
      this.cloneValuesToCriteria(criteria);
    }

    if (this.accordingEssays.includes(this.data.essay.idEssayType) && this.data.essay.according == null) {
      emptyValue = true;
    } else if (this.criteriaSimpleEssays.includes(this.data.essay.idEssayType) ||
      this.pointEssay.includes(this.data.essay.idEssayType)) {
      emptyValue = true;
      this.data.essay.criterias.forEach(criteria => {
        criteria.values.forEach(value => {
          if (value.value != null) {
            emptyValue = false;
          }
        })
      });
    } else if (this.locationEssays.includes(this.data.essay.idEssayType)) {
      emptyValue = true;
      this.data.essay.criterias.forEach(criteria => {
        criteria.values.forEach(value => {
          value.data.forEach(data => {
            if (data.value != null) {
              emptyValue = false;
            }
          });
        });
      });
    }

    if (this.data.essay.active) {
      if (this.dataEssay.includes(this.data.essay.idEssayType)) {
        this.data.essay.criterias.forEach(criteria => {
          criteria.values.forEach(value => {
            let filter = new FilterAir();
            if (value.uuidFilter != null) {
              if (ExecutionAirUtils.checkIdFilter(value.uuidFilter)) {
                filter = this.filtersEssay.find(f => f.id.toString() === value.uuidFilter);
              } else {
                filter = this.filtersEssay.find(f => f.idOffline === value.uuidFilter);
              }
            }
            if (filter.totalFiltersSecondStage == null) {
              if (value.data?.length < 1 && value.value == null) {
                if (filter.idTypeFilter !== FilterAirEnum.HEPA_EXTRACTION_1 &&
                  this.data.essay.idEssayType !== EssayAirTypeEnum.AIR_SPEED) {
                  emptyAllValues = true;
                }
              }
              value.data?.forEach(data => {
                if (data.value == null && value.value == null) {
                  emptyAllValues = true;
                }
              });
            }
          });
        });
      }
      if (this.hepaFilterEssays.includes(this.data.essay.idEssayType)) {
        this.data.essay.criterias.forEach(criteria => {
          criteria.values.forEach(value => {
            if (value.value == null) {
              emptyAllValues = true;
            }
          });
        });
      }
    }

    if (emptyValue && this.data.essay.active) {
      errors.push(this.translate.instant('executionEdit.dialog.essay.value.canNotEmpty'));
    }

    if (emptyAllValues) {
      errors.push(this.translate.instant('executionEdit.dialog.essay.value.fillAllValues'));
    }

    // Check if internal equipments are empty
    if (!this.data.essay.equipmentNA && this.data.essay.active) {
      if (this.data.essay.equipments == null || this.data.essay.equipments[0]?.idEquipment == null) {
        errors.push(this.translate.instant('executionEdit.dialog.essay.equipment.canNotEmpty'));
      }
    }

    if (!this.data.essay.active && StringUtils.isEmpty(this.data.essay.reasonActive)) {
      errors.push(this.translate.instant('common.reasonChangeError'));
    }

    // Check if video is necessary and empty
    if (this.showUploadVideo() && this.data.essay.idVideo == null) {
      errors.push(this.translate.instant('executionEdit.dialog.essay.video.canNotEmpty'));
    }

    // Check if in a air speed essay the extraction surface and frontal window surface
    // are empty when the room/equipment is channeled
    if (!this.isChanneled) {
      if (this.data.equipment?.idType === EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_IIA ||
        this.data.equipment?.idType === EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_IIB) {
        if (this.data.essay.idEssayType === EssayAirTypeEnum.AIR_SPEED) {
          this.data.essay.essayValues.forEach(field => {
            if (field.idField === FieldEnum.EXTRACTION_SURFACE) {
              if (field.value === '') {
                errors.push(this.translate.instant('executionEdit.dialog.essay.extractionSurface.canNotEmpty'));
              }
            }
            if (field.idField === FieldEnum.FRONTAL_WINDOW_SURFACE) {
              if (field.value === '') {
                errors.push(this.translate.instant('executionEdit.dialog.essay.frontalWindowSurface.canNotEmpty'));
              }
            }
          });
        }
      }
    }

    if (this.data.essay.idEssayType === EssayAirTypeEnum.REN_FLOW_AND_RATE) {
      this.data.essay.essayValues.forEach(field => {
        const value = field.value as string;
        if (field.idField === FieldEnum.VOLUME && (value == null || value === '' || +value === 0)) {
          errors.push(this.translate.instant('executionEdit.dialog.essay.volume.canNotEmpty'));
        }
      });
    }

    if (this.showParticleCount && this.data.essay.active) {
      const totalPoints = ExecutionAirUtils.getTotalCountingPoints(this.data.essay);

      const countingPoints = this.data.essay.valuesParticles.map(a => a.location).filter(ArrayUtils.unique).length;

      if (countingPoints < totalPoints) {
        errors.push(this.translate.instant('executionEdit.dialog.essay.particleCount.insufficientParticles'));
      }
    }

    if (errors.length !== 0) {
      this.snackBarService.sendError(errors.join('\n'));
      return;
    }

    // Convert the datas to a string
    this.data.essay.criterias.forEach(criteria => {
      criteria.values.filter(value => !ArrayUtils.isEmpty(value.data) && value.data[0].value != null)
        .forEach(value => value.value = value.data.map(a => a.value).join(';'));
    });

    if (!this.data.essay.regFc) {
      this.data.essay.regFc = new Date();
      this.data.essay.regUser = this.currentUser.username;
    }

    this.data.essay.modFc = new Date();
    this.data.essay.modUser = this.currentUser.username;

    if (this.showParticleCount && this.data.essay.active) {
      this.data.essay.regFc = new Date(Math.max(... this.data.essay.valuesParticles?.map(v => DateUtils.anyToDate(v.date))
        .map(d => d.getTime())));

      if (this.data.essay.regFc == null) {
        this.data.essay.regUser = null;
        this.data.essay.modFc = null;
        this.data.essay.modUser = null;
      }
    }

    if (!this.showUploadVideo()) {
      this.data.essay.idVideo = null;
    }

    if (this.data.essay.equipmentNA) {
      this.data.essay.equipments = [];
    }

    this.data.essay.essayValues?.forEach(f => f.value = f._value);

    if (this.askForReason) {
      this.requestReason((reason) => {
        this.data.essay.reasonChange = reason;

        const response = new ExecutionEssayDialogResponse();
        response.essay = this.data.essay;
        response.filters = this.filtersEssay;

        this.dialogRef.close(response);
      });
    } else {
      const response = new ExecutionEssayDialogResponse();
      response.essay = this.data.essay;
      response.filters = this.filtersEssay;

      this.dialogRef.close(response);
    }

  }

  delete(): void {
    const message = this.translate.instant('protocolEdit.dialog.essay.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        this.dialogRef.close('delete');
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  lookupEquipment($event): void {
    let results = this.equipmentAutoComplete;

    const filter = new EquipmentAutocompleteFilter();

    filter.query = $event.target.value as string;
    filter.pageIndex = 0;
    filter.pageSize = 10;
    filter.sortBy = 'name';
    filter.sortDirection = 'asc';
    filter.forExecution = true;

    this.internalEquipmentService.findAutocomplete(filter).pipe(takeUntil(this.destroy$)).subscribe(item => {
      if (item.content != null) {
        results = item.content as InternalEquipment[];
      } else {
        results = item as InternalEquipment[];
      }

      this.equipmentAutoComplete = results;
    }, () => {
      results = [];
      this.equipmentAutoComplete = results;
    });
  }

  newValue(indexCriteria: number): void {
    const criteria = this.data.essay.criterias[indexCriteria];
    const values = this.getValuesOfCriteria(criteria);

    const simpleEssays = [EssayAirTypeEnum.NOISE, EssayAirTypeEnum.LIGHT,
    EssayAirTypeEnum.UV_INTENSITY];
    const filtersEssays = [EssayAirTypeEnum.HEPA_FILTER_INTEGRITY, EssayAirTypeEnum.REN_FLOW_AND_RATE];

    if (this.data.room != null) {
      filtersEssays.push(EssayAirTypeEnum.WASTE_OF_LOAD);
    } else if (this.data.equipment != null) {
      simpleEssays.push(EssayAirTypeEnum.WASTE_OF_LOAD);
    }

    const result = new EssayExecutionResultAir();

    if (simpleEssays.includes(this.data.essay.idEssayType)) {
      values.push(result);
    }

    if (this.locationEssays.includes(this.data.essay.idEssayType)) {
      let length: number;
      if (criteria.values.length > 0) {
        length = criteria.values[0].data.length
      } else {
        length = 5;
      }
      result.data = Array(length).fill(new DataValueEssay()) as DataValueEssay[];

      values.push(result);
    }

    if (filtersEssays.includes(this.data.essay.idEssayType)) {

      const filter = new FilterAir();

      const dialogRef = this.dialog.open(ProtocolEditDialogFilterComponent, {
        minWidth: '50%',
        maxHeight: '95vh',
        data: {
          accessoryFilterTypes: this.accessoryFilterTypes,
          filterTypes: this.filterAirTypes,
          filter,
          isEdit: false,
          isExecution: true,
          idExecution: this.data.execution.id
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res: FilterAir) => {

        if (res != null) {
          res.filterName = this.setFilterName(res);
          this.filtersEssay.push(res);
          this.ngOnInit();
        }
      });
    }

  }

  addDataValue(indexCriteria: number, indexValue: number): void {
    if (this.data.essay.criterias[indexCriteria].values[indexValue].data == null) {
      this.data.essay.criterias[indexCriteria].values[indexValue].data = [];
    }

    this.data.essay.criterias[indexCriteria].values[indexValue].data.push(new DataValueEssay());
  }

  showDeleteValue(criteria: Criteria): boolean {
    return this.getValuesOfCriteria(criteria).length > 1;
  }

  deleteValue(indexCriteria: number, indexValue: number): void {
    const criteria = this.data.essay.criterias[indexCriteria];

    this.getValuesOfCriteria(criteria).splice(indexValue, 1);
  }

  getValuesOfCriteria(criteria: Criteria): EssayExecutionResultAir[] {
    return !ArrayUtils.isEmpty(criteria?.values) ?
      criteria.values.sort((a, b) => (a.idFilter > b.idFilter) ? 1 : ((b.idFilter > a.idFilter) ? -1 : 0))
      : this.data.essay.values.sort((a, b) => (a.idFilter > b.idFilter) ? 1 : ((b.idFilter > a.idFilter) ? -1 : 0));
  }

  getCriteriaOfEssay(): string {
    if (this.data.essay == null) {
      return '';
    }

    let res = this.data.essay.criterias.map(crit => EssayUtils.getCriteriaToShowAir(crit, this.data.essay.idEssayType, this.translate))
      .join('<br />');

    if (StringUtils.isEmpty(res)) {
      res = this.translate.instant('protocolEdit.dialog.essay.fields.empty') as string;
    }

    return res;
  }

  getCriteria(crit: Criteria): string {
    if (this.data.essay == null) {
      return '';
    }

    let res = EssayUtils.getCriteriaToShowAir(crit, this.data.essay.idEssayType, this.translate);

    if (StringUtils.isEmpty(res)) {
      res = this.translate.instant('protocolEdit.dialog.essay.fields.empty') as string;
    }

    return res;
  }

  addEquipment(): void {
    const eq = new EssayExecutionAirInternalEquipment();
    this.data.essay.equipments.push(eq);
  }

  showDeleteEquipment(): boolean {
    return this.data.essay.equipments?.length > 1;
  }

  deleteEquipment(indexEquipment: number): void {
    this.data.essay.equipments.splice(indexEquipment, 1);
  }

  onSensorChange(event: any, equipmentIndex: number): void {
    const equipmentSelected = event.option.value as InternalEquipment;

    const eq = this.data.essay.equipments[equipmentIndex];
    eq.idEquipment = equipmentSelected.id;
    eq.equipmentName = equipmentSelected.name;
  }

  setData(results: EssayExecutionResultAir, indexData: number, event: string): void {
    const values = results.data;

    values.splice(indexData, 1, new DataValueEssay(+event));
  }

  getEquipmentName(idEquipment: number): string {
    let eqName = '';

    if (idEquipment != null) {
      this.internalEquipmentService.findOne(idEquipment).pipe(takeUntil(this.destroy$)).subscribe((result: InternalEquipment) => {
        eqName = result.name.concat(' (').concat(result.serialNum).concat(')');
        return eqName;
      });
    } else {
      return eqName;
    }
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  onChangeAppTypeCheck(event: MatCheckboxChange): void {
    this.data.essay.equipmentNA = event.checked;
  }

  deleteDataLocation(indexCriteria: number, indexLocation: number, indexData: number): void {
    const message = this.translate.instant('executionEdit.dialog.essay.delete.dataLocation') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (indexLocation > -1 && indexData > -1) {
          this.data.essay.criterias[indexCriteria].values[indexLocation].data.splice(indexData, 1);
        }
      }
    });
  }

  editFilterActiveCoal(filter: FilterAir): void {
    const index = this.data.equipment.filters.indexOf(filter);

    this.editFilter(index);
  }

  editFilterByUUID(filterUUID: string): void {
    const index = this.getIndexFilter(filterUUID);

    this.editFilter(index);
  }

  editFilter(index: number): void {
    const isEquipment = this.data.equipment != null;
    const filters = isEquipment ? this.data.equipment.filters : this.data.room.filters;
    const filter = filters[index];

    const dialogRef = this.dialog.open(ProtocolEditDialogFilterComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        accessoryFilterTypes: this.accessoryFilterTypes,
        filterTypes: this.filterAirTypes,
        filter: _.cloneDeep(filter),
        isEdit: true,
        isExecution: true,
        idExecution: this.data.execution.id,
        executionStatus: this.data.execution.idStatus
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res: FilterAir) => {

      if (res != null) {
        res.filterName = this.setFilterName(res);
        if (isEquipment) {
          this.data.equipment.filters[index] = res;
        } else {
          this.data.room.filters[index] = res;
        }
        this.filtersEssay[index] = res;
      }
    });
  }

  downloadJosetizer(): void {
    this.spinnerService.show();

    this.josetAirService.downloadJosetizer().subscribe((res: Blob) => {
      saveAs(res, 'josetizer.zip');

      this.spinnerService.hide();
    }, err => {
      if (err != null && err.error != null && typeof err.error === 'string') {
        this.snackBarService.sendError(err.error as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('executionEdit.dialog.generateReport.form.error.generic') as string);
      }
      this.spinnerService.hide();
    });

  }

  josetizeParticles(): void {
    this.spinnerService.show();

    this.josetAirService.hello().subscribe(() => {
      // Caso conectado

      const promiseClimet = new Promise<void>((resolve, reject) => {
        this.josetAirService.climet().subscribe((results: EssayExecutionAirResultParticles[]) => {
          results = this.removeDuplicatedParticles(results);

          this.data.essay.valuesParticles = results;

          this.spinnerService.hide();

          reject();
        }, () => {
          resolve();
        });
      });

      const promiseLighthouse = new Promise<void>((resolve, reject) => {
        this.josetAirService.lighthouse(ExecutionAirUtils.getTotalCountingPoints(this.data.essay))
          .subscribe((results: EssayExecutionAirResultParticles[]) => {
            results = this.removeDuplicatedParticles(results);

            this.data.essay.valuesParticles = results;

            this.spinnerService.hide();
            reject();
          }, () => {
            resolve();
          });
      });

      const promisesFail = [promiseClimet, promiseLighthouse];

      void Promise.all(promisesFail).then(() => {
        this.snackBarService.sendError('No se ha conseguido leer ningún contador de partículas');

        this.spinnerService.hide();
      });

    }, err => {
      // Caso no hay Josetizador

      console.error(err);

      this.snackBarService.sendWarn('Para esta funcionalidad debe ejecutar el Josetizador');

      this.spinnerService.hide();
    });
  }

  downloadCountStrip(): void {
    this.spinnerService.show();

    this.executionService.downloadCountStrip(this.data.execution, this.data.essay).subscribe((res: Blob) => {
      let name = 'contaje';

      if (this.data.equipment != null) {
        name = AirUtils.showEquipment(this.data.equipment);
      } else if (this.data.room != null) {
        name = this.data.room.name;
      } else if (this.data.compressedGas != null) {
        name = this.data.compressedGas.code;
      }

      saveAs(res, this.translate.instant('executionEdit.files.countStrip', { name }) as string);

      this.spinnerService.hide();
    }, err => {
      if (err != null && err.error != null && typeof err.error === 'string') {
        this.snackBarService.sendError(err.error as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('executionEdit.dialog.generateReport.form.error.generic') as string);
      }
      this.spinnerService.hide();
    });

  }

  showFilterName(reference: string): string {
    let res: string = null;
    let filter = new FilterAir();

    const index = this.getIndexFilter(reference);

    filter = this.filtersEssay[index];

    if (filter.idTypeFilter != null) {
      const type = this.accessoryFilterTypes?.find(a => a.id === filter.idTypeFilter);

      if (type != null) {
        res = 'filterType.' + type.translation;
      }
    } else {
      const type = this.filterAirTypes?.find(a => a.id === filter.idType);
      if (type != null) {
        res = 'filterType.' + type.translation;
      }
    }

    return res;
  }

  showFilterNameSatu(filterSatu: FilterAir): string {
    let res: string = null;
    let filter = new FilterAir();

    const index = this.getIndexFilterSatu(filterSatu);

    filter = this.filtersEssay[index];

    if (filter.idTypeFilter != null) {
      const type = this.accessoryFilterTypes?.find(a => a.id === filter.idTypeFilter);

      if (type != null) {
        res = 'filterType.' + type.translation;
      }
    } else {
      const type = this.filterAirTypes.find(a => a.id === filter.idType);
      if (type != null) {
        res = 'filterType.' + type.translation;
      }
    }

    return res;
  }

  getSizeFilter(reference: string): string {
    const index = this.getIndexFilter(reference);

    const filter = this.filtersEssay[index];

    let res = '';

    if (filter.diameter != null) {
      res = 'Ø'.concat(`${filter.diameter}`);
    } else {
      const sizes = [filter.length, filter.width, filter.height];

      res = sizes.filter(a => a != null).join('x');
    }

    // Caso tiene cambio de filtro
    if (StringUtils.isNotEmpty(filter.maker)) {
      res = res.concat(` ${filter.maker} (${filter.serialNum})`);
    }

    return res;
  }

  getSizeFilterSatu(filterSatu: FilterAir): string {
    const index = this.getIndexFilterSatu(filterSatu);

    const filter: FilterAir = this.filtersEssay[index];

    if (filter.diameter != null) {
      return 'Ø'.concat(`${filter.diameter}`);
    } else {
      return `${filter.length}`.concat('x').concat(`${filter.width}`);
    }
  }

  getIndexFilter(reference: string): number {
    let index: number;

    if (reference.length > 10) {
      index = this.filtersEssay.findIndex(f => `${f.idOffline}` === reference);
    } else {
      index = this.filtersEssay.findIndex(f => `${f.id}` === reference);
    }
    return index;
  }

  getIndexFilterSatu(filter: FilterAir): number {
    let index: number;
    let reference: string;

    if (filter.id != null) {
      reference = `${filter.id}`;
    } else {
      reference = `${filter.idOffline}`;
    }

    if (reference.length > 10) {
      index = this.filtersEssay.findIndex(f => `${f.idOffline}` === reference);
    } else {
      index = this.filtersEssay.findIndex(f => `${f.id}` === reference);
    }
    return index;
  }

  downloadExcelParticleCount(): void {
    this.spinnerService.show();

    this.attachmentAirService.downloadExcelToEssayExecution(this.data.execution.id, this.data.essay.id, this.data.essay.idExcel)
      .pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        saveAs(res, 'excel.csv');
        this.spinnerService.hide();
      }, error => {
        console.error(error);
        this.spinnerService.hide();
      });
  }

  uploadExcelParticleCount(event): void {
    const file = event.target.files[0] as File;

    this.spinnerService.show();

    void ExcelUtils.excelToParticleCountValidate(file,this.attachmentThermalService,this.translate).then((error:string)=>{
      if (!error 
        || this.data.essay.idEssayType === EssayAirTypeEnum.RECOVERY_ESSAY 
        || this.data.essay.idEssayType === EssayAirTypeEnum.ANEX_COUNT 
        || this.data.essay.idEssayType === EssayAirTypeEnum.PARTICLE_COUNT
        || this.data.essay.idEssayType === EssayAirTypeEnum.CO_CONCENTRATION 
        || this.data.essay.idEssayType === EssayAirTypeEnum.NO_CONCENTRATION 
        || this.data.essay.idEssayType === EssayAirTypeEnum.NO2_CONCENTRATION 
        || this.data.essay.idEssayType === EssayAirTypeEnum.CO2_CONCENTRATION ){
        void ExcelUtils.excelToParticleCount(file, this.attachmentThermalService).then((result: ResultExcelParticles) => {

          this.attachmentAirService.uploadExcelToEssayExecution(this.data.execution.id, this.data.essay, file).subscribe((idFile: number) => {
            this.data.essay.valuesParticles = result.particles;
            if (!isNaN(idFile)) {
              this.data.essay.idExcel = idFile;
            } else {
              this.data.essay.idExcel = 0;
            }
            this.spinnerService.hide();
          });
          this.data.essay.equipments = [new EssayExecutionAirInternalEquipment()];
          if (StringUtils.isNotEmpty(result.serialNum)) {
            this.internalEquipmentService.findOneBySerialNum(result.serialNum).subscribe((eq: InternalEquipment) => {
              if (eq.expired) {
                this.data.essay.valuesParticles = null;
                this.snackBarService.sendError(this.translate.instant('executionEdit.essays.equipment.form.error.expired') as string);
             } else {
              const essayEq = new EssayExecutionAirInternalEquipment();
              essayEq.idEquipment = eq.id;
              essayEq.equipmentName = eq.name;
              essayEq.idEssay = this.data.essay.id;
              this.data.essay.equipments = [essayEq];
             }
            });
          }
        });
      }else {
        this.uploadExcelParticleCountInput.nativeElement.value = null;
        this.snackBarService.sendError(error);
        this.spinnerService.hide();
      }
    })
  }

  showParticleNum(num: number): string {
    return num != null ? num.toString() : this.translate.instant('common.notApplicable') as string;
  }

  getParticleSize(criteria: Criteria): string[] {
    return ExecutionAirUtils.getParticleSize(criteria);
  }


  getClassParticleNum(criteria: Criteria, size: string, total: number): string {
    const particleSize = this.getParticleSize(criteria);

    if (!particleSize.includes(size)) {
      return '';
    }

    const gradeClassType = criteria.customValue1?.toLowerCase();
    // eslint-disable-next-line max-len
    const isRepose = this.data.essay.idEssayType === EssayAirTypeEnum.PARTICLE_COUNT_REPOSE || this.data.essay.idEssayType === EssayAirTypeEnum.PARTICLE_COUNT || this.data.essay.idEssayType === EssayAirTypeEnum.ANEXOI_COUNT_REPOSE || this.data.essay.idEssayType === EssayAirTypeEnum.ANEX_COUNT;
    // eslint-disable-next-line max-len
    const isAnexo = this.data.essay.idEssayType === EssayAirTypeEnum.ANEXOI_COUNT_REPOSE || this.data.essay.idEssayType === EssayAirTypeEnum.ANEXOI_COUNT_WORKING || this.data.essay.idEssayType === EssayAirTypeEnum.ANEX_COUNT;
    const isGass = this.data.essay.idEssayType === EssayAirTypeEnum.PARTICLE_COUNT;

    let criteriaV1: number = null;
    let criteriaV2:number  = null;
    const resultValue = criteria.customValue2?.split(';');
    if (resultValue != null && resultValue[0]?.length > 0) {
      // eslint-disable-next-line radix
      criteriaV1 = Number(resultValue[0]);
    }

    if (resultValue != null && resultValue[1]?.length > 0) {
      criteriaV2 = Number(resultValue[1]);
    }

    let count = 0;
    if (!isGass) {
      count = ExecutionAirUtils.getTotalParticlesBySizeWithCriteriaV(size, gradeClassType, isRepose, isAnexo, criteriaV1, criteriaV2);
    } else {
      if (gradeClassType?.includes('class')) {
        count = ExecutionAirUtils.getTotalParticlesGasBySize(size, gradeClassType);
      } else {
        count = ExecutionAirUtils.getTotalParticlesBySize(size, gradeClassType, isRepose);
      }
    }

    total = total != null ? total : 0;

    return count >= total ? 'essayAccording' : 'essayNotAccording bold';
  }

  getValueAverageParticleCountCompressedGas(particleSize: string): number {
    const valuesParticles = this.data.essay.valuesParticles;
    const last3Particles = valuesParticles.slice(-3);

    let res: number[] = [];

    if (particleSize === '0.1') {
      res = last3Particles.map(a => a.zeroOne);
    } else if (particleSize === '0.2') {
      res = last3Particles.map(a => a.zeroTwo);
    } else if (particleSize === '0.3') {
      res = last3Particles.map(a => a.zeroThree);
    } else if (particleSize === '0.5') {
      res = last3Particles.map(a => a.zeroFive);
    } else if (particleSize === '1.0') {
      res = last3Particles.map(a => a.oneZero);
    } else if (particleSize === '5.0') {
      res = last3Particles.map(a => a.fiveZero);
    }

    return NumberUtils.round(NumberUtils.average(res), 0);
  }

  isSelectedMulti(valuesStr: string | string[], valueCheck: string): boolean {
    if (valuesStr == null || valueCheck == null) {
      return false;
    }

    let values = [];
    if (typeof valuesStr === 'string') {
      values = valuesStr.split(';');
    } else {
      values = valuesStr;
    }

    return values.includes(valueCheck);
  }

  editCriteria(index: number): void {
    const essayValue = this.data.essay.criterias;

    let criteriaRemove = [];

    const criteria = this.data.essay.criterias[index];

    try {
      criteriaRemove = essayValue.map(crit => crit.idType).filter(crit => crit !== criteria.idType);
    } catch (e) { }

    const dialogRef = this.dialog.open(ExecutionEditEssayCriteriaComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        criteria: _.cloneDeep(criteria),
        idConfig: this.data.idConfig,
        idEssayType: this.data.essay.idEssayType,
        isEdit: true,
        criteriasRemove: criteriaRemove
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res: Criteria) => {

      if (res != null) {
        const originalCriteria = this.data.essay.criterias[index];

        originalCriteria.idType = res.idType;
        originalCriteria.criteriaValue1 = res.criteriaValue1;
        originalCriteria.criteriaValue2 = res.criteriaValue2;
        originalCriteria.customValue1 = res.customValue1;
        originalCriteria.customValue2 = res.customValue2;
        originalCriteria.showDiffInDeviations = res.showDiffInDeviations;
        originalCriteria.showDiffInObservations = res.showDiffInObservations;
        originalCriteria.criteriaValueChangeReason = res.criteriaValueChangeReason;
      }
    });
  }

  deleteCriteria(index: number): void {
    this.data.essay.criterias.splice(index, 1);
  }

  showAverageSpeed(location: EssayExecutionResultAir): number {
    if (ArrayUtils.isEmpty(location.data)) {
      return null;
    }

    let velocity = 0;
    location.data.forEach(data => velocity += +data.value);

    return NumberUtils.round(velocity / location.data.length, 2);
  }

  showFlow(location: EssayExecutionResultAir, round: boolean = true): number {
    let velocity = 0;
    let filter = new FilterAir();

    if (location.uuidFilter != null) {
      if (ExecutionAirUtils.checkIdFilter(location.uuidFilter)) {
        filter = this.filtersEssay.find(f => f.id.toString() === location.uuidFilter);
      } else {
        filter = this.filtersEssay.find(f => f.idOffline === location.uuidFilter);
      }
    }

    const filterSup = ExecutionAirUtils.getFilterSurface(filter);

    velocity = ArrayUtils.calcAvg(location.data?.map(d => +d.value), false);

    if (velocity == null) {
      return 0;
    }

    let res = NumberUtils.round(velocity, 2) * filterSup * 3600;

    if (round) {
      res = NumberUtils.round(res, 2);
    }

    return res;
  }

  getEssayName(essayType: number, translate = true): string {
    if (ArrayUtils.isEmpty(this.essayTypes)) {
      return null;
    }

    let res = this.essayTypes.find(essay => essay.id === essayType)?.translation;

    if (translate) {
      res = this.translate.instant('essayAir.' + res) as string;
    }

    return res;
  }

  getCriteriaByEssay(): Criteria[] {
    const essay = this.data.essay;

    if (essay.idEssayType === EssayAirTypeEnum.REN_FLOW_AND_RATE) {
      const hasRenRate = essay.criterias.some(c => c.customValue1 === 'renovationPerHour');
      const hasFlow = essay.criterias.some(c => c.customValue1 === 'caudalMeassurement');
      if (hasRenRate && hasFlow) {
        this.renFlowAndRatehasBoth = true;
        return essay.criterias.filter(c => c.customValue1 === 'renovationPerHour');
      }
    } else if (this.showAirSpeedBioII) {
      return [essay.criterias[0]];
    } else if (essay.idEssayType === EssayAirTypeEnum.AIR_SPEED && (
      this.data?.equipment?.idType === EquipmentTypeAirEnum.PROTECTION_HEAVY_CABIN ||
      this.data?.equipment?.idType === EquipmentTypeAirEnum.AISLATOR ||
      this.data?.equipment?.idType === EquipmentTypeAirEnum.CELL ||
      this.data?.equipment?.idType === EquipmentTypeAirEnum.TRANSFER)) {

      const hasAvg = essay.criterias.some(c => c.customValue1 === SpeedTypeCriteria.AVERAGE_SPEED);
      const hasExtra = essay.criterias.some(c => c.customValue1 === SpeedTypeCriteria.EXTRACTION_SPEED);
      if (hasAvg && hasExtra) {
        this.heavyHasBothCriterias = true;
        return essay.criterias.filter(c => c.customValue1 === SpeedTypeCriteria.AVERAGE_SPEED);
      }
    } else if (essay.idEssayType === EssayAirTypeEnum.DEW_POINT) {
      essay.criterias.forEach(c => c.values.filter(v => v.reference == null).forEach(v => v.reference = this.getDewPointValues()[0]));
    }

    return essay.criterias;
  }

  getValueFromData(result: EssayExecutionResultAir, index: number): string {
    return result.data[index].value;
  }

  getDateFromData(result: EssayExecutionResultAir, index: number): Date {
    const dateStr = this.getValueFromData(result, index);

    const date = moment(dateStr, 'DD/MM/YYYY');

    return DateUtils.anyToDate(date.toDate());
  }

  saveData(result: EssayExecutionResultAir, index: number, event: string): void {
    const value = event;

    result.data[index].value = value;
  }

  saveDate(result: EssayExecutionResultAir, index: number, event: any): void {

    let value = event.target.value as Date | string;
    if (value != null) {

      if (value instanceof Date) {
        value = moment(value).format('DD/MM/YYYY');
      }

      const date = moment(value + ' 12:00:00 + 0:00', 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }

      result.data[index] = new DataValueEssay(DateUtils.toDateStr(date));
    } else {
      result.data[index] = null;
    }

  }

  hasMicrobiologicalAmbient(): boolean {
    return ExecutionAirUtils.hasMicrobiologicalAmbient(this.data.essay);
  }

  hasMicrobiologicalSurface(): boolean {
    return ExecutionAirUtils.hasMicrobiologicalSurface(this.data.essay);
  }

  getDewPointValues(): string[] {
    if (!this.showDewPoint) {
      return [];
    }

    return ['minimum', 'average'];
  }

  showTotalFlow(criteria: Criteria, round: boolean = true): number {
    let caudalTotal = 0;

    criteria.values.forEach(value => {
      if (ArrayUtils.isEmpty(value.data) && value.value != null) {
        caudalTotal += +value.value;
      } else {
        caudalTotal += this.showFlow(value, false);
      }
    });

    if (round) {
      caudalTotal = NumberUtils.round(caudalTotal, 2)
    }

    return caudalTotal;
  }

  showRenH(criteria: Criteria, round: boolean = true): number {
    const flow = this.showTotalFlow(criteria, round);
    let volume: number = null;

    this.data.essay.essayValues.forEach(field => {
      if (field.idField === 16) {
        volume = Number(field._value);
      }
    });

    if (volume == null) {
      return null;
    }

    let res = flow / volume;

    if (round) {
      res = NumberUtils.round(res, 2)
    }

    return res;
  }

  showUploadVideo(): boolean {
    if (!this.data.essay.active || (!this.data.essay.id && this.onlineService.latestOnline)) {
      return false;
    }

    const fieldInVideo = this.data.essay.essayValues?.find(f => f.idField === FieldEnum.IN_VIDEO);
    let res = false;

    if (fieldInVideo) {
      let value: string | string[] = fieldInVideo.value;

      if (Array.isArray(value)) {
        value = value.join('');
      }

      res = value === 'true';
    }

    return res;
  }

  uploadVideo(event): void {
    const file = event.target.files[0] as File;

    this.spinnerService.show();

    this.attachmentAirService.uploadVideoToEssayExecution(this.data.execution.id, this.data.essay, file).subscribe((item: number) => {
      if (!isNaN(item)) {
        this.data.essay.idVideo = item;
      } else {
        this.data.essay.idVideo = 0;
      }

      this.spinnerService.hide();

      this.snackBarService.sendSuccess(this.translate.instant('userEdit.dialog.form.uploadCertificate.ok') as string);
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('userEdit.dialog.form.uploadCertificate.error') as string);
    });
  }

  downloadVideo(): void {
    this.spinnerService.show();

    this.attachmentAirService.downloadVideoToEssayExecution(this.data.execution.id, this.data.essay.id).pipe(takeUntil(this.destroy$))
      .subscribe((res: Blob) => {
        saveAs(res, 'video.mp4');
        this.spinnerService.hide();
      }, error => {
        console.error(error);
        this.spinnerService.hide();
      });
  }

  openVideo(): void {
    const observable = this.attachmentAirService.getUrlVideoToEssayExecution(this.data.execution.id, this.data.essay.id)
      .pipe(takeUntil(this.destroy$));

    const data: DialogDataVideoPlayer = { observable };

    this.dialog.open(VideoPlayerComponent, {
      minWidth: '90%',
      maxHeight: '95vh',
      data
    });

  }

  removeVideo(): void {
    this.attachmentAirService.deleteVideoToEssayExecution(this.data.execution.id, this.data.essay.id)
      .pipe(takeUntil(this.destroy$)).subscribe(() => this.data.essay.idVideo = null);

  }

  uploadCertificate(event): void {
    const file = event.target.files[0] as File;

    this.spinnerService.show();

    this.attachmentAirService.uploadCertificateToEssayExecution(this.data.execution.id, this.data.essay, file).subscribe((item: number) => {
      if (!isNaN(item)) {
        this.data.essay.idCertificate = item;
      } else {
        this.data.essay.idCertificate = 0;
      }

      this.spinnerService.hide();

      this.snackBarService.sendSuccess(this.translate.instant('userEdit.dialog.form.uploadCertificate.ok') as string);
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('userEdit.dialog.form.uploadCertificate.error') as string);
    });
  }

  downloadCertificate(): void {
    this.spinnerService.show();

    this.attachmentAirService.downloadCertificateToEssayExecution(this.data.execution.id, this.data.essay.id).pipe(takeUntil(this.destroy$))
      .subscribe((res: Blob) => {
        saveAs(res, 'cert.pdf');
        this.spinnerService.hide();
      }, error => {
        console.error(error);
        this.spinnerService.hide();
      });
  }

  setAerosol(event: MatCheckboxChange, result: EssayExecutionResultAir): void {
    result.aerosol = event.checked;
  }

  setForceAccording(event: MatCheckboxChange): void {
    this.data.essay.forceAccording = event.checked;
  }

  justCaudal(event: MatCheckboxChange, location: EssayExecutionResultAir): void {

    location.showLocations = event.checked;

    if (this.mapJustCaudal) {
      this.mapJustCaudal.set(location, event.checked);
    }

    if (!location.dataReset || location.dataReset?.length === 0) {
      location.dataReset = _.cloneDeep(location.data);
    }

    if (event.checked) {
      location.data = [];
      location.value = null;
    } else {
      if (location.dataReset?.length > 0) {
        location.data = location.dataReset;
      } else {
        location.data = Array(4).fill(new DataValueEssay()) as DataValueEssay[];
      }
    }
  }

  checkJustCaudal(location: EssayExecutionResultAir): boolean {
    if (location?.idFilter) {
      const filt = this.filtersEssay?.find(filter => filter.id === location.idFilter);
      if (filt.idType === FilterAirTypeEnum.DIFFUSER) {
        if(_.isString(location.value) && location.value?.includes(';')){
          location.value = this.showFlow(location).toString();
        }
        return true;
      } else {
        return this.mapJustCaudal?.get(location);
      }
    } else {
      return this.mapJustCaudal?.get(location);
    }
  }

  disabledJustCaudal(location: EssayExecutionResultAir): boolean {
    if (location?.idFilter) {
      const filt = this.filtersEssay?.find(filter => filter.id === location.idFilter);
      if (filt?.idType === FilterAirTypeEnum.DIFFUSER) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  enableEditField(field: Field): boolean {
    if (!field.editableInExecution) {
      return false;
    }

    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return allowedStatuses.includes(this.data.execution.idStatus);
  }

  enableEditCriteria(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return allowedStatuses.includes(this.data.execution.idStatus);
  }

  openEditField(fieldIndex: number): void {
    const field = this.data.essay.essayValues[fieldIndex];

    const dialogRef = this.dialog.open(ExecutionEditFieldEditComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        field: _.cloneDeep(field),
        fromProtocol: this.data.essay.fromProtocol,
        isAnexI: this.data.essay.idEssayType === EssayAirTypeEnum.ANEXOI_COUNT_REPOSE
        || this.data.essay.idEssayType === EssayAirTypeEnum.ANEXOI_COUNT_WORKING
        || this.data.essay.idEssayType === EssayAirTypeEnum.ANEX_COUNT,
        essayType: this.data.essay.idEssayType
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Field) => {

      if (result != null) {
        this.data.essay.essayValues[fieldIndex] = result;

        if (this.showMicrobiological) {
          this.data.essay.criterias.forEach(c => this.updateMicrobiologicalResults(c));
        }
      }
    });
  }

  openNewCriteria(): void {
    const essayValue = this.data.essay.criterias;

    let criteriaRemove = [];

    const idEquipmentType = this.data.equipment?.idType || 1;

    try {
      criteriaRemove = essayValue.map(crit => crit.idType);
    } catch (e) { }

    const dialogRef = this.dialog.open(ProtocolEditEssayCriteriaAirComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        criteria: new Criteria(),
        idEssayType: this.data.essay.idEssayType,
        idEquipmentType,
        isEdit: false,
        criteriasRemove: criteriaRemove,
        entityType: this.entityType
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res: Criteria) => {

      if (res != null) {
        res.values = [];
        const index = this.data.essay.criterias.push(res);
        this.newValue(index - 1);
        this.deleteValue(index - 1, 0);
      }
    });
  }

  showSpeedType(): string {
    let type = '';

    if (this.data.equipment !== undefined) {
      if (this.data.equipment.idType === EquipmentTypeAirEnum.LAMINAR_CABIN_HORIZONTAL ||
        this.data.equipment.idType === EquipmentTypeAirEnum.LAMINAR_CABIN_VERTICAL ||
        this.data.equipment.idType === EquipmentTypeAirEnum.LAMINAR_CABIN_30_70) {
        type = this.translate.instant('protocolEdit.dialog.essay.criteria.fastSpeed.label') as string;
      } else if (this.data.equipment.idType === EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_I ||
        this.data.equipment.idType === EquipmentTypeAirEnum.CARVING_TABLE ||
        this.data.equipment.idType === EquipmentTypeAirEnum.EXTRACTION_CABIN ||
        this.data.equipment.idType === EquipmentTypeAirEnum.EXTRACTOR_DISPLAY_CASE) {
        type = this.translate.instant('protocolEdit.dialog.essay.criteria.frontalSpeed.label') as string;
      } else if (this.data.equipment.idType === EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_III) {
        type = this.translate.instant('protocolEdit.dialog.essay.criteria.gloveSpeed.label') as string;
      } else if (this.data.equipment.idType === EquipmentTypeAirEnum.AIR_DRIVE_UNIT) {
        type = this.translate.instant('protocolEdit.dialog.essay.criteria.ImpulsionSpeed.label') as string;
      } else if (this.data.equipment.idType === EquipmentTypeAirEnum.AIR_EXTRACTION_UNIT) {
        type = this.translate.instant('protocolEdit.dialog.essay.criteria.extractionSpeed.label') as string;
      } else if (this.data.equipment.idType === EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_IIA ||
        this.data.equipment.idType === EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_IIB) {
        // TODO cambiar dependiendo del filtro
        type = this.translate.instant('executionEdit.essaySpeed.speeds') as string;
      }
    }
    return type;
  }

  changeChanneled(event: MatCheckboxChange, criteria: Criteria): void {
    this.isChanneled = event.checked;
    if (!event.checked) {
      criteria.values.forEach(value => {
        value.reference = null;
      })
    } else if (event.checked) {
      criteria.values.forEach(value => {
        let filt = new FilterAir();
        if (value.idFilter != null) {
          filt = this.filtersEssay.find(filter => filter.id === value.idFilter);
        } else {
          filt = this.filtersEssay.find(filter => filter.idOffline === value.uuidFilter);
        }

        if (filt?.idType === FilterAirTypeEnum.FILTER) {
          if (filt?.idTypeFilter === FilterAirEnum.HEPA_EXTRACTION_1 ||
            filt?.idTypeFilter === FilterAirEnum.HEPA_EXTRACTION_2) {
            value.reference = 'canalizada';
          }
        }
      });
    }
  }

  showFrontSpeed(result: EssayExecutionResultAir): number {

    this.data.essay.essayValues.forEach(field => {
      if (field.idField === FieldEnum.EXTRACTION_SURFACE) {
        this.exSurface = +field.value;
      }

      if (field.idField === FieldEnum.FRONTAL_WINDOW_SURFACE) {
        this.frontSurface = +field.value;
      }
    });

    if (result.data[0]?.value != null) {
      const speed = ArrayUtils.calcAvg(result.data.map(value => Number(value.value)));

      const flow = speed * this.exSurface * 3600;

      return NumberUtils.round(flow / (this.frontSurface * 3600), 2);
    }

    else return 0;
  }

  enableEditEssay(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return allowedStatuses.includes(this.data.execution.idStatus)
  }

  showFrontSpeedByFilter(uuidFilter: string): boolean {
    const index = this.getIndexFilter(uuidFilter);
    const filter = this.filtersEssay[index];

    let extraction: boolean;

    if (this.filters.includes(filter.idType) &&
      filter.idTypeFilter === FilterAirEnum.HEPA_EXTRACTION_1) {
      extraction = true;
    }
    return extraction && !this.isChanneled;
  }

  public setValue(field: Field, index: number, value: string | MatCheckboxChange | MatSelectionListChange): void {

    if (value instanceof MatCheckboxChange) {
      value = value.checked.toString();
    } else if (value instanceof MatSelectionListChange) {
      value = value.source.selectedOptions.selected.map(i => i.value as string).join(';');
    }

    field.setValue(index, value);
  }

  public getValuesEssayRecovery(): string {
    const essay = this.data.essay;

    const mapResults = ExecutionAirUtils.getValuesEssayRecoveryShow(essay.valuesParticles);

    let res = '';

    for (const [location, recovery] of mapResults.entries()) {
      res += `${location}: ${recovery}<br />`;
    }

    return res;
  }

  private selectCriteriaType(): void {
    const recoveryEssays = [EssayAirTypeEnum.RECOVERY_ESSAY];
    const introductionEssays = [EssayAirTypeEnum.INDUCTION_ESSAY];
    const renFlowAndRateEssays = [EssayAirTypeEnum.REN_FLOW_AND_RATE];
    const integrityEssay = [EssayAirTypeEnum.HEPA_FILTER_INTEGRITY];
    const saturationEssay = [EssayAirTypeEnum.FILTER_SATURATION];
    const microEssay = [EssayAirTypeEnum.MICROBIO_ESSAY];
    const speedEssay = [EssayAirTypeEnum.AIR_SPEED];
    const dewPointEssay = [EssayAirTypeEnum.DEW_POINT];

    this.criteriaSimple = this.criteriaSimpleEssays.includes(this.data.essay.idEssayType);
    this.showLocations = this.locationEssays.includes(this.data.essay.idEssayType);
    if (this.data.essay.idEssayType === EssayAirTypeEnum.WASTE_OF_LOAD && this.data.room != null) {
      this.wasteOfLoadRoom = true;
      this.criteriaSimple = false;
    }

    this.showAccording = this.accordingEssays.includes(this.data.essay.idEssayType);
    this.showParticleCount = this.particleCountEssays.includes(this.data.essay.idEssayType);
    this.showRecoveryEssay = recoveryEssays.includes(this.data.essay.idEssayType);
    this.showFilterHepa = this.hepaFilterEssays.includes(this.data.essay.idEssayType);
    this.renFlowAndRate = renFlowAndRateEssays.includes(this.data.essay.idEssayType);
    this.integrity = integrityEssay.includes(this.data.essay.idEssayType);
    this.showSaturation = saturationEssay.includes(this.data.essay.idEssayType);
    this.showIntroduction = introductionEssays.includes(this.data.essay.idEssayType);
    this.showSpeed = speedEssay.includes(this.data.essay.idEssayType);
    this.showMicrobiological = microEssay.includes(this.data.essay.idEssayType);
    this.showDewPoint = dewPointEssay.includes(this.data.essay.idEssayType);
  }

  private isHepaFilter(filter: FilterAir): boolean {
    const hepas = [FilterAirEnum.HEPA_IMPULSION, FilterAirEnum.HEPA_EXTRACTION_1, FilterAirEnum.HEPA_EXTRACTION_2];

    return (this.filters.includes(filter.idType) && hepas.includes(filter.idTypeFilter));
  }

  private setFilterName(filter: FilterAir): string {
    let res: string = null;

    if (filter.idTypeFilter != null) {
      const type = this.accessoryFilterTypes?.find(a => a.id === filter.idTypeFilter);

      if (type != null) {
        res = 'filterType.' + type.translation;
      }
    } else {
      const type = this.filterAirTypes.find(a => a.id === filter.idType);
      if (type != null) {
        res = 'filterType.' + type.translation;
      }
    }

    return res;
  }

  private configureValueByFilter(value: EssayExecutionResultAir, filt: FilterAir): void {
    if (filt?.idType === FilterAirTypeEnum.FILTER || filt?.idType === FilterAirTypeEnum.FILTER_WITH_DIFFUSER ||
      filt?.idType === FilterAirTypeEnum.IMPULSION_RACK) {
      if (filt?.idTypeFilter === FilterAirEnum.HEPA_IMPULSION || filt?.idTypeFilter === FilterAirEnum.VEILED_FILTERS ||
        filt?.idType === FilterAirTypeEnum.IMPULSION_RACK) {
        value.showLocations = true;
        if (value.value != null) {
          let arrayData: string[] = [];
          if (typeof value.value === 'string') {
            arrayData = value.value.split(';');
          } else {
            arrayData[0] = value.value;
          }
          if (arrayData.length < 2) {
            value.value = arrayData[0];
            value.showLocations = false;
          } else {
            value.data = arrayData.map(data => new DataValueEssay(+data));
          }
        } else {
          value.data = Array(4).fill(new DataValueEssay()) as DataValueEssay[];
        }
      } else {
        value.showLocations = false;
      }
    } else if (filt?.idType === FilterAirTypeEnum.DIFFUSER) {
      if (value.value != null) {
        const arrayData = value.value.split(';');
        if (arrayData.length < 2) {
          value.value = arrayData[0];
          value.showLocations = false;
        } else {
          value.data = arrayData.map(data => new DataValueEssay(+data));
        }
      }
      value.showLocations = false;
    }
  }

  private configureValueByFilterSpeed(value: EssayExecutionResultAir, filt: FilterAir): void {
    if (this.data.equipment != null) {
      if (this.data.equipment.idType === EquipmentTypeAirEnum.LAMINAR_CABIN_HORIZONTAL ||
        this.data.equipment.idType === EquipmentTypeAirEnum.LAMINAR_CABIN_VERTICAL ||
        this.data.equipment.idType === EquipmentTypeAirEnum.LAMINATE_FLOW_MODULE) {
        if (filt?.idType === FilterAirTypeEnum.FILTER) {
          if (filt?.idTypeFilter === FilterAirEnum.HEPA_IMPULSION || filt?.idTypeFilter === FilterAirEnum.VEILED_FILTERS ||
            filt?.idTypeFilter === FilterAirEnum.HEPA_EXTRACTION_1 ||
            filt?.idTypeFilter === FilterAirEnum.HEPA_EXTRACTION_2) {
            value.showLocations = true;
            if (value.value != null) {
              const arrayData = value.value.split(';');
              if (arrayData.length < 2) {
                value.value = arrayData[0];
                value.showLocations = false;
              } else {
                value.data = arrayData.map(data => new DataValueEssay(+data));
              }
            } else {
              value.data = Array(4).fill(new DataValueEssay()) as DataValueEssay[];
            }
          }
        }
      } else if (this.data.equipment.idType === EquipmentTypeAirEnum.LAMINAR_CABIN_30_70 ||
        this.data.equipment.idType === EquipmentTypeAirEnum.AIR_DRIVE_UNIT) {
        if (filt?.idType === FilterAirTypeEnum.FILTER) {
          if (filt?.idTypeFilter === FilterAirEnum.HEPA_IMPULSION || filt?.idTypeFilter === FilterAirEnum.VEILED_FILTERS) {
            value.showLocations = true;
            if (value.value != null) {
              const arrayData = value.value.split(';');
              if (arrayData.length < 2) {
                value.value = arrayData[0];
                value.showLocations = false;
              } else {
                value.data = arrayData.map(data => new DataValueEssay(+data));
              }
            } else {
              value.data = Array(4).fill(new DataValueEssay()) as DataValueEssay[];
            }
          }
        }
      } else if (this.data.equipment.idType === EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_IIA ||
        this.data.equipment.idType === EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_IIB) {
        this.showIsChanneled = true;
        if (filt?.idType === FilterAirTypeEnum.FILTER) {
          if (filt?.idTypeFilter === FilterAirEnum.HEPA_IMPULSION || filt?.idTypeFilter === FilterAirEnum.VEILED_FILTERS) {
            value.showLocations = true;
            if (value.value != null) {
              const arrayData = value.value.split(';');
              if (arrayData.length < 2) {
                value.value = arrayData[0];
                value.showLocations = false;
              } else {
                value.data = arrayData.map(data => new DataValueEssay(+data));
              }
            } else {
              value.data = Array(8).fill(new DataValueEssay()) as DataValueEssay[];
            }
          } else if (filt?.idTypeFilter === FilterAirEnum.HEPA_EXTRACTION_1) {
            value.showLocations = true;
            if (value.value != null) {
              const arrayData = value.value.split(';');
              if (arrayData.length < 2) {
                value.value = arrayData[0];
                value.showLocations = false;
              } else {
                value.data = arrayData.map(data => new DataValueEssay(+data));
              }
              if (value.reference === 'canalizada') {
                this.isChanneled = true;
              }
            } else {
              value.data = Array(4).fill(new DataValueEssay()) as DataValueEssay[];
            }
          }
        }
      } else if (this.data.equipment.idType === EquipmentTypeAirEnum.PROTECTION_HEAVY_CABIN ||
        this.data.equipment.idType === EquipmentTypeAirEnum.FFU ||
        this.data.equipment.idType === EquipmentTypeAirEnum.AISLATOR ||
        this.data.equipment.idType === EquipmentTypeAirEnum.CELL ||
        this.data.equipment.idType === EquipmentTypeAirEnum.TRANSFER) {
        value.showLocations = true;
        if (value.value != null) {
          const arrayData = value.value.split(';');
          if (arrayData.length < 2) {
            value.value = arrayData[0];
            value.showLocations = false;
          } else {
            value.data = arrayData.map(data => new DataValueEssay(+data));
          }
        } else {
          value.data = Array(4).fill(new DataValueEssay()) as DataValueEssay[];
        }
      } else if (this.data.equipment.idType === EquipmentTypeAirEnum.AIR_EXTRACTION_UNIT) {
        if (filt?.idType === FilterAirTypeEnum.FILTER) {
          if (filt?.idTypeFilter === FilterAirEnum.HEPA_EXTRACTION_1) {
            value.showLocations = true;
            if (value.value != null) {
              const arrayData = value.value.split(';');
              if (arrayData.length < 2) {
                value.value = arrayData[0];
                value.showLocations = false;
              } else {
                value.data = arrayData.map(data => new DataValueEssay(+data));
              }
            } else {
              value.data = Array(4).fill(new DataValueEssay()) as DataValueEssay[];
            }
          }
        }
      } else {
        value.showLocations = false;
      }
    } else if (this.data.room != null) {
      const hepas = [FilterAirEnum.HEPA_IMPULSION, FilterAirEnum.VEILED_FILTERS];
      const diffuser = [FilterAirTypeEnum.DIFFUSER, FilterAirTypeEnum.IMPULSION_RACK];

      const showLocations = (this.filters.includes(filt?.idType) && hepas.includes(filt?.idTypeFilter)) || diffuser.includes(filt?.idType);

      if (showLocations) {
        value.showLocations = true;
        if (value.value != null) {
          const arrayData = value.value.split(';');
          if (arrayData.length < 2) {
            value.value = arrayData[0];
            value.showLocations = false;
          } else {
            value.data = arrayData.map(data => new DataValueEssay(+data));
          }
        } else {
          value.data = Array(4).fill(new DataValueEssay()) as DataValueEssay[];
        }
      }
    }
  }

  private updateMicrobiologicalResults(criteria: Criteria) {
    const values = this.getValuesOfCriteria(criteria);

    const totalPoints = ExecutionAirUtils.getTotalCountingPoints(this.data.essay);
    // const newValues = Array(totalPoints).fill(new EssayExecutionResultAir()) as EssayExecutionResultAir[];
    const newValues = [];
    for(let i = 0; i < totalPoints; i++) {
      newValues.push(new EssayExecutionResultAir());
    }
    for (let i = 0; i < values.length && i < totalPoints; i++) {
      let oldValue = values[i];

      if (!(oldValue instanceof EssayExecutionResultAir)) {
        const s = new EssayExecutionResultAir();

        for (const prop in (oldValue as any)) {
          if (prop !== 'value') {
            s[prop] = oldValue[prop];
          }
        }

        s.data = ((oldValue as any).value.split(';') as string[]).map(v => new DataValueEssay(v));

        oldValue = s;
      }

      newValues[i] = _.cloneDeep( oldValue);
    }

    newValues.filter(val => val.data?.length !== 3)
      .forEach(val => val.data = [new DataValueEssay(), new DataValueEssay(), new DataValueEssay()]);

    criteria.values = newValues;
  }

  private requestReason(callback: (reason: string) => void) {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

  private isRenFilter(filter: FilterAir): boolean {
    const hepas = [FilterAirEnum.HEPA_IMPULSION, FilterAirEnum.VEILED_FILTERS];
    const diffuser = [FilterAirTypeEnum.DIFFUSER, FilterAirTypeEnum.IMPULSION_RACK];

    return (this.filters.includes(filter.idType) && hepas.includes(filter.idTypeFilter)) || diffuser.includes(filter.idType);
  }

  private cloneValuesToCriteria(criteria: Criteria): void {
    this.data.essay.criterias = this.data.essay.criterias.map(c => {
      if (c === criteria) {
        return c;
      }

      for (let i = 0; i < criteria.values.length; i++) {
        const value = _.cloneDeep(criteria.values[i]);
        const valueC = c.values[i];

        if (valueC) {
          value.id = valueC.id;
          if (valueC.idCriteria != null) {
            value.idCriteria = valueC.idCriteria;
          }
          c.values[i] = value;
        } else {
          value.id = null;
          c.values.push(value);
        }
      }
      return c;
    });
  }

  private removeDuplicatedParticles(list: EssayExecutionAirResultParticles[]): EssayExecutionAirResultParticles[] {
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    list = list.sort(function(a, b) {
      return DateUtils.anyToDate(a.date).getTime() - DateUtils.anyToDate(b.date).getTime();
    });

    return list;//ArrayUtils.isEmpty(list) ? list : _.uniqWith(list, (loc1, loc2) => loc1.date === loc2.date && loc1.location === loc2.location);
  }

}
