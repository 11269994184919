import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Constants } from 'src/app/utils/constants';
import { Location } from '@angular/common';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StringUtils } from 'src/app/utils/stringUtils';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnDestroy, OnInit {

  matcher = new MyErrorStateMatcher();
  changePasswordForm: UntypedFormGroup;

  user: User;

  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  isGuest = false;
  urlRedirection: string;

  private destroy$ = new Subject<void>();

  constructor(
    fb: UntypedFormBuilder,
    private location: Location,
    private userService: UserService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private cookieService: CookieService,
    private router: Router) {
    this.user = new User();

    this.userService.profile().pipe(takeUntil(this.destroy$)).subscribe((item: User) => this.user = item);

    this.changePasswordForm = fb.group({
      currentPassword: [this.currentPassword, [Validators.required]],
      newPassword: [this.newPassword, [Validators.required, Validators.pattern(Constants.PASSWORD_PATTERN)]],
      confirmPassword: [this.confirmPassword, [Validators.required, Validators.pattern(Constants.PASSWORD_PATTERN)]]
    }, { validator: this.checkPasswords });
  }

  ngOnInit(): void {
    const rol = this.cookieService.get('rol');

    if (rol === 'guest') {
      this.isGuest = true;
    }

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkPasswords(group: UntypedFormGroup) { // here we have the 'passwords' group
    const pass = group.controls.newPassword.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notMatch: true };
  }

  changePassword(): void {
    if (!this.changePasswordForm.valid) {
      if (this.changePasswordForm.errors[Constants.FIELD_NO_MATCH]) {
        this.snackBarService.sendError(this.translate.instant('profile.changePassword.confirmPassword.error.notMatch') as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('profile.changePassword.form.error.invalid') as string);
      }

      return;
    }

    this.spinnerService.show();

    this.userService.changePassword(this.currentPassword, this.newPassword).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.snackBarService.sendSuccess(this.translate.instant('profile.changePassword.form.ok') as string);
      this.spinnerService.hide();
    }, err => {
      this.spinnerService.hide();
      if (err.error) {
        this.snackBarService.sendError(err.error as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('profile.save.form.error.invalid') as string);
      }
    });
  }

  getInitials(): string {
    let res = '';
    if (this.user.fullName) {
      res = StringUtils.getInitials(this.user.fullName);
    }
    return res;
  }

  save(): void {
    this.spinnerService.show();

    this.userService.save(this.user).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.snackBarService.sendSuccess(this.translate.instant('profile.save.form.ok') as string);
      this.spinnerService.hide();
    }, err => {
      this.spinnerService.hide();
      if (err.error) {
        this.snackBarService.sendError(err.error as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('profile.save.form.error') as string);
      }
    });
  }

  cancel(): void {
    this.location.back();
  }

  redirectionPage(): void {
    const rol = this.cookieService.get('rol');

    if (rol === 'guest') {
      void this.router.navigateByUrl('/portal-client');
    } else {
      void this.router.navigateByUrl('/');
    }
  }
}
