/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, Inject } from '@angular/core';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Equipment } from 'src/app/model/equipment';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentTypeService } from 'src/app/services/equipmentType.service';
import { EquipmentType } from 'src/app/model/equipmentType';
import * as moment from 'moment';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subject, takeUntil } from 'rxjs';
import { InternalEquipment } from 'src/app/model/internalEquipment';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { InternalEquipmentService } from 'src/app/services/internalEquipment.service';
import { CalibrationEditCreateEquipmentComponent } from '../../calibrations/calibration-edit/calibration-edit-create-equipment.component';
import { EquipmentAutocompleteFilter } from 'src/app/model/autocompleteFilter';
import { CalibrationResponsibleEnum } from 'src/app/model/calibration';
import { EquipmentPerformances } from 'src/app/utils/equipmentUtils';

export interface DialogDataEquipment {
  equipment: Equipment;
  isEdit: boolean;
  idClient: number;
}

@Component({
  selector: 'app-protocol-edit-dialog-equipment',
  templateUrl: 'protocol-edit-dialog-equipment.component.html'
})
export class ProtocolEditEquipmentDialogComponent {

  public equipmentAutoComplete: InternalEquipment[];

  matcher = new MyErrorStateMatcher();
  tituloDialog: string;
  form: UntypedFormGroup;

  equipmentTypes: EquipmentType[];

  private destroy$ = new Subject<void>();

  constructor(
    fb: UntypedFormBuilder,
    private equipmentTypeService: EquipmentTypeService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<ProtocolEditEquipmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataEquipment,
    private internalEquipmentService: InternalEquipmentService,
    public dialog: MatDialog) {
    if (data.isEdit) {
      this.tituloDialog = this.translate.instant('protocolEdit.dialog.equipment.title.edit', { name: data.equipment.equipment }) as string;
    } else {
      this.tituloDialog = this.translate.instant('protocolEdit.dialog.equipment.title.create') as string;
    }

    this.equipmentTypeService.findAll().subscribe((res: EquipmentType[]) => {
      this.equipmentTypes = res;

      if (this.data.equipment.idType == null) {
        this.data.equipment.idType = 1;
      }
    });
  }

  onOkClick(): void {
    if (this.data.equipment.internalId) {
      this.dialogRef.close(this.data.equipment);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onEquipmentChange($event: any, trigger: MatAutocompleteTrigger): void {
    const equipmentSelected = $event.option.value as InternalEquipment;

    if (equipmentSelected.nearToExpire || equipmentSelected.expired) {
      const expirationDate = moment(equipmentSelected.expirationDate).format('DD/MMM/YYYY').replace('./', '/').toUpperCase();


      let message = this.translate.instant('executionEdit.essays.sensor.form.error.nearToExpire', { expirationDate }) as string;

      if (equipmentSelected.expired) {
        message = this.translate.instant('executionEdit.essays.sensor.form.error.expired') as string;
      }

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
        if (result === true) {
          this.data.equipment.idEquipment = equipmentSelected.id;
          this.data.equipment.equipment = equipmentSelected.equipment;
          this.data.equipment.model = equipmentSelected.model;
          this.data.equipment.maker = equipmentSelected.maker;
          this.data.equipment.serialNum = equipmentSelected.serialNum;
          this.data.equipment.equipment = equipmentSelected.equipment;
          this.data.equipment.internalId = equipmentSelected.name;
          this.data.equipment.location = equipmentSelected.location;
        }
      });
    } else {
      this.data.equipment.model = equipmentSelected.model;
      this.data.equipment.maker = equipmentSelected.maker;
      this.data.equipment.serialNum = equipmentSelected.serialNum;
      this.data.equipment.equipment = equipmentSelected.equipment;
      this.data.equipment.internalId = equipmentSelected.name;
      this.data.equipment.location = equipmentSelected.location;
      this.data.equipment.idEquipment = equipmentSelected.id;
    }
  }

  lookupEquipment($event): void {
    let results = this.equipmentAutoComplete;

    const filter = new EquipmentAutocompleteFilter();

    filter.query = $event.target.value;
    filter.pageIndex = 0;
    filter.pageSize = 10;
    filter.sortBy = 'name';
    filter.sortDirection = 'asc';
    filter.forCalibrationPattern = true;
    filter.idTypeAction = EquipmentPerformances.QUALIFICATION;

    this.internalEquipmentService.findAutocomplete(filter).pipe(takeUntil(this.destroy$)).subscribe(item => {
      results = item.content;
      this.equipmentAutoComplete = results;
    }, () => {
      results = [];
      this.equipmentAutoComplete = results;
    });
  }

  createEquipment(): void {
    this.dialogRef.close();
    this.dialog.open(CalibrationEditCreateEquipmentComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        idType: CalibrationResponsibleEnum.EXTERNAL,
        idClient: this.data.idClient
      }
    });
  }

  displayFn(eq?: InternalEquipment | string | { name: string }): string {
    if (!eq) {
      return null;
    }

    let res: string = null;

    if (typeof eq === 'string') {
      res = eq;
    } else if (eq instanceof InternalEquipment || eq.name != null) {
      res = eq.name;
    }

    return res;
  }

}
