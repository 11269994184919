export class InternalEquipmentUsed {
    id: number;
    name: string;
    serialNumber: string;
    equipment: string;
    numberDaysUsed: number;
    january: number;
    february: number;
    march: number;
    april: number;
    may: number;
    june: number;
    july: number;
    august: number;
    september: number;
    october: number;
    november: number;
    december: number;
    useYear: string;
}

export class InternalEquipmentUsedFilter {
    name: string;
    year: string;
    identificative: string;
}