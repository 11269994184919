import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExternalCalibrationValue } from 'src/app/model/calibration';
import { ThemeService } from 'src/app/services/theme.service';
import { Constants } from '../../../utils/constants';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../services/snackBar.service';
import { SpinnerService } from '../../../services/spinner.service';

export interface DialogDataExternalCalibrationValue {
  item: ExternalCalibrationValue;
}

@Component({
  selector: 'app-internal-equipment-edit-create-calibration-value',
  templateUrl: './internal-equipment-edit-create-calibration-value.component.html'
})
export class InternalEquipmentEditCreateCalibrationValueComponent {

  numberRegexp: RegExp = Constants.NUMBER_WITH_POINT_REGEXP;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogDataExternalCalibrationValue,
    public dialogRef: MatDialogRef<InternalEquipmentEditCreateCalibrationValueComponent>,
    public dialog: MatDialog,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private themeService: ThemeService) { }

  onOkClick(): void {
    const errs = [];
    if (this.data.item.average && !this.numberRegexp.test(this.data.item.average.toString())) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.asLeft.cols.average.error'));
    } else if (this.data.item.uncertainty && !this.numberRegexp.test(this.data.item.uncertainty.toString())) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.uncertainties.cols.U.error'));
    } else if (this.data.item.point && !this.numberRegexp.test(this.data.item.point.toString())) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.asLeft.cols.point.error'));
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.item);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
      this.spinnerService.hide();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTheme(): string {
    return this.themeService.getTheme();
  }

}
