import { CriteriaType, CriteriaTypeCustomSubValue, CriteriaTypeCustomValue } from '../model/criteriaType';
import { Observable, from } from 'rxjs';

import { DexieService } from './dexieService';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { OnlineService } from './online.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class CriteriaTypeService {

    constructor(private http: HttpClient,
        private dexieService: DexieService,
        private onlineService: OnlineService) {
    }

    findByIdEssay(idEssay: number): Observable<any> {
        const url = environment.coreUrl + `/criteriaType/essay/${idEssay}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOne(id: number): Observable<any> {
        const url = environment.coreUrl + `/criteriaType/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllCalibrates(): Observable<any> {
        const url = environment.coreUrl + '/criteriaType/calibrates';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findByIdEssayAir(idEssay: number): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + `/criteriaType/essay/air/${idEssay}`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.findByIdEssayAirOffline(idEssay));
        }
    }

    findCustomValues(idCriteriaType: number): Observable<any> {

        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + `/criteriaType/${idCriteriaType}/customValues`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.customValues.where({ idCriteriaType }).toArray());
        }
    }

    findCustomValuesByEssay(idEssayType: number): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + `/criteriaType/air/essay/${idEssayType}/customValues`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.customValues.where({ idEssayType }).toArray());
        }
    }

    findAllCustomValues(): Observable<any> {
        const url = environment.coreUrl + '/criteriaType/air/essay/customValues';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllCustomSubValues(): Observable<any> {
        const url = environment.coreUrl + '/criteriaType/air/essay/customSubvalues';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findCustomSubValues(idCustom: number): Observable<any> {

        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + `/criteriaType/customValue/${idCustom}/customSubvalues`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.customSubValues.where({ idCriteriaTypeCustom: idCustom }).toArray());
        }
    }

    findAll(): Observable<any> {
        const url = environment.coreUrl + '/criteriaType/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllAir(): Observable<any> {
        const url = environment.coreUrl + '/criteriaType/essay/air/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    saveCriteriaTypeToDexie(): Promise<void> {
        return new Promise<void>((resolve) => this.findAll().subscribe((res: CriteriaType[]) => {
            if (res) {
                void this.dexieService.criteriaTypes.clear().then(() => {
                    res.forEach(type => {
                        void this.dexieService.criteriaTypes.put(type).then(() => resolve());
                    });
                });
            }
        }));
    }

    saveCriteriaConfigToDexie(): Promise<void> {
        return new Promise<void>((resolve) => this.findAllAir().subscribe((res: CriteriaType[]) => {
            if (res) {
                void this.dexieService.criteriaConfig.clear().then(() => {
                    res.forEach(type => {
                        void this.dexieService.criteriaConfig.put(type).then(() => resolve());
                    });
                });
            }
        }));
    }

    saveCustomValuesToDexie(): Promise<void> {
        return new Promise<void>((resolve) => this.findAllCustomValues().subscribe((res: CriteriaTypeCustomValue[]) => {
            if (res) {
                void this.dexieService.customValues.clear().then(() => {
                    res.forEach(type => {
                        void this.dexieService.customValues.put(type, type.id).then(() => resolve());
                    });
                });
            }
        }));
    }

    saveCustomSubValuesToDexie(): Promise<void> {
        return new Promise<void>((resolve) => this.findAllCustomSubValues().subscribe((res: CriteriaTypeCustomSubValue[]) => {
            if (res) {
                void this.dexieService.customSubValues.clear().then(() => {
                    res.forEach(type => {
                        void this.dexieService.customSubValues.put(type, type.id).then(() => resolve());
                    });
                });
            }
        }));
    }

    findCustomValuesById(idCriteria: number): Observable<any> {

        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + `/criteriaType/${idCriteria}/customValues`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.customValues.where({ idCriteria }).toArray());
        }
    }

    private async findByIdEssayAirOffline(idEssay: number): Promise<any> {
        const essaysId = [];
        const criteriaConfigs = await this.dexieService.criteriaConfig.where({ idEssay }).toArray();

        criteriaConfigs.forEach(res => essaysId.push(res.idCriteriaType));

        const result = await this.dexieService.criteriaTypes.where('id').anyOf(essaysId).toArray();
        result.forEach(res => {
            const critConfig = criteriaConfigs.find(c => c.idCriteriaType === res.id);
            res.defaultValue1 = critConfig?.defaultValue1;
            res.defaultValue2 = critConfig?.defaultValue2;
        });

        return result;
    }
}
