import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { CustomDatepickerHeaderComponent } from 'src/app/components/shared/datepicker-custom-header/datepicker-custom-header.component';
import { EquipmentType } from 'src/app/model/equipmentType';
import { GenericDocumentListFilter } from 'src/app/model/genericDocumentListFilter';
import { ProtocolStatus } from 'src/app/model/protocol';
import { User } from 'src/app/model/user';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';

@Component({
  selector: 'app-customer-portal-generic-document-list-filter',
  templateUrl: './customer-portal-generic-document-list-filter.component.html'
})
export class CustomerPortalGenericDocumentListFilterComponent implements OnInit {

  @Output() filterEmitter = new EventEmitter<GenericDocumentListFilter>();

  filter: GenericDocumentListFilter;
  equipmentTypes: EquipmentType[];
  users: User[];
  projectManagers: User[];

  expandedFilter = true;

  matcher = new MyErrorStateMatcher();
  form: UntypedFormGroup;

  isList: boolean;

  customDatepickerHeader = CustomDatepickerHeaderComponent;

  private destroy$ = new Subject<void>();

  constructor(
    fb: UntypedFormBuilder,
    private route: ActivatedRoute) {
    this.cleanFilter();

    this.form = fb.group({
      idClient: [this.filter.idClient],
      documentCode: [this.filter.documentCode],

      idStatus: [this.filter.idStatus],
    });
  }

  ngOnInit(): void {

    if (this.isList === false) {
      this.filter.idStatus = ProtocolStatus.FIRMADO;
    }

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  doFilter(): void {
    this.expandedFilter = false;

    this.filterEmitter.emit(this.filter);
  }

  cleanFilter(): void {
    this.filter = new GenericDocumentListFilter();

    if (this.isList === false) {
      this.filter.idStatus = ProtocolStatus.FIRMADO;
    }
  }

  saveDate(field: string, event: any): void {

    let value: Date | string = event.target.value;
    if (value != null) {

      if (value instanceof Date) {
        value = moment(value).format('DD/MM/YYYY');
      }

      const date = moment(value + ' 12:00:00 + 0:00', 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }

      this.filter[field] = date;
    } else {
      this.filter[field] = null;
    }

  }


}
