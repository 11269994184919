import { Component, OnInit } from '@angular/core';
import { InternalEquipmentUsedFilter } from '../../../model/internalEquipmentUsed';
import { CustomDatepickerHeaderComponent } from '../../shared/datepicker-custom-header/datepicker-custom-header.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { InternalEquipmentUsedService } from '../../../services/internalEquipmentUsed.service';
import { Subject, takeUntil } from 'rxjs';
import * as saveAs from 'file-saver';
import { SpinnerService } from '../../../services/spinner.service';
import * as moment from 'moment';
import { InternalEquipmentUsedUsersService } from '../../../services/internalEquipmentUsedUsers.service';
import { SnackBarService } from '../../../services/snackBar.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-internal-equipment-used',
  templateUrl: './internal-equipment-used.component.html',
  styleUrls: ['./internal-equipment-used.component.scss']
})
export class InternalEquipmentUsedComponent implements OnInit {

  today = moment.now();
  filter: InternalEquipmentUsedFilter = new InternalEquipmentUsedFilter();
  showView: boolean = false;
  codeUserEquipment: string;
  customDatepickerHeader = CustomDatepickerHeaderComponent;

  private destroy$ = new Subject<void>();

  constructor(private internalEquipmentUsedService: InternalEquipmentUsedService,
    private spinnerService: SpinnerService,
    private internalEquiomentUsedUsersService: InternalEquipmentUsedUsersService,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<InternalEquipmentUsedComponent>,
  ) { }

  ngOnInit(): void {
  }

  generateExcel() {
    if (this.validateData()) {
      this.spinnerService.show();
      this.internalEquipmentUsedService.findAllAndExcel(this.filter).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        const name =  `internalEquipmentUsed-${this.today}.xlsx`;
        saveAs(res, name);
        this.spinnerService.hide();
        this.dialogRef.close();
      }, error => {
        console.error(error);
        this.spinnerService.hide();
        this.dialogRef.close();
      });
    }
  }

  validateData(): boolean {
    let validate = true;

    if (!this.filter.year) {
      this.filter.year = moment().year().toString();
    } else {
      const vNumber = new RegExp('^(19[0-9]{2}|20[0-9]{2})$');
      if (!vNumber.test(this.filter.year)) {
        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.used.filter.year.error') as string);
      }
    } 
    return validate;
  } 

  showAndValidateUser() {
    if (this.codeUserEquipment && this.codeUserEquipment != '') {
      this.spinnerService.show();
      this.internalEquiomentUsedUsersService.validateUser(this.codeUserEquipment).pipe(takeUntil(this.destroy$)).subscribe((res: boolean) => {
        if (res) {
          this.showView = true;
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.used.invalidate.code') as string);
        }
      },err => {
        this.spinnerService.hide();
        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.used.invalidate.code') as string);
      });
    } else {
      this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.used.code.error') as string);
    }
  }

  closeValidate() {
    this.dialogRef.close();
  }

  saveDate(field: string, event: MatDatepickerInputEvent<Date>): void {
    const dateValue = event.value;
    if (dateValue != null) {
      this.filter[field] = new Date(dateValue);
    } else {
      this.filter[field] = null;
    }
  }


}
