import * as moment from 'moment';

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ProtocolFilter, ProtocolStatus } from 'src/app/model/protocol';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { AppTypeEnum } from 'src/app/model/appType';
import { Client } from 'src/app/model/client';
import { ClientService } from 'src/app/services/client.service';
import { Constants } from 'src/app/utils/constants';
import { CustomDatepickerHeaderComponent } from '../../shared/datepicker-custom-header/datepicker-custom-header.component';
import { GenericClass } from 'src/app/model/genericClass';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { Phase } from 'src/app/model/phase';
import { PhaseService } from 'src/app/services/phase.service';
import { ProcessService } from 'src/app/services/process.service';
import { ProtocolStatusService } from 'src/app/services/protocolStatus.service';
import { Subject } from 'rxjs';
import { User } from 'src/app/model/user';
import { UsersService } from 'src/app/services/users.service';
import { takeUntil } from 'rxjs/operators';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-protocol-list-filter',
  templateUrl: './protocol-list-filter.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ProtocolListFilterComponent implements OnInit, OnDestroy {

  @Output() filterEmitter = new EventEmitter<ProtocolFilter>();

  filter: ProtocolFilter;

  clients: Client[];
  clientsFiltered: Client[];

  phases: Phase[];
  statuses: GenericClass[];
  processes: GenericClass[];
  users: User[];
  projectManagers: User[];

  expandedFilter = true;

  matcher = new MyErrorStateMatcher();
  form: UntypedFormGroup;

  isList: boolean;

  customDatepickerHeader = CustomDatepickerHeaderComponent;

  private destroy$ = new Subject<void>();

  constructor(
    fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private phaseService: PhaseService,
    private protocolStatusService: ProtocolStatusService,
    private processService: ProcessService,
    private usersService: UsersService) {
    this.cleanFilter();

    this.form = fb.group({
      idClient: [this.filter.idClient],
      idProcess: [this.filter.idProcess],
      phase: [this.filter.idPhase],
      projectNo: [this.filter.projectNo],
      documentCode: [this.filter.documentCode],
      clientDocumentCode: [this.filter.clientDocumentCode],

      regUser: [this.filter.regUser],
      projectManager: [this.filter.projectManager],

      regFcStart: [this.filter.regFcStart],
      modFcStart: [this.filter.modFcStart],
      regFcEnd: [this.filter.regFcEnd],
      modFcEnd: [this.filter.modFcEnd],

      idStatus: [this.filter.idStatus],

      equipment: [this.filter.equipment],
      equipmentMaker: [this.filter.equipmentMaker],
      equipmentModel: [this.filter.equipmentModel],
      equipmentSerialNum: [this.filter.equipmentSerialNum],
      equipmentInternalId: [this.filter.equipmentInternalId],
      equipmentLocation: [this.filter.equipmentLocation],

      realizationFcStart: [this.filter.realizationFcStart],
      realizationFcEnd: [this.filter.realizationFcEnd],

      department: [this.filter.department]
    });
  }

  ngOnInit(): void {
    this.clients = [];
    this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Client[]) => {
      const empty = new Client();
      empty.id = null;
      empty.name = '---';

      data = [empty].concat(data);

      this.clients = data;
      this.clientsFiltered = this.clients.slice();
    });

    this.phases = [];
    this.phaseService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Phase[]) => {
      data = data.filter(d => d.enabled);
      this.phases = data;
    });

    this.statuses = [];
    this.protocolStatusService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.statuses = data);

    this.processes = [];
    this.processService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.processes = data);

    this.users = [];
    this.usersService.findAllFromGroupAppType(AppTypeEnum.THERMAL).pipe(takeUntil(this.destroy$))
      .subscribe((data: User[]) => this.users = data);

    this.projectManagers = [];
    this.usersService.findAllRespFromGroup().pipe(takeUntil(this.destroy$)).subscribe((data: User[]) => this.projectManagers = data);

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.filter.idPhase = +params[Constants.FIELD_ID_PHASE];
    });

    if (this.isList === false) {
      this.filter.idStatus = ProtocolStatus.FIRMADO;
    }

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  doFilter(): void {
    this.expandedFilter = false;

    this.filterEmitter.emit(this.filter);
  }

  cleanFilter(): void {
    this.filter = new ProtocolFilter();
    if (this.isList === false) {
      this.filter.idStatus = ProtocolStatus.FIRMADO;
    }
  }

  saveDate(field: string, event: MatDatepickerInputEvent<Date>): void {

    let dateValue = event.value;
    if (dateValue != null) {
      /*while (dateValue.getFullYear() < 1970) {
       dateValue.setFullYear(dateValue.getFullYear() + 100);
       }*/
      this.filter[field] = new Date(dateValue);
    } else {
      this.filter[field] = null;
    }
  }

}
