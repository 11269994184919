import * as _ from 'lodash-es';

import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CompressedGas, CompressedGasEnum } from 'src/app/model/compressedGasesAir';
import { DialogDataConfirmSave, ProtocolEditConfirmSaveComponent }
  from '../../thermal/protocol-edit/protocol-edit-dialog-confirmSave.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GenericClass, GenericClassTranslate, GenericWarnError } from '../../../model/genericClass';
import { ProtocolAir, ProtocolStatus } from '../../../model/protocol';
import { RoomAir, RoomAirEnum } from 'src/app/model/roomAir';

import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { AirUtils } from 'src/app/utils/airUtils';
import { Area } from 'src/app/model/area';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { CheckWarnsErrorsComponent } from '../../shared/check-warns-errors/check-warns-errors.component';
import { Client } from '../../../model/client';
import { ClientService } from '../../../services/client.service';
import { CompressedGasesTypeService } from 'src/app/services/compressedGasesType.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { Constants } from 'src/app/utils/constants';
import { EditDocumentCodeEditComponent } from '../../shared/edit-document-code-edit/edit-document-code-edit.component';
import { EditReferenceDocsComponent } from '../../shared/edit-reference-docs/edit-reference-docs.component';
import { EquipmentAir } from '../../../model/equipment';
import { EquipmentType } from 'src/app/model/equipmentType';
import { EquipmentTypeAirService } from 'src/app/services/equipmentTypeAir.service';
import { Essay } from '../../../model/essay';
import { EssayAirService } from 'src/app/services/essayAir.service';
import { EssayProtocolAir } from 'src/app/model/essayProtocolAir';
import { EssayType } from 'src/app/model/essayType';
import { EssayUtils } from 'src/app/utils/essayUtils';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatTable } from '@angular/material/table';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { NumberUtils } from 'src/app/utils/numberUtils';
import { OnlineService } from 'src/app/services/online.service';
import { ProcessService } from 'src/app/services/process.service';
import { ProtocolAirService } from 'src/app/services/protocolAir.service';
import { ProtocolEditAttachmentsComponent } from './protocol-edit-attachments.component';
import { ProtocolEditAuditComponent } from './protocol-edit-audit.component';
import { ProtocolEditDialogAreaComponent } from './protocol-edit-dialog-area.component';
import { ProtocolEditDialogAutomaticSignComponent } from './protocol-edit-dialog-automatic-sign.component';
import { ProtocolEditDialogCompressedGasAirComponent } from './protocol-edit-dialog-compressed-gas-air.component';
import { ProtocolEditDialogDuplicateComponent } from './protocol-edit-dialog-duplicate.component';
import { ProtocolEditDialogDuplicateEquipmentComponent } from './protocol-edit-dialog-duplicate-equipment.component';
import { ProtocolEditDialogEquipmentAirComponent } from './protocol-edit-dialog-equipment-air.component';
import { ProtocolEditDialogEssayAirComponent } from './protocol-edit-dialog-essay-air.component';
import { ProtocolEditDialogManualSignComponent } from './protocol-edit-dialog-manual-sign.component';
import { ProtocolEditDialogRoomAirComponent } from './protocol-edit-dialog-room-air.component';
import { ProtocolEditGenerateReportComponent } from './protocol-edit-generate-report.component';
import { ReasonDialogComponent } from '../../shared/reason-dialog/reason-dialog.component';
import { ReferenceDocument } from 'src/app/model/referenceDocument';
import { RoomTypeAirService } from 'src/app/services/roomTypeAir.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StringUtils } from 'src/app/utils/stringUtils';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { UsersService } from 'src/app/services/users.service';
import { Volume } from 'src/app/model/volume';
import { takeUntil } from 'rxjs/operators';
import { RoomUtils } from 'src/app/utils/RoomUtils';
import { FieldEnum } from 'src/app/utils/fieldUtils';
import { RoleEnum } from '../../../model/roleUser';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MovePositionEssay } from '../../../model/essayProtocolAir';

@Component({
  selector: 'app-protocol',
  templateUrl: './protocol-edit.component.html',
  styleUrls: ['./protocol-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ProtocolEditComponent implements OnInit, OnDestroy {

  @ViewChild('equipmentTable', { static: true }) equipmentTable: MatTable<any>;
  @ViewChild('criticalInstrTable') criticalInstrTable: MatTable<any>;
  @ViewChild('essaysTable', { static: true }) essaysTable: MatTable<any>;
  @ViewChildren('attachmentsComponent') attachmentsComponent: ProtocolEditAttachmentsComponent;
  @ViewChild(ProtocolEditAuditComponent) audit: ProtocolEditAuditComponent;
  @ViewChild('refDocTable', { static: true }) refDocTable: MatTable<any>;
  @ViewChild('filtersTableEq') filtersTableEq: MatTable<any>;
  @ViewChild('filtersTableRoom') filtersTableRoom: MatTable<any>;
  @ViewChild('filtersTableCGas') filtersTableCGas: MatTable<any>;

  disableOfflineButton = false;

  currentUser: User;

  protocol: ProtocolAir;
  clients: Client[];
  clientsFiltered: Client[];
  respField: User[];

  processes: GenericClass[];

  areaTab = 0;
  equipmentTab: number;
  roomTab: number;
  compressedGasTab: number;

  areas: Area[] = [];
  equipmentList: EquipmentAir[] = [];
  equipmentCols = ['type', 'equipment', 'maker', 'model', 'serialNum', 'internalId', 'location', 'edit', 'delete'];
  criticalInstrCols = ['equipment', 'internalId', 'procediment', 'edit', 'delete'];

  equipmentTypes: EquipmentType[];
  equipmentTypesAir: EquipmentType[];
  roomAirTypes: GenericClassTranslate[];
  compressedGasesAirTypes: GenericClassTranslate[];

  disableEssayButtonDialog = true;
  essays: Essay[] = [];
  essaysCols = ['essayType', 'variables', 'criteria', 'load', 'edit', 'delete'];
  essaytypes: EssayType[];

  matcher = new MyErrorStateMatcher();
  form: UntypedFormGroup;

  displayedColsRefDoc = ['code', 'name', 'edit', 'delete'];

  previousIndex: number;
  columnEssay: string[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private protocolService: ProtocolAirService,
    private clientService: ClientService,
    private processService: ProcessService,
    private onlineService: OnlineService,
    private userService: UserService,
    private usersService: UsersService,
    private essayService: EssayAirService,
    private equipmentTypeService: EquipmentTypeAirService,
    private equipmentTypeAirService: EquipmentTypeAirService,
    private roomTypeService: RoomTypeAirService,
    private compressedGasesTypeService: CompressedGasesTypeService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    this.spinnerService.show();

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const id = params[Constants.FIELD_ID] as number;

      if (id == null || isNaN(+id)) {
        this.cancel();
      }

      this.protocol = new ProtocolAir();
      if (+id === 0) {
        this.spinnerService.hide();

        const ref1 = new ReferenceDocument();
        ref1.code = 'UNE-EN ISO 14644';
        ref1.name = this.translate.instant('protocolEdit.referenceDocs.default.iso14644.name', { breakline: '\n\t' }) as string;

        const ref2 = new ReferenceDocument();
        ref2.code = 'GMP';
        ref2.name = this.translate.instant('protocolEdit.referenceDocs.default.gmp.name', { breakline: '\n\t' }) as string;

        this.protocol.referenceDocs = [ref1, ref2];
      } else {
        this.protocolService.findOne(id).pipe(takeUntil(this.destroy$)).subscribe((result: ProtocolAir) => {
          if (result != null) {
            this.protocol = result;

            if (this.protocol == null) {
              this.cancel();
              return;
            }

            this.protocol.areas.forEach(area => {
              area.rooms = area.rooms.map(room => AirUtils.objectToRoom(room)).sort((eq1, eq2) => {
                let order = eq1.order - eq2.order
 
                if (order === 0) {
                  order = eq1.name.localeCompare(eq2.name);
                }

                return order;
              });

              area.equipments = area.equipments.sort((eq1, eq2) => {
                let order = eq1.order - eq2.order

                if (order === 0) {
                  order = eq1.id - eq2.id;
                }

                return order;
              });

              area.rooms.forEach(room => room.filters = room.filters.sort((f1, f2) => f1.id - f2.id));
              area.equipments.forEach(eq => eq.filters = eq.filters.sort((f1, f2) => f1.id - f2.id));
            });

            this.areas = this.protocol.areas || [];

            if (this.attachmentsComponent instanceof QueryList) {
              this.attachmentsComponent = this.attachmentsComponent.first as ProtocolEditAttachmentsComponent;
            }

            this.attachmentsComponent.setIdProtocol(this.protocol.id);

            this.protocol.referenceDocs?.sort((eq1, eq2) => {
              let order = eq1.id - eq2.id;

              if (order === 0) {
                order = eq1.id - eq2.id;
              }

              return order;
            });

            this.spinnerService.hide();
          } else {
            this.protocol = null;
          }
        }, () => {
          this.protocol = null;
          this.cancel();
        });

      }

    });

    this.form = fb.group({
      idClient: [this.protocol.idClient, [Validators.required]],
      idProcess: [this.protocol.idProcess, [Validators.required]],
      projectNo: [this.protocol.projectNo, [Validators.required]],
      documentCode: [{ value: this.protocol.documentCode, disabled: true }],
      clientDocumentCode: [this.protocol.clientDocumentCode],

      projectManager: [this.protocol.usernameProjectManager],

      regUser: [{ value: this.protocol.regUser, disabled: true }],
      modUser: [{ value: this.protocol.modUser, disabled: true }],
      regFc: [{ value: this.protocol.regFc, disabled: true }],
      modFc: [{ value: this.protocol.modFc, disabled: true }],
      currentVersion: [{ value: this.protocol.currentVersion, disabled: true }],
      signedVersion: [{ value: this.protocol.signedVersion, disabled: true }],
      observations: [{ value: this.protocol.observations }],
      notes: [{ value: this.protocol.notes }]
    });

  }

  ngOnInit(): void {
    this.onlineService.online$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.disableOfflineButton = !res;
    });

    this.disableOfflineButton = !this.onlineService.latestOnline;

    this.currentUser = this.userService.currentProfile;

    this.clients = [];
    this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Client[]) => {
      this.clients = data;
      this.clientsFiltered = this.clients.slice();
    });

    this.processes = [];
    this.processService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.processes = data);

    this.respField = [];
    this.usersService.findAllRespFromGroup().pipe(takeUntil(this.destroy$)).subscribe((data: User[]) => this.respField = data);

    this.equipmentTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: EquipmentType[]) => this.equipmentTypes = res);
    this.roomTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: GenericClassTranslate[]) => this.roomAirTypes = res);
    this.compressedGasesTypeService.findAll().pipe(takeUntil(this.destroy$))
      .subscribe((res: GenericClassTranslate[]) => this.compressedGasesAirTypes = res);
    this.equipmentTypeAirService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: EquipmentType[]) => this.equipmentTypesAir = res);
    this.essayService.findAllAir().pipe(takeUntil(this.destroy$)).subscribe((res: EssayType[]) => this.essaytypes = res);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 1) {
      this.audit.getAudit();
    }
  }

  onAreaTabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.areaTab = tabChangeEvent.index;
    this.equipmentTab = null;
    this.roomTab = null;
  }

  openDialogArea(): void {
    const dialogRef = this.dialog.open(ProtocolEditDialogAreaComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        area: new Area(),
        isEdit: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Area) => {
      if (result != null) {
        this.protocol.areas.push(result);
      }
    });
  }

  openDialogEditArea(idx: number): void {
    const eq = this.protocol.areas[idx];

    const dialogRef = this.dialog.open(ProtocolEditDialogAreaComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        area: _.cloneDeep(eq),
        isEdit: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Area) => {
      if (result != null) {
        this.protocol.areas[idx] = result;
      }
    });
  }

  deleteArea(index: number): void {
    const message = this.translate.instant('protocolEdit.dialog.area.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (index > -1) {
          this.protocol.areas.splice(index, 1);
        }
      }
    });
  }

  openDialogEquipment(areaIndex: number): void {
    let equipment = new EquipmentAir();
    const order = Math.max(...this.protocol.areas[areaIndex].equipments.map(r => r.order)) || 0;
    equipment.order = order + 1;
    const dialogRef = this.dialog.open(ProtocolEditDialogEquipmentAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipmentTypes: this.equipmentTypesAir,
        equipment: equipment, 
        isEdit: false,
        isExecution: false,
        idExecution: null
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EquipmentAir) => {
      if (result != null) {
        if (this.protocol.areas[areaIndex].equipments == null) {
          this.protocol.areas[areaIndex].equipments = [];
        }
        this.protocol.areas[areaIndex].equipments.push(result);
        this.protocol.areas[areaIndex].equipments = this.reorderEquipment(this.protocol.areas[areaIndex].equipments);
      }
    });
  }

  openDialogEditEquipment(areaIndex: number, idx: number): void {
    event.stopPropagation();

    const eq = this.protocol.areas[areaIndex].equipments[idx];

    const dialogRef = this.dialog.open(ProtocolEditDialogEquipmentAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipmentTypes: this.equipmentTypesAir,
        equipment: _.cloneDeep(eq),
        isEdit: true,
        isExecution: false,
        idExecution: null
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EquipmentAir) => {
      if (result != null) {
        this.protocol.areas[areaIndex].equipments[idx] = result;
        this.protocol.areas[areaIndex].equipments = this.reorderEquipment(this.protocol.areas[areaIndex].equipments);
      }
    });
  }

  duplicateEquipment(areaIndex: number, idx: number): void {
    event.stopPropagation();

    const eq = this.protocol.areas[areaIndex].equipments[idx];

    const dialogRef = this.dialog.open(ProtocolEditDialogDuplicateEquipmentComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipmentTypes: this.equipmentTypesAir,
        equipment: _.cloneDeep(eq)
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((equipment: EquipmentAir) => {
      if (equipment != null) {

        this.protocol.areas[areaIndex].equipments.push(this.cleanIdEquipments(equipment));
        this.protocol.areas[areaIndex].equipments = this.reorderEquipment(this.protocol.areas[areaIndex].equipments);

      }
    });

  }

  deleteEquipment(areaIndex: number, index: number): void {
    event.stopPropagation();

    const message = this.translate.instant('protocolEdit.dialog.equipment.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (index > -1) {
          this.protocol.areas[areaIndex].equipments.splice(index, 1);
        }
      }
    });

  }

  openDialogRoom(areaIndex: number): void {
    const room = new RoomAir();
    room.idType = RoomAirEnum.ROOM;
    const order = Math.max(...this.protocol.areas[areaIndex].rooms.map(r => r.order)) || 0;
    room.order = order + 1;

    const dialogRef = this.dialog.open(ProtocolEditDialogRoomAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        roomTypes: this.roomAirTypes,
        room,
        isEdit: false,
        isExecution: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: RoomAir) => {
      if (result != null) {
        if (this.protocol.areas[areaIndex].rooms == null) {
          this.protocol.areas[areaIndex].rooms = [];
        }

        this.protocol.areas[areaIndex].rooms.push(result);
        this.protocol.areas[areaIndex].rooms = this.reorderRooms(this.protocol.areas[areaIndex].rooms); 
      }
    });
  }

  openDialogEditRoom(areaIndex: number, idx: number): void {
    event.stopPropagation();

    const eq = this.protocol.areas[areaIndex].rooms[idx];

    const dialogRef = this.dialog.open(ProtocolEditDialogRoomAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        roomTypes: this.roomAirTypes,
        room: _.cloneDeep(eq),
        isEdit: true,
        isExecution: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: RoomAir) => {
      if (result != null) {
        this.protocol.areas[areaIndex].rooms[idx] = result;
        this.protocol.areas[areaIndex].rooms = this.reorderRooms(this.protocol.areas[areaIndex].rooms);

        this.protocol.areas.forEach((area) => {
          area.rooms.forEach((room) => {

            const sumVolume =  RoomUtils.getTotalVolume(room);
            room.essays.forEach( eassy =>{
              eassy.essayValues.forEach( value =>{
                if (value.idField==FieldEnum.VOLUME) {
                  value._value = sumVolume.toString();
                }
              })
            })
          });
        }); 
      }
    });
  }

  duplicateRoom(areaIndex: number, idx: number): void {
    event.stopPropagation();

    const room = this.protocol.areas[areaIndex].rooms[idx];

    const dialogRef = this.dialog.open(ProtocolEditDialogDuplicateComponent, {
      minWidth: '80%',
      maxHeight: '95vh'
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {

        const dupRoom = _.cloneDeep(room);
        dupRoom.name = result;

        this.protocol.areas[areaIndex].rooms.push(this.cleanIdRooms(dupRoom));
        this.protocol.areas[areaIndex].rooms = this.reorderRooms(this.protocol.areas[areaIndex].rooms);
      }
    });

  }

  deleteRoom(areaIndex: number, index: number): void {
    event.stopPropagation();

    const message = this.translate.instant('protocolEdit.dialog.room.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (index > -1) {
          this.protocol.areas[areaIndex].rooms.splice(index, 1);
        }
      }
    });

  }

  moveToTop(areaIndex: number, index: number): void {
    event.stopPropagation();

    const rooms = this.protocol.areas[areaIndex].rooms;
    ArrayUtils.moveToFirst(rooms, index);

    this.protocol.areas[areaIndex].rooms = this.reorderRooms(rooms);

    this.roomTab = 0;
  }

  movePrevious(areaIndex: number, index: number): void {
    event.stopPropagation();

    const rooms = this.protocol.areas[areaIndex].rooms;
    ArrayUtils.moveToPrevious(rooms, index);

    this.protocol.areas[areaIndex].rooms = this.reorderRooms(rooms);

    this.roomTab = index - 1;
  }

  moveNext(areaIndex: number, index: number): void {
    event.stopPropagation();

    const rooms = this.protocol.areas[areaIndex].rooms;
    ArrayUtils.moveToNext(rooms, index);

    this.protocol.areas[areaIndex].rooms = this.reorderRooms(rooms);

    this.roomTab = index + 1;
  }

  moveToBottom(areaIndex: number, index: number): void {
    event.stopPropagation();

    const rooms = this.protocol.areas[areaIndex].rooms;
    ArrayUtils.moveToLast(rooms, index);

    this.protocol.areas[areaIndex].rooms = this.reorderRooms(rooms);

    this.roomTab = rooms.length - 1;
  }

  getTotalSurface(room: RoomAir): number {
    return NumberUtils.sum(room.volumes.map(v => v.surface));
  }

  getTotalVolume(room: RoomAir): number {
    return NumberUtils.sum(room.volumes.map(v => v.surface * v.height));
  }

  openDialogCompressedGas(areaIndex: number): void {
    const compressedGas = new CompressedGas();
    compressedGas.idType = CompressedGasEnum.COMPRESSED_AIR;
    const order = Math.max(...this.protocol.areas[areaIndex].compressedGases.map(r => r.order)) || 0;
    compressedGas.order = order + 1;

    const dialogRef = this.dialog.open(ProtocolEditDialogCompressedGasAirComponent, {
      minWidth: '60%',
      maxHeight: '95vh',
      data: {
        compressedGasTypes: this.compressedGasesAirTypes,
        compressedGas,
        isEdit: false,
        isExecution: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CompressedGas) => {
      if (result != null) {
        if (this.protocol.areas[areaIndex].compressedGases == null) {
          this.protocol.areas[areaIndex].compressedGases = [];
        }

        this.protocol.areas[areaIndex].compressedGases.push(result);
        this.protocol.areas[areaIndex].compressedGases = this.reorderCompressedGases(this.protocol.areas[areaIndex].compressedGases);
      }
    });
  }

  openDialogEditCompressedGas(areaIndex: number, idx: number): void {
    event.stopPropagation();

    const eq = this.protocol.areas[areaIndex].compressedGases[idx];

    const dialogRef = this.dialog.open(ProtocolEditDialogCompressedGasAirComponent, {
      minWidth: '60%',
      maxHeight: '95vh',
      data: {
        compressedGasTypes: this.compressedGasesAirTypes,
        compressedGas: _.cloneDeep(eq),
        isEdit: true,
        isExecution: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CompressedGas) => {
      if (result != null) {
        this.protocol.areas[areaIndex].compressedGases[idx] = result;
        this.protocol.areas[areaIndex].compressedGases = this.reorderCompressedGases(this.protocol.areas[areaIndex].compressedGases);
      }
    });
  }

  duplicateCompressedGas(areaIndex: number, idx: number): void {
    event.stopPropagation();

    const compressedGas = this.protocol.areas[areaIndex].compressedGases[idx];

    const dialogRef = this.dialog.open(ProtocolEditDialogDuplicateComponent, {
      minWidth: '80%',
      maxHeight: '95vh'
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {

        const dupCompressedGas = _.cloneDeep(compressedGas);
        dupCompressedGas.code = result;

        this.protocol.areas[areaIndex].compressedGases.push(this.cleanIdCompressedGas(dupCompressedGas));
        this.protocol.areas[areaIndex].compressedGases = this.reorderCompressedGases(this.protocol.areas[areaIndex].compressedGases);
      }
    });

  }

  deleteCompressedGas(areaIndex: number, index: number): void {
    event.stopPropagation();

    const message = this.translate.instant('protocolEdit.dialog.compressedGas.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (index > -1) {
          this.protocol.areas[areaIndex].compressedGases.splice(index, 1);
        }
      }
    });

  }

  moveToTopCompressedGas(areaIndex: number, index: number): void {
    event.stopPropagation();

    const rooms = this.protocol.areas[areaIndex].compressedGases;
    ArrayUtils.moveToFirst(rooms, index);

    this.protocol.areas[areaIndex].compressedGases = this.reorderCompressedGases(rooms);

    this.compressedGasTab = 0;
  }

  movePreviousCompressedGas(areaIndex: number, index: number): void {
    event.stopPropagation();

    const compressedGases = this.protocol.areas[areaIndex].compressedGases;
    ArrayUtils.moveToPrevious(compressedGases, index);

    this.protocol.areas[areaIndex].compressedGases = this.reorderCompressedGases(compressedGases);

    this.compressedGasTab = index - 1;
  }

  moveNextCompressedGas(areaIndex: number, index: number): void {
    event.stopPropagation();

    const compressedGases = this.protocol.areas[areaIndex].compressedGases;
    ArrayUtils.moveToNext(compressedGases, index);

    this.protocol.areas[areaIndex].compressedGases = this.reorderCompressedGases(compressedGases);

    this.compressedGasTab = index + 1;
  }

  moveToBottomCompressedGas(areaIndex: number, index: number): void {
    event.stopPropagation();

    const compressedGases = this.protocol.areas[areaIndex].compressedGases;
    ArrayUtils.moveToLast(compressedGases, index);

    this.protocol.areas[areaIndex].compressedGases = this.reorderCompressedGases(compressedGases);

    this.compressedGasTab = compressedGases.length - 1;
  }

  openDialogEssayEquipment(index: number, areaIndex: number): void {
    event.stopPropagation();

    const equipment = this.protocol.areas[areaIndex].equipments[index];

    const dialogRef = this.dialog.open(ProtocolEditDialogEssayAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        essay: new EssayProtocolAir(),
        idsEssaysToRemove: equipment.essays.map(e => e.idEssayType),
        idEquipment: equipment.idType,
        isEdit: false,
        surfaceRoom: null,
        accesories: equipment.accessories,
        filters: equipment.filters,
        isEquipment: true,
        isExecution: false,
        entityType: 'equipment'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EssayProtocolAir) => {
      if (result != null) {
        this.protocol.areas[areaIndex].equipments[index].essays.push(result);
      }
    });
  }

  openDialogEssayRoom(index: number, areaIndex: number): void {
    event.stopPropagation();

    const room = this.protocol.areas[areaIndex].rooms[index];

    const dialogRef = this.dialog.open(ProtocolEditDialogEssayAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        essay: new EssayProtocolAir(),
        idsEssaysToRemove: room.essays.map(e => e.idEssayType),
        idEquipment: 1,
        isEdit: false,
        surfaceRoom: this.calculateSurface(room.volumes),
        volumeRoom: this.calculateVolume(room.volumes),
        accesories: null,
        filters: room.filters,
        isEquipment: false,
        isExecution: false,
        entityType: 'room'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EssayProtocolAir) => {
      if (result != null) {
        this.protocol.areas[areaIndex].rooms[index].essays.push(result);
      }
    });
  }

  openDialogEssayCompressedGas(index: number, areaIndex: number): void {
    event.stopPropagation();

    const compressedGas = this.protocol.areas[areaIndex].compressedGases[index];

    const dialogRef = this.dialog.open(ProtocolEditDialogEssayAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        essay: new EssayProtocolAir(),
        idsEssaysToRemove: compressedGas.essays.map(e => e.idEssayType),
        idEquipment: 1,
        isEdit: false,
        surfaceRoom: null,
        volumeRoom: null,
        accesories: null,
        filters: [],
        isEquipment: false,
        isExecution: false,
        entityType: 'compressedGas'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EssayProtocolAir) => {
      if (result != null) {
        this.protocol.areas[areaIndex].compressedGases[index].essays.push(result);
        
      }
    });
  }

  openDialogEditEssayRoom(areaIndex: number, roomIndex: number, essayIndex: number | string): void {

    const room = this.protocol.areas[areaIndex].rooms[roomIndex];

    let essay: EssayProtocolAir = null;

    if (typeof essayIndex === 'number') {
      essay = room.essays[essayIndex];
    } else {
      essay = room.essays.find(e => {
        const name = this.getEssayName(e.idEssayType, false);
        return name === essayIndex;
      });

      if (essay != null) {
        essayIndex = room.essays.findIndex(e => e === essay);
      }
    }

    const idsEssaysToRemove = room.essays.map(e => e.idEssayType).filter(e => e !== essay.idEssayType);

    const dialogRef = this.dialog.open(ProtocolEditDialogEssayAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        essay: _.cloneDeep(essay),
        idsEssaysToRemove,
        idEquipment: 1,
        isEdit: true,
        surfaceRoom: this.calculateSurface(room.volumes),
        volumeRoom: this.calculateVolume(room.volumes),
        accesories: null,
        filters: room.filters,
        isEquipment: false,
        isExecution: false,
        entityType: 'room'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EssayProtocolAir | string) => {
      if (result != null) {
        if (result === 'delete') {
          this.deleteEssayRoom(areaIndex, roomIndex, essayIndex as number);
        } else {
          this.protocol.areas[areaIndex].rooms[roomIndex].essays[essayIndex] = result;
        }
      }
    });
  }

  deleteEssayRoom(areaIndex: number, roomIndex: number, essayIndex: number): void {
    if (essayIndex > -1) {
      this.protocol.areas[areaIndex].rooms[roomIndex].essays.splice(essayIndex, 1);
    }
  }

  openDialogEditEssayEquipment(areaIndex: number, equipmentIndex: number, essayIndex: number | string): void {
    const equipment = this.protocol.areas[areaIndex].equipments[equipmentIndex];
    let essay: EssayProtocolAir = null;

    if (typeof essayIndex === 'number') {
      essay = equipment.essays[essayIndex];
    } else {
      essay = equipment.essays.find(e => {
        const name = this.getEssayName(e.idEssayType, false);
        return name === essayIndex;
      });

      if (essay != null) {
        essayIndex = equipment.essays.findIndex(e => e === essay);
      }
    }

    if (essay == null) {
      return;
    }

    const idsEssaysToRemove = equipment.essays.map(e => e.idEssayType).filter(e => e !== essay.idEssayType);

    const dialogRef = this.dialog.open(ProtocolEditDialogEssayAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        essay: _.cloneDeep(essay),
        idsEssaysToRemove,
        idEquipment: equipment.idType,
        isEdit: true,
        volumeRoom: null,
        accesories: equipment.accessories,
        filters: equipment.filters,
        isEquipment: true,
        isExecution: false,
        entityType: 'equipment'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EssayProtocolAir | string) => {
      if (result != null) {
        if (result === 'delete') {
          this.deleteEssayEquipment(areaIndex, equipmentIndex, essayIndex as number);
        } else {
          this.protocol.areas[areaIndex].equipments[equipmentIndex].essays[essayIndex] = result;
        }
      }
    });
  }

  deleteEssayEquipment(areaIndex: number, equipmentIndex: number, essayIndex: number): void {
    if (essayIndex > -1) {
      this.protocol.areas[areaIndex].equipments[equipmentIndex].essays.splice(essayIndex, 1);
    }
  }

  openDialogEditEssayCompressedGas(areaIndex: number, compressedGasIndex: number, essayIndex: number | string): void {

    const compressedGas = this.protocol.areas[areaIndex].compressedGases[compressedGasIndex];

    let essay: EssayProtocolAir = null;

    if (typeof essayIndex === 'number') {
      essay = compressedGas.essays[essayIndex];
    } else {
      essay = compressedGas.essays.find(e => {
        const name = this.getEssayName(e.idEssayType, false);
        return name === essayIndex;
      });

      if (essay != null) {
        essayIndex = compressedGas.essays.findIndex(e => e === essay);
      }
    }

    const idsEssaysToRemove = compressedGas.essays.map(e => e.idEssayType).filter(e => e !== essay.idEssayType);

    const dialogRef = this.dialog.open(ProtocolEditDialogEssayAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        essay: _.cloneDeep(essay),
        idsEssaysToRemove,
        idEquipment: 1,
        isEdit: true,
        surfaceRoom: null,
        volumeRoom: null,
        accesories: null,
        filters: [],
        isEquipment: false,
        isExecution: false,
        entityType: 'compressedGas'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EssayProtocolAir | string) => {
      if (result != null) {
        if (result === 'delete') {
          this.deleteEssayCompressedGas(areaIndex, compressedGasIndex, essayIndex as number);
        } else {
          this.protocol.areas[areaIndex].compressedGases[compressedGasIndex].essays[essayIndex] = result;
        }
      }
    });
  }

  deleteEssayCompressedGas(areaIndex: number, roomIndex: number, essayIndex: number): void {
    if (essayIndex > -1) {
      this.protocol.areas[areaIndex].compressedGases[roomIndex].essays.splice(essayIndex, 1);
    }
  }

  getEquipmentType(eq: EquipmentAir): string {
    if (ArrayUtils.isEmpty(this.equipmentTypes)) {
      return null;
    }

    const eqType = this.equipmentTypes.find(e => e.id === eq.idType);
    return eqType?.translation;
  }


  saveProtocol(): void {

    const errores: string[] = [];

    if (!this.form.valid) {
      errores.push(this.translate.instant('protocolEdit.form.error.notValid') as string);
    }

    let noEquipmentOrRoom = false;

    if (ArrayUtils.isEmpty(this.protocol.areas)) {
      noEquipmentOrRoom = true;
    } else {
      noEquipmentOrRoom = this.protocol.areas.find(a => !ArrayUtils.isEmpty(a.equipments) || !ArrayUtils.isEmpty(a.rooms)
        || !ArrayUtils.isEmpty(a.compressedGases)) == null;
    }

    if (noEquipmentOrRoom) {
      errores.push(this.translate.instant('protocolEdit.dialog.confirmSave.form.error.anyEquipmentOrRoom') as string);
    }

    if (errores.length === 0) {

      if (this.protocol.id == null) {
        this.save();
      } else {

        const showReason = (+this.protocol.signedVersion) !== 0;

        if (showReason) {

          const dialogRef = this.dialog.open(ProtocolEditConfirmSaveComponent, {
            minWidth: '30vw',
            data: {
              protocol: this.protocol,
              reason: this.protocol.reason
            }
          });

          dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: DialogDataConfirmSave) => {
            if (result != null) {
              this.protocol.reason = result.reason;
              this.save();
            }
          });

        } else {
          this.save();
        }

      }

    } else if (errores.length !== 0) {
      this.snackBarService.sendError(errores.join('\n'));
    }

  }

  manualSign(): void {
    const errores = this.getErrorsSign();

    if (errores.length !== 0) {
      this.snackBarService.sendError(errores.join('\n'));
    } else {
      this.prepareToSave();

      const dialogRef = this.dialog.open(ProtocolEditDialogManualSignComponent, {
        minWidth: '20%',
        maxHeight: '95vh',
        data: { protocol: this.protocol }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
        this.spinnerService.hide();

        if (result != null) {
          this.clientService.findOne(this.protocol.idClient).subscribe((client: Client) => {
            if (client.idGroup != null) {
              void this.cloneRow(this.protocol.id, client.idGroup).then(() => this.reloadPage());
            } else {
              this.reloadPage();
            }
          });
        }
      });
    }

  }

  automaticSign(): void {
    this.spinnerService.show();

    const dialogRef = this.dialog.open(ProtocolEditDialogAutomaticSignComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: { protocol: this.protocol }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {

      if (result != null) {
        this.clientService.findOne(this.protocol.idClient).subscribe((client: Client) => {

          if (client.idGroup != null) {
            void this.cloneRow(this.protocol.id, client.idGroup).then(() => this.reloadPage());
          } else {
            this.reloadPage();
          }

        });
      } else {
        this.spinnerService.hide();
      }
    });
  }

  downloadPdf(): void {
    this.dialog.open(ProtocolEditGenerateReportComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: { protocol: this.protocol }
    });
  }

  cancel(): void {
    void this.router.navigateByUrl('/air/protocols');
  }

  openNewRefDoc(): void {
    const dialogRef = this.dialog.open(EditReferenceDocsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        refDoc: new ReferenceDocument(),
        isEdit: false,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ReferenceDocument) => {

      if (result != null) {
        this.protocol.referenceDocs.push(result);

        this.refDocTable.renderRows();
      }
    });
  }

  editRefDoc(refDocIndex: number): void {

    const refDoc = this.protocol.referenceDocs[refDocIndex];

    const dialogRef = this.dialog.open(EditReferenceDocsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        refDoc: _.cloneDeep(refDoc),
        isEdit: true,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ReferenceDocument) => {

      if (result != null) {
        this.protocol.referenceDocs[refDocIndex] = result;

        this.refDocTable.renderRows();
      }
    });
  }

  removeRefDoc(refDocIndex: number): void {
    this.protocol.referenceDocs.splice(refDocIndex, 1);

    this.refDocTable.renderRows();
  }

  showAutomaticSign(): boolean {
    const current = this.currentUser;

    return this.canSign() && current.automaticSign;
  }

  showManualSign(): boolean {
    const current = this.currentUser;

    return this.canSign() && current.manualSign;
  }

  showRevertSign(): boolean {
    const resultRol = this.currentUser?.profiles?.find(e => e.idRole === RoleEnum.MANAGER || e.idRole === RoleEnum.ADMIN);
    return this.protocol.idStatus === ProtocolStatus.FIRMADO
    && (resultRol != null && resultRol !== undefined);
  }

  canEditDocumentCode(): boolean {
    const allowedStatuses = [ProtocolStatus.PENDIENTE_FIRMA];

    return this.protocol.id != null && allowedStatuses.includes(this.protocol.idStatus);
  }

  editDocumentCode(): void {
    const dialogRef = this.dialog.open(EditDocumentCodeEditComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        documentCode: this.protocol.documentCode
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {

      if (result != null) {
        this.protocol.documentCode = result;
      }
    });
  }

  hasArea(): boolean {
    return !this.protocol.areas.length && !this.areas.length;
  }

  revertSign(): void {

    if (this.protocol.usedIn > 0) {
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('protocolEdit.form.revertSign.isInUse') as string,
          canCancel: false,
          messageOk: this.translate.instant('button.accept') as string
        }
      });

      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.translate.instant('calibrateEquipmentEdit.form.revertSign.confirm') as string
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {

      if (result === true) {
        this.requestReason((reason) => {
          const dialogRefConfirmation = this.dialog.open(ActionConfirmPasswordComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {}
          });
          dialogRefConfirmation.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
            this.protocolService.revertSign(this.protocol.id, reason, token).pipe(takeUntil(this.destroy$)).subscribe(() => {
              this.spinnerService.hide();
              this.reloadPage(this.protocol.id);
            });
          }, err => {
            if (err != null && err.error != null && typeof err.error === 'string') {
              this.snackBarService.sendError(err.error as string);
            } else {
              this.snackBarService.sendError(this.translate.instant('protocolEdit.form.revertSign.error') as string);
            }
          });
        });
      }
    });

  }

  getDisplayColumns(essays: EssayProtocolAir[]): string[] {
    const res = ['essay'];

    const sortMap = AirUtils.getMapOrderEssay();

    this.columnEssay = [];
    res.push(...essays.sort((e1, e2) => {
      if (!e1.position && !e2.position) {
        const sort1 = sortMap.get(e1.idEssayType) || 99;
        const sort2 = sortMap.get(e2.idEssayType) || 99;
        return sort1 - sort2;
      } else {
        return e1.position - e2.position;
      }
    }).map(e => this.essaytypes?.find(essay => essay.id === e.idEssayType)?.translation).filter(e => e != null));
    this.columnEssay = res;
    return res;
  }

  getEssayName(essayType: number, translate = true): string {
    if (ArrayUtils.isEmpty(this.essaytypes)) {
      return null;
    }

    let res = this.essaytypes.find(essay => essay.id === essayType)?.translation;

    if (translate) {
      res = this.translate.instant('essayAir.' + res) as string;
    }

    return res;
  }

  getCriteriaByEssayName(eq: EquipmentAir | RoomAir, essayName: string): string {
    const essay = eq.essays.find(e => {
      const name = this.getEssayName(e.idEssayType, false);

      return name === essayName;
    });

    return this.getCriteriaOfEssay(essay);
  }

  getCriteriaOfEssay(essay: EssayProtocolAir): string {
    if (essay == null) {
      return '';
    }

    let res = essay.criterias.map(crit => EssayUtils.getCriteriaToShowAir(crit, essay.idEssayType, this.translate)).join('<br />');

    if (StringUtils.isEmpty(res)) {
      res = this.translate.instant('protocolEdit.dialog.essay.fields.empty') as string;
    }

    return res;
  }

  showEquipment(eq: EquipmentAir): string {
    const arr: string[] = [];

    arr.push(eq.maker);
    arr.push(eq.model);
    arr.push(`(${eq.serialNum})`);

    if (StringUtils.isNotEmpty(eq.inventoryNum)) {
      arr.push(`(${eq.inventoryNum})`);
    }

    return arr.join(' ');
  }

  prepareToSign(): void {
    const errores = this.getErrorsSign();

    if (errores.length !== 0) {
      this.snackBarService.sendError(errores.join('\n'));
    } else {
      this.automaticSign();
    }
  }

  cleanIdRooms(room: RoomAir): RoomAir {

    room.id = null;
    room.filters.forEach(filter => {
      filter.id = null;
      filter.idRoom = null;
    });
    room.volumes.forEach(volume => {
      volume.id = null;
      volume.idRoom = null;
    })
    room.essays.forEach(essay => {
      essay.id = null;
      essay.idRoom = null;

      essay.criterias.forEach(criteria => criteria.id = null);
      essay.essayValues.forEach(field => field.id = null);
    });

    return room;
  }

  cleanIdEquipments(equipment: EquipmentAir): EquipmentAir {

    equipment.id = null;
    equipment.filters.forEach(filter => {
      filter.id = null;
      filter.idRoom = null;
    });
    equipment.accessories.forEach(accessory => {
      accessory.id = null;
      accessory.idEquipment = null;
    });
    equipment.essays.forEach(essay => {
      essay.id = null;
      essay.idEquipment = null;

      essay.criterias.forEach(criteria => criteria.id = null);
      essay.essayValues.forEach(field => field.id = null);
    });

    return equipment;
  }

  cleanIdCompressedGas(compressedGas: CompressedGas): CompressedGas {

    compressedGas.id = null;
    compressedGas.essays.forEach(essay => {
      essay.id = null;
      essay.idRoom = null;

      essay.criterias.forEach(criteria => criteria.id = null);
      essay.essayValues.forEach(field => field.id = null);
    });

    return compressedGas;
  }

  moveToTopEquipment(areaIndex: number, index: number): void {
    event.stopPropagation();

    const equipments = this.protocol.areas[areaIndex].equipments;
    ArrayUtils.moveToFirst(equipments, index);

    this.protocol.areas[areaIndex].equipments = this.reorderEquipment(equipments);

    this.equipmentTab = 0;
  }

  movePreviousEquipment(areaIndex: number, index: number): void {
    event.stopPropagation();

    const equipments = this.protocol.areas[areaIndex].equipments;
    ArrayUtils.moveToPrevious(equipments, index);

    this.protocol.areas[areaIndex].equipments = this.reorderEquipment(equipments);

    this.equipmentTab = index - 1;
  }

  moveNextEquipment(areaIndex: number, index: number): void {
    event.stopPropagation();

    const equipments = this.protocol.areas[areaIndex].equipments;
    ArrayUtils.moveToNext(equipments, index);

    this.protocol.areas[areaIndex].equipments = this.reorderEquipment(equipments);

    this.equipmentTab = index + 1;
  }

  moveToBottomEquipment(areaIndex: number, index: number): void {
    event.stopPropagation();

    const equipments = this.protocol.areas[areaIndex].equipments;
    ArrayUtils.moveToLast(equipments, index);

    this.protocol.areas[areaIndex].equipments = this.reorderEquipment(equipments);

    this.equipmentTab = equipments.length - 1;
  }
   
  dropEquipment(event: CdkDragDrop<EssayProtocolAir[]>, indexArea: number, indexEquipment: number): void {
    let equipment = this.protocol.areas[indexArea].equipments[indexEquipment];

    this.protocolService.moveEssayProtocol(equipment.id, 1, event.previousIndex, event.currentIndex)
    .subscribe((res: MovePositionEssay[]) => {
      this.protocol.areas[indexArea].equipments[indexEquipment].essays.forEach(e => {
        const resFilter = res.find(fe => fe.idTestType === e.idEssayType);
        e.position = resFilter.position;
        moveItemInArray(this.columnEssay, event.previousIndex, event.currentIndex);
        this.filtersTableEq.renderRows();
      });
    });
  }

  dropRoomEssay(event: CdkDragDrop<EssayProtocolAir[]>, indexArea: number, indexRoom: number): void {
    let room = this.protocol.areas[indexArea].rooms[indexRoom];
    this.protocolService.moveEssayProtocol(room.id, 2,event.previousIndex, event.currentIndex)
    .subscribe((res: MovePositionEssay[]) => {
      this.protocol.areas[indexArea].rooms[indexRoom].essays.forEach(e => {
        const resFilter = res.find(fe => fe.idTestType === e.idEssayType);
        e.position = resFilter.position;
        moveItemInArray(this.columnEssay, event.previousIndex, event.currentIndex);
        this.filtersTableRoom.renderRows();
      });
    });
  }

  dropcompressedGasesEssay(event: CdkDragDrop<EssayProtocolAir[]>, indexArea: number, indexGas: number): void {
    let compressedGas = this.protocol.areas[indexArea].compressedGases[indexGas];
    this.protocolService.moveEssayProtocol(compressedGas.id, 3, event.previousIndex, event.currentIndex)
    .subscribe((res: MovePositionEssay[]) => {
      this.protocol.areas[indexArea].compressedGases[indexGas].essays.forEach(e => {
        const resFilter = res.find(fe => fe.idTestType === e.idEssayType);
        e.position = resFilter.position;
        moveItemInArray(this.columnEssay, event.previousIndex, event.currentIndex);
        this.filtersTableCGas.renderRows();
      });
    });
  }

  private canSign(): boolean {
    const allowedStatuses = [ProtocolStatus.PENDIENTE_FIRMA];

    return this.protocol != null && allowedStatuses.includes(this.protocol.idStatus);
  }

  private prepareToSave() {
    this.protocol.areas = this.areas;
  }

  private reloadPage(idProtocol = this.protocol.id) {
    if (idProtocol == null) {
      idProtocol = this.protocol.id;
    }

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    void this.router.navigateByUrl(`/air/protocol?id=${idProtocol}`);
  }

  private save() {
    const isNew = this.protocol.id == null;

    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
      if (token != null) {

        this.spinnerService.show();

        // this.prepareToSave();

        this.protocolService.save(this.protocol, token).pipe(takeUntil(this.destroy$)).subscribe((res: ProtocolAir) => {
          this.protocol = res;
          this.reloadPage();

          if (isNew) {
            this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.create.ok') as string);

            void this.router.navigateByUrl(`/air/protocol?id=${res.id}`);
          } else {
            this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.update.ok') as string);
          }

          this.spinnerService.hide();
        }, err => {
          if (err != null && err.error != null && typeof err.error === 'string') {
            this.snackBarService.sendError(err.error as string);
          } else if (isNew) {
            this.snackBarService.sendError(this.translate.instant('protocolEdit.form.create.error') as string);
          } else {
            this.snackBarService.sendError(this.translate.instant('protocolEdit.form.update.error') as string);
          }

          this.spinnerService.hide();
        });

      } else {
        this.spinnerService.hide();
      }
    });

  }

  private requestReason(callback: (reason: string) => void) {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

  private calculateVolume(volumes: Volume[]): number {
    return NumberUtils.round(NumberUtils.fixPrecision(ArrayUtils.sum(volumes.map(v => v.surface * v.height))), 1);
  }

  private calculateSurface(volumes: Volume[]): number {
    return NumberUtils.round(NumberUtils.fixPrecision(ArrayUtils.sum(volumes.map(v => v.surface))), 2);
  }

  private reorderRooms(rooms: RoomAir[]) {
    for (let i = 0; i < rooms.length; i++) {
      const room = rooms[i];
      room.order = i + 1;
    }

    return rooms;
  }

  private reorderCompressedGases(compressedGases: CompressedGas[]) {
    for (let i = 0; i < compressedGases.length; i++) {
      const room = compressedGases[i];
      room.order = i + 1;
    }

    return compressedGases;
  }


  private reorderEquipment(eqs: EquipmentAir[]) {
    for (let i = 0; i < eqs.length; i++) {
      const room = eqs[i];
      room.order = i + 1;
    }

    return eqs;
  }

  private getErrorsSign(): string[] {
    const errores: string[] = [];
    if (ArrayUtils.isEmpty(this.protocol.areas)) {
      errores.push(this.translate.instant('protocolEdit.areas.empty') as string);
    }
    this.protocol.areas.forEach(area => {
      if (ArrayUtils.isEmpty(area.equipments) && ArrayUtils.isEmpty(area.rooms) && ArrayUtils.isEmpty(area.compressedGases)) {
        errores.push(this.translate.instant('protocolEdit.areas.equipment.rooms.empty') as string);
      } else {
        if (!ArrayUtils.isEmpty(area.equipments)) {
          area.equipments.forEach(equipment => {
            if (ArrayUtils.isEmpty(equipment.essays)) {
              const name = this.showEquipment(equipment);

              errores.push(this.translate.instant('protocolEdit.areas.equipment.essay.empty', { name }) as string);
            }
          });
        }
        if (!ArrayUtils.isEmpty(area.rooms)) {
          area.rooms.forEach(room => {
            const name = room.name;
            if (ArrayUtils.isEmpty(room.essays)) {
              errores.push(this.translate.instant('protocolEdit.areas.room.essay.empty', { name }) as string);
            }
          });
        }
        if (!ArrayUtils.isEmpty(area.compressedGases)) {
          area.compressedGases.forEach(gas => {
            const name = gas.code;
            if (ArrayUtils.isEmpty(gas.essays)) {
              errores.push(this.translate.instant('protocolEdit.areas.compressedGas.essay.empty', { name }) as string);
            }
          });
        }
      }
    });

    return errores;
  }

  private cloneRow(id: number, idGroup: number): Promise<void> {

    return new Promise<void>((resolve) => {

      const dialogRefConfirm = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('generic.clone.sign') as string
        }
      });

      dialogRefConfirm.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: boolean) => {
        if (result === true) {

          this.spinnerService.show();

          this.protocolService.checkCopyToGroup(id, idGroup).subscribe((res: GenericWarnError) => {
            this.spinnerService.hide();

            const dialogRef = this.dialog.open(CheckWarnsErrorsComponent, {
              minWidth: '20%',
              maxHeight: '95vh',
              data: {
                warns: res.warns,
                errors: res.errors
              }
            });

            dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((canContinue: boolean) => {
              if (canContinue === true) {
                this.spinnerService.show();
                this.protocolService.copyToGroup(id, idGroup).subscribe(() => {
                  this.spinnerService.hide();

                  this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.clone.ok') as string);
                  resolve();
                }, () => {
                  this.spinnerService.hide();
                  this.snackBarService.sendError(this.translate.instant('protocolEdit.form.clone.error') as string);
                  resolve();
                });
              } else {
                resolve();
              }
            });
          }, () => {
            this.spinnerService.hide();
            this.snackBarService.sendError(this.translate.instant('protocolEdit.form.clone.error') as string);
            resolve();
          });
        } else {
          resolve();
        }
      });

    });
  }

}