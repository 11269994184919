import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { CookieService } from 'ngx-cookie';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { OnlineService } from './online.service';
import { User } from '../model/user';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { RoleUser } from '../model/roleUser';

@Injectable()
export class UserService {

    currentLang: string;

    private usersSubject = new BehaviorSubject<User>(new User());
    private unreadSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public user$ = this.usersSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public unread$ = this.unreadSubject.asObservable();

    constructor(private http: HttpClient, private onlineService: OnlineService, private cookieService: CookieService) {
    }

    public get currentProfile(): User {
        return JSON.parse(localStorage.getItem('profile')) as User;
    }

    public get currentRoleUser(): RoleUser[] {
        const profile = JSON.parse(localStorage.getItem('profile')) as User;
        let distinctRpl: RoleUser[] = [];

        if (profile) {
            const hash = {};
            distinctRpl = profile.profiles.filter(o => hash[o.idGroup] ? false : hash[o.idGroup] = true);
        }
        return distinctRpl;
    }

    profile(): Observable<any> {
        if (!this.onlineService.latestOnline) {
            const username = this.cookieService.get('User');

            const user = new User();
            user.username = username;
            user.fullName = username;
            user.activeRoleTranslation = 'offline';

            return of(user);
        }

        const url = environment.coreUrl + '/profile/';
        const cthis = this;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)), map(this.saveProfile), map(u => {
            cthis.unreadSubject.next(u.unreadNotifications);
            return u;
        }));
    }

    save(user: User): Observable<any> {
        const url = environment.coreUrl + '/profile/';

        const cthis = this;

        return this.http.post(url, user).pipe(map(httpRes => HttpUtils.extractData(httpRes)), map(this.saveProfile), map(u => {
            cthis.unreadSubject.next(u.unreadNotifications);
            return u;
        }));
    }

    updateProfileSidebar(callback?: () => void): void {
        this.profile().subscribe((item: User) => {
            this.setProfileSidebar(item);

            if (callback) {
                callback();
            }
        });
    }

    setProfileSidebar(user: User): void {
        this.usersSubject.next(user);
    }

    changePassword(currentPassword: string, newPassword: string): Observable<any> {
        const url = environment.coreUrl + '/profile/changePassword';

        const data = { currentPassword, newPassword };

        return this.http.post(url, data).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    changeProfile(idGroup: number, idApp: number): Observable<any> {
        const url = environment.coreUrl + '/profile/changeProfile';

        const data = { idGroup, idApp };

        return this.http.post(url, data).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    checkUser(password: string): Observable<any> {
        const url = environment.thermalUrl + '/users/checkUser';

        return this.http.post(url, { password }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    saveProfile(res: User) {
        localStorage.setItem('profile', JSON.stringify(res));

        return res;
    }

    restorePasswordPortal(username: string): Observable<any> {
        const url = environment.coreUrl + `/users/restorePasswordPortal/${username}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
