<div class="main-container small-layout">
  <form>

    <div class="toolbar-list">
      <div class="toolbar-list-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <h1 *ngIf="client != null && client.id != null">
            {{ 'common.edit' | translate }} {{ breadcrumbLabel }}</h1>
          <h1 *ngIf="client == null || client.id == null">
            {{ 'clientEdit.general.newClient' | translate }}</h1>
        </div>
        <div class="breadcrumbs">
          <a href="#">{{ 'common.start.label' | translate }}</a> /
          <a routerLink="/clients">{{ 'clientList.title' | translate }}</a> /
          <span *ngIf="client != null && client.id != null">{{ breadcrumbLabel }}</span>
          <span *ngIf="client == null || client.id == null">{{ 'clientEdit.general.newClient' | translate }}</span>
        </div>
      </div>
      <div class="toolbar-back-button">
        <a (click)="cancel()" mat-button>
          <mat-icon>undo</mat-icon> {{ 'button.back' | translate }}
        </a>
      </div>
    </div>

    <mat-card class="card">
      <mat-card-content>

        <div class="form-block">
          <div class="form-icon">
            <mat-icon>format_size</mat-icon>
          </div>
          <div class="form-content">

            <div fxFlex="50%" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'clientEdit.general.name.label' | translate }}" name="name"
                  [(ngModel)]="client.name" [errorStateMatcher]="matcher" required>
                <mat-error>
                  {{ 'clientEdit.general.name.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-checkbox [(ngModel)]="client.nonPayments" name="nonPayments">
                  {{ 'clientEdit.general.nonPayments.label' | translate }}
              </mat-checkbox>
            </div>
          </div>
          <div class="side-block"></div>
        </div>

        <div class="form-block">
          <div class="form-icon">
            <mat-icon>location_on</mat-icon>
          </div>
          <div class="form-content">

            <div fxFlex="50%" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'clientEdit.general.address.label' | translate }}" name="address"
                  [(ngModel)]="client.address" [errorStateMatcher]="matcher" required>
                <mat-error>
                  {{ 'clientEdit.general.address.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'clientEdit.general.city.label' | translate }}" name="city"
                  [(ngModel)]="client.city" [errorStateMatcher]="matcher">
                <mat-error>
                  {{ 'clientEdit.general.city.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'clientEdit.general.postalCode.label' | translate }}" name="postalCode"
                  [(ngModel)]="client.postalCode" [errorStateMatcher]="matcher">
                <mat-error>
                  {{ 'clientEdit.general.postalCode.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'clientEdit.general.state.label' | translate }}" name="state"
                  [(ngModel)]="client.state" [errorStateMatcher]="matcher">
                <mat-error>
                  {{ 'clientEdit.general.state.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'clientEdit.general.country.label' | translate }}" name="country"
                  [(ngModel)]="client.country" [errorStateMatcher]="matcher">
                <mat-error>
                  {{ 'clientEdit.general.country.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

          </div>
          <div class="side-block"></div>
        </div>

        <div class="form-block" *ngIf="client != null && client.id != null">
          <div class="form-icon">
            <mat-icon>camera_alt</mat-icon>
          </div>
          <div class="form-content">

            <div fxFlex="50" fxFlex.lt-md="100%" class="top-padding" fxLayout="column">

              <mat-slide-toggle *ngIf="image != null" [(ngModel)]="client.showLogo" name="showLogo" [checked]="true"
                [disabled]="false">
                {{ 'clientEdit.logo.showLogo' | translate }}
              </mat-slide-toggle>

              <div [ngClass]="image != null ? 'top-padding' : ''">
                <button mat-raised-button (click)="uploadLogo.click()" class="stroked-primary">
                  <mat-icon>cloud_upload</mat-icon>
                  <span *ngIf="image == null"> {{ 'button.uploadLogo' | translate }} </span>
                  <span *ngIf="image != null"> {{ 'button.uploadNewLogo' | translate }} </span>
                </button>
              </div>

              <input accept="image/*" hidden type="file" (change)="onLogoUpload($event)" #uploadLogo>

            </div>

            <div fxFlex="50" fxFlex.lt-md="100%" class="top-padding">
              <img class="companyLogo" [src]="showImage()">
            </div>

          </div>
          <div class="side-block"></div>
        </div>

        <div class="form-block" *ngIf="showGroupToClone()">
          <div class="form-icon">
            <mat-icon>folder_copy</mat-icon>
          </div>

          <div class="form-content">
            <div fxFlex="50" fxFlex.lt-md="100%" class="top-padding" fxLayout="column">
              <mat-form-field class="campoFormulario" style="margin-top: -1em;">
                <mat-label> {{ 'clientEdit.group' | translate }} </mat-label>
                <mat-select #groupSelect name="idGroup" [(ngModel)]="client.idGroup" required>
                  <mat-option [value]="null"> -- </mat-option>
                  <mat-option *ngFor="let group of groups" [value]="group.id">
                    {{ group.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="side-block"></div>
        </div>

      </mat-card-content>
      <mat-toolbar color="secondary">
        {{ 'clientEdit.contact.person.title' | translate }}

        <button mat-icon-button (click)="openDialogPerson()">
            <mat-icon>add</mat-icon>
        </button>
    </mat-toolbar>
      <mat-card-content>
            
            <div fxFlex="100%" fxFlex.lt-md="100%" class="top-padding">
              <ul>
                <li *ngFor="let person of client.persons; let indexValue = index">
                  <div fxLayout="row">
                    <div fxFlex="10">
                      {{ person.name }}
                    </div>
                    <div fxFlex="10">
                      {{ person.username }}
                    </div>
                    <div fxFlex="10">
                      <div *ngIf="person.preferential"> "Preferente"</div>
                    </div>
                    <div fxFlex>
                      <button mat-icon-button (click)="openDialogEditPerson(indexValue)" matTooltip="{{ 'button.edit' | translate }}">
                        <mat-icon>mode_edit</mat-icon>
                      </button>
                    
                      <button mat-icon-button (click)="deleteRow(indexValue)" [disabled]="disableOfflineButton"
                      matTooltip="{{ 'button.delete' | translate }}">
                      <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-icon-button (click)="restorePassword(indexValue)" matTooltip="{{ 'button.restore' | translate }}">
                      <mat-icon>settings_backup_restore</mat-icon>
                    </button>
                    </div>
                  </div>
              </li>
              </ul>
            </div>
      </mat-card-content>
      <mat-card-actions>
        <div fxLayoutAlign="start center" class="form-footer-action">
          <button mat-raised-button color="primary" (click)="saveClient()">{{ 'button.save' | translate }}</button>
          <button mat-raised-button *ngIf="client.id != null" (click)="downloadAuditPdf()">
            {{ 'button.download-audit' | translate }}</button>
          <button mat-button color="secondary" (click)="cancel()">
            <mat-icon>clear</mat-icon> {{ 'button.cancel' | translate }}
          </button>
        </div>
      </mat-card-actions>
    </mat-card>

  </form>
</div>