import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ProcedureFilter } from '../../../../model/procedure';

@Component({
  selector: 'app-procedure-list-filter',
  templateUrl: './procedure-list-filter.component.html',
  styleUrls: ['./procedure-list-filter.component.scss']
})
export class ProcedureListFilterComponent implements OnInit {

  @Output() filterEmitter = new EventEmitter<ProcedureFilter>();

  form: UntypedFormGroup;
  filter: ProcedureFilter;
  expandedFilter = true;
  private destroy$ = new Subject<void>();

  constructor(fb: UntypedFormBuilder) {
    this.cleanFilter();

    this.form = fb.group({
      name: [this.filter.name],
      description: [this.filter.description]
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  doFilter(): void {
    this.expandedFilter = false;

    this.filterEmitter.emit(this.filter);
  }

  cleanFilter(): void {
    this.filter = new ProcedureFilter();
  }

}
