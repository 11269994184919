import { AccessoryEquipment } from './accessoryEquipment';
import { EssayProtocolAir } from './essayProtocolAir';
import { FilterAir } from './filterAir';
import { StringUtils } from '../utils/stringUtils';

export class Equipment {

    id: number;
    equipment: string;
    maker: string;
    model: string;
    serialNum: string;
    location: string;
    internalId: string;
    idEquipment: number;
    idType: number;
}

export class EquipmentExecution {

    id: number;
    equipment: string;
    maker: string;
    model: string;
    serialNum: string;
    location: string;
    internalId: string;
    idType: number;

    equipmentProtocol: string;
    makerProtocol: string;
    modelProtocol: string;
    serialNumProtocol: string;
    locationProtocol: string;
    internalIdProtocol: string;
    idTypeProtocol: number;

    reasonChange: string;
    showDiffInDeviations: boolean;
    showDiffInObservations: boolean;

    fromProtocol: boolean;

    regUser: string;
	modUser: string;
	regFc: Date;
	modFc: Date;
    idEquipment: number;
}

export class EquipmentAir {
    id: number;
    idArea: number;

    idType: number;
    maker: string;
    model: string;
    serialNum: string;
    internalId: string;
    room: string;
    inventoryNum: string;
    idOffline:string;

    order: number;

    idTypeProtocol: number;
    makerProtocol: string;
    modelProtocol: string;
    serialNumProtocol: string;
    locationProtocol: string;
    roomProtocol: string;
    inventoryNumProtocol: string;

    orderProtocol: number;

    fromProtocol: boolean;
    reasonChange: string;
    showDiffInDeviations = true;
    showDiffInObservations: boolean;

    alarmType: string;
    equipmentStatus: string;
    workingTime: string;
    variatorPosition: string;
	regUser: string;
	modUser: string;
	regFc: Date;
	modFc: Date;
    essays: EssayProtocolAir[] = [];
    filters: FilterAir[] = [];
    accessories: AccessoryEquipment[] = [];

    show(): string {
        const arr: string[] = [];

        arr.push(this.maker);
        arr.push(this.model);
        arr.push(`(${this.serialNum})`);

        if (StringUtils.isNotEmpty(this.inventoryNum)) {
            arr.push(`(${this.inventoryNum})`);
        }

        return arr.join(' ');
    }
}
