/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalStateService {

  private homeAirStateSubject = new BehaviorSubject<string>('default-state');
  private homeThermalStateSubject = new BehaviorSubject<string>('default-state');
  private homeCalibratesStateSubject = new BehaviorSubject<string>('calibrates-state');
  calibratesState$ = this.homeCalibratesStateSubject.asObservable();

  private tabStates: Record<string, BehaviorSubject<any>> = {};

  private selectedComponentSubject = new BehaviorSubject<string>('');

  // eslint-disable-next-line @typescript-eslint/member-ordering
  selectedComponent$: Observable<string> = this.selectedComponentSubject.asObservable();

  constructor() {
  }

  updateTabState(tabId: string, newState: any): void {
    if (!this.tabStates[tabId]) {
      this.tabStates[tabId] = new BehaviorSubject<any>({});
    }

    const currentTabState = this.tabStates[tabId].getValue();
    this.tabStates[tabId].next({ ...currentTabState, ...newState });
  }

  getTabState(tabId: string): Observable<any> {
    if (!this.tabStates[tabId]) {
      this.tabStates[tabId] = new BehaviorSubject<any>({});
    }

    return this.tabStates[tabId].asObservable();
  }

  setHomeAirState(newState: string): void {
    this.homeAirStateSubject.next(newState);
  }

  getHomeAirState(): Observable<string> {
    return this.homeAirStateSubject.asObservable();
  }

  setHomeThermalState(newState: string): void {
    this.homeThermalStateSubject.next(newState);
  }

  getHomeThermalState(): Observable<string> {
    return this.homeThermalStateSubject.asObservable();
  }


  getHomeCalibratesState(): Observable<string> {
    return this.homeCalibratesStateSubject.asObservable();
  }

  setHomeCalibratesState(newState: string): void {
    this.homeCalibratesStateSubject.next(newState);
  }
}
